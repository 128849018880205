<div class="main-content">
  <!-- Sessão de titulo, onde há um resumo de clientes, cameras ativas e detecções ativas -->
  <div class="header">
    <h2 class="style-title">Lista de Parceiros</h2>
    <div class="style-title-data" *ngIf="!loading && !isMaster">
      <span>
        Clientes: {{partners.clients_total_amount}}
      </span>
      <span>
        Estoque de Câmeras: {{cameras.used}}/{{cameras.total}}
      </span>
      <span>
        Estoque de Detecções: {{analytics.used}}/{{analytics.total}}
      </span>
    </div>
    <div class="style-title-data" *ngIf="!loading && isMaster">
      <span>
        Clientes: {{partners.clients_total_amount}}
      </span>
      <span>
        Câmeras usadas: {{cameras.used}}
      </span>
      <span>
        Detecções usadas: {{analytics.used}}
      </span>
    </div>
  </div>
  <!-- Sessão onde se encontra o filtro de parceiros e o botão de adicionar parceiro -->
  <div class="section">
    <div class="search-input">
      <input type="text" placeholder="Pesquisar na lista de parceiros"
          [formControl]="searchInputControl" (keyup)="requestSearch()">

      <i nz-icon nzType="search" nzTheme="outline" class="clickable" (click)="requestSearch()"></i>
    </div>
    <button class="btn btn-primary add-button" (click)="addPartner()" *ngIf="!isAssociate || adminAssociate">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Adicionar parceiro
    </button>
  </div>
  <!-- Sessão de listagem de parceiros -->
  <table mat-table [dataSource]="dataSource" class="style-table">
    <!-- Nome do parceiro -->
    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef>Parceiro</th>
      <td mat-cell *matCellDef="let element"> {{element.partner_name}} </td>
    </ng-container>
    <!-- Email do parceiro -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <!-- Quantidade de clientes -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Clientes</th>
      <td mat-cell *matCellDef="let element"> <span>{{element.clients_amount}}</span> </td>
    </ng-container>
    <!-- Quantidade de Cameras -->
    <ng-container matColumnDef="camera">
      <th mat-header-cell *matHeaderCellDef>Câmeras</th>
      <td mat-cell *matCellDef="let element"> <span>{{element.cameras_total}}</span> </td>
    </ng-container>
    <!-- Quantidade de Analiticos -->
    <ng-container matColumnDef="analytic">
      <th mat-header-cell *matHeaderCellDef>Detecções<br>inteligentes</th>
      <td mat-cell *matCellDef="let element"> <span>{{element.analytics_total}}</span> </td>
    </ng-container>
    <!-- Detalhes -->
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <i nz-icon (click)="viewPartner(element)" nzType="right" nzTheme="outline" class="details"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable" (click)="viewPartner(row)"></tr>
  </table>
  <!-- Loading enquanto a requisição de listagem de parceiros não chega -->
  <div *ngIf="loading" class="loading_container">
    <span nz-icon class="center" nzType="loading" nzTheme="outline"></span>
  </div>
  
  <app-feedback imageUrl="no_client.svg" *ngIf="!loading && no_table" [noUsers]="true" type="parceiros" ></app-feedback>
  
</div>

<app-modal></app-modal>