<div class="header-modal">
  <span class="title-modal">EDITAR CLIENTE</span>
  <div (click)="closeModal()" class="close-modal">
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </div>
</div>

<div class="form-container">
  <form [formGroup]="editForm">

    <div class="input-container">
      <mat-form-field class="label-container">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error *ngIf="editForm.get('name').hasError('required')">
          Nome <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row input-container">
      <mat-form-field class="label-container col-5">
        <mat-label>CPF/CNPJ</mat-label>
        <input type="text" matInput mask="CPF_CNPJ" formControlName="cpf_cnpj" readonly>
        <mat-error *ngIf="editForm.get('cpf_cnpj').hasError('required')">
          CPF/CNPJ <strong>é requerido<br></strong>
        </mat-error>
        <mat-error *ngIf="editForm.get('cpf_cnpj').hasError('CPFInvalido')">
          CPF <strong>inválido</strong>
        </mat-error>
        <mat-error *ngIf="editForm.get('cpf_cnpj').hasError('CNPJInvalido')">
          CNPJ <strong>inválido</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="label-container col-5">
        <mat-label>Celular</mat-label>
        <input type="text" mask="(00) 0000-0000||(00) 0 0000-0000" formControlName="cell">
        <mat-error *ngIf="editForm.get('cell').hasError('required')">
          Celular <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field class="label-container">
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="email" readonly>
        <mat-error *ngIf="editForm.get('email').hasError('email') && !editForm.get('email').hasError('required')">
          Por favor digite um formato de e-mail válido
        </mat-error>
        <mat-error *ngIf="editForm.get('email').hasError('required')">
          Email <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field class="label-container">
        <mat-label>Alterar senha</mat-label>
        <input type="password" matInput formControlName="password" (change)="checkPassword()">
        <mat-error *ngIf="editForm.get('password').hasError('required')">
          Senha <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field class="label-container">
        <mat-label>Confirmar senha</mat-label>
        <input type="password" matInput formControlName="confirm" onpaste="return false;" (change)="checkPassword()">
        <mat-error *ngIf="editForm.get('confirm').hasError('required')">
          Confirmar senha <strong>é requerido<br></strong>
        </mat-error>
        <mat-error *ngIf="editForm.get('confirm').hasError('unequal')">
          Senhas <strong>não conferem!</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-container">
      <div class="label-container slider">
        <span class="slider-text">Central de monitoramento</span>
        <mat-slide-toggle formControlName="monitoringCenter"></mat-slide-toggle>
      </div>
    </div>

    <div class="input-container" style="margin-top: 15px">
      <div class="label-container slider" [hidden]="!editForm.get('monitoringCenter').value">
        <span class="slider-text">Botão de pânico</span>
        <mat-slide-toggle formControlName="panicButton"></mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="editForm.get('monitoringCenter').value" class="row justify-content-start" style="margin-top: 22px; margin-left: 9%">
      <span class="label-container" style="font-size: 14px; color: #333;">Contato de emergência</span>
    </div> 
    <div [hidden]="!editForm.get('monitoringCenter').value" class="row input-container">
      <mat-form-field class="label-container col-5">
        <mat-label>Nome</mat-label>
        <input type="text" matInput formControlName="contactEmergency" (change)="validateEmergencyContact()">
        <mat-error *ngIf="editForm.get('contactEmergency').hasError('required')">
          Nome <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="label-container col-5">
        <mat-label>Celular</mat-label>
        <input type="text" matInput formControlName="phoneEmergency" mask="(00) 0000-0000||(00) 0 0000-0000"
              (change)="validateEmergencyNumber()">
        <mat-error *ngIf="editForm.get('phoneEmergency').hasError('required')">
          Celular <strong>é requerido</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="row form-container input-container">
  <button class="btn btn-primary" (click)="onEdit()" *ngIf="!loading">
    SALVAR
  </button>
  <div *ngIf="loading">
    <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
  </div>

  <button class="btn btn-secondary" (click)="closeModal()" style="margin-left: 2rem;">
    CANCELAR
  </button>

</div>