import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Plan } from 'app/cameras/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { Kit, KitAmount, UserInventory } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-add-kit-form',
    templateUrl: './user-add-kit-form.component.html',
    styleUrls: ['./user-add-kit-form.component.scss']
})
export class UserAddKitFormComponent implements OnInit, OnDestroy {
    readonly transferItems$ = this.store.select((state: AppState) => state.Users.transferItemsFeedback);
    readonly kits$ = this.store.select((state: AppState) => state.Users.kitsList);

    @Output() modalClose = new EventEmitter<string>();

    hide = true;
    transferItems: Subscription;
    kits: Subscription;
    inventory: UserInventory;
    kitAmount: KitAmount[];
    buyers_sub: string;
    optionsInventory: any;

    editForm = new UntypedFormGroup({
        amount: new UntypedFormControl('', [Validators.required]),
        kit: new UntypedFormControl('', [Validators.required]),
    });

    loadingSubmit: boolean;
    loadingKits: boolean;
    amountLimit: number;
    isMaster: boolean;
    kitList: Kit[];
    plans: Plan[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private router: Router) { }

    ngOnInit(): void {
        this.transferItemsResult();
        this.inventory = this.data.data.userInventory;
        this.buyers_sub = this.data.data.buyers_sub;
        this.kitAmount = this.data.data.user_storage;
        this.isMaster = this.data.data.master;
        this.plans = this.data.data.plans;

        if (this.isMaster) {
            this.getKits();
        } else {
            this.optionsInventory = this.processList(this.filterValidKits());
        }
    }

    getKits() {
        this.loadingKits = true;
        this.store.dispatch(UserActions.get_kits({
            sub: localStorage.getItem('sub')
        }));
        this.getKitsResult();
    }

    getKitsResult() {
        this.kits = this.kits$.subscribe(kit => {
            if (kit) {
                this.kitList = kit;
                this.optionsInventory = this.processListMaster(this.filterValidKits());
                setTimeout(() => {
                    this.loadingKits = false;
                }, 2000);
            }
        });
    }

    ngOnDestroy() {
        if (this.transferItems) {
            this.transferItems.unsubscribe();
        }
        if (this.kits) {
            this.kits.unsubscribe();
        }

        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    closeModal() {
        this.modalClose.emit();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['users/partner/view']);
    }

    submitForm() {
        this.loadingSubmit = true;
        let errorPass = false;

        if (this.editForm.get('kit').invalid) {
            errorPass = true;
            this.editForm.get('kit').markAsTouched();
        }
        if (this.editForm.get('amount').invalid) {
            errorPass = true;
            this.editForm.get('amount').markAsTouched();
        }

        if (!errorPass) {
            this.store.dispatch(UserActions.transfer_items({
                user_sub: localStorage.getItem('sub'),
                buyers_sub: this.buyers_sub,
                kits: [{
                    kit_id: Number(this.editForm.get('kit').value),
                    amount: Number(this.editForm.get('amount').value)
                }],
                cameras: null,
                analytics_amount: null
            }));
        } else {
            this.loadingSubmit = false;
        }
    }

    transferItemsResult() {
        this.transferItems = this.transferItems$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'transfer-items', result.message);
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'transfer-items-fail', result.message);
                    this.store.dispatch(UserActions.cleanup());
                }
            }
        });
    }

    filterValidKits() {
        if (this.isMaster) {
            return this.kitList;
        }
        return this.inventory.kits.filter(kit => (kit.available && this.isOnStock(kit.id)));
    }

    isOnStock(id: number) {
        let stock = 0;
        this.kitAmount.forEach(kitAmount => {
            if (kitAmount.kit_id === id) {
                stock = kitAmount.amount;
            }
        });

        return (stock > 0);
    }

    setLimit() {
        this.kitAmount.forEach(kitAmount => {
            if (kitAmount.kit_id === Number(this.editForm.get('kit').value)) {
                this.amountLimit = kitAmount.amount;
            }
        });
    }

    checkLimit() {
        if (this.editForm.get('amount').value == '') {
            this.editForm.get('amount').setErrors({'required': true});
        } else if (!this.isMaster && (Number(this.editForm.get('amount').value) > (this.amountLimit))) {
            this.editForm.get('amount').setErrors({'invalid': true});
        } else {
            this.editForm.get('amount').setErrors(null);
        }
    }

    processListMaster(list) {
        if (this.isMaster && list) {
            return list.map(e => {
                const planDetails = this.getPlanDetails(e.plan_id);
                const d = `${e.cameras_amount} ${this.processPlural('câmera', e.cameras_amount)} / ${e.analytics_amount} ${this.processPlural('analítico', e.analytics_amount)}`;
                return {
                    id: e.id,
                    title: `Câmera ${planDetails.resolution.alias} - ${planDetails.days_storage} ${this.processPlural('dia', planDetails.days_storage)}`,
                    description: d
                };
            });
        }
    }

    processList(list) {
        return list.map(e => {
            return {
                id: e.id,
                title: `Câmera ${e.plan.resolution.alias} - ${e.plan.days_storage} ${this.processPlural('dia', e.plan.days_storage)}`,
                description: `${e.cameras_amount} ${this.processPlural('câmera', e.cameras_amount)} / ${e.analytics_amount} ${this.processPlural('analítico', e.analytics_amount)}`
            };
        });
    }

    processPlural(text, number) {
        return text + (number > 1 ? 's' : '');
    }

    changeKit(event) {
        this.editForm.get('kit').setValue(event[0]);
    }

    getPlanDetails(plan_id) {
        return this.plans.find(element => {
            return element.id === plan_id;
        });
    }
}
