<div class="main-content">

    <div class="container-fluid">
        <div class="flex add_cam">
            <span class="style-title2" (click)="toPreviousPath()">{{previousRouter}}</span>
            <i class="material-icons">arrow_forward_ios</i>
            <span class="style-title">Adicionar Câmeras</span>
        </div>
    </div>

    <nz-steps id="stepsDiv" [nzCurrent]="currentStep">
        <nz-step class="step-container" *ngFor="let step of activeSteps; index as stepNumber"
        [ngClass]="{'active': stepNumber == currentStep, 'visited': stepNumber < currentStep}" [nzTitle]="step"></nz-step>
    </nz-steps>

    <div id="pressEnter" class="stepsContent">
        <!-- Importação -->
        <div id="first-step" *ngIf="currentStep === 0">
            <span class="step-title">Adicione um arquivo com as informações das câmeras</span>
            <span class="step-subtitle">O arquivo deve ter o formato CSV</span>

            <div class="link-container">
                <span class="link-name">Arquivo CSV 
                    <i
                    class="tooltip-icon"
                    nz-icon
                    nzType="question-circle" 
                    nzTheme="outline"
                    nz-popover
                    [nzPopoverContent]="csvTooltip"
                    nzPopoverTrigger="hover"
                    nzPopoverPlacement="right"
                    nzPopoverMouseLeaveDelay="0.5"
                    nzPopoverOverlayClassName="tooltip-container">
                </i> :</span>
                <div class="card" *ngIf="fileName != ''">
                    <i nz-icon nzType="file" nzTheme="outline"></i>
                    <span class="title">{{fileName}}</span>
                    <span class="close" (click)="deleteUploadCSV()"><span nz-icon nzType="close" nzTheme="outline"></span></span>
                </div>
                <span class="import-button" (click)="fileCSVbutton.click()" [class.disabled]="sendList">
                    <input #fileCSVbutton type="file" id="csv" style="display:none" onclick="this.value=null;" value=""
                        (change)="changeListener($event);" accept=".csv">
                    <i nz-icon nzType="download" nzTheme="outline"></i> Importar arquivo</span>
            </div>

            <span class="cam-values" *ngIf="(currentStep - 1) == 0">{{read_csv.length}} câmeras encontradas</span>

            <button class="btn next-button btn-primary" (click)="goToNextStep()" [disabled]="!sendList">Próximo</button>
        </div>
        <!-- Cadastro -->
        <div id="second-step" *ngIf="currentStep === 1">
            <span class="step-title">Cadastrando as câmeras</span>
            <span class="step-subtitle">Estamos importando as informações das suas câmeras</span>
            <div class="link-container">
                <div>
                    <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="!finishCams"></i>
                    <i nz-icon nzType="check-circle" nzTheme="fill" class="posfix" *ngIf="finishCams"></i>
                </div>
                <div class="message-loading">
                    <span class="message-one" *ngIf="!finishCams">Verificando informações das câmeras</span>
                    <span class="message-one" *ngIf="finishCams">Câmeras verificadas</span>
                    <span class="message-two" *ngIf="!finishCams">Estamos verificando a conexão das suas câmeras, isso pode levar alguns minutos.</span>
                    <span class="message-two" *ngIf="finishCams">Todas as suas câmeras foram verificadas</span>
                </div>
            </div>
            <div class="cam-loading">
                <nz-table #basicTable [nzData]="read_csv" class="table style-table antd-table" [nzTableLayout]="'auto'"
                [nzFrontPagination]="'false'" showTitle="false" [nzPaginationPosition]="'both'" [nzSize]="'small'">
                    <thead>
                      <tr>
                        <th>Câmera</th>
                        <th>Validação de FPS e resolução</th>
                        <th>Validação de streaming</th>
                        <th>Finalização do cadastramento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of basicTable.data">
                        <td class="text">{{data.data.alias}}</td>
                        <td>
                            <i nz-icon nz-popover [nzPopoverContent]="data.requests.plan.message" [nzType]="data.requests.plan.status"
                            nzTheme="outline"></i>
                        </td>
                        <td>
                            <i nz-icon nz-popover [nzPopoverContent]="data.requests.create.message" [nzType]="data.requests.create.status"
                            nzTheme="outline"></i>
                        </td>
                        <td>
                            <i nz-icon nz-popover [nzPopoverContent]="data.requests.thumbnail.message" [nzType]="data.requests.thumbnail.status"
                            nzTheme="outline"></i>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
            </div>
            <button class="btn next-button btn-primary" [disabled]="!finishCams" (click)="toPreviousPath()">Concluir</button>
        </div>
    </div>
</div>

<ng-template #csvTooltip>
    <div class="tooltip-text">
        <p>
            O arquivo deve manter a seguinte sequência de colunas com os respectivos títulos:
        </p>
        <p>
            Coluna A (<strong>Nome da Câmera</strong>),<br>Coluna B (<strong>URL</strong>),
            <br>Coluna C (<strong>Plano da Câmera</strong>),<br>Coluna D (<strong>CEP</strong>),<br>
            Coluna E (<strong>COMPLEMENTO</strong>),<br>Coluna F (<strong>RUA</strong>),
            <br>Coluna G (<strong>INDICAR CÂMERAS OFF-LINE</strong>).
        </p>
        <span>
            Baixe aqui o template da planilha:
            <br>
            <a href="../assets/csv/Template.xlsx" download>Link</a>
        </span>
    </div>
</ng-template>
