<div [ngClass]="{'main-content-clientView': clientView,
                 'main-content-cameraList': isClient,
                 'main-content background' : !(clientView || isClient)}">
  <div *ngIf="!(clientView || isClient)" class="container-header">
    <span class="style-title">
      Grupo de Câmeras
    </span>
  </div>

  <div *ngIf="!(clientView || isClient)" class="first-row">
    <div class="selector-container">
      <app-filter [list]="filterOptions" 
      [selected]="1"
      [type]="'simple'"
      (filterStatusChange)="changeFilter($event)" *ngIf="hasGroups"></app-filter>
    </div>
    <div [hidden]="(clientView || isClient) || commonAssociate" class="add-group">
      <button class="btn btn-primary add-button" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
        <i nz-icon nzType="plus" nzTheme="outline"></i> CRIAR GRUPO
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="dropdown-menu-group">
          <li nz-menu-item class="drop-group" (click)="addGroupCamera()"><i nz-icon nzType="user" nzTheme="outline"></i>Grupo privado</li>
          <li nz-menu-item class="drop-group" (click)="addGroupCamera(true)"><i nz-icon nzType="global" nzTheme="outline"></i>Grupo público</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>

  <ng-container *ngIf="hasGroups && !loading">
    <table [ngClass]="{'table-client-view': clientView}" 
    mat-table [dataSource]="dataSource" class="style-table">
  
      <ng-container matColumnDef="camera">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="!(clientView || isClient)">Câmera</span>
          <span *ngIf="(clientView || isClient)">Grupo</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.cameras_info_group.cameras.length == 1">
            <img class="style-thumbnail-one" src="{{element.cameras_info_group.cameras[0].thumbnail}}" (error)="brokenImageHandler($event)">
          </ng-container>
          <ng-container *ngIf="element.cameras_info_group.cameras.length == 2">
            <div class="gallery">
              <figure class="gallery__item gallery__item--1">
                <img src="{{element.cameras_info_group.cameras[0].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--2">
                <img src="{{element.cameras_info_group.cameras[1].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
            </div>
          </ng-container>
          <ng-container *ngIf="element.cameras_info_group.cameras.length == 3">
            <div class="gallery">
              <figure class="gallery__item gallery__item--3">
                <img src="{{element.cameras_info_group.cameras[0].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--4">
                <img src="{{element.cameras_info_group.cameras[1].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--5">
                <img src="{{element.cameras_info_group.cameras[2].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
            </div>
          </ng-container>
          <ng-container *ngIf="element.cameras_info_group.cameras.length == 4">
            <div class="gallery">
              <figure class="gallery__item gallery__item--6">
                <img src="{{element.cameras_info_group.cameras[0].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--7">
                <img src="{{element.cameras_info_group.cameras[1].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--8">
                <img src="{{element.cameras_info_group.cameras[2].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--9">
                <img src="{{element.cameras_info_group.cameras[3].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
            </div>
          </ng-container>
          <ng-container *ngIf="element.cameras_info_group.cameras.length >= 5">
            <div class="gallery">
              <figure class="gallery__item gallery__item--6">
                <img src="{{element.cameras_info_group.cameras[0].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--7">
                <img src="{{element.cameras_info_group.cameras[1].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--8">
                <img src="{{element.cameras_info_group.cameras[2].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
              </figure>
              <figure class="gallery__item gallery__item--10">
                <img src="{{element.cameras_info_group.cameras[3].thumbnail}}" class="gallery__img" (error)="brokenImageHandler($event)">
                <div class="overlay-text">
                  <span>+{{element.cameras_info_group.cameras.length - 4}}</span>
                </div>
              </figure>
            </div>
          </ng-container>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="!(clientView || isClient)">Grupo</span>
        </th>
        <td mat-cell *matCellDef="let element">{{element.alias}}</td>
      </ng-container>
  
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span class="public-label" *ngIf="element.is_public"><i nz-icon nzType="global" nzTheme="outline"></i>Público</span>
          <span class="private-label" *ngIf="!element.is_public"><i nz-icon nzType="user" nzTheme="outline"></i>Privado</span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Clientes</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngFor="let client of element.clients_info_group | slice:0:4; let i = index">
            {{client.name}}<ng-container *ngIf="i < 3 && i != (element.clients_info_group.length - 1)">, </ng-container><ng-container *ngIf="i == 3">...</ng-container>
          </ng-container>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef>Usuários</th>
        <td mat-cell *matCellDef="let element">
          {{element.clients_info_group.length + element.associateds_info_group?.length}}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button class="btn btn-just-icon btn-round btn-settings" [disabled]="!allowSettings" (click)="configGroupCamera(element)">
            <i nz-icon nzType="setting" nzTheme="outline"></i>
          </button>
          <i nz-icon nzType="right" nzTheme="outline" class="details" (click)="openGroupView(element)"></i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-container>

  <app-feedback imageUrl="nogroupcameras.png" context="group-list" type="grupo de câmeras" *ngIf="!hasGroups && !loading" [noGroupCameras]="true"></app-feedback>

  <div *ngIf="loading" class="loading_container">
    <span nz-icon  nzType="loading" nzTheme="outline"></span>
  </div>
</div>

<app-modal *ngIf="!(clientView || isClient)"></app-modal>