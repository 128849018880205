import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoginActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { GoogleAnalyticsService } from 'app/Shared/Services/google-analytics.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';

@Component({
    selector: 'app-confirm-register',
    templateUrl: './confirm-register.component.html',
    styleUrls: ['./confirm-register.component.scss']
})
export class ConfirmRegisterComponent implements OnInit, OnDestroy {
    readonly result$ = this.store.select((state: AppState) => state.login.confirmResult);
    code = new UntypedFormControl('', [Validators.required]);
    loading = false;
    loadingSend = false;
    profileSub: Subscription;
    confirmSub: Subscription;
    constructor(private router: Router, private readonly store: Store<AppState>, private analytics: GoogleAnalyticsService,
        private validationResponse: ValidationResponseHandlerModule) { }

    msgSuccess: boolean;
    isResend = false;
    pageName = 'confirm-register';

    ngOnInit() {
        this.msgSuccess = false;
        this.confirmResult();
    }

    ngOnDestroy() {
        if (this.profileSub) {
            this.profileSub.unsubscribe();
        }
        this.store.dispatch(LoginActions.cleanup());
    }

    confirmResult() {
        this.confirmSub = this.result$.subscribe(result => {
            if (result) {
                if (result.success) {
                    if (this.isResend) {
                        this.validationResponse.validationResponseHandler(200, this.pageName, 'resend-code', 'login.resend_code');
                        this.isResend = false;
                    }
                    else {
                        this.msgSuccess = true;
                        this.analytics.eventEmitter('sign_up');
                    }
                }
                else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'login-message', null, result.message);
                }
                this.loadingSend = false;
                this.loading = false;
            }
        });
    }

    goToLogin() {
        this.router.navigateByUrl('/login/signin');
    }

    onConfirm() {
        if (this.code.value) {
            this.loading = true;
            this.store.dispatch(LoginActions.confirm_register({
                username: localStorage.getItem('username'),
                code: this.code.value,
                resend: false
            }));
        } else {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'code-first', 'login.code_first');
        }
    }

    resendCode() {
        this.loadingSend = true;
        this.isResend = true;
        const username = localStorage.getItem('username');
        this.store.dispatch(LoginActions.confirm_register({
            username: username,
            code: '',
            resend: true
        }));
    }

}
