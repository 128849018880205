import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { Camera, ListType } from '../models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CameraService } from '../Services/camera.service';
import { CameraActions } from '../Services/actions';
import { Router } from '@angular/router';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';

@Component({
    selector: 'app-camera-list-invite',
    templateUrl: './camera-list-invite.component.html',
    styleUrls: ['./camera-list-invite.component.scss']
})
export class CameraListInviteComponent implements OnInit, OnDestroy {

    // thumbUrl = environment.wowzaThumbNailUrl;
    subCamInv: Subscription;
    associate_id = environment.associateId;
    tableHeaders = ['thumbnail', 'nome', 'proprietario', 'status'];
    pageName = 'camera-list-invite';
    tableRows: MatTableDataSource<Camera> = new MatTableDataSource([] as Camera[]);
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    constructor(private router: Router, private cameraService: CameraService, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit() {
        const sub = localStorage.getItem('sub');
        this.tableRows.data = null;
        // this.tableRows.data = this.cameras;
        this.subCamInv = this.cameraService.getInvitedCameras(sub, ListType.Live).subscribe(result => {
            if (result != null) {
                this.tableRows.data = result;
                this.paginator.hidePageSize = true;
                this.tableRows.paginator = this.paginator;
            }
            else {
                // showAlert('Você não possui câmeras compartilhadas!', 'danger');
                this.validationResponse.validationResponseHandler(400, this.pageName, 'not-cameras-shared', 'cameras.not_shared');
            }
        });
    }

    ngOnDestroy() {
        if (this.subCamInv) {
            this.subCamInv.unsubscribe();
        }
        CameraActions.cleanup();
    }

    viewCamera(cam: Camera) {
        CameraActions.put_view_camera({ payload: cam });
        this.router.navigateByUrl('/cameras/view');
    }

}
