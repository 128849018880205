import { UntypedFormControl, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { Feedback } from 'app/Shared/models';
import * as validator from 'cpf-cnpj-validator';
export interface Session
{
    model: 'session';
    token: string;
    refreshToken: string;
    accessToken: string;
    active_modules: String[];
    sub: string;
    analiticos_permission: boolean;
    profile_url: string;
    profile_name: string;
    avva_integration: boolean;
    permission_invite: ClientPermission;
    user_name?: string;
    partner_permission?: {
        view_live?: boolean;
        view_recording?: boolean;
    };
    association?: {
        access_control: AccessControl;
        associate: Associate;
    };
    cost_center?: string;
    cost_center_mark?: boolean;
}

export interface AccessControl {
    id: number;
    alias: string;
    identifier: string;
    view: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean;
}

export interface Associate {
    name: string;
    email: string;
}

export interface ClientPermission
{
    view_live: boolean;
    view_recording: boolean;
    view_alert_history: boolean;
}
export interface LoginState
{
    session?: Session;
    registerResult?: Feedback;
    loginResult?: Session | Feedback;
    confirmResult?: Feedback;
    logoutResult?: Feedback;
    resetResult?: Feedback;
}


export function validatePassword (c: UntypedFormControl): ValidationErrors | null
{
    let validation: ValidationErrors = null;
    const regexList: RegExp[] = [/[0-9]+/, /[A-Z]+/, /[a-z]+/, /\W+/];
    const errorList: string[] = ['numberError', 'upperCaseError', 'lowerCaseError', 'symbolError'];
    let count = 0;
    regexList.forEach(regex => {
        if (!regex.test(c.value))
        {
            validation = {
                ...validation,
                [errorList[count]]: true
            };
        }
        count++;
    });
    return validation;
}

export function isValidURL (c: UntypedFormControl): ValidationErrors | null
{
    const link = c.value;
    try
    {
        const isValidLink = Boolean(new URL(link));
        return !isValidLink ? {'linkInvalid': true} : null;
    } catch (e) {
        return {'linkInvalid': true};
    }
}


export function validateCPFCNPJ (c: UntypedFormControl): ValidationErrors | null
{
    const value = c.value.replace(/\D/g, '');
    if (value.length > 0) {
        if (value.length > 11)
        {
            const exp = validator.cnpj.isValid(value, false);
            return !exp ? {'CNPJInvalido': true} : null;
        }
        else if (value.length == 11)
        {
            const exp = validator.cpf.isValid(value, false);
            return !exp ? {'CPFInvalido': true} : null;
        }
        else
        {
            return {'CPFInvalido': true};
        }
    }
}

export function validateCNPJ (c: UntypedFormControl): ValidationErrors | null
{
    const value = c.value.replace(/\D/g, '');
    if (value.length > 0) {
        if (value.length > 11)
        {
            const exp = validator.cnpj.isValid(value, false);
            return !exp ? {'CNPJInvalido': true} : null;
        }
        else
        {
            return {'CNPJInvalido': true};
        }
    }
}


export function validateCEP (c: UntypedFormControl): ValidationErrors | null
{
    const value = c.value.replace(/\D/g, '');
    if (value.length > 0) {
        if (value.length == 8)
        {
            return null;
        }
        else
        {
            return {'CEPInvalid': true};
        }
    }
}

export function confirmPassword (c: UntypedFormGroup): ValidationErrors | null
{
    const password = c.get('password');
    const confirm = c.get('confirm');
    const exp = password.value != confirm.value;
    return exp ? {'passwordMatch': true} : null;
}

export function numberValidator(control: UntypedFormControl): any {
    const phone = control.value;
    if (phone !== null && phone.length == 11 && /\d+/g.test(phone)) {
        return null;
    } else {
        const validanumber = /^[(]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{5}(-)?[0-9]{4}$/;
        return validanumber.test(phone) ? null : { numberInvalid: true };
    }
}
