import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { confirmPassword, validatePassword } from '../models';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoginActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { CodeInputComponent } from 'angular-code-input';

export class ValidatorConfirmPass implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const exp = control && control.invalid && (control.dirty || control.touched);
        return exp || (form.errors != null);
    }
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    readonly reset$ = this.store.select((state: AppState) => state.login.resetResult);
    @ViewChild('codeInput') codeInput: CodeInputComponent;

    eventPressEnter: Subscription;
    email: string;
    loading = false;
    isNextVision = false;
    resetSub: Subscription;
    forgotForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', [Validators.required]),
        verificationCode: new UntypedFormControl('', [Validators.required])
    }, { validators: confirmPassword });
    confirmation: boolean;
    toPassword: boolean;
    codeValid: boolean;
    resetEmail: boolean;
    envitedEmail: boolean;
    codeError: boolean;
    matcher = new ValidatorConfirmPass();
    msgSuccess: boolean;
    currentStep = 0;
    public codeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    hide = false;
    countdownInterval: ReturnType<typeof setInterval>;
    initCountTimeout: ReturnType<typeof setTimeout>;

    get passwordInput() { return this.forgotForm.get('password'); }
    get confirmPasswordInput() { return this.forgotForm.get('confirm'); }
    constructor(private router: Router, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.toUse) {
                this.forgotForm.get('email').setValue(params.toUse);
            }
        });
        this.resetEmail = false;
        this.confirmation = false;
        this.msgSuccess = false;
        this.toPassword = false;
        this.codeValid = false;
        this.envitedEmail = false;
        this.codeError = false;
        this.getResult();
        if (localStorage.getItem('Envtag') == 'nextvision') {
            this.isNextVision = true;
        }
    }

    ngOnDestroy() {
        if (this.resetSub) {
            this.resetSub.unsubscribe();
        }
        this.store.dispatch(LoginActions.cleanup());
    }

    goToPrevious() {
        this.currentStep--;
    }

    goToNext() {
        this.currentStep++;
    }

    getResult() {
        this.resetSub = this.reset$.subscribe(result => {
            if (result) {
                this.loading = false;
                if (result.success) {
                    if (this.currentStep == 0) {
                        this.validationResponse.validationResponseHandler(200, 'confirm-register', 'send-email', 'login.send_email');
                        this.initCount();
                        this.goToNext();
                    } else if (this.currentStep == 1) {
                        this.envitedEmail = true;
                        this.validationResponse.validationResponseHandler(200, 'confirm-register', 'send-email', 'login.send_email');
                        setTimeout(() => {
                            this.initCount();
                        }, 1500);
                    } else if (result.statusCode == 401) {
                        this.resetCode();
                        this.initCount();
                        this.reSendCode();
                        this.validationResponse.validationResponseHandler(400, 'confirm-register', 'code-invalid', result.message);
                    } else {
                        this.msgSuccess = true;
                        this.validationResponse.validationResponseHandler(200, 'confirm-register', 'reset-password', 'login.reset_password');
                        this.goToNext();
                        setTimeout(() => {
                            this.goToLogin();
                        }, 1000);
                    }
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'confirm-register', 'login-message', null, result.message);
                }
            }
        });
    }

    countdown(duration, display) {
        clearInterval(this.countdownInterval);
        let seconds = duration;
        this.countdownInterval = setInterval(() => {
            seconds = seconds < 10 ? '0' + seconds : seconds;
            display.textContent = seconds;
            if (--seconds < 0) {
                this.resetEmail = true;
            }
        }, 1000);
    }

    initCount() {
        clearTimeout(this.initCountTimeout);
        const duration = 30;
        this.resetEmail = false;
        this.envitedEmail = false;
        this.initCountTimeout = setTimeout(() => {
            const display = document.querySelector('#timer');
            this.countdown(duration, display);
        }, 1000);
    }

    goToPassword() {
        if (this.codeValid) {
            this.toPassword = true;
            this.goToNext();
        } else {
            this.codeError = true;
        }
    }

    onCodeCompleted(code: string) {
        this.forgotForm.get('verificationCode').setValue(code);
    }

    onCodeChanged(code: string) {
        if (code.length == 6) {
            this.codeError = false;
            this.codeValid = true;
        } else {
            this.codeValid = false;
        }
    }

    resetCode() {
        if (this.codeInput) {
            this.codeInput.reset();
        }
        this.forgotForm.reset();
    }

    forgotPassword() {
        if (this.forgotForm.get('email').valid) {
            this.email = this.forgotForm.get('email').value;
            if (this.email.length > 0) {
                this.loading = true;
                this.store.dispatch(LoginActions.reset_password({
                    username: this.email
                }));
            }
        }
    }

    reSendCode() {
        this.goToPrevious();
    }

    confirmNewPassword() {
        let passConfirm = true;
        const code = this.forgotForm.get('verificationCode').value;
        const password = this.forgotForm.get('password').value;
        this.forgotForm.get('email').setValue(this.email);
        const confirm = this.forgotForm.get('confirm').value;
        this.confirmation = false;
        if (this.forgotForm.errors != null) {
            if (this.forgotForm.errors.passwordMatch) {
                this.confirmation = true;
            }
            passConfirm = false;
        }
        if (this.forgotForm.invalid) {
            passConfirm = false;
        }

        if (passConfirm) {
            this.loading = true;
            this.store.dispatch(LoginActions.reset_password({
                username: this.email,
                password: password,
                code: code
            }));
        }
    }

    goToLogin() {
        this.router.navigateByUrl('/login/signin');
    }

}
