import Swal from 'sweetalert2';

export function showAlert(message: string, type: string) {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        title: '<span style = "color: white">' + message + '</span>',
        timerProgressBar: true,
        didOpen: (t) => {
            t.addEventListener('mouseenter', Swal.stopTimer);
            t.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    switch (type) {
        case 'warning':
            toast.fire({
                background: '#ED971E',
                icon: 'warning',
                iconColor: '#FFFF',
                width: '500px',
            });
            break;
        case 'danger':
            toast.fire({
                background: '#F0896F',
                icon: 'error',
                iconColor: '#FFFF',
                width: '500px',
            });
            break;
        case 'success':
            toast.fire({
                background: '#61B257',
                icon: 'success',
                iconColor: '#FFFF',
                width: '500px',
            });
            break;
    }
}
