import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Subscription } from 'rxjs';
import { Camera } from '../models';

@Component({
    selector: 'app-camera-manege',
    templateUrl: './camera-manege.component.html',
    styleUrls: ['./camera-manege.component.scss']
})
export class CameraManegeComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();

    pageName = 'camera-data';
    generateSelected = null;
    returnSwal: Subscription;
    userSub: string;
    childSub: string;
    camera: Camera;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.camera = this.data.data.camera;
        this.userSub = localStorage.getItem('sub');
        this.childSub = localStorage.getItem('clientView');
    }

    ngOnDestroy() {
        this.closeModal();
    }

    // Selecionar o gerenciamento
    selectGenerate() {
        this.generateSelected = true;
    }

    // Chama a deleção da câmera
    deleteCam() {
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-camera', 'cameras.delete_camera');
        this.modalClose.emit();
    }

    // validateReturnMethodCalled(returnResultSwal: ResultAlert) {
    //   if (returnResultSwal.methodCalled === 'deleteCam') {
    //     if (returnResultSwal.isConfirmed) {
    //       this.store.dispatch(this.actions.delete_camera({ camera_id: this.camera.id, child_sub: this.childSub }));
    //       this.service.closeModals(true);
    //     }
    //     // if (this.returnSwal) {
    //     //   this.returnSwal.unsubscribe();
    //     // }
    //   }
    // }

    closeModal() {
        this.modalClose.emit();
    }
}
