import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, tap } from 'rxjs/operators';
import { Camera, Mosaic, Video, ListType, GuestsList, HealthCheck, HealthHistory, HealthPanel, Address, CheckResolution,
    CameraThumbnail, GroupCamera, CreateGroup, CamerasReport, Cameras, PaginationCameras, SortColumnCamera, BlockedCamerasReport,
    GroupCamerasReport, ProviderIntegratedCameras} from '../models';
import { showAlert } from 'app/Shared/Helpers/showAlert';
import { getToken } from 'app/Shared/Helpers/getToken';
import { Feedback, OperationStatus, ResultAlert } from 'app/Shared/models';
import { getMaxDate } from 'app/Shared/Helpers/maxDate';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class CameraService {
    private backend_url = environment.apiUrl;
    // Retorna os endereços selecionados no modal de endereços em cameraForm
    private objectData = new BehaviorSubject<Address>(null);
    currentAddress = this.objectData.asObservable();

    private cancelModal = new BehaviorSubject<Boolean>(null);
    closeModal = this.cancelModal.asObservable();

    constructor(private http: HttpClient) {
    }

    //Serviço que retorna a lista de câmeras de um determinado usuário
    getCameras(userSub: string, child_sub = null, str_filter = null, cam_stat_filter?: boolean, page = null,
        limit = null, group_id: number = null, clients_id?: number[], short: boolean = false,
        active: boolean = null, sort_by: SortColumnCamera[] = null, protocol: string = null, boxes: boolean = false): Observable<Cameras | Feedback> {
        const url = `${this.backend_url}/api/resources/camera/allusercameras`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            str_filter: str_filter,
            cam_stat_filter: cam_stat_filter,
            page: page,
            limit: limit,
            clients_id: clients_id,
            group_id: group_id,
            short: short,
            active: active,
            sort_by: sort_by,
            protocol: protocol,
            boxes: boxes
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: false,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const cameras = {
                        model: 'cameras',
                        cameras: event['results'][0].data ? event['results'][0].data : [] as Camera[],
                        status: OperationStatus.Success
                    };
                    return cameras as Cameras;
                }
            })
        );
    }

    getGroupCameras(userSub: string, child_sub = null, group_id: number, type_request: 'list'|'data'|'cameras',
        groups_camera?: Camera[] | GroupCamera[], page = null, limit = null, str_filter: string = null,
        active: boolean = null, sort_by: SortColumnCamera[] = null): Observable<GroupCamera[] | PaginationCameras> {
        const url = `${this.backend_url}/api/resources/cameragroups/getcameragroups`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            group_id: group_id,
            type_request: type_request,
            groups_camera: groups_camera,
            page: page,
            limit: limit,
            str_filter: str_filter,
            active: active,
            sort_by
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return event['results'][0].data ? event['results'][0].data : [];
                }
            })
        );
    }

    getClientsToGroup(userSub: string, group_id: number): Observable<GroupCamera[]> {
        const url = `${this.backend_url}/api/resources/cameragroups/clientstogroup`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            group_id: group_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    updateCameraGroup(userSub: string, child_sub = null, group_id: number, groupName: string,
        delCameras: number[] = [], delClients: number[] = [], delAssociates: number[] = [],
        addCameras: number[] = [], addClients: number[] = [], addAssociates: number[] = []): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/cameragroups/editcameragroup`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            id_group: group_id,
            del_cameras: delCameras,
            add_cameras: addCameras,
            del_associates: delAssociates,
            add_associates: addAssociates,
            del_clients: delClients,
            add_clients: addClients,
            group_name: groupName,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                } else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    //Serviço que retorna uma imagem de preview de uma câmera dado seu link RTSP
    getPreviewCamera(linkRstp: string): Observable<string> {
        const url = `${this.backend_url}/api/bases/stream/getthumbnailconvert`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            url: linkRstp
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401 && event['errorMessage'] != undefined) {
                    //showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return event['errorMessage'] == undefined ? event['statusCode'] : 504;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : '';
                }
            })
        );
    }

    //Serviço que retorna as câmeras às quais o usuário está convidado
    getInvitedCameras(userSub: string, list_type: ListType, active: boolean = null, child_sub?: string, strFilter?: string): Observable<any> {
        const url = `${this.backend_url}/api/resources/camera/allinvitedcameras`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            list_type: list_type,
            str_filter: strFilter,
            active: active
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return null;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    //Serviço que retorna os planos de um determinado associado
    //TO-DO: MOVER PARA OUTRO MÓDULO
    getPlans(associateId: number): Observable<any> {
        const data = {
            idAssociate: associateId,
            sub: localStorage.getItem('sub')
        };

        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const url = `${this.backend_url}/api/resources/plan/getplans`;
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return null;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    //Serviço que retorna os tipos de analíticos de um usuário
    getAnalyticTypes(sub: string, myAnalytic: boolean, cameras: Camera[]) {
        const data = {
            sub: sub,
            myAnalytic: myAnalytic,
            cameras: cameras
        };

        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const url = `${this.backend_url}/api/resources/analytic/analytictypes`;
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return null;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    //Serviço que cadastra uma nova câmera para o usuário
    postCam(camera: Camera, sub: string, child_sub?: string, create_off?: boolean): Observable<Feedback> {
        const data = {
            name: camera.alias,
            uri: camera.src,
            address: camera.address,
            sub: sub,
            child_sub: child_sub,
            idPlan: camera.id_plan,
            idAssociate: environment.associateId,
            createOff: create_off,
            is_integrated_camera: camera.is_integrated_camera,
            url_http: camera.url_http,
            cam_model: camera.cam_model,
            cam_user: camera.cam_user,
            cam_pass: camera.cam_pass
        };
        const url = `${this.backend_url}/api/resources/camera/createcamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feedback: Feedback = {
                            model: 'feedback',
                            message: event['results'][0].message,
                            status: OperationStatus.Fail,
                            statusCode: event['statusCode'],
                        };
                        return feedback;
                    }
                    else {
                        return {
                            model: 'feedback',
                            status: OperationStatus.Success,
                            message: 'Sucesso',
                            data: event['results'][0].data
                        };
                    }
                }
                else {
                    return {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: 'Serviço fora do ar'
                    };
                }
            })
        );

    }

    updateCameraInformation(camera: Camera, sub: string, child_sub: string): Observable<any> {
        const data = {
            idCamera: camera.id,
            alias: camera.alias,
            idPlan: camera.id_plan,
            address: camera.address,
            url: camera.link_rtsp,
            sub: sub,
            child_sub: child_sub,
            integrated_camera_status: camera.integrated_camera_status,
            cam_model: camera.cam_model,
            url_http: camera.url_http,
            cam_user: camera.cam_user,
            cam_pass: camera.cam_pass
        };
        const url = `${this.backend_url}/api/resources/camera/updatecameras`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                } else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    getChunklist(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'text' }).pipe(
            map(result => {
                const regex = /^(?!#).+$/gm;
                const chunklist = result.match(regex).join('');
                const baseUrl = url.split('/');
                baseUrl[baseUrl.length - 1] = chunklist;
                return baseUrl.join('/');
            })
        );
    }

    /*
    CONVIDADOS
  */

    getGuests(camera_id: number, sub: string, child_sub?: string): Observable<GuestsList[] | Feedback> {
        const data = {
            sub: sub,
            child_sub,
            idCamera: camera_id
        };

        const url = `${this.backend_url}/api/resources/guest/invitationcamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: `Código ${event['statusCode']} :${event['results'][0].message}`
                    };
                    return feedback;
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    inviteUser(camera_id: number, email: string, viewRecording: boolean, receiveAnalyticAlert: boolean,
        viewLive: boolean, viewAlertHistory: boolean, resend: boolean, sub: string,  guestCam: string[],
        child_sub?: string): Observable<any> {
        const data = {
            sub: sub,
            child_sub: child_sub,
            emailUser: email,
            cameras: guestCam ? guestCam : [{
                idCamera: camera_id,
                viewRecording: viewRecording,
                receiveAnalyticAlert: receiveAnalyticAlert,
                viewLive: viewLive,
                viewAlertHistory: viewAlertHistory,
            }],
            resend: resend
        };

        const url = `${this.backend_url}/api/resources/guest/newinvite`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                return event;
                // if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                //   showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                //   //return null;
                //   return false;
                // }
                // else {
                //   //return event['results'][0].data;
                //   return true;
                // }
            })
        );
    }

    updateGuest(camera_id: number, email: string, viewRecording: boolean, receiveAnalyticAlert: boolean,
        viewLive: boolean, viewAlertHistory: boolean, sub: string, child_sub?: string): Observable<any> {
        const data = {
            sub: sub,
            child_sub: child_sub,
            email: email,
            idCamera: camera_id,
            viewRecording: viewRecording,
            analyticAlert: receiveAnalyticAlert,
            viewLive: viewLive,
            alertHistory: viewAlertHistory
        };

        const url = `${this.backend_url}/api/resources/guest/updateguest`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                return event;
            })
        );
    }

    //Serviço que deleta uma lista de convidados de uma determinada câmera
    deleteGuestList(camera_id: number, guestsList: any[], user_sub: string, child_sub: string): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/guest/deleteguest`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            idCamera: camera_id,
            idUser: guestsList,
            sub: user_sub,
            child_sub: child_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    //Serviço que remove uma câmera de um outro usuário
    deleteGuestCamera(userSub: string, camera_id: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/guest/deleteinvitation`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            idCamera: camera_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    /*
  FIM DE CONVIDADOS
  */

    //Serviço que remove uma câmera de determinado usuário
    deleteCamera(camera_id: number, child_sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/camera/deletecamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const userSub = localStorage.getItem('sub');

        const data = {
            idCamera: camera_id,
            sub: userSub,
            child_sub: child_sub
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    //Serviço que retorna uma playlist de vídeos de determinada câmera de um período de tempo determinado
    getVideos(userSub: string, child_sub: string, timeBegin: string, timeEnd: string, cam_id: number, withAlert: boolean, refresh: boolean): Observable<Video[]> {
        const url = `${this.backend_url}/api/resources/camera/getrecording`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            time_begin: timeBegin,
            time_end: timeEnd,
            id_camera: cam_id,
            with_alert: withAlert,
            refresh: refresh
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    return event['results'][0].data.length > 0 ? event['results'][0].data : [];
                }
            })
        );
    }

    //Serviço que atualiza informações da câmera
    updateCamera(camera_id: number, newName: string, newPlan: number): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/camera/updatecameras`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const userSub = localStorage.getItem('sub');
        const data =
    {
        idCamera: camera_id,
        alias: newName,
        idPlan: newPlan,
        sub: userSub
    };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    // showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    updateStatusCamera(camera_id: number, status: boolean): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/camera/updatestatuscamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            idCamera: camera_id,
            sub: localStorage.getItem('sub'),
            status: status
        };
        return this.http.put(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    // showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    getCameraHealthStatus(id_camera: number, sub: string): Observable<HealthCheck> {
        const url = `${this.backend_url}/api/resources/camera/currentstatus`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            camera_id: id_camera,
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return null;
                }
                else {
                    return event['results'][0].data[0];
                }
            })
        );
    }

    getCameraHealthHistory(id: number, page: number, sub: string,  data_begin?: string, data_end?: string): Observable<HealthPanel[] | Feedback> {
        const url = `${this.backend_url}/api/resources/camera/statushistory`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            camera_id: id,
            sub: sub,
            page: page,
            ...(data_begin && data_end && { date_filter_begin: data_begin }),
            ...(data_begin && data_end && { date_filter_end: data_end })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        success: false,
                        message: event['results'][0].message,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const eventData: HealthHistory[] = event['results'][0].data;
                    if (eventData) {
                        const g = eventData.reduce((groups, d) => {
                            const date = d.event_begin.split('T')[0];
                            if (!groups[date]) {
                                groups[date] = [];
                            }
                            groups[date].push(d);
                            return groups;
                        }, {});
                        const panels: HealthPanel[] = Object.keys(g).map((date) => {
                            return {
                                events: g[date],
                                startDate: new Date(`${date}Z-0300`),
                                endDate: getMaxDate(g[date].map(e => e.event_end))
                            };
                        });
                        return panels.sort((a, b) => a.startDate.valueOf() < b.startDate.valueOf() ? 1 : -1);
                    }
                    else {
                        return null;
                    }
                }
            })
        );
    }

    /**
   * MOSAICOS
   */

    //Serviço que retorna as possíveis configurações de mosaico
    getMosaics(): Observable<Mosaic[]> {
        const url = `${this.backend_url}/api/resources/mosaic/gettemplatemosaic`;
        const headers = {
            headers: new HttpHeaders({
                'token': getToken()
            })
        };
        return this.http.get(url, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    const mosaics: Mosaic[] = Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                    return mosaics.map(m => {
                        const mosaic: Mosaic = {
                            id_mosaic_template: m.id,
                            width: m.width,
                            height: m.height
                        };
                        return mosaic;
                    });
                }
            })
        );
    }

    //Serviço que retorna o mosaico atual de determinado usuário
    getUserMosaics(sub: string): Observable<Mosaic[]> {
        const url = `${this.backend_url}/api/resources/mosaic/returnmosaicbyuser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return [];
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data as Mosaic[] : [];
                }
            })
        );
    }

    //Serviço que salva o mosaico do usuário
    saveMosaic(mosaic: Mosaic): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/mosaic/mountmosaic`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: localStorage.getItem('sub'),
            id_mosaic: mosaic.id_mosaic_template,
            list_cameras: mosaic.cameras.filter(c => c.id_camera > 0).map(c => c.id_camera),
            name: mosaic.name,
            fixed: mosaic.fixed
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return false;
                }
                else {
                    return true;
                }
            })
        );
    }

    editMosaic(mosaic: Mosaic): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/mosaic/updatemosaic`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: localStorage.getItem('sub'),
            id_mosaic: mosaic.id,
            id_mosaic_template: mosaic.id_mosaic_template,
            list_cameras: mosaic.cameras.filter(c => c.id_camera > 0).map(c => c.id_camera),
            name: mosaic.name,
            fixed: mosaic.fixed
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: `Código ${event['statusCode']} :${event['results'][0].message}`
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: 'Mosaico editado com sucesso'
                    };
                    return feedback;
                }
            })
        );
    }

    deleteMosaic(id: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/mosaic/deletemosaic`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: localStorage.getItem('sub'),
            id_mosaic: id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: `Código ${event['statusCode']} :${event['results'][0].message}`
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: 'Mosaico deletado com sucesso'
                    };
                    return feedback;
                }
            })
        );
    }

    // Service que recebe o endereço pelo modal e é resgatado na tela de camera form
    getAddressInformationToComponent(cameraAddress: Address) {
        this.objectData.next(cameraAddress);
    }

    closeModals(closeData: Boolean) {
        this.cancelModal.next(closeData);
    }

    // Retorna a lista de endereços
    getAddress(userSub: string): Observable<Address[]> {
        const url = `${this.backend_url}/api/resources/camera/getaddresshistory`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    //Serviço que cadastra uma nova câmera para o usuário
    CheckCameraResolution(cameraResolution: CheckResolution): Observable<Feedback> {
        const data = {
            uri: cameraResolution.uri,
            height: cameraResolution.height,
            width: cameraResolution.width,
            fps: cameraResolution.fps,
            plan_id: cameraResolution.plan_id
        };
        const url = `${this.backend_url}/api/bases/stream/checkresolution`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feedback: Feedback = {
                            model: 'feedback',
                            message: event['results'][0].message,
                            status: OperationStatus.Fail
                        };
                        return feedback;
                    }
                    else {
                        return {
                            model: 'feedback',
                            status: OperationStatus.Success,
                            message: 'Sucesso'
                        };
                    }
                }
                else {
                    return {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: 'Serviço fora do ar'
                    };
                }
            })
        );

    }

    //Serviço que cadastra uma nova câmera para o usuário
    CreateCameraThumbnail(cameraThumbnail: CameraThumbnail): Observable<Feedback | string> {
        const data = {
            hashname: cameraThumbnail.hashname,
            sub: cameraThumbnail.sub,
            uri: cameraThumbnail.uri,
        };
        const url = `${this.backend_url}/api/resources/camera/getthumbnail`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode']) {
                    if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                        const feedback: Feedback = {
                            model: 'feedback',
                            message: event['results'][0].message,
                            status: OperationStatus.Fail
                        };
                        return feedback;
                    }
                    else {
                        return cameraThumbnail.refresh ? event['results'][0].data[0].thumbnail_url : {
                            model: 'feedback',
                            status: OperationStatus.Success,
                            message: 'Sucesso',
                            statusCode: event['statusCode']
                        };
                    }
                }
                else {
                    return {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        message: 'Serviço fora do ar'
                    };
                }
            })
        );

    }

    //Serviço que retorna uma playlist de vídeos de determinada câmera de um período de tempo determinado
    getUrlSignedVideos(urlSigned: string): Observable<string> {
        const url = `${this.backend_url + '/api/bases/multimedia/getmedia?objectKey='}${urlSigned}`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        return this.http.get(url, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    showAlert(`Código ${event['statusCode']} :${event['results'][0].message}`, 'danger');
                    return '';
                }
                else {
                    return Array.isArray(event['results']) ? event['results'][0].data['location'] : '';
                }
            })
        );
    }

    getRTMPUri(user_sub: string): Observable<string> {
        const url = `${this.backend_url}/api/resources/camera/generatertmplink`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: user_sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    return event['results'][0].data;
                }
            })
        );
    }

    createGroupCamera(payload: CreateGroup): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/cameragroups/createcameragroup`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: payload.sub,
            name: payload.name,
            cameras_id: payload.cameras_id,
            associateds_id: payload.associateds_id,
            clients_id: payload.clients_id,
            is_public: payload.is_public,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        status: OperationStatus.Success,
                        data: event['results'][0].data,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    deleteCameraGroup(user_sub: string, group_id: number): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/cameragroups/deletecameragroup`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: user_sub,
            group_id: group_id
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        success: false,
                        status: OperationStatus.Fail,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        success: true,
                        status: OperationStatus.Success,
                        model: 'feedback',
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    getCamerasReport(userSub: string, clients_id?: number[]): Observable<CamerasReport[]> {
        const url = `${this.backend_url}/api/resources/dashboard/camerasreport`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            clients_id: clients_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    getBlockedCamerasReport(userSub: string, child_sub = null, page = null, limit = null, clients_id = null): Observable<BlockedCamerasReport[]> {
        const url = `${this.backend_url}/api/resources/dashboard/blockedcamerasreport`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            child_sub: child_sub,
            clients_id: clients_id,
            page: page,
            limit: limit,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    getGroupCamerasReport(userSub: string, group_id: number): Observable<GroupCamerasReport[]> {
        const url = `${this.backend_url}/api/resources/dashboard/cameragroupreport`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
            group_id: group_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    getProviderIntegratedCameras(userSub: string): Observable<ProviderIntegratedCameras[]> {
        const url = `${this.backend_url}/api/resources/camera/allprovidersintegratedcamera`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: userSub,
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    return [];
                } else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

}
