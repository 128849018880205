import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginRoutingModule } from './login.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmRegisterComponent } from './confirm-register/confirm-register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CodeInputModule } from 'angular-code-input';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [LoginComponent, RegisterComponent, ConfirmRegisterComponent, ForgotPasswordComponent, LogoutComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LoginRoutingModule,
        MatProgressBarModule,
        MatSelectModule,
        NzInputModule,
        NzIconModule,
        CodeInputModule,
        NzSelectModule,
        NzToolTipModule,
        NzStepsModule,
        NgOptimizedImage,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers: [provideNgxMask()]
})
export class LoginModule { }
