<div class="main-content background-container">
    <div class="button-close">
        <i class="material-icons hover-close" (click)="closeCancel()">close</i>
    </div>
    <div class="row">
        <span class="style-title">Alterar link do contrato</span>
    </div>
    <form [formGroup]="linkForm">
        <div class="container_form_top">
            <div class="currentLink">
                <span class="input-title">Link atual</span>
                <input formControlName="currentLink" readonly type="text" class="input">
            </div>
            <div class="newLink">
                <span class="input-title">Novo link</span>
                <input type="text" placeholder="Cole aqui..."
                    formControlName="newLink" class="input">
                <mat-error *ngIf="!linkForm.get('newLink').hasError('required') && linkForm.get('newLink').hasError('linkInvalid') && linkForm.get('newLink').touched">
                    Formato <strong>inválido</strong>
                </mat-error>
                <mat-error *ngIf="linkForm.get('newLink').hasError('required') && linkForm.get('newLink').touched">
                    Contrato <strong>é requerido</strong>
                </mat-error>
            </div>
        </div>
    </form>
    <div class="container_button">
        <button (click)="closeConfirm()" type="button"
            class="btn btn-md btn-primary tamButton save">SALVAR
            <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></span>
        </button>
    </div>
</div>
