import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { Ticket, SessionGLPI, GLPIFile } from './glpi.models';
import { Feedback } from 'app/Shared/models';
import { createAction, props } from '@ngrx/store';

type Payload =  SessionGLPI | GLPIFile | number | boolean | string | Feedback | any;

export interface Metadata {
    user_sub?: string;
    child_sub?: string;
    ticket?: Ticket;
    session_token?: string;
    document_ids?: number[];
    document?: File | any;
}

export type GLPIAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class GLPIActions {
    //Ações de GLPI genéricas
    static readonly CREATE_TICKET = 'CREATE_TICKET';
    static readonly CREATE_TICKET_RETURN = 'CREATE_TICKET_RETURN';
    static readonly CREATE_TICKET_CLEAR = 'CREATE_TICKET_CLEAR';
    static readonly CLEANUP_ACTION = 'CLEANUP_ACTION';
    static readonly GET_SESSION = 'GET_SESSION';
    static readonly GET_SESSION_RETURN = 'GET_SESSION_RETURN';
    static readonly UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
    static readonly UPLOAD_DOCUMENT_RETURN = 'UPLOAD_DOCUMENT_RETURN';
    static readonly UPLOAD_DOCUMENT_CLEAR = 'UPLOAD_DOCUMENT_CLEAR';

    static create_ticket = createAction(
        GLPIActions.CREATE_TICKET,
        props<Metadata>()
    );

    static create_ticket_clear = createAction(
        GLPIActions.CREATE_TICKET_CLEAR
    );

    static create_ticket_return = createAction(
        GLPIActions.CREATE_TICKET_RETURN,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        GLPIActions.CLEANUP_ACTION
    );

    static get_session = createAction(
        GLPIActions.GET_SESSION,
        props<Metadata>()
    );

    static get_session_return = createAction(
        GLPIActions.GET_SESSION_RETURN,
        props<{ payload: Payload }>()
    );

    static upload_document = createAction(
        GLPIActions.UPLOAD_DOCUMENT,
        props<Metadata>()
    );

    static upload_document_return = createAction(
        GLPIActions.UPLOAD_DOCUMENT_RETURN,
        props<{ payload: Payload }>()
    );

    static upload_document_clear = createAction(
        GLPIActions.UPLOAD_DOCUMENT_CLEAR
    );
}
