// import { SharedService } from 'app/Shared/Services/shared.service';
import Swal from 'sweetalert2';
import { ResultAlert } from '../models';
import { showAlert } from '../Helpers/showAlert';
import { SharedService } from '../Services/shared.service';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
export class ValidationResponseAnalytics {
    resultAlert: ResultAlert;
    constructor() {
    }

    swalDanger(title: string) {
        showAlert(title, 'danger');
    }

    swalWarning(title: string) {
        showAlert(title, 'warning');
    }

    swalSuccess(title: string) {
        showAlert(title, 'success');
    }

    success_operation_200(title: string, swalParameters: any) {
        showAlert(swalParameters, 'success');
    }

    failure_operation_400(title: string, swalParameters: any) {
        showAlert(swalParameters, 'danger');
    }

    configure_analytic_confirm_delete_200() {
        Swal.fire({
            title: 'Excluir analítico?',
            text: 'Essa ação não poderá ser revertida',
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: 'EXCLUIR',
            cancelButtonText: 'CANCELAR'
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteAnalytic');
        });

    }


}
