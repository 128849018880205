<nz-spin [nzSpinning]="loading || waiting" [nzSize]="'large'" [nzIndicator]="loadingCameraTemplate" class="spin-mosaic">
<div >
    <div class="main-content background-globals">
        <span class="main-title">Mosaico de Câmeras</span>
        <div class="top-container">
            <div class="container-start">
                <div *ngIf="(isPartner || isPartnerAssociate) && !loadingClients" class="filter-container">
                    <app-filter [list]="clientListSimple" type="simple" placeholder="Selecionar cliente"
                        (filterStatusChange)="selectClient($event)" [showSelected]="true"
                        [hasIconPrefix]="true" iconType="user" [hasToggle]="true" [search]="true"></app-filter>
                </div>
                <div class="filter-container" *ngIf="hasGroups">
                    <app-filter [list]="groupListSimple" type="simple" placeholder="Selecionar Grupo"
                        (filterStatusChange)="selectGroup($event)" [showSelected]="true"
                        [hasIconPrefix]="true" iconType="video-camera" [hasToggle]="true"></app-filter>
                </div>
                <span class="dropup">
                    <a nz-dropdown [nzDropdownMenu]="menu" *ngIf="currentMosaic" nzTrigger="click"
                        class="transparent" (nzVisibleChange)="isLayoutDropdownActive = !isLayoutDropdownActive">
                        <img *ngIf="currentMosaic.id_mosaic_template == 1" src="../../../assets/img/Icons/grid1x1.svg" class="mosaic-icon">
                        <img *ngIf="currentMosaic.id_mosaic_template == 2" src="../../../assets/img/Icons/grid2x2.svg" class="mosaic-icon">
                        <img *ngIf="currentMosaic.id_mosaic_template == 3" src="../../../assets/img/Icons/grid3x3.svg" class="mosaic-icon">
                        <span nz-icon nzType="up" nzTheme="outline" class="posfix" *ngIf="isLayoutDropdownActive"></span>
                        <span nz-icon nzType="down" nzTheme="outline" class="posfix" *ngIf="!isLayoutDropdownActive"></span>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable class="dropdown-template">
                          <li nz-menu-item (click)="chooseMosaic(1)"><a><img src="../../../assets/img/Icons/grid1x1.svg"></a></li>
                          <li nz-menu-item (click)="chooseMosaic(2)"><a><img src="../../../assets/img/Icons/grid2x2.svg"></a></li>
                          <li nz-menu-item (click)="chooseMosaic(3)"><a><img src="../../../assets/img/Icons/grid3x3.svg"></a></li>
                        </ul>
                    </nz-dropdown-menu>
                </span>
            </div>
            <div class="container-end">
                <button class="btn btn-primary lg:btn-sm xl:btn-sm add-mosaic-button op-5" *ngIf="!hasCameras"
                    nz-popover [nzPopoverContent]="popoverNoCams" nzPopoverOverlayClassName="popover-container-view">
                    <span nz-icon nzType="plus" nzTheme="outline" class="add-mosaic-icon"></span>
                    <span class="add-mosaic">Adicionar mosaico</span>
                </button>
                <button (click)="openCreateMosaic()" *ngIf="hasCameras" class="btn lg:btn-sm xl:btn-sm btn-primary add-mosaic-button">
                    <span nz-icon nzType="plus" nzTheme="outline" class="add-mosaic-icon"></span>
                    <span class="add-mosaic">Adicionar mosaico</span>
                </button>
                <!--Botão de collapse-->
                <div class="block xs:hidden button-collapsable">
                    <div *ngIf="!collapsed" (click)="collapseSidebar()">
                        <i nz-icon nzType="video-camera" nzTheme="outline" style="margin-right: 10px; color: #306EBF"></i>
                        <i nz-icon nzType="double-right" nzTheme="outline"></i>
                    </div>
                    <div *ngIf="collapsed" (click)="collapseSidebar()">
                        <i nz-icon nzType="double-left" nzTheme="outline" style="margin-right: 10px; color: #306EBF"></i>
                        <i nz-icon nzType="video-camera" nzTheme="outline"></i>
                    </div>
                </div>
            </div>
            <ng-template #popoverNoCams>
                <div class="tooltip-camera">
                    <span>Você não possui câmeras cadastradas, adicione uma câmera para criar mosaicos</span>
                    <button (click)="toAddCamera()" class="btn btn-primary btn-add-cam">Adicionar câmera</button>
                </div>
            </ng-template>
        </div>
        <div class="container-fluid">
            <div [class.collapsed]="collapsed" class="grid background-mosaic">
                <!--Mosaico-->
                <div id="mosaic">
                    <!--Grid com vídeos-->
                    <div id="videoGrid" fxLayout="row wrap">
                        <div *ngFor="let el of elem; index as i" [id]="('backgroundStyle' + i)"
                            (click)="selectVideo(i)" [style.height]="getHeight(currentMosaic.height)"
                            [ngClass]="{'selected': isSelected(i, getCameraStatus(i))}"
                            class="videoplayer container_grid_videos"
                            [fxFlex]="getContainerWidth(currentMosaic.width)" 
                            [ngClass]="{'without-image':getCameraStatus(i) != null || el.loading, 'with-image':getCameraStatus(i) === null}">
                            <nz-spin [nzSpinning]="el.loading" [nzIndicator]="loadingCameraTemplate" class="spin-live">
                                <div class="top-overlay" *ngIf="getCameraStatus(i) !== null">
                                    <span class="status_cams">
                                        <!-- Retorna o status visual da situação da câmera -->
                                        <div class="camera-overlay">
                                            <div *ngIf="getCameraStatus(i) !== null">
                                                <span class="nomeCam_statusCam" [id]="('indicator' + i)" [ngClass]="{
                                                    'camera_status_true': getCameraStatus(i),
                                                    'camera_status_false': !getCameraStatus(i)
                                                }">
                                                    <i *ngIf="!getCameraStatus(i)" nz-icon nzType="loading" nzTheme="outline"></i>
                                                </span>
                                            </div>
                                            <img class="profile_pic" [matTooltip]="getUserInviteName(i)"
                                                matTooltipClass="tip-style" matTooltipPosition="above"
                                                [src]="getUserImage(i)" height="28" width="28"
                                                [ngClass]="{'camera_status_unknow':getCameraStatus(i) === null}"
                                                *ngIf="getUserImage(i) != undefined" onerror="this.src='../../../assets/img/placeholder.jpg';">
                                            <span nz-tooltip [nzTooltipTitle]="getCameraName(i)" nzTooltipOverlayClassName="cam-name"
                                            nzTooltipPlacement="top" [nzTooltipOverlayStyle]="{'top': '6px'}">{{displayCamName(getCameraName(i))}}</span>
                                        </div>
                                    </span>
                                    <div class="menu_cameras select_for_permission" *ngIf="!isFullscreen && getCameraStatus(i) !== null">
                                        <span class="menu-edit" nz-icon nzType="more" nz-dropdown nzTrigger="click" [nzDropdownMenu]="select"
                                                nzTheme="outline" (click)="validatePermission(i)" [nzOverlayStyle]="{'overflow': 'hidden'}"></span>
                                        <nz-dropdown-menu #select="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item *ngFor="let info of informationMenu" [value]="info"
                                                    (click)="managingCameras(info, i)">{{info}}</li>
                                                <li nz-menu-divider *ngIf="informationMenu && informationMenu.length > 0"></li>
                                            </ul>
                                        </nz-dropdown-menu>
                                        <i nz-icon nzType="close" nzTheme="outline" class="menu-delete" (click)="removeCamera(i, true)"></i>
                                    </div>
                                </div>
                                <div *ngIf="!isFullscreen && getCameraStatus(i) !== null" class="fullscreen-div" (click)="openFullscreen('backgroundStyle' + i)">
                                    <span class="live-camera-button" nz-icon nzType="expand" nzTheme="outline"></span>
                                </div>
                                <div [id]="('videoIndex' + i)"></div>
                                <video #video autoplay [id]="'play' + i"></video>
                                <div [ngClass]="getClassCamera(i, statusCam, currentMosaic.width)"
                                    [id]="('videos' + i)">
                                </div>
                            </nz-spin>
                        </div>
                    </div>
                </div>
                <!--Sidebar para seleção de câmeras para o mosaico-->
                <div id="sidebar" [style.display]="collapsed ? 'none' : 'block'" class="row-span-3 background-sidebar">
                    <div class="btn-group" placement="bottom left">
                        <button nz-button class="btn-dropdown btn btn-ant op-5" nz-popover
                                [nzPopoverContent]="!hasCameras ? popoverNoCams : popoverNoMosaics"
                                *ngIf="!hasCameras || userMosaics.length == 0" nzPopoverOverlayClassName="popover-container-view">
                            <span style="padding-top: 2px;">{{'Selecionar Mosaico'}}</span>
                            <span nz-icon nzType="up" nzTheme="outline" class="posfix"></span>
                        </button>
                        <ng-template #popoverNoMosaics>
                            <div class="tooltip-camera">
                                <span>Você não possui mosaicos cadastrados, adicione um mosaico para visualizar</span>
                                <button (click)="openCreateMosaic()" class="btn btn-primary btn-add-cam">Adicionar mosaico</button>
                            </div>
                        </ng-template>
                        <button nz-button class="btn-dropdown btn btn-ant" nz-dropdown [nzDropdownMenu]="mosaicList" nzPlacement="bottomRight"
                                nzTrigger="click" *ngIf="hasCameras && userMosaics.length > 0 && currentMosaic" [nzClickHide]="false">
                            <div class="prefix"><img [src]="getDimentionsMosaic(currentMosaic)" style="width: 16px" alt=""></div>
                            <span style="padding-top: 2px;">{{this.currentMosaic.name != null ? this.currentMosaic.name.substring(0, 15) : 'Selecionar Mosaico'}}</span>
                            <span nz-icon nzType="up" nzTheme="outline" class="posfix" *ngIf="isDropdownActive"></span>
                            <span nz-icon nzType="down" nzTheme="outline" class="posfix" *ngIf="!isDropdownActive"></span>
                        </button>
                        <nz-dropdown-menu #mosaicList="nzDropdownMenu">
                            <ul nz-menu class="dropdown-mosaic" [ngStyle]="{'display': userMosaics.length != 0 ? 'block' : 'none'}">
                                <ng-container *ngFor="let mosaic of userMosaics;">
                                    <li nz-menu-item  class="flex" (click)="changeMosaic(mosaic)">
                                        <a [class.active]="activeList.includes(mosaic.id)" class="flex flex-row clickable simple-{{mosaic.id}}">
                                            <span class="flex dropup" style="color: #333;">
                                                <span class="material-icons drop-icon" nz-icon nzType="more" nzTheme="outline" nz-dropdown nzPlacement="left"
                                                    nzTrigger="click" [nzDropdownMenu]="selectOptionsMosaic" (click)="$event.stopPropagation()"></span>
                                                <nz-dropdown-menu #selectOptionsMosaic="nzDropdownMenu">
                                                    <ul nz-menu class="mini-dropdown">
                                                        <li nz-menu-item (click)="openEditMosaic(mosaic)">
                                                            <a style="display:flex; align-items: center;">
                                                                <span class="edit-mosaic-icon" nz-icon nzType="edit" nzTheme="outline"></span>
                                                                <span class="mosaic-crud">Editar mosaico</span>
                                                            </a>
                                                        </li>
                                                        <li nz-menu-item (click)="deleteMosaic(mosaic)">
                                                            <a style="display:flex; align-items: center;">
                                                                <span class="edit-mosaic-icon" nz-icon nzType="delete" nzTheme="outline"></span>
                                                                <span class="mosaic-crud">Excluir mosaico</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nz-dropdown-menu>
                                            </span>
                                            <span class="flex push-pin">
                                                <img [src]="getDimentionsMosaic(mosaic)" width="20">
                                            </span>
                                            <div class="flex w-full justify-between">
                                                <span class="mosaic-name" *ngIf="mosaic && mosaic.name">
                                                    &nbsp;{{mosaic.name.length > 13 ? mosaic.name.substring(0, 13)+"..." : mosaic.name}}
                                                </span>
                                                <span class="checkbox-icon" nz-icon nzType="check" nzTheme="outline"></span>
                                            </div>
                                            <ng-template #elseNameMosaic>
                                                <span class="mosaic-name">
                                                    &nbsp; Mosaico 1
                                                    <span class="checkbox-icon" nz-icon nzType="check" nzTheme="outline"></span>
                                                </span>
                                            </ng-template>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <nz-input-group [nzSuffix]="suffixIconSearch" class="input-container">
                        <input type="text" nz-input placeholder="Pesquisar" [formControl]="searchInputControl" (keyup)="searchCameras()" class="input-value" />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <i nz-icon nzType="search" (click)="searchCameras()"></i>
                    </ng-template>
                    <div class="card-select-cam gap-20" *ngIf="cameras">
                        <div id="card-cam" class="row-span-2">
                            <div class="title" *ngIf="!isGuest">
                                Minhas câmeras ativas
                                <p class="category">{{totalAmount  + totalAmountGroup}} câmeras</p>
                            </div>
                            <div class="title" *ngIf="isGuest">
                                Câmeras de {{associate.name}} ativas
                                <p class="category">{{totalAmount  + totalAmountGroup}} câmeras</p>
                            </div>
                            <div class="camera-card">
                                <div id="container" class="camera-body search-results" *ngIf="!(loadingCamera || loadingGroup);else loadingCameraTemplate"
                                        infinite-scroll [infiniteScrollDistance]="0.05" [infiniteScrollThrottle]="1"
                                        [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
                                    <ng-container *ngFor="let camera of cameras">
                                        <div class="restrain-width">
                                            <div class="cam-row screenshot_cams">
                                                <div [ngClass]="camera.status === false ? 'image_thumbnail' : ''"
                                                    [id]="camera.id">
                                                    <img class="round flex-size" [ngClass]="{'thumb': inMosaic(camera)}"
                                                        (click)="chooseCam(camera, true)" [src]="camera.cache_thumb | async | safeHtml"
                                                        (error)="brokenImageHandler($event)">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="row cam-row cam-name alias_cams" [ngStyle.xs]="{'min-width': '90px'}">
                                                <span nz-tooltip [nzTooltipTitle]="camera.alias" nzTooltipOverlayClassName="cam-name"
                                                nzTooltipPlacement="top" [nzTooltipOverlayStyle]="{'top': '6px'}">
                                                    {{displayCamName(camera.alias)}}
                                                </span>
                                                <div class="id-cam-container">
                                                    <span class="id-cam">#{{camera.id}}</span>
                                                </div>
                                                <div class="guest_name_container">
                                                    <i nz-icon nzType="user" nzTheme="outline"></i>
                                                    <span class="guest_name">{{formatOwnerName(camera.owner)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="loadingAlerts" *ngIf="loadingScroll">
                                        <span nz-icon nzType="loading" nzTheme="outline"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="card-shared">
                            <div class="title">
                                Compartilhadas comigo
                                <p class="category">{{camerasShared.length}} câmeras</p>
                            </div>
                            <div class="camera-card">
                                <div id="containerShared" *ngIf="!loadingCamera;else loadingCameraTemplate">
                                    <ng-container *ngFor="let camera of camerasShared">
                                        <div class="restrain-width">
                                            <div class="cam-row screenshot_cams">
                                                <div [ngClass]="camera.status === false ? 'image_thumbnail' : ''"
                                                    [id]="camera.id">
                                                    <img class="round flex-size"
                                                        [ngClass]="{'thumb': inMosaic(camera)}" (error)="brokenImageHandler($event)"
                                                        (click)="chooseCam(camera, true)" [src]="camera.cache_thumb | async | safeHtml">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="row cam-row cam-name alias_cams" [ngStyle.xs]="{'min-width': '90px'}">
                                                <span nz-tooltip [nzTooltipTitle]="camera.alias" nzTooltipOverlayClassName="cam-name"
                                                nzTooltipPlacement="top" [nzTooltipOverlayStyle]="{'top': '6px'}">
                                                    {{displayCamName(camera.alias)}}
                                                </span>
                                                <div class="id-cam-container">
                                                    <span class="id-cam">#{{camera.id}}</span>
                                                </div>
                                                <div class="guest_name_container">
                                                    <i nz-icon nzType="user" nzTheme="outline"></i>
                                                    <span class="guest_name">{{camera.name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Componente responsável por aparecer quando não houver câmeras cadastradas -->
</div>
</nz-spin>
<ng-template #loadingCameraTemplate>
    <div class="loadingCamera">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</ng-template>

<app-camera-invite (waiting)="loadingEvent($event)" (exitModal)="whenModalClose($event)"></app-camera-invite>
<app-camera-edit (waiting)="loadingEvent($event)" (exitModal)="whenModalClose($event)"></app-camera-edit>
<app-analytics-controller (waiting)="loadingEvent($event)" (exitModal)="whenModalClose($event)"></app-analytics-controller>
<app-camera-modal-mosaic (exitModal)="restartStreamings($event)"></app-camera-modal-mosaic>
<app-modal></app-modal>