import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'app/Login/Services/login.service';

@Injectable({ providedIn: 'root'})
export class ConfirmGuard  {
    constructor(private router: Router, private loginService: LoginService)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('username') != null)
        {
            return true;
        }
        else
        {
            this.loginService.navSideShowEmitter.emit(false);
            this.router.navigate(['/login/signin'], { queryParams: { returnUrl: state.url}});
            return false;
        }
    }
}
