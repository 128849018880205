<div class="plan-container">
    <span class="loading-plan" nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingPlans"></span>
    <div class="plan-card" *ngFor="let plan of availableInventory" [id]="plan.id_plan + plan.alias" (click)="changePlan(plan.id_plan)">
        <div class="plan-icon" [id]="activePlan + '==' + plan.id_plan">
            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'SVG'">
            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'VGA'">
            <img src="./../../../assets/img/Icons/camera_svg.svg" alt="" *ngIf="plan.resolution.alias === 'D1'">
            <img src="./../../../assets/img/Icons/camera_hd.svg" alt="" *ngIf="plan.resolution.alias === 'HD'">
            <img src="./../../../assets/img/Icons/camera_full_hd.svg" alt="" *ngIf="plan.resolution.alias === 'FULL HD'">
        </div>
        <div class="plan-description">
            <span class="step-title">Câmera {{plan.resolution.alias}}</span>
            <div class="step-subtitle">{{plan.days_storage}} {{processPlural('dia', plan.days_storage)}}</div>
        </div>
        <div class="plan-text">
            <ul>
                <li>Backup {{processPlural('do', plan.days_storage)}} {{processPlural('último', plan.days_storage)}} {{plan.days_storage}} {{processPlural('dia', plan.days_storage)}} de gravação.</li>
                <li>Acesso ilimitado via aplicativo.</li>
                <li>Resolução {{plan.resolution.alias}} {{plan.resolution.width}} x {{plan.resolution.height}}</li>
            </ul>
        </div>
        <div class="selected_icon" *ngIf="activePlan === plan.id_plan">
            <i nz-icon nzType="check" nzTheme="outline"></i>
        </div>
    </div>
</div>
<!-- Botões - salvar, cancelar-->
<div class="flex">
    <div class="col-md-12 col-sm-12 col-12 margin_buttons" *ngIf="adminAssociate || isPartner">
        <button type="button" class="btn btn-md tamButton button_cancel_camera"
                (click)="closeModal()"><span>Cancelar</span></button>
        <button type="button" class="btn btn-md btn-primary tamButton button_save_permission"
                [disabled]="!save_changes || loading" (click)="saveChanges()">
            <span>Salvar Alterações</span><i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loading"></i></button>
    </div>
</div>

