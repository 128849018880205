import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-blocked',
    templateUrl: './user-blocked.component.html',
    styleUrls: ['./user-blocked.component.scss']
})
export class UserBlockedComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    logout() {
        this.router.navigateByUrl('/login/logout');
    }

}
