import { Routes } from '@angular/router';
import { UserSummaryComponent } from './user-summary/user-summary.component';
import { AuthGuard } from 'app/Shared/Guards/auth.guard';
import { UserPartnersComponent } from './user-partners/user-partners.component';
import { ClientListComponent } from './user-client-list/user-client-list.component';
import { ClientViewComponent } from './user-client-view/user-client-view.component';
import { UserBlockedComponent } from './user-blocked/user-blocked.component';
import { UserKitsComponent } from './user-kits/user-kits.component';
import { UserPartnerViewComponent } from './user-partner-view/user-partner-view.component';

export const usersRoutes: Routes = [
    {
        path: 'profile',
        component: UserSummaryComponent,
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'payments',
    //     component: UserPaymentsComponent,
    //     canActivate: [AuthGuard, BillingGuard]
    // },
    {
        path: 'partners',
        component: UserPartnersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'kits',
        component: UserKitsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'clients/list',
        component: ClientListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'client/view',
        component: ClientViewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'blocked',
        component: UserBlockedComponent
    },
    {
        path: 'partner/view',
        component: UserPartnerViewComponent,
        canActivate: [AuthGuard]
    }
];
