<div class="main-content">
  <div class="header-modal">
    <span class="title-modal">Adicionar Parceiro</span>
    <div (click)="closeModal()" class="close-modal">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
  </div>

  <div class="subtitle-modal">
    Sua senha deve ser composta por pelo menos 1 letra maiúscula, 1 número e um caractere especial (ex: !&#64;#$%&*).
  </div>

  <div class="form-container">
    <form [formGroup]="partnersForm">
      <div class="row">
        <div class="label-container" style="display: flex;">
          <div class="input-value">
            <div class="input-legend"><span>Nome da empresa</span></div>
            <input nz-input type="text" placeholder="Digite aqui..." formControlName="companyName" class="input-container">
             <mat-error *ngIf="partnersForm.get('companyName').hasError('required') && partnersForm.get('companyName').touched">
              Nome da empresa <strong>é requerido</strong>
            </mat-error>
          </div>
          <div class="input-value-three">
            <div class="input-legend"><span>Centro de Custo</span></div>
            <input nz-input type="text" placeholder="Digite aqui..." formControlName="costCenter" class="input-container">
             <mat-error *ngIf="partnersForm.get('costCenter').hasError('required') && partnersForm.get('costCenter').touched">
              Centro de Custo <strong>é requerido</strong>
            </mat-error>
          </div>
          <div class="flex w-1/4 input-value-three cost-center-bond">
            <label class="cost-center-bond" nz-checkbox formControlName="costCenterBond">Vincular Centro de Custo
              do Parceiro aos seus clientes</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label-container" style="display: flex;">
          <div class="input-value">
            <div class="label-email"><span>E-mail</span></div>
          <input nz-input type="text" placeholder="Digite aqui..." formControlName="email" class="input-container">
           <mat-error
              *ngIf="partnersForm.get('email').hasError('email') && !partnersForm.get('email').hasError('required') && partnersForm.get('email').touched">
              Por favor digite um formato de e-mail válido
            </mat-error>
            <mat-error *ngIf="partnersForm.get('email').hasError('required') && partnersForm.get('email').touched">
              Email <strong>é requerido</strong>
            </mat-error>
          </div>
            <div class="input-value-three">
                <div class="label-email"><span>CPF/CNPJ</span></div>
                <input nz-input type="text" placeholder="Digite aqui..." formControlName="cpf_cnpj" mask="CPF_CNPJ" class="input-container">
                <mat-error *ngIf="partnersForm.get('cpf_cnpj').hasError('required') && partnersForm.get('cpf_cnpj').touched">
                    CPF/CNPJ <strong>é requerido<br></strong>
                </mat-error>
                <mat-error *ngIf="partnersForm.get('cpf_cnpj').hasError('CPFInvalido')">
                    CPF <strong>inválido</strong>
                </mat-error>
                <mat-error *ngIf="partnersForm.get('cpf_cnpj').hasError('CNPJInvalido')">
                    CNPJ <strong>inválido</strong>
                </mat-error>
            </div>
          <div class="input-value-three">
            <div class="label-email"><span>Link do contrato</span></div>
            <input nz-input type="text" placeholder="Cole aqui..." formControlName="contract" class="input-container">
            <mat-error *ngIf="partnersForm.get('contract').hasError('required') && partnersForm.get('contract').touched">
              Contrato <strong>é requerido<br></strong>
            </mat-error>
            <mat-error *ngIf="!partnersForm.get('contract').hasError('required') && partnersForm.get('contract').hasError('linkInvalid') && partnersForm.get('contract').touched">
              Formato <strong>inválido</strong>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label-container" style="display: flex;">
          <div class="input-value">
            <div class="label-email"><span>Senha</span></div>
            <input type="password" placeholder="Digite aqui..." formControlName="password" (blur)="validateFormPartner()" class="round-input">
              <mat-error *ngIf="partnersForm.get('password').hasError('required') && partnersForm.get('password').touched">
                Senha <strong>é requerido</strong>
              </mat-error>
          </div>
          <div class="input-value-two">
            <div class="label-email">
              <span>Confirmar senha</span>
            </div>
            <input type="password" placeholder="Digite aqui..." formControlName="confirm" (blur)="validateFormPartner()" class="round-input" onpaste="return false;">
            <mat-error *ngIf="partnersForm.get('confirm').hasError('required') && partnersForm.get('confirm').touched">
              Confirmar senha <strong>é requerido<br></strong>
            </mat-error>
            <mat-error *ngIf="confirmation">
              Senhas <strong>não conferem!</strong>
            </mat-error>
          </div>
        </div>
        <div class="label-container permission-container slider" style="display: flex; justify-content: space-between;" *ngIf="isMaster">
          <div class="permission-label">
            <span class="slider-text">Permitir visualização de câmeras ao vivo e gravações de usuários cadastrados</span>
          </div>
          <div class="permission-text">
            <nz-switch formControlName="permission"></nz-switch>
          </div>
        </div>
        <div class="label-container error-code" *ngIf="partnersForm.get('password').invalid && (partnersForm.get('password').dirty || partnersForm.get('password').touched)">
          <span>*Sua senha deve conter ao menos
            <span *ngIf="partnersForm.get('password').errors.minlength">8 caracteres, </span>
            <span *ngIf="partnersForm.get('password').errors.lowerCaseError;">1 letra minúscula, </span>
            <span *ngIf="partnersForm.get('password').errors.upperCaseError;">1 letra maiúscula, </span>
            <span *ngIf="partnersForm.get('password').errors.numberError;">1 número, </span>
            <span *ngIf="partnersForm.get('password').errors.symbolError;">1 caractere especial (ex: !&#64;#$%&*)</span>.
          </span>
        </div>
      </div>
      <div class="flex create-button">
        <button class="btn btn-primary enter-button" (click)="onRegister()" *ngIf="!loading">
          Adicionar Parceiro
        </button>
        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></span>
      </div>
    </form>
  </div>
</div>
