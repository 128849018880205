import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { Camera } from 'app/cameras/models';
import { TypeAnalytic } from 'app/analytics/models';
import { ActionPayload } from '../models';
import { createAction, props } from '@ngrx/store';

type Payload = ActionPayload | any;

export interface Metadata {
    userSub?: string;
    child_sub?: string;
    current_data?: string;
    list_cameras?: Camera[];
    analytics_type?: TypeAnalytic[];
    page?: number;
    limit?: number;
    comment?: string;
    evaluation?: boolean;
    id_alert?: number;
    list_alert?: number[];
    recording_filter?: string;
    group_id?: number[];
}

export type DetectionAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class DetectionActions {
    static readonly GET_DETECTIONS = 'GET_DETECTIONS';
    static readonly GET_DETECTIONS_RETURN = 'GET_DETECTIONS_RETURN';
    static readonly CLEANUP = 'CLEANUP';
    static readonly REVIEW_ALERT = 'REVIEW_ALERT';
    static readonly REVIEW_ALERT_RETURN = 'REVIEW_ALERT_RETURN';
    static readonly DELETE_ALERTS = 'DELETE_ALERTS';
    static readonly DELETE_ALERTS_RETURN = 'DELETE_ALERTS_RETURN';
    static readonly RESET_STATUS_REVIEW = 'RESET_STATUS_REVIEW';


    static get_detections = createAction(
        DetectionActions.GET_DETECTIONS,
        props<Metadata>()
    );

    static get_detections_return = createAction(
        DetectionActions.GET_DETECTIONS_RETURN,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        DetectionActions.CLEANUP
    );

    static review_alerts = createAction(
        DetectionActions.REVIEW_ALERT,
        props<Metadata>()
    );

    static review_alerts_return = createAction(
        DetectionActions.REVIEW_ALERT_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_alerts = createAction(
        DetectionActions.DELETE_ALERTS,
        props<Metadata>()
    );

    static delete_alerts_return = createAction(
        DetectionActions.DELETE_ALERTS_RETURN,
        props<{ payload: Payload }>()
    );

    static reset_status_review = createAction(
        DetectionActions.RESET_STATUS_REVIEW
    );
}
