import { Store } from '@ngrx/store';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { validatePassword } from '../models';
import { UserActions } from '../Services/actions';
import { isValidURL, confirmPassword, validateCPFCNPJ } from '../../Login/models';

@Component({
    selector: 'app-user-partners-form',
    templateUrl: './user-partners-form.component.html',
    styleUrls: ['./user-partners-form.component.scss']
})
export class UserPartnersFormComponent implements OnInit, OnDestroy {
    readonly registerPartnerUser$ = this.store.select((state: AppState) => state.Users.registerPartnerUser);

    registerPartnerUser: Subscription;

    loading: boolean;
    isMaster: boolean;

    @Output() modalClose = new EventEmitter<string>();
    hide = true;
    confirmation = false;
    partnersForm = new UntypedFormGroup({
        companyName: new UntypedFormControl('', [Validators.required]),
        costCenter: new UntypedFormControl('', [Validators.required]),
        costCenterBond: new UntypedFormControl('', [Validators.required]),
        contract: new UntypedFormControl('', [Validators.required, isValidURL]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', [Validators.required]),
        cpf_cnpj: new UntypedFormControl('', [Validators.required, validateCPFCNPJ]),
        permission: new UntypedFormControl(false, []),
    }, { validators: confirmPassword });

    get passwordInput() { return this.partnersForm.get('password'); }
    get confirmPasswordInput() { return this.partnersForm.get('confirm'); }

    constructor(private router: Router, private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.loading = false;
        this.isMaster = localStorage.getItem('profile_name') == '7d4e9f24-17e8-11ec-9621-0242ac130002';
        this.partnersForm.get('costCenterBond').setValue(false);
    }

    ngOnDestroy() {
        if (this.registerPartnerUser) {
            this.registerPartnerUser.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    validateFormPartner() {
        this.confirmation = false;
        if (this.partnersForm.invalid) {
            if (this.partnersForm.errors != null) {
                if (this.partnersForm.errors.passwordMatch == null) {
                    this.confirmation = true;
                }
                else {
                    this.confirmation = this.partnersForm.errors.passwordMatch;
                }
            }
        }
    }

    onRegister() {
        this.loading = true;
        let errorPass = false;

        if (this.partnersForm.get('companyName').invalid) {
            errorPass = true;
            this.partnersForm.get('companyName').markAsTouched();
        }
        if (this.partnersForm.get('email').invalid) {
            errorPass = true;
            this.partnersForm.get('email').markAsTouched();
        }
        if (this.partnersForm.get('password').invalid) {
            errorPass = true;
            this.partnersForm.get('password').markAsTouched();
        }
        if (this.partnersForm.get('confirm').invalid) {
            errorPass = true;
            this.partnersForm.get('confirm').markAsTouched();
        }
        if (this.partnersForm.get('cpf_cnpj').invalid) {
            errorPass = true;
            this.partnersForm.get('cpf_cnpj').markAsTouched();
        }
        if (this.partnersForm.get('contract').invalid) {
            errorPass = true;
            this.partnersForm.get('contract').markAsTouched();
        }
        if (this.partnersForm.get('costCenter').invalid) {
            errorPass = true;
            this.partnersForm.get('costCenter').markAsTouched();
        }
        if (this.partnersForm.get('costCenterBond').invalid) {
            errorPass = true;
            this.partnersForm.get('costCenterBond').markAsTouched();
        }
        if (this.confirmation) {
            errorPass = true;
        }

        if (!errorPass) {
            this.store.dispatch(UserActions.create_partners({
                user_sub: localStorage.getItem('sub'),
                name: this.partnersForm.get('companyName').value,
                email: this.partnersForm.get('email').value,
                password: this.partnersForm.get('password').value,
                cnpj: this.partnersForm.get('cpf_cnpj').value,
                partner_permission: this.partnersForm.get('permission').value,
                contract: this.partnersForm.get('contract').value,
                costCenter: this.partnersForm.get('costCenter').value,
                costCenterMark: this.partnersForm.get('costCenterBond').value === true,
            }));
            this.registerPartnerUser = this.registerPartnerUser$.subscribe(register_feedback => {
                if (register_feedback) {
                    if (register_feedback.status === 2) {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(200, 'user', 'register-partner-user', register_feedback.message);
                        this.closeModal();
                    } else {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(400, 'user', 'register-partner-user-fail', register_feedback.message);
                        this.store.dispatch(UserActions.cleanup());
                    }
                }
            });
        } else {
            this.loading = false;
        }
    }

    closeModal() {
        this.modalClose.emit();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/partners']);
    }

}
