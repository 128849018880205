import { Feedback } from 'app/Shared/models';
import { Observable } from 'rxjs';

export interface CEP {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    unidade: string;
    ibge: string;
    gia: string;
}

export interface Resolution {
    alias: string;
    width: number;
    height: number;
}

export enum OperationStatus {
    Unloaded,
    Loading,
    Success,
    Fail
}

export interface CheckResolution {
    uri: string;
    height?: number;
    width?: number;
    fps?: number;
    plan_id?: number;
}

export interface CameraThumbnail {
    hashname: string;
    sub: string;
    uri: string;
    refresh?: boolean;
}


export interface Plan {
    id?: number;
    alias: string;
    price: number;
    icon?: string;
    days_storage: number;
    color: string;
    max_invites: number;
    max_fps: number;
    resolution?: {
        alias: string;
        width: string;
        height: string;
    };
    fantasy_name?: string;
}

export interface AnalyticTypes {
    types: string[];
}

export interface ClientsToGroup {
    client_id: number;
    client_name: string;
    client_sub?: string;
    associates: [
        {
            name: string;
            id: number;
        }
    ];
    cameras: [
        {
            name: string;
            id: number;
        }
    ];
}

export interface Camera {
    id?: number;
    status?: boolean;
    src?: string;
    url_view?: string;
    alias: string;
    name?: string;
    user_picture?: string;
    hashname?: string;
    thumbnail?: string;
    address?: Address;
    id_plan?: number;
    guests?: number;
    active?: boolean;
    camera_group?: [
        {
            name: string;
            type: string;
        }
    ];
    analytics?: number;
    last_modify?: string;
    registration_date?: string;
    link_rtsp?: string;
    link_rtmp?: string;
    owner?: string;
    is_processing_analytic?: boolean;
    is_testing_analytics?: boolean;
    protocol?: string;
    analytic_available?: number;
    rtmp_warning?: boolean;
    deleted?: boolean;
    sub?: string;
    created_off?: boolean;
    plan_details?: [{
        id?: number;
        alias?: string;
        color?: string;
        price?: number;
        max_invites?: number;
        max_fps?: number;
        days_storage?: number;
        res?: string;
        widht?: number;
        height?: number;
    }];
    id_user?: number;
    select?: boolean;
    show_groups?: boolean;
    str_filter?: string;
    cam_stat_filter?: boolean;
    date_active?: string;
    id_camera?: number;
    cache_thumb?: Observable<string> | string;
    refresh_thumb?: boolean;
    informationMenu?: string[];
    analytic_status?: 'warning' | 'disabled' | 'running';
    integrated_camera_status?: boolean;
    is_integrated_camera?: boolean;
    url_http?: string;
    cam_model?: {
        hash?: string;
        name?: string;
    };
    cam_user?: string;
    cam_pass?: string;
    integrated_camera?: IntegratedCamera;
}

export interface IntegratedCamera {
    camera_model_hash: string;
    camera_model_name: string;
    hash_device: string;
    id: number;
    id_camera: number;
    login: string;
    password: string;
    status_camera_integrated?: 'running' | 'warning' | 'disabled' | 'processing';
    url_http: string;
}

export interface CamerasReport {
    percent_on: number;
    percent_off_72h: number;
    percent_off_not_72h: number;
    total: number;
    cameras_on: number;
    cameras_off_72h: number;
    cameras_off_not_72h: number;
}

export interface GroupCamerasReport {
    percent_on: number;
    percent_off_72h: number;
    percent_off_not_72h: number;
    total: number;
    cameras_on: number;
    cameras_off_72h: number;
    cameras_off_not_72h: number;
}

export interface BlockedCamerasReport {
    sub: string;
    child_sub: string;
    clients_id: string[];
    cameras: BlockedCamerasPagination;
}

export interface BlockedCamerasPagination {
    cameras: InfoBlockedCameras;
    actual: number;
    pages: number;
    limit: number;
    total: number;
}

export interface InfoBlockedCameras {
    id_camera: number;
    id: number;
    observation: string;
    client_name: string;
}


export interface GroupCamera {
    alias: string;
    id_camera_group: number;
    is_public: boolean;
    registered_at: string;
    clients_info_group?: ClientsInfoGroup[];
    cameras_info_group?: PaginationCameras;
    associateds_info_group?: AssociatedsInfoGroup[];
}

export interface ClientsInfoGroup {
    id_user: number;
    name: string;
    sub: string;
    email: string;
    cam_count_in_group: number;
    select?: boolean;
}

export interface AssociatedsInfoGroup {
    id_user: number;
    name: string;
    sub: string;
    email: string;
    client_name: string;
    select?: boolean;
}

export interface Address {
    id?: number;
    cep?: string;
    rua?: string;
    numero?: number;
    complemento?: string;
    cidade?: string;
    bairro?: string;
    estado?: string;
    latitude?: string;
    longitude?: string;
}

export interface Video {
    url: string;
    media_create_date: string;
    media_end_date: string;
    media_duration: string;
    alerts?: {
        startedat: string;
    }[];
}

export interface HealthCheck {
    downtime: number;
    downtime_ratio: number;
    current_status: string;
    status: {
        range: number[];
        alias: string;
    }[];
}

export interface HealthHistory {
    event_end: string;
    event_begin: string;
    down_time: number;
    date?: string;
}

export interface HealthPanel {
    startDate: Date;
    endDate: Date;
    events: HealthHistory[];
}

export interface CameraState {
    postCam?: Feedback;
    cameras?: Cameras;
    groupCameraID?: GroupCamera[];
    groupCamera?: GroupCamera[] | PaginationCameras;
    addresses?: Address[];
    check_resolution?: Feedback;
    camera_thumbnail?: Feedback | string;
    cameraShared?: Camera[];
    plans?: Plan[];
    camera_view?: Camera;
    camera_id?: number;
    plan_id?: number;
    plan_max_invite?: number;
    guests?: any[];
    playlist?: Video[];
    resettingPlaylist?: boolean;
    modal_open_invite_user: ModalState;
    modal_open_update_camera: ModalState;
    modal_open_list_address_camera: ModalState;
    modal_create_mosaic: ModalState;
    deleteCam?: Feedback;
    updateCam?: Feedback;
    updateCameraGroup?: Feedback;
    updateStatusCam?: number;
    deleteGuest?: number;
    inviteUsers?: any;
    updateGuests?: any;
    mosaics?: Mosaic[];
    currentMosaic?: Mosaic;
    editMosaic?: Mosaic;
    userMosaics?: Mosaic[];
    camera_address: Address;
    success?: boolean;
    previewCam?: string;
    urlSignedVideo?: string;
    editStatus?: Feedback;
    deleteStatus?: Feedback;
    camera_view_analytics?: Camera;
    connectionHealth?: HealthCheck;
    connectionHistory?: HealthPanel[];
    historyLoading?: boolean;
    finalPage?: boolean;
    typesAnalytic?: AnalyticTypes[];
    created_by?: string;
    RTMPUri?: RTMPUri;
    OpenViewGroup?: boolean;
    clientsGroup?: ClientsToGroup[];
    createGroup?: Feedback;
    deleteCamGroup?: Feedback;
    camerasReport?: CamerasReport[];
    blockedCamerasReport?: BlockedCamerasReport[];
    groupCamerasReport?: GroupCamerasReport[];
    providerIntegratedCameras?: ProviderIntegratedCameras[];
}

export interface GuestsList {
    name: string;
    email: string;
    sub: string;
    id: number;
    profilePicture: string;
    view_alert_history: boolean;
    view_live: boolean;
    view_recording: boolean;
    receive_analytic_alert: boolean;
    invitation_time: string;
}

export interface AddCamGuestData {
    user_sub: string;
    child_sub: string;
    camera_id: number;
    camera_name: string;
    email: string;
    viewRecording: boolean;
    receiveAnalyticAlert: boolean;
    viewLive: boolean;
    viewAlertHistory: boolean;
}

export interface NavButton {
    title: string;
    status: boolean;
}

export enum UpdateState {
    Fail = -1,
    Cancel = -2,
}

export interface UpdateCamera {
    name: string;
    plan: string;
}

export interface CameraId {
    id_camera: number;
    id?: number;
    alias?: string;
    url_view?: string;
    status?: boolean;
    hashname?: string;
    thumbnail?: string;
}

export interface Mosaic {
    id_mosaic_template?: number;
    id?: number;
    height?: number;
    width?: number;
    cameras?: CameraId[];
    maxIndex?: number;
    name?: string;
    fixed?: boolean;
    timeStatus?: number;
}

export enum ListType {
    Live,
    Recording,
    Report
}

export enum ModalState {
    Open,
    Cancel,
    Closed
}

export interface RTMPUri {
    uri: string;
}

export interface CreateGroup {
    sub: string;
    name: string;
    cameras_id: number[];
    associateds_id: number[];
    clients_id: number[];
    is_public: boolean;
}

export interface AddInfoGroupData {
    mode: string;
    name: string;
    id: number;
    associate_list?: number[];
    client_list?: number[];
    camera_list?: number[];
}


export interface PaginationCameras {
    cameras: Camera[];
    actual: number;
    pages: number;
    limit: number;
    total: number;
    total_analytic: number;
}

export interface Cameras {
    model: 'cameras';
    cameras: PaginationCameras | Camera[];
    status: OperationStatus;
}

export interface SortColumnCamera {
    column: string;
    sort: 'DESC' | 'ASC';
}

export interface ProviderIntegratedCameras {
    providersId: number;
    manufacturer: string;
    models: string;
    providersName: string;
    hashProvider: string;
}
