import { UserInventory, validatePassword } from '../../Users/models';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import { Style } from 'ol/style';
import { Translate } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import { CameraService } from '../Services/camera.service';
import { environment } from 'environments/environment';
import { Plan, Camera, Address, CheckResolution, CameraThumbnail } from '../models';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../Shared/Services/shared.service';
import { CameraActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { Feedback, OperationStatus, ResultAlert } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { UserActions } from 'app/Users/Services/actions';
import { Clipboard } from '@angular/cdk/clipboard';
import { isValidURL } from 'app/Login/models';

@Component({
    selector: 'app-camera-form',
    templateUrl: './camera-form.component.html',
    styleUrls: ['./camera-form.component.scss']
})
export class CameraFormComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly previewCam$ = this.store.select((state: AppState) => state.camera.previewCam);
    readonly status$ = this.store.select((state: AppState) => state.camera.postCam);
    readonly check_resolu$ = this.store.select((state: AppState) => state.camera.check_resolution);
    readonly camera_thumbn$ = this.store.select((state: AppState) => state.camera.camera_thumbnail);
    readonly address$ = this.store.select((state: AppState) => state.camera.addresses);
    readonly RTMPUriResult$ = this.store.select((state: AppState) => state.camera.RTMPUri);
    readonly providerIntegratedCameras$ = this.store.select((state: AppState) => state.camera.providerIntegratedCameras);
    readonly userInventoryResult$ = this.store.select((state: AppState) => state.Users.userInventory);

    //Subscriptions
    getAddress: Subscription;
    prevCam: Subscription;
    postCam: Subscription;
    check_resolution: Subscription;
    camera_thumbnail: Subscription;
    returnSwal: Subscription;
    valueChange: Subscription;
    paymentMethodSub: Subscription;
    updatePaymentSub: Subscription;
    camAddressServiceSub: Subscription;
    userInventory: Subscription;
    RTMPUri: Subscription;
    providerIntegratedCameras: Subscription;

    plans: Observable<Plan[]>;
    planList: Plan[];
    public cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    canvasDraw: HTMLCanvasElement;
    ctxDraw: CanvasRenderingContext2D;
    imgCam: any;
    linkImgCam = '../../../assets/img/splash_logo.svg';
    public map: Map;
    public source: OSM;
    public view: View;
    longitude: number;
    latitude: number;
    previousRouter = 'Início';
    pathPreviousRouter = '/maps/home';
    iconFeature: Feature;
    iconStyle: Style;
    vectorSource: VectorSource;
    // vectorLayer: VectorLayer;
    translate: Translate;
    hasMarker = false;
    currentStep = 0;
    cameras: Camera;
    prevLinkRTSP: string;
    offlineCam: boolean;
    camName;

    camForm = new UntypedFormGroup({
        camSrc: new UntypedFormControl('', [Validators.required, Validators.pattern(/(rtsp:\/\/)[a-zA-Z0-9!#_.]+[:][a-zA-Z0-9!#_.@]+[@][a-zA-Z0-9.:\-_]+[\/]/)]),
        camName: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(?!\s{1})((?!\s{2,}).)*$/)])
    });

    intregatedCamForm = new UntypedFormGroup({
        urlHttp: new UntypedFormControl('', [isValidURL, Validators.required]),
        user: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required]),
    });

    addressForm = new UntypedFormGroup({
        cep: new UntypedFormControl('', [Validators.required]),
        rua: new UntypedFormControl('', [Validators.required]),
        numero: new UntypedFormControl('', [Validators.pattern('[0-9]+')]),
        cidade: new UntypedFormControl('', [Validators.required]),
        estado: new UntypedFormControl('', [Validators.required]),
        bairro: new UntypedFormControl('', [Validators.required]),
        complemento: new UntypedFormControl(''),
    });
    noNumberForm = new UntypedFormGroup({
        noNum: new UntypedFormControl('', [Validators.required])
    });
    planForm = new UntypedFormGroup({
        plan: new UntypedFormControl('', [Validators.required])
    });
    states: string[] = environment.estados;
    estados = environment.Estados;
    loadingGeo = false;
    loadingCep = false;
    loading = false;
    loadingCheckResolution = false;
    loadingGetThumb = false;
    loadingCreateCamera = false;
    showResolution = false;
    showGetThumb = false;
    showCreateCamera = false;
    loadingPaymentMethod = false;
    loadingPrevCam: boolean;
    idAssociate = environment.associateId;
    mapShow = false;
    camAddressObject: Address;
    waiting: boolean;
    hasListAddress;
    notHasListAddress;
    userSub: string;
    client_sub: string;
    addressObject: Address;
    rstpIsValid = false;
    seeMenuTab = true;
    stepAPIOne = null;
    stepAPITwo = null;
    stepAPIThree = null;
    pageName = 'camera-form';
    showAddress: boolean;
    selectedPlanInfo: Plan;
    waitSavingPayment: boolean;
    partnerClient: boolean;
    partner: boolean;
    inventory: UserInventory;
    activeSteps: string[];
    rtmpSteps = ['Escolher protocolo', 'Conectar via link RTMP', 'Cadastrar dados da câmera', 'Escolher plano de gravação'];
    rtspSteps = ['Escolher protocolo', 'Conectar via link RTSP', 'Confirmar imagem', 'Cadastrar dados da câmera', 'Escolher plano de gravação'];
    protocol: string;
    noAddress = false;
    copied = false;
    counterRTMP = 0;
    errorRTMP = false;
    successRTMP = false;
    isLoadingRTMP = false;
    loadingPost = false;
    openFailDropdown = false;
    isLoadingRTSP = false;
    errorRTSP = false;
    isRTMPPopoverVisible = false;
    isRTMPCameraListExpanded = false;
    hide = true;
    openExpandedForm: boolean;

    modelList: {
        id: number | string;
        text: string;
    }[];

    camModel: {
        hash: string;
        name: string;
    }[];

    constructor(private camService: CameraService, private service: SharedService, private router: Router, private readonly store: Store<AppState>,
        private cameraAddressService: CameraService, private validationResponse: ValidationResponseHandlerModule,
        private clipboard: Clipboard) {}

    ngOnInit() {
        this.activeSteps = ['Escolher protocolo', 'Conectar câmera'];

        //Checa se o usuario é cliente avantia ou cliente de um parceiro
        if (localStorage.getItem('profile_name') == '61902d2b-3ada-49f3-b42a-1775bc064bb0') {
            this.partnerClient = true;
        } else if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.partner = true;
        } else {
            this.partnerClient = false;
            this.partner = false;
        }

        this.userSub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.hasListAddress = false;
        this.notHasListAddress = true;
        this.offlineCam = false;
        if (localStorage.getItem('clientView') == null){
            if (localStorage.getItem('previousRouter') != null) {
                // if (localStorage.getItem('previousRouter') == '/maps/home') {
                //   this.previousRouter = 'Mapa';
                //   this.pathPreviousRouter = '/maps/home';
                // }
                if (localStorage.getItem('previousRouter') == '/cameras/list') {
                    this.previousRouter = 'Lista de Câmeras';
                    this.pathPreviousRouter = '/cameras/list';
                }
            }
        }
        else{
            this.previousRouter = 'Início';
            this.pathPreviousRouter = 'users/client/view';

        }
        this.loadingPrevCam = false;
        this.currentStep = 0;
        //Lê planos do backend
        this.getPlans();
        this.getPrevCam();
        this.getPostCamResult();
        this.getCheckResolutionResult();
        this.getCreateThumbnailResult();
        this.getUserInventory();
        this.getRTMPUri();
        this.getProviderIntegratedCameras();
        this.noNumberForm.get('noNum').setValue(false);
        this.valueChange = this.noNumberForm.get('noNum').valueChanges.subscribe(value => {
            if (value) {
                this.addressForm.get('numero').setValue('');
                this.addressForm.get('numero').disable();
            }
            else {
                this.addressForm.get('numero').setValue('');
                this.addressForm.get('numero').enable();
            }
        });
        // Pega o retorno do endereço após a seleção no modal
        this.camAddressServiceSub = this.cameraAddressService.currentAddress.subscribe(camAddressObject => {
            if (camAddressObject) {
                this.hasListAddress = true;
                this.notHasListAddress = false;
                this.insertAddress(camAddressObject);
            }
        });
        // Recebe o retorno das informações do swal
        this.returnSwal = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        this.store.dispatch(UserActions.get_user_payment_method({ user_sub: this.userSub }));
        this.openExpandedForm = false;
    }

    newAddress() {
        this.notHasListAddress = false;
    }

    insertAddress(cameraAddress: Address) {
        this.addressForm.get('cep').setValue(cameraAddress.cep);
        this.addressForm.get('rua').setValue(cameraAddress.rua);
        this.addressForm.get('cidade').setValue(cameraAddress.cidade);
        this.addressForm.get('estado').setValue(cameraAddress.estado);
        this.addressForm.get('bairro').setValue(cameraAddress.bairro);
        this.noNumberForm.get('noNum').setValue(false);
        if (!cameraAddress.numero) {
            this.noNumberForm.get('noNum').setValue(true);
        }
        else {
            this.addressForm.get('numero').setValue(cameraAddress.numero);
        }
    }

    ngOnDestroy() {
        if (this.prevCam) {
            this.prevCam.unsubscribe();
        }
        if (this.postCam) {
            this.postCam.unsubscribe();
        }
        if (this.getAddress) {
            this.getAddress.unsubscribe();
        }
        if (this.check_resolution) {
            this.check_resolution.unsubscribe();
        }
        if (this.camera_thumbnail) {
            this.camera_thumbnail.unsubscribe();
        }
        if (this.valueChange) {
            this.valueChange.unsubscribe();
        }
        if (this.returnSwal) {
            this.returnSwal.unsubscribe();
        }
        if (this.paymentMethodSub) {
            this.paymentMethodSub.unsubscribe();
        }
        if (this.updatePaymentSub) {
            this.updatePaymentSub.unsubscribe();
        }
        if (this.camAddressServiceSub) {
            this.camAddressServiceSub.unsubscribe();
        }
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        if (this.RTMPUri) {
            this.RTMPUri.unsubscribe();
        }
        if (this.providerIntegratedCameras) {
            this.providerIntegratedCameras.unsubscribe();
        }

        this.store.dispatch(CameraActions.cleanup_post());
        this.store.dispatch(CameraActions.clear_camera_resolution());
        this.store.dispatch(CameraActions.get_provider_integrated_cameras_clear());
        this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
        this.store.dispatch(CameraActions.reset_preview());
        this.store.dispatch(UserActions.cleanup());
        this.isRTMPPopoverVisible = false;
    }

    ngAfterViewInit() {
    //Renderização da câmera
    // this.initMap();
    // setTimeout(() => this.initMap(), 1500);
    }

    // Inicializa o mapa com a posição atual do usuario
    // initMap() {
    //   this.view = new View({
    //     center: [0, 0],
    //     zoom: 16
    //   });
    //   this.map = new Map({
    //     layers: [
    //       new TileLayer({
    //         preload: 3,
    //         source: new OSM({})
    //       })
    //     ],
    //     view: this.view
    //   });
    //   const element = document.getElementById('map');
    //   //centraliza o mapa para a localização do navegador
    //   this.map.setTarget(element);
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       this.latitude = position.coords.latitude;
    //       this.longitude = position.coords.longitude;
    //       this.view.animate({
    //         center: fromLonLat([this.longitude, this.latitude]),
    //         duration: 2000
    //       });
    //       this.placeMarker(this.longitude, this.latitude);
    //     });
    //   }
    //   this.currentStep = 0;
    //   this.wizard.goToStep(0);
    //   this.mapShow = true;
    // }

    getUserInventory() {
        this.store.dispatch(UserActions.get_user_inventory({
            user_sub: this.userSub,
            child_sub: this.client_sub
        }));
        this.userInventory = this.userInventoryResult$.subscribe(result => {
            if (result) {
                this.inventory = result[0];
                if (this.planList) {
                    this.planList = this.filterPlans(this.planList);
                }
            }
        });
    }

    // Valida o link RTSP e pega um preview da camera
    getPrevCam() {
        this.prevCam = this.previewCam$.subscribe(result => {
            if (result != null) {
                if ((result.length > 0) && (Number(result) != 404) && (Number(result) != 504)) {
                    if (this.protocol == 'rtsp') {
                        this.currentStep = 2;
                        this.resetPageScroll();
                    } else {
                        this.currentStep = 1;
                        this.successRTMP = true;
                    }
                    this.rstpIsValid = true;
                    this.linkImgCam = result;
                    this.plotImage();
                }
                else {
                    if (this.protocol == 'rtsp') {
                        this.isLoadingRTSP = false;
                        this.errorRTSP = true;
                        if (this.prevLinkRTSP != this.camForm.get('camSrc').value) {
                            this.prevLinkRTSP = this.camForm.get('camSrc').value;
                        }
                    } else {
                        if (this.counterRTMP < 5) {
                            this.counterRTMP++;
                            setTimeout(() => {
                                this.store.dispatch(CameraActions.get_preview_camera({ previewCam: this.camForm.get('camSrc').value, countRTMP: this.counterRTMP }));
                            }, 12000);
                        } else {
                            this.errorRTMP = true;
                            this.copied = false;
                        }
                    }
                }
                this.store.dispatch(CameraActions.reset_preview());
                this.loadingPrevCam = false;
            }
        });
    }

    toPreviousPath() {
        this.router.navigateByUrl(this.pathPreviousRouter);
    }

    // Encaminha para o proximo wizard
    goToNextStep() {
        switch (this.currentStep) {
            case 0:
                this.isRTMPPopoverVisible = false;
                if (this.protocol == 'rtsp') {
                    this.currentStep = 1;
                    this.resetPageScroll();
                } else {
                    this.refreshRTMPUri();
                    this.currentStep = 1;
                    this.resetPageScroll();
                    this.camForm.get('camSrc').disable();
                }
                break;
            case 1:
                if (this.protocol == 'rtsp') {
                    if (this.validateLinkStep(true)) {
                        if (this.prevLinkRTSP == this.camForm.get('camSrc').value) {
                            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-camera-rtsp-offline', 'cameras.camera_rtsp_offline');
                        } else {
                            this.isLoadingRTSP = true;
                            this.errorRTSP = false;
                            this.store.dispatch(CameraActions.get_preview_camera({ previewCam: this.camForm.get('camSrc').value }));
                            this.loadingPrevCam = true;
                        }
                    }
                } else {
                    if (!this.successRTMP) {
                        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-camera-rtmp-offline', 'cameras.camera_rtmp_offline');
                    }
                    if (this.validateLinkStep(false)) {
                        this.currentStep = 2;
                        this.resetPageScroll();
                    }
                }
                break;
            case 2:
                if (this.protocol == 'rtsp') {
                    this.currentStep = 3;
                    this.resetPageScroll();
                } else {
                    if (this.camForm.touched) {
                        if (this.validateCamData()) {
                            this.currentStep = 3;
                            this.resetPageScroll();
                        }
                    }
                }
                break;
            case 3:
                if (this.protocol == 'rtsp') {
                    if (this.validateCamData()) {
                        this.currentStep = 4;
                        this.resetPageScroll();
                    }
                } else {
                    if (!this.planForm.get('plan').invalid){
                        if (this.offlineCam) {
                            this.insertCamera();
                        } else {
                            this.checkCameraResolution();
                        }
                    } else {
                        this.validationResponse.validationResponseHandler(400, this.pageName, 'plan-null', 'cameras.plan_null');
                    }
                }
                break;
            case 4:
                if (this.protocol == 'rtsp') {
                    if (!this.planForm.get('plan').invalid){
                        if (this.offlineCam) {
                            this.insertCamera();
                        } else {
                            this.checkCameraResolution();
                        }
                    } else {
                        this.validationResponse.validationResponseHandler(400, this.pageName, 'plan-null', 'cameras.plan_null');
                    }
                }
                break;
        }
    }

    // Volta para a escolha de plano
    goToPrevStep() {
        this.currentStep = this.currentStep - 1;
        this.setDivMargin(3);
        this.loading = false;
        this.loadingCheckResolution = false;
        this.loadingCreateCamera = false;
        this.loadingGetThumb = false;
    }

    // Plota a imagem do preview da câmera
    plotImage(): string {
        return 'data:image/png;base64,' + this.linkImgCam;
    }

    //Consulta o endereço a partir do CEP e seta no formulário
    getCepAddress(): void {
        this.loadingCep = true;
        let cep: string = this.addressForm.get('cep').value;
        cep = cep.replace(/\D/g, '');
        if (this.addressForm.get('cep').invalid) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'cep-null', 'cameras.cep_null');
            this.loadingCep = false;
        }
        else {
            this.service.getAddressFromCEP(cep).toPromise().then(result => {
                if (result.erro) {
                    this.validationResponse.validationResponseHandler(404, this.pageName, 'cep-not-found', 'cameras.cep_not_found');
                }
                else {
                    this.addressForm.get('rua').setValue(result.logradouro);
                    this.addressForm.get('bairro').setValue(result.bairro);
                    this.addressForm.get('cidade').setValue(result.localidade);
                    this.addressForm.get('estado').setValue(result.uf);
                    this.getLongLat();
                }
                this.loadingCep = false;
            }).catch(error => {
                this.loadingCep = false;
                this.validationResponse.validationResponseHandler(400, this.pageName, 'cep-calculator', 'cameras.cep_calculator_error');
            });
        }
    }

    //Consulta latitude e longitude através do endereço
    getLongLat(): void {
        this.loadingGeo = true;
        const data = {
            logradouro: this.addressForm.get('rua').value,
            bairro: this.addressForm.get('bairro').value,
            cidade: this.addressForm.get('cidade').value,
            estado: this.addressForm.get('estado').value,
            country: 'Brasil'
        };
        this.service.getGeoLocation(data).toPromise().then(result => {
            if (result.length) {
                this.latitude = parseFloat(result[0].lat);
                this.longitude = parseFloat(result[0].lon);
                //Centraliza mapa para novas coordenadas
                // this.view.animate({
                //   center: fromLonLat([this.longitude, this.latitude]),
                //   duration: 2000,
                //   zoom: 16
                // });
                // this.placeMarker(this.longitude, this.latitude);
            }
            else {
                this.validationResponse.validationResponseHandler(404, this.pageName, 'address-not-found', 'cameras.address_not_found');
            }
            this.loadingGeo = false;
        });
    }

    // Insere o pin no mapa
    // placeMarker(long: number, lat: number) {
    //   //Atualizar posição do marcador
    //   if (this.hasMarker) {
    //     this.iconFeature.setGeometry(new Point(fromLonLat([long, lat])));
    //   }
    //   //cria marcador
    //   else {
    //     this.iconFeature = new Feature({
    //       geometry: new Point(fromLonLat([long, lat]))
    //     });
    //     this.iconStyle = new Style({
    //       image: new Icon({
    //         anchor: [0.5, 46],
    //         anchorXUnits: IconAnchorUnits.FRACTION,
    //         anchorYUnits: IconAnchorUnits.PIXELS,
    //         src: '../../../assets/img/overley.png'
    //       })
    //     });
    //     this.iconFeature.setStyle(this.iconStyle);
    //     //adiciona marcador ao mapa
    //     this.vectorSource = new VectorSource({
    //       features: [this.iconFeature]
    //     });
    //     this.vectorLayer = new VectorLayer({
    //       source: this.vectorSource
    //     });
    //     this.map.addLayer(this.vectorLayer);
    //     //habilita translação no marcador
    //     this.translate = new Translate({
    //       features: new Collection([this.iconFeature])
    //     });
    //     //adiciona callback a evento disparado no fim de uma translação
    //     this.translate.on('translateend', this.logPosition.bind(this));
    //     this.map.addInteraction(this.translate);
    //     //Indica que já existe um marcador
    //     this.hasMarker = true;
    //   }
    // }

    //Recupera endereço a partir de coordenadas
    // logPosition(event: TranslateEvent) {
    //   const coord = toLonLat(event.coordinate);
    //   this.addressForm.disable();
    //   this.longitude = coord[0];
    //   this.latitude = coord[1];
    //   this.service.getReverseGeo(coord).toPromise().then(result => {
    //     this.replaceForm(result);
    //     this.addressForm.enable();
    //   }).catch(error => console.log(error));
    // }

    //atualiza formulário com dados do novo endereço
    // replaceForm(result: any) {
    //   this.addressForm.get('cep').setValue(result.address.postcode);
    //   this.addressForm.get('rua').setValue(result.address.road);
    //   //this.addressForm.get('num').setValue(result.address.house_number);
    //   this.addressForm.get('bairro').setValue(result.address.suburb);
    //   this.addressForm.get('cidade').setValue(result.address.city);
    //   // inicio das funções para mapeamento
    //   const estadoUf = result.address.state;
    //   const campo = this.addressForm;
    //   // Função para inserir o estado no section
    //   this.estados.forEach(function (uf) {
    //     if (uf.estado === estadoUf) {
    //       campo.get('estado').setValue(uf.uf);
    //     }
    //   });
    // }

    // Retorna a lista de planos
    getPlans() {
        this.plans = this.camService.getPlans(this.idAssociate);
        this.plans.subscribe(async planList => {
            if (planList) {
                if (!this.inventory) {
                    this.planList = planList;
                } else {
                    this.planList = this.filterPlans(planList);
                }
            }
        });
    }

    filterPlans(plans) {
        return plans.filter(plan => {
            const ci = this.inventory.inventory.ci[plan.id];
            return ci ? ci.available > 0 : false;
        });
    }

    // Retorna o id do plano escolhido para o FormControl
    getIdPlan(id_plan) {
        this.planForm.get('plan').setValue(id_plan);
    }

    //cadastra câmera
    checkCameraResolution() {
        if (this.planForm.get('plan').valid) {
            const planId = Number(this.planForm.get('plan').value);
            const findResolutionInformations = this.planList.find(plan => plan.id == planId);
            this.selectedPlanInfo = findResolutionInformations;
            this.loading = true;
            this.loadingCheckResolution = true;
            this.showResolution = true;
            this.loadingCreateCamera = true;
            this.loadingGetThumb = true;
            const checkResolution: CheckResolution = {
                uri: this.camForm.get('camSrc').value,
                height: Number(findResolutionInformations.resolution.height),
                width: Number(findResolutionInformations.resolution.width),
                fps: Number(findResolutionInformations.max_fps)
            };
            this.store.dispatch(CameraActions.check_camera_resolution({
                check_resolution: checkResolution
            }));
        }
        else {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'plan-null', 'cameras.plan_null');
        }
    }

    insertCamera() {
        const camera: Camera = {
            src: this.camForm.get('camSrc').value,
            alias: this.camForm.get('camName').value,
            address: this.addressObject,
            id_plan: this.planForm.get('plan').value,
            is_integrated_camera: this.openExpandedForm,
            url_http: this.intregatedCamForm.get('urlHttp').value,
            cam_model: this.camModel && this.openExpandedForm ? this.camModel[0] : null,
            cam_user: this.intregatedCamForm.get('user').value,
            cam_pass: this.intregatedCamForm.get('password').value,
        };
        this.loading = true;
        this.loadingCheckResolution = false;
        this.loadingCreateCamera = true;
        this.showCreateCamera = true;
        this.loadingGetThumb = true;
        this.store.dispatch(CameraActions.create_camera({
            camera: camera,
            user_sub: this.userSub,
            child_sub: this.client_sub,
            create_off: this.offlineCam
        }));
    }

    createTHumbnail(hashname: string) {
        this.loading = true;
        this.loadingCheckResolution = false;
        this.loadingCreateCamera = false;
        this.loadingGetThumb = true;
        this.showGetThumb = true;
        const userSub = localStorage.getItem('sub');
        const cameraThumbnail: CameraThumbnail = {
            hashname: hashname,
            sub: userSub,
            uri: this.camForm.get('camSrc').value,
        };
        this.store.dispatch(CameraActions.create_camera_thumbnail({
            camera_thumbnail: cameraThumbnail
        }));
    }
    // Retorna o feedback de cadastro da câmera
    getCheckResolutionResult() {
        this.check_resolution = this.check_resolu$.subscribe(result => {
            if (result) {
                if (result.status === OperationStatus.Success) {
                    this.insertCamera();
                    this.stepAPIOne = true;
                }
                else if (result.status === OperationStatus.Fail) {
                    this.loading = false;
                    this.loadingCheckResolution = false;
                    this.loadingCreateCamera = false;
                    this.loadingGetThumb = false;
                    this.store.dispatch(CameraActions.cleanup_post());
                    this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
                    this.store.dispatch(CameraActions.clear_camera_resolution());
                    const returnMsg = result.message ? result.message : 'Não foi possível checar a resolução da camera';
                    // Chama o swal de erro e exibe a mensagem de erro ou sucesso
                    // this.validationResponse.validationResponseHandler(400, this.pageName, 'create-camera', 'cameras.create_camera_error');
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'create-camera-resolution-error', returnMsg);
                }
            }
        });
    }

    // Retorna o feedback de cadastro da câmera
    getPostCamResult() {
        this.postCam = this.status$.subscribe(result => {
            if (result) {
                if (result.status === OperationStatus.Success) {
                    this.stepAPITwo = true;
                    if (this.offlineCam) {
                        this.finishCreateCam();
                    } else {
                        this.createTHumbnail(result.data['hashname']);
                    }
                }
                else if (result.status === OperationStatus.Fail) {
                    if (result.statusCode != 500) {
                        this.store.dispatch(CameraActions.cleanup_post());
                        this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
                        this.store.dispatch(CameraActions.clear_camera_resolution());
                        // Chama o swal de erro e exibe a mensagem de erro ou sucesso
                        this.validationResponse.validationResponseHandler(400, this.pageName, 'create-camera', 'cameras.create_camera_error');
                    } else {
                        this.validationResponse.validationResponseHandler(500, this.pageName, 'create-camera-limit', result.message);
                        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                        this.router.onSameUrlNavigation = 'reload';
                        this.router.navigate(['/cameras/list']);
                    }
                }
            }
        });
    }

    // Valida o retorno do Swal e o método do pai ao qual fez a chamada
    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'tryAgain') {
            if (returnResultSwal.isConfirmed) {
                this.stepAPIOne = null;
                this.stepAPITwo = null;
                this.stepAPIThree = null;
                this.store.dispatch(CameraActions.cleanup_post());
                this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
                this.store.dispatch(CameraActions.clear_camera_resolution());
                this.loading = false;
                this.insertCamera();
                this.checkCameraResolution();
                this.service.cleanSwal();
            }
            else if (returnResultSwal.isDismissed) {
                this.store.dispatch(CameraActions.cleanup_post());
                this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
                this.store.dispatch(CameraActions.clear_camera_resolution());
                this.loading = false;
                this.service.cleanSwal();
                this.router.navigateByUrl('/cameras/list');
            }
        } else if (returnResultSwal.methodCalled === 'offlineRTMPCam') {
            if (returnResultSwal.isConfirmed) {
                this.offlineCam = true;
                this.currentStep = 2;
                this.resetPageScroll();
                setTimeout(() => {
                    this.goToNextStep();
                }, 50);
            }
            else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'offlineRTSPCam') {
            if (returnResultSwal.isConfirmed) {
                this.offlineCam = true;
                this.currentStep = 2;
                this.resetPageScroll();
                setTimeout(() => {
                    this.goToNextStep();
                }, 50);
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    // Retorna o feedback de cadastro da câmera
    getCreateThumbnailResult() {
        this.camera_thumbnail = this.camera_thumbn$.subscribe(result => {
            if (result) {
                result = result as Feedback;
                if (result.status != OperationStatus.Unloaded) {
                    this.finishCreateCam();
                }
            }
        });
    }

    finishCreateCam() {
        this.validationResponse.validationResponseHandler(200, this.pageName, 'create-camera', 'cameras.create_camera_success');
        if (this.client_sub != null) {
            this.router.navigateByUrl('/users/client/view');
        } else {
            this.router.navigateByUrl('/cameras/list');
        }
        this.store.dispatch(CameraActions.cleanup_post());
        this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
        this.store.dispatch(CameraActions.clear_camera_resolution());
        this.loading = false;
        this.loadingCheckResolution = false;
        this.loadingCreateCamera = false;
        this.loadingGetThumb = false;
        this.stepAPIThree = true;
    }

    // Valida os passos para habilitar e desabilitar
    validateLinkStep(printMsg: boolean): boolean {
        let validate = true;
        if (this.protocol == 'rtsp') {
            if (this.camForm.invalid) {
                if (this.camForm.get('camSrc').invalid) {
                    if (printMsg) {
                        this.validationResponse.validationResponseHandler(400, this.pageName, 'invalid-rtsp', 'cameras.invalid_rtsp');
                    }
                    validate = false;
                }
                return validate;
            }
            return true;
        }
        else {
            if (this.successRTMP) {
                return true;
            }
            return false;
        }
    }

    // validateSecondStep(printMsg: boolean): boolean {
    //   let validate = true;
    //   if (this.camForm.invalid) {
    //     if (this.camForm.get('camName').invalid) {
    //       if (printMsg) {
    //         this.validationResponse.validationResponseHandler(400, this.pageName, 'camera-name-null', 'cameras.camera_name_null');
    //       }
    //       validate = false;
    //     }
    //     return validate;
    //   }
    //   return true;
    // }

    validateCamData(): boolean {
        let validate = true;
        if (this.camForm.invalid) {
            this.camForm.markAllAsTouched();
            if (this.camForm.get('camName').invalid) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'name-required', 'cameras.name_required');
            }

            validate = false;
            return validate;
        }

        if (this.addressForm.invalid && !this.noAddress) {
            this.addressForm.markAllAsTouched();
            const cep = this.addressForm.get('cep').invalid;
            const endereco = this.addressForm.get('rua').invalid;
            const numero = this.addressForm.get('numero').invalid;
            const cidade = this.addressForm.get('cidade').invalid;
            const bairro = this.addressForm.get('bairro').invalid;
            const estado = this.addressForm.get('estado').invalid;
            const exp = this.addressForm.get('cep').invalid || this.addressForm.get('rua').invalid || this.addressForm.get('cidade').invalid ||
        this.addressForm.get('bairro').invalid || this.addressForm.get('estado').invalid;
            if (cep) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'cep-required', 'cameras.cep_required');
            }
            else if (endereco) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'address-required', 'cameras.address_required');
            }
            else if (numero) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'number-required', 'cameras.number_required');
            }
            else if (bairro) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'neighborhood-required', 'cameras.neighborhood_required');
            }
            else if (cidade) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'city-required', 'cameras.city_required');
            }
            else if (estado) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'state-required', 'cameras.state_required');
            }

            validate = !exp;
        }

        this.addAddress();
        return validate;
    }

    // Inserção de animação, onde agora quando eu clicar no texto abaixo do input ele some e aparece a informação
    // apenas em resoluções até 959px
    animationHelp() {
        if (document.getElementById('slidein').style.opacity === '' || document.getElementById('slidein').style.opacity === '0') {
            document.getElementById('slideout').style.opacity = '0';
            document.getElementById('slidein').style.opacity = '100';
        } else {
            document.getElementById('slidein').style.opacity = '0';
            document.getElementById('slideout').style.opacity = '100';
        }
    }

    addAddress() {
        if (!this.noAddress) {
            this.addressObject = this.addressForm.value;
            this.addressObject.latitude = this.latitude ? this.latitude.toString() : '';
            this.addressObject.longitude = this.longitude ? this.longitude.toString() : '';
        } else {
            this.addressObject = {};
        }
    }

    addressEmpty() {
        this.noAddress = !this.noAddress;
        this.addressForm.markAsUntouched();
    }

    // Abrir modal de analíticos da câmera
    openModalCameraAddress() {
        this.store.dispatch(CameraActions.put_list_camera_address(null));
        this.store.dispatch(CameraActions.open_modal_list_camera_address());
    }

    // Valida o carregamento da tela
    loadingEvent(event: boolean) {
        this.waiting = event;
    }

    calculateClasses() {
        return {
            'btn-primary': true
        };
    }

    ImgClasses(steps): string {
        switch (steps) {
            case null:
                return '';
            case true:
                return 'active_api_icon';
            case false:
                return 'inactive_api_icon';
        }
    }

    FontClasses(steps): string {
        switch (steps) {
            case null:
                return 'onload_api';
            case true:
                return 'active_api';
            case false:
                return 'inactive_api';
        }
    }

    getPlanProperty(plan_id: number, property: string): string {
        if (this.planList != undefined && this.planList != null) {
            const plan = this.planList.find(pl => pl.id === plan_id);
            let aliasReformed;
            if (plan) {
                switch (property) {
                    // Nome do plano
                    case 'alias':
                        if (plan.alias === 'FREE') {
                            aliasReformed = '1 dia';
                        } else {
                            aliasReformed = plan.alias + ' dias';
                        }
                        return aliasReformed;
                        // Cor do plano
                    case 'color':
                        return plan.color;
                        // Preço do plano
                    case 'price':
                        if (plan.price != 0) {
                            aliasReformed = plan.price.toString().replace('.', ',');
                        }
                        else {
                            aliasReformed = '0';
                        }
                        return aliasReformed;
                    case 'days_storage':
                        return plan.days_storage.toString();
                    default:
                        return 'error';
                }
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    // Boolean que mostra o componente responsável pela troca de address quando o user clica para alterar
    changeAddress() {
        this.showAddress = true;
    }

    // Retorna emit Output de edit-payments para deixar de mostrar o component
    reciverFeedback(editPaymentFeeback) {
        this.showAddress = editPaymentFeeback;
    }

    // Set margin top
    setDivMargin(currentStep) {
        const div = document.getElementById('stepsDiv');
        if (currentStep < 3) {
            div.style.marginTop = '-3.75rem';
        }
        else if (currentStep === 3) {
            div.style.marginTop = '-35.5rem';
        }
        else if (currentStep === 4) {
            div.style.marginTop = '-25rem';
        }
    }

    selectProtocol(protocol: string) {
        this.protocol = protocol;
        switch (protocol) {
            case 'rtsp':
                this.activeSteps = this.rtspSteps;
                break;
            case 'rtmp':
                this.activeSteps = this.rtmpSteps;
                break;
        }
        this.goToNextStep();
    }

    refreshRTMPUri() {
        if (!this.copied && !this.successRTMP) {
            this.isLoadingRTMP = true;
            this.camForm.get('camSrc').setValue(null);
            this.store.dispatch(CameraActions.get_rtmp_url({
                user_sub: this.userSub
            }));
        }
    }

    getRTMPUri() {
        this.RTMPUri = this.RTMPUriResult$.subscribe(result => {
            if (result && this.protocol == 'rtmp') {
                this.camForm.get('camSrc').setValue(result[0].uri);
                this.isLoadingRTMP = false;
            }
        });
    }

    copyUri() {
        this.clipboard.copy(this.camForm.get('camSrc').value);
        if (!this.copied) {
            this.successRTMP = false;
            this.errorRTMP = false;
            this.copied = true;
            this.counterRTMP = 0;
            setTimeout(() => {
                this.store.dispatch(CameraActions.get_preview_camera({
                    previewCam: this.camForm.get('camSrc').value
                }));
            }, 5000);
        }
    }

    //Implementar função para subir o scroll
    resetPageScroll() {
        const mainPanel = document.querySelector('.main-panel');
        mainPanel.scrollTo(0, 0);
    }

    toggleAccordeon() {
        this.openFailDropdown = !this.openFailDropdown;
    }

    toggleExpandedCameraList(force = false) {
        if (force) {
            this.isRTMPCameraListExpanded = false;
        } else {
            this.isRTMPCameraListExpanded = !this.isRTMPCameraListExpanded;
        }
    }

    toggleForm() {
        if (this.modelList != undefined) {
            this.openExpandedForm = !this.openExpandedForm;
            this.intregatedCamForm.reset();
        }
    }

    getProviderIntegratedCameras() {
        this.store.dispatch(CameraActions.get_provider_integrated_cameras({
            user_sub: this.userSub,
        }));
        this.providerIntegratedCameras = this.providerIntegratedCameras$.subscribe(result => {
            if (result) {
                this.modelList = result.map(element => {
                    return {
                        id: element.hashProvider,
                        text: element.models
                    };
                });
            }
        });
    }

    getModel(event) {
        this.camModel = [event].map(element => {
            return {
                hash: element.id,
                name: element.text
            };
        });
    }

}
