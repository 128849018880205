import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { LoginActions, LoginAction, Metadata } from './actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HandleError } from 'app/Shared/Helpers/HandleError';
import { OperationStatus } from 'app/Shared/models';

@Injectable()
export class LoginEffects {
    constructor (private service: LoginService, private actions: Actions, private error: HandleError){}

    register$ = createEffect(() =>
        this.actions.pipe(
            ofType(LoginActions.REGISTER),
            mergeMap((action: Metadata) => this.service.register(action.user).pipe(
                map(result => {
                    return LoginActions.register_result({ payload: result });
                }),
                catchError(this.error.handleError<LoginAction>('registerUser', LoginActions.register_result({
                    payload: {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: 'Falha interna de serviço, tentar novamente mais tarde'
                    }
                }), LoginActions.register(action)))
            )
            )
        )
    );

    confirmRegister$ = createEffect(() =>
        this.actions.pipe(
            ofType(LoginActions.CONFIRM_REGISTER),
            mergeMap((action: Metadata) => this.service.confirmRegistration(action.username, action.code, action.resend).pipe(
                map(result => {
                    return LoginActions.confirm_register_result({ payload: result });
                }),
                catchError(this.error.handleError<LoginAction>('confirmRegister', LoginActions.confirm_register_result({
                    payload: {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: 'Falha interna de serviço, tentar novamente mais tarde'
                    }
                }), LoginActions.confirm_register(action)))
            )
            )
        )
    );

    login$ = createEffect(() =>
        this.actions.pipe(
            ofType(LoginActions.SIGNIN),
            mergeMap((action: Metadata) => this.service.login(action.username, action.password, action.player_id).pipe(
                map(result => {
                    return LoginActions.signin_status({ payload: result });
                }),
                catchError(this.error.handleError<LoginAction>('Login', LoginActions.signin_status({
                    payload: {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: 'Falha interna de serviço, tentar novamente mais tarde'
                    }
                }), LoginActions.signin(action)))
            )
            )
        )
    );

    logout$ = createEffect(() =>
        this.actions.pipe(
            ofType(LoginActions.SIGNOUT),
            mergeMap((action: Metadata) => this.service.logout(action.userSub).pipe(
                map(result => {
                    return LoginActions.signout_feedback({ payload: result });
                }),
                catchError(this.error.handleError<LoginAction>('Logout', LoginActions.signout_feedback({
                    payload: {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: 'Falha interna de serviço, tentar novamente mais tarde'
                    }
                }), LoginActions.signout(action)))
            )
            )
        )
    );

    resetPassword$ = createEffect(() =>
        this.actions.pipe(
            ofType(LoginActions.RESET_PASSWORD),
            mergeMap((action: Metadata) => this.service.forgotPassword(action.username, action.password, action.code).pipe(
                map(result => {
                    return LoginActions.reset_password_result({ payload: result });
                }),
                catchError(this.error.handleError<LoginAction>('ResetPassword', LoginActions.reset_password_result({
                    payload: {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: 'Falha interna de serviço, tentar novamente mais tarde'
                    }
                }), LoginActions.reset_password(action)))
            )
            )
        )
    );
}
