import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'app/Login/Services/login.service';
import { ImageService } from '../Services/image.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(private router: Router, private loginService: LoginService, private imgService: ImageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const keepConnected = localStorage.getItem('keep-connected');
        if (keepConnected != null) {
            if (keepConnected === 'false') {
                if (!sessionStorage.getItem('id-token')) {
                    const storedUsers = localStorage.getItem('strdUsr');
                    localStorage.clear();
                    // this.imgService.deleteCacheThumb();
                    localStorage.setItem('strdUsr', storedUsers);
                    sessionStorage.clear();
                    this.loginService.navSideShowEmitter.emit(false);
                    this.router.navigate(['/login/signin'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                if (localStorage.getItem('id-token') != null) {
                    return true;
                }
                else {
                    const storedUsers = localStorage.getItem('strdUsr');
                    localStorage.clear();
                    // this.imgService.deleteCacheThumb();
                    localStorage.setItem('strdUsr', storedUsers);
                    sessionStorage.clear();
                    this.loginService.navSideShowEmitter.emit(false);
                    this.router.navigate(['/login/signin'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
            }
        }
        else {
            const storedUsers = localStorage.getItem('strdUsr');
            localStorage.clear();
            // this.imgService.deleteCacheThumb();
            localStorage.setItem('strdUsr', storedUsers);
            sessionStorage.clear();
            this.loginService.navSideShowEmitter.emit(false);
            this.router.navigate(['/login/signin'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
