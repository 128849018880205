<div class="page-header container_without_cameras" [class.by-group]="transparent" [ngStyle]="{'background-color': noCalendar ? '#F3F9FF !important' : 'transparent'}">
    <div class="container" [class.no-calendar]="noCalendar">
        <div class="without_cams_col" [class.img-no-calendar]="noCalendar">
            <div class="row">
                <img src="../../../assets/img/{{imageUrl}}">
            </div>
            <div class="row without_cams_row">
                <span class="style-title" *ngIf="!noFound && !noCalendar">
                    Lista de {{type}} vazia
                </span>
                <span class="style-title" *ngIf="noFound">
                    Nenhum resultado encontrado
                </span>
                <div class="style-title" *ngIf="noCalendar">
                    Nenhum calendário habilitado para o cliente
                </div>
            </div>
            <div class="col-md-12 container_register">
                <div class="row without_cams_row">
                    <div *ngIf="noCameras">
                        <span class="style-label" *ngIf="!is_partner"><a href="#/cameras/cadastro">Cadastre uma câmera</a> 
                            para começar a gravar na nuvem<br>e receber alertas de detecção inteligente.</span>
                        <span class="style-label" *ngIf="is_partner">Cadastre uma câmera
                            para começar a gravar na nuvem<br>e receber alertas de detecção inteligente.</span>
                    </div>
                    <div *ngIf="noAlerts">
                        <span class="style-label" *ngIf="!client_sub">Aguarde até que ao menos uma das detecções inteligentes identifique e 
                            gere os alertas que você precisa, sendo exibidos nessa tela.</span>
                        <span class="style-label" *ngIf="client_sub">Se já existem detecções configuradas em modo de teste para o cliente, aguarde que a 
                            lista de alertas será exibida nessa tela.</span>
                    </div>
                    <span class="style-label" *ngIf="noFound">
                        Tente outra busca ou utilize os filtros para procurar novamente.
                    </span>
                    <span class="style-label" *ngIf="noGroupCameras">
                        Crie um grupo de câmeras e compartilhe com seus<br>clientes e convidados.
                    </span>
                    <span class="style-label label-calendar" *ngIf="noCalendar">
                        Crie um calendário de feriados e configure os horários de<br>funcionamento das detecções inteligentes para as datas
                    </span>
                    <span *ngIf="noUsers && (type == 'clientes' && 'parceiros')" class="style-label">
                        Adicione {{type}} a sua lista de usuários
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>