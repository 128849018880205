import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { UserInventory } from 'app/Users/models';
import { UserActions } from 'app/Users/Services/actions';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Plan } from '../models';
import { CameraActions } from '../Services/actions';

@Component({
    selector: 'app-camera-stock',
    templateUrl: './camera-stock.component.html',
    styleUrls: ['./camera-stock.component.scss']
})
export class CameraStockComponent implements OnInit, OnDestroy {
    readonly userInventoryResult$ = this.store.select((state: AppState) => state.Users.userInventory);
    readonly planResult$ = this.store.select((state: AppState) => state.camera.plans);

    userInventorySub: Subscription;
    planSub: Subscription;

    loadingStock: boolean;
    activeTab: string;

    inventory: UserInventory;
    plans: Plan[];

    inventoryLoading: boolean;
    plansLoading: boolean;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(private readonly store: Store<AppState>, private service: SharedService) { }

    ngOnInit(): void {
        this.inventoryLoading = true;
        this.plansLoading = true;

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        this.activeTab = 'cams';

        const sub = localStorage.getItem('sub');
        this.store.dispatch(UserActions.get_user_inventory({
            user_sub: sub
        }));
        this.store.dispatch(CameraActions.get_plans({
            associateId: environment.associateId
        }));

        this.subscribeStores();
    }

    ngOnDestroy() {
        if (this.userInventorySub) {
            this.userInventorySub.unsubscribe();
        }
        if (this.planSub) {
            this.planSub.unsubscribe();
        }

        this.store.dispatch(CameraActions.cleanup());
        this.store.dispatch(UserActions.cleanup());
    }

    subscribeStores() {
        this.userInventorySub = this.userInventoryResult$.subscribe((result) => {
            if (result) {
                this.inventory = result[0];
                this.inventoryLoading = false;
            }
        });
        this.planSub = this.planResult$.subscribe((result) => {
            if (result) {
                this.plans = result;
                this.plansLoading = false;
            }
        });
    }

    requestKits() {
        if (this.inventory) {
            const dataModal = {
                data: {
                    kit: null,
                    inventories: this.inventory,
                    manyKits: true
                },
                tipoModal: 'solicitar_kit',
                modalHeight: '73vh',
                modalWidth: '50vw'
            };
            this.service.returnDataToModal(dataModal);
        }
    }

    changeTab(event) {
        const buttons = document.querySelectorAll('.btn-tab');
        buttons.forEach((e) => e.classList.remove('active'));

        event.target.classList.add('active');
        this.activeTab = event.target.id;
    }
}
