import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { AnalyticActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GroupAnalytics } from '../models';

@Component({
    selector: 'app-group-analytics',
    templateUrl: './group-analytics.component.html',
    styleUrls: ['./group-analytics.component.scss', '../styles.scss']
})
export class GroupAnalyticsComponent implements OnInit, OnDestroy {
    readonly groupAnalytics$ = this.store.select((state: AppState) => state.analytic.groupAnalytics);
    getGroupAnalytic: Subscription;
    @Output() groups = new EventEmitter<GroupAnalytics[]>();
    @Output() analyticGroupId = new EventEmitter<number>();
    groupAnalytic: GroupAnalytics[];
    analytic_available: number;
    @Input()
    set analytics_available(analytics_available: number) {
        this.analytic_available = analytics_available;
    }

    constructor(private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        this.store.dispatch(AnalyticActions.get_analytics_group());
        this.getGroupAnalytics();
    }

    ngOnDestroy()
    {
        this.getGroupAnalytic.unsubscribe();
        this.store.dispatch(AnalyticActions.cleanup_group_analytics());
    }

    getGroupAnalytics() {
        this.getGroupAnalytic = this.groupAnalytics$.subscribe(groups => {
            if (groups) {
                this.groupAnalytic = groups;
                this.groupAnalytic.sort((a, b) => b.id - a.id);
                this.groups.emit(groups);
            }
            else {
                this.groupAnalytic = [];
            }
        });
    }

    selectGroup(groupId: number)
    {
        this.analyticGroupId.emit(groupId);
    }

}
