import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { LoginService } from '../Services/login.service';
import { LoginActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { GoogleAnalyticsService } from 'app/Shared/Services/google-analytics.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {
    pageName = 'logout';
    readonly logout$ = this.store.select((state: AppState) => state.login.logoutResult);
    sub: Subscription;
    constructor(private router: Router, private loginService: LoginService, private readonly store: Store<AppState>, private analytics: GoogleAnalyticsService,
        private validationResponse: ValidationResponseHandlerModule, private service: SharedService) {
    }

    ngOnInit() {
        this.getLogoutResult();
    }

    getLogoutResult() {
        this.sub = this.logout$.subscribe(result => {
            if (result) {
                if (result.success) {
                    this.service.setLogin(false);
                    const storedUsers = localStorage.getItem('strdUsr');
                    localStorage.clear();
                    // this.imageService.deleteCacheThumb();
                    localStorage.setItem('strdUsr', storedUsers);
                    sessionStorage.clear();
                    this.clearAllInterval();
                    this.analytics.eventEmitter('logout', 'engagement');
                    this.router.navigateByUrl('/login/signin');
                    this.loginService.navSideShowEmitter.emit(false);
                }
                else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'authentication-logout-error', 'login.authentication_logout_error', result.message);
                    // this.router.navigateByUrl('/maps/home');
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.clearAllInterval();
        this.store.dispatch(LoginActions.cleanup());
    }

    clearAllInterval() {
        const intervalId = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
        for (let i = 1; i < intervalId; i++) {
            window.clearInterval(i);
        }
    }

}
