import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UpdateState } from '../models';
import { CameraAction, CameraActions, Metadata } from './actions';
import { CameraService } from './camera.service';
import { HandleError } from '../../Shared/Helpers/HandleError';
import { OperationStatus } from 'app/Shared/models';
import { of } from 'rxjs';

@Injectable()
export class CameraEffects {
    constructor(private service: CameraService, private actions: Actions, private error: HandleError) {}

    postCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.CREATE_CAMERA),
            mergeMap((action: Metadata) => this.service.postCam(action.camera, action.user_sub, action.child_sub, action.create_off).pipe(
                map(result => {
                    return CameraActions.create_camera_status({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('postCamera', CameraActions.create_camera_status({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                CameraActions.create_camera(action)))
            )
            )
        )
    );

    checkCameraResolution$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.CHECK_CAMERA_RESOLUTION),
            mergeMap((action: Metadata) => this.service.CheckCameraResolution(action.check_resolution).pipe(
                map(result => {
                    return CameraActions.check_camera_resolution_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('checkCameraResolution', CameraActions.check_camera_resolution_return({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                CameraActions.check_camera_resolution(action)))
            )
            )
        )
    );

    cameraThumbnail$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.CREATE_CAMERA_THUMBNAIL),
            mergeMap((action: Metadata) => this.service.CreateCameraThumbnail(action.camera_thumbnail).pipe(
                map(result => {
                    return CameraActions.create_camera_thumbnail_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('cameraThumbnail', CameraActions.create_camera_thumbnail_return({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                CameraActions.create_camera_thumbnail(action)))
            )
            )
        )
    );

    getCameras$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_CAMERAS),
            mergeMap((action: Metadata) => this.service.getCameras(action.user_sub, action.child_sub, action.str_filter, action.cam_stat_filter, action.page,
                action.limit, action.group_id, action.clients_id, action.short, action.active, action.sort_by, action.protocol,
                action.boxes).pipe(
                map(result => {
                    return CameraActions.get_cameras_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getCameras', CameraActions.get_cameras_return({ payload: [] }),
                    CameraActions.get_cameras(action)))
            )
            )
        )
    );

    getGroupCameras$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_GROUP_CAMERAS),
            mergeMap((action: Metadata) => this.service.getGroupCameras(action.user_sub, action.child_sub, action.group_id, action.type_request,
                action.groups_camera, action.page, action.limit, action.str_filter, action.active, action.sort_by).pipe(
                map(result => {
                    return action.group_id ? CameraActions.get_group_camerasID_return({ payload: result }) : CameraActions.get_group_cameras_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getGroupCameras',
                    !action.group_id ? CameraActions.get_group_cameras_return({ payload: [] }) : CameraActions.get_group_camerasID_return({ payload: [] }),
                    CameraActions.get_group_cameras(action)))
            )
            )
        )
    );

    getClientsToGroup$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_CLIENTS_GROUP),
            mergeMap((action: Metadata) => this.service.getClientsToGroup(action.user_sub, action.group_id).pipe(
                map(result => {
                    return CameraActions.get_clients_group_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getClientsToGroup', CameraActions.get_clients_group_return({ payload: [] }),
                    CameraActions.get_clients_group(action)))
            )
            )
        )
    );

    updateCameraGroup$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.UPDATE_CAMERA_GROUP),
            mergeMap((action: Metadata) => this.service.updateCameraGroup(action.user_sub, action.child_sub, action.group_id, action.nameCameraGroup,
                action.listDeleteCameras, action.listDeleteClients, action.listDeleteAssociates,
                action.listAddCameras, action.listAddClients, action.listAddAssociates).pipe(
                map(result => {
                    return CameraActions.update_camera_group_result({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('updateCameraGroup', CameraActions.update_camera_group_result({
                    payload: {
                        model: 'feedback',
                        message: 'Falha interna de serviço, tentar novamente mais tarde',
                        success: false
                    }
                }), CameraActions.update_camera_group(action)))
            )
            )
        )
    );

    deleteCameraGroup$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.DELETE_GROUP_CAMERAS),
            mergeMap((action: Metadata) => this.service.deleteCameraGroup(action.user_sub, action.group_id).pipe(
                map(result => {
                    return CameraActions.delete_group_cameras_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('deleteCameraGroup', CameraActions.delete_group_cameras_return({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.delete_group_cameras(action)))
            )
            )
        )
    );

    getAddress$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_LIST_ADDRESS),
            mergeMap((action: Metadata) => this.service.getAddress(action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_list_address_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getAddress', CameraActions.get_list_address_return({ payload: [] }),
                    CameraActions.get_list_address(action)))
            )
            )
        )
    );

    getPreviewCameras$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_PREVIEW_CAMERA),
            mergeMap((action: Metadata) => this.service.getPreviewCamera(action.previewCam).pipe(
                map(result => {
                    return CameraActions.get_preview_camera_return({ payload: result });
                }),
                catchError((error) => {
                    if (action.countRTMP >= 5) {
                        return this.error.handleError<CameraAction>(
                            'getPreviewCamera',
                            CameraActions.get_preview_camera_return({ payload: '' })
                        )(error);
                    } else {
                        return of(CameraActions.get_preview_camera_return({ payload: '' }));
                    }
                })
            )
            )
        )
    );

    getInvitedCameras$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_INVITED_CAMERAS),
            mergeMap((action: Metadata) => this.service.getInvitedCameras(action.user_sub, action.list_type, action.active, action.child_sub, action.str_filter).pipe(
                map(result => {
                    return CameraActions.get_invited_cameras_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getInvitedCameras', CameraActions.get_invited_cameras_return({ payload: [] }),
                    CameraActions.get_invited_cameras(action)))
            )
            )
        )
    );

    deleteCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.DELETE_CAMERA),
            mergeMap((action: Metadata) => this.service.deleteCamera(action.camera_id, action.child_sub).pipe(
                map(result => {
                    return CameraActions.delete_camera_feedback({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('deleteCamera', CameraActions.delete_camera_feedback({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.delete_camera(action)))
            )
            )
        )
    );

    deleteGuestCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.DELETE_GUEST_CAMERA),
            mergeMap((action: Metadata) => this.service.deleteGuestCamera(action.user_sub, action.camera_id).pipe(
                map(result => {
                    return CameraActions.delete_camera_feedback({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('deleteGuestCamera', CameraActions.delete_camera_feedback({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.delete_guest_camera(action)))
            )
            )
        )
    );

    updateCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.UPDATE_CAMERA),
            mergeMap((action: Metadata) => this.service.updateCamera(action.camera_id, action.newName, action.newPlan).pipe(
                map(result => {
                    return CameraActions.update_camera_feedback({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<CameraAction>('updateCamera', CameraActions.update_camera_feedback({ payload: OperationStatus.Unloaded }),
                    CameraActions.update_camera(action)))
            )
            )
        )
    );

    updateInformationCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.UPDATE_CAMERA_INFOR),
            mergeMap((action: Metadata) => this.service.updateCameraInformation(action.camera, action.user_sub, action.child_sub).pipe(
                map(result => {
                    return CameraActions.update_camera_feedback({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('updateInformationCamera', CameraActions.update_camera_feedback({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.update_camera(action)))
            )
            )
        )
    );

    updateStatusCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.UPDATE_STATUS_CAMERA),
            mergeMap((action: Metadata) => this.service.updateStatusCamera(action.camera_id, action.status).pipe(
                map(result => {
                    return CameraActions.update_status_camera_feedback({ payload: result ? action.index : UpdateState.Fail });
                }),
                catchError(this.error.handleError<CameraAction>('updateStatusCamera', CameraActions.update_status_camera_feedback({ payload: UpdateState.Cancel }),
                    CameraActions.update_status_camera(action)))
            )
            )
        )
    );

    getPlans$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_PLANS),
            mergeMap((action: Metadata) => this.service.getPlans(action.associateId).pipe(
                map(result => {
                    return CameraActions.get_plans_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getPlans', CameraActions.get_plans_return({ payload: [] }),
                    CameraActions.get_plans(action)))
            )
            )
        )
    );

    getAnalyticTypes$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_ANALYTICS_TYPES),
            mergeMap((action: Metadata) => this.service.getAnalyticTypes(action.user_sub, action.myAnalytic, action.cameras).pipe(
                map(result => {
                    return CameraActions.get_analytics_types_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getAnalyticTypes', CameraActions.get_analytics_types_return({ payload: [] }),
                    CameraActions.get_analytics_types(action)))
            )
            )
        )
    );

    getVideos$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_VIDEOS),
            mergeMap((action: Metadata) => this.service.getVideos(action.user_sub, action.child_sub, action.timeBegin, action.timeEnd, action.camera_id,
                action.withAlert, action.refresh).pipe(
                map(result => {
                    return CameraActions.get_videos_success({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getVideos', CameraActions.get_videos_success({ payload: [] }),
                    CameraActions.get_videos(action)))
            )
            )
        )
    );

    getHealthcheck$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_HEALTHCHECK),
            mergeMap((action: Metadata) => this.service.getCameraHealthStatus(action.camera_id, action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_healthcheck_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getHealthCheck', CameraActions.get_healthcheck_return(null),
                    CameraActions.get_healthcheck(action)))
            )
            )
        )
    );

    getHealthHistory$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_HEALTH_HISTORY),
            mergeMap((action: Metadata) => this.service.getCameraHealthHistory(action.camera_id, action.page, action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_health_history_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getHealthHistory', CameraActions.get_health_history_return({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.get_health_history(action)))
            )
            )
        )
    );

    getHealthHistoryFilter$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_HEALTH_HISTORY_FILTER),
            mergeMap((action: Metadata) => this.service.getCameraHealthHistory(action.camera_id, action.page, action.user_sub, action.timeBegin, action.timeEnd).pipe(
                map(result => {
                    return CameraActions.get_health_history_filter_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getHealthHistoryFilter', CameraActions.get_health_history_filter_return({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.get_health_history_filter(action)))
            )
            )
        )
    );

    getGuests$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_GUESTS),
            mergeMap((action: Metadata) => this.service.getGuests(action.camera_id, action.user_sub, action.child_sub).pipe(
                map(result => {
                    return CameraActions.get_guests_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getGuests', CameraActions.get_guests_return({ payload: [] }), CameraActions.get_guests(action)))
            )
            )
        )
    );

    inviteUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.INVITE_USER_CAMERA),
            mergeMap((action: Metadata) => this.service.inviteUser(action.camera_id, action.email, action.viewRecording, action.receiveAnalyticAlert, action.viewLive,
                action.viewAlertHistory, action.resend, action.user_sub, action.guestCam, action.child_sub).pipe(
                map(result => {
                    return CameraActions.invite_user_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('inviteUser', CameraActions.invite_user_camera_return({ payload: OperationStatus.Unloaded }),
                    CameraActions.invite_user_camera(action)))
            )
            )
        )
    );

    updateUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.UPDATE_INVITE_USER_CAMERA),
            mergeMap((action: Metadata) => this.service.updateGuest(action.camera_id, action.email, action.viewRecording, action.receiveAnalyticAlert,
                action.viewLive, action.viewAlertHistory, action.user_sub, action.child_sub).pipe(
                map(result => {
                    return CameraActions.update_invite_user_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('updateGuest', CameraActions.update_invite_user_camera_return({ payload: OperationStatus.Unloaded }),
                    CameraActions.update_invite_user_camera(action)))
            )
            )
        )
    );

    deleteGuest$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.DELETE_GUEST),
            mergeMap((action: Metadata) => this.service.deleteGuestList(action.camera_id, action.guestList, action.user_sub, action.child_sub).pipe(
                map(result => {
                    return CameraActions.delete_guest_feedback({ payload: result ? OperationStatus.Success : OperationStatus.Fail });
                }),
                catchError(this.error.handleError<CameraAction>('deleteGuestList', CameraActions.delete_guest_feedback({ payload: OperationStatus.Unloaded }),
                    CameraActions.delete_guest(action)))
            )
            )
        )
    );

    getMosaics$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_MOSAICS),
            mergeMap(action => this.service.getMosaics().pipe(
                map(result => {
                    return CameraActions.get_mosaics_return({ payload: result});
                }),
                catchError(this.error.handleError<CameraAction>('getMosaics', CameraActions.get_mosaics_return({ payload: []}),
                    CameraActions.get_mosaics()))
            )
            )
        )
    );

    getMosaicUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_USER_MOSAICS),
            mergeMap((action: Metadata) => this.service.getUserMosaics(action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_user_mosaics_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getMosaic', CameraActions.get_user_mosaics_return({ payload: []}),
                    CameraActions.get_user_mosaics(action)))
            )
            )
        )
    );

    saveMosaic$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.SAVE_MOSAIC),
            mergeMap((action: Metadata) => this.service.saveMosaic(action.mosaic).pipe(
                map(result => {
                    return CameraActions.save_mosaic_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('saveMosaic', CameraActions.save_mosaic_return({ payload: false }),
                    CameraActions.save_mosaic(action)))
            )
            )
        )
    );

    editMosaic$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.EDIT_MOSAIC),
            mergeMap((action: Metadata) => this.service.editMosaic(action.mosaic).pipe(
                map(result => {
                    return CameraActions.edit_mosaic_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('editMosaic', CameraActions.edit_mosaic_return({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.edit_mosaic(action)))
            )
            )
        )
    );

    deleteMosaic$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.DELETE_MOSAIC),
            mergeMap((action: Metadata) => this.service.deleteMosaic(action.idMosaic).pipe(
                map(result => {
                    return CameraActions.delete_mosaic_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('deleteMosaic', CameraActions.delete_mosaic_return({
                    payload: {
                        model: 'feedback',
                        success: false,
                        message: 'Falha de rede, tente novamente mais tarde',
                        status: OperationStatus.Fail
                    }
                }), CameraActions.delete_mosaic(action)))
            )
            )
        )
    );

    getUrlSignedVideos$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_URL_SIGNED_VIDEO),
            mergeMap((action: Metadata) => this.service.getUrlSignedVideos(action.urlSigned).pipe(
                map(result => {
                    return CameraActions.get_url_signed_video_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getUrlSignedVideos', CameraActions.get_url_signed_video_return({ payload: '' }),
                    CameraActions.get_url_signed_video(action)))
            )
            )
        )
    );

    getRTMPUri$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_RTMP_GENERATED_URL),
            mergeMap((action: Metadata) => this.service.getRTMPUri(action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_rtmp_url_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getRTMPUri', CameraActions.get_rtmp_url_return({ payload: '' }),
                    CameraActions.get_rtmp_url(action)))
            )
            )
        )
    );

    createGroupCameras$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.CREATE_GROUP),
            mergeMap((action: Metadata) => this.service.createGroupCamera(action.createCamGroup).pipe(
                map(result => {
                    return CameraActions.create_group_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('createGroupCamera', CameraActions.create_group_return({ payload: [] }),
                    CameraActions.create_group(action)))
            )
            )
        )
    );

    getCamerasReport$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_CAMERAS_REPORT),
            mergeMap((action: Metadata) => this.service.getCamerasReport(action.user_sub, action.clients_id).pipe(
                map(result => {
                    return CameraActions.get_cameras_report_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getCamerasReport', CameraActions.get_cameras_report_return({ payload: [] }),
                    CameraActions.get_cameras_report(action)))
            )
            )
        )
    );

    getBlockedCamerasReport$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_BLOCKED_CAMERAS_REPORT),
            mergeMap((action: Metadata) => this.service.getBlockedCamerasReport(action.user_sub, action.child_sub, action.page, action.limit, action.clients_id).pipe(
                map(result => {
                    return CameraActions.get_blocked_cameras_report_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getBlockedCamerasReport', CameraActions.get_blocked_cameras_report_return({ payload: [] }),
                    CameraActions.get_blocked_cameras_report(action)))
            )
            )
        )
    );

    getGroupCamerasReport$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_GROUP_CAMERAS_REPORT),
            mergeMap((action: Metadata) => this.service.getGroupCamerasReport(action.user_sub, action.group_id).pipe(
                map(result => {
                    return CameraActions.get_group_cameras_report_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getBlockedCamerasReport', CameraActions.get_group_cameras_report_return({ payload: [] }),
                    CameraActions.get_group_cameras_report(action)))
            )
            )
        )
    );

    getProviderIntegrated$ = createEffect(() =>
        this.actions.pipe(
            ofType(CameraActions.GET_PROVIDER_INTEGRATED_CAMERAS),
            mergeMap((action: Metadata) => this.service.getProviderIntegratedCameras(action.user_sub).pipe(
                map(result => {
                    return CameraActions.get_provider_integrated_cameras_return({ payload: result });
                }),
                catchError(this.error.handleError<CameraAction>('getBlockedCamerasReport', CameraActions.get_provider_integrated_cameras_return({ payload: [] }),
                    CameraActions.get_provider_integrated_cameras(action)))
            )
            )
        )
    );
}
