import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../Login/Services/login.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { showAlert } from './showAlert';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HandleError {
    renewToken;
    constructor(private loginService: LoginService, private router: Router) {
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     * @param action
     */
    handleError<T>(operation = 'operation', result?: T, action?: T) {
        return (error: any): Observable<T> => {
            //TODO: enviar erro para infraestruerrortura remota de logging
            // console.error(error);
            if (error instanceof HttpErrorResponse) {
                const httpError = error as HttpErrorResponse;
                console.log(localStorage.getItem('sub') + ' = ' + localStorage.getItem('identifier'));
                if (httpError.status === 401 && !this.operationRetry(operation)) {
                    if (localStorage.getItem('identifier')) {
                        return this.loginService.renewToken().pipe(
                            map(success => {
                                if (success) {
                                    localStorage.setItem(operation + '-token', localStorage.getItem(operation + '-token') + 1);
                                    return action as T;
                                }
                                else {
                                    this.router.navigateByUrl('/login/signin');
                                    // return result as T;
                                }
                            }),
                            catchError(_error => {
                                return of(result as T);
                                // this.router.navigateByUrl('/login/signin');
                            })
                        );
                    } else {
                        this.router.navigateByUrl('/login/signin');
                    }
                }
                else if (httpError.status === 0) {
                    localStorage.removeItem(operation + '-token');
                    return of(result as T);
                }
                else if (httpError.status === 504) {
                    if (operation != 'userNotification') {
                        showAlert('Timeout na requisição', 'danger');
                    }
                    localStorage.removeItem(operation + '-token');
                    return of(result as T);
                } else {
                    this.router.navigateByUrl('/login/signin');
                }
            }
            localStorage.removeItem(operation + '-token');
            //retorna resultado vazio
            return of(result as T);
        };
    }

    operationRetry(operation = 'operation'): boolean {
        const retryOp = localStorage.getItem(operation + '-token');
        if (retryOp == null) {
            localStorage.setItem(operation + '-token', '1');
            return false;
        } else if (retryOp == '11111') {
            localStorage.removeItem(operation + '-token');
            return true;
        } else {
            return false;
        }
    }
}
