<div class="main-content">
  <div class="close-modal">
    <span (click)="closeModal()" style="cursor:pointer;" nz-icon nzType="close" nzTheme="outline"></span>
  </div>
  <div class="custom-container">
    <div *ngIf="loadingVideo">
      <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
    </div>
    <div class="video-container" [ngClass]="loadingVideo === true ? 'hidden': ''">
      <div class="playback-animation" id="playback-animation">
        <svg class="playback-icons">
          <use class="hidden" href="#play-icon"></use>
          <use href="#pause"></use>
        </svg>
      </div>
      <video class="video" id="video" preload="auto" poster="{{currentAlert.alert.screenshotfile}}">
        <source src="{{currentAlert.alert.link_video}}" type="video/mp4">
      </video>
  
      <div class="video-controls hidden" id="video-controls">
        <div class="video-progress">
          <progress id="progress-bar" value="0" min="0"></progress>
          <input class="seek" id="seek" value="0" min="0" type="range" step="1">
          <div class="seek-tooltip" id="seek-tooltip">00:00</div>
        </div>
  
        <div class="bottom-controls">
          <div class="left-controls">
            <button data-title="Play (k)" id="play">
              <svg class="playback-icons">
                <use href="#play-icon"></use>
                <use class="hidden" href="#pause"></use>
              </svg>
            </button>
  
            <div class="time">
              <time id="time-elapsed">00:00</time>
              <span> / </span>
              <time id="duration">00:00</time>
            </div>
          </div>
  
          <div class="right-controls" style="display:flex;">
            <a id="download-button" download="">
              <svg>
                <use href="#download"></use>
              </svg>
            </a>
            <button data-title="Full screen (f)" class="fullscreen-button" id="fullscreen-button">
              <svg>
                <use href="#fullscreen"></use>
                <use href="#fullscreen-exit" class="hidden"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
  
      <div class="feedback-buttons" *ngIf="!currentAlert.alert.review" id="feedback-buttons">
        <div class="thumbs-up">
          <button (click)="reviewAlertThumbsUp(currentAlert.alert.id)" data-title="Gostei" class="thumbs-up-button"
            id="thumbs-up">
            Gostei
            <svg>
              <use href="#thumbs-up-icon"></use>
            </svg>
          </button>
        </div>
        <div class="thumbs-down">
          <button (click)="reviewAlertThumbsDown(currentAlert.alert.id)" data-title="Não gostei"
            class="thumbs-down-button" id="thumbs-down">
            Não gostei
            <svg>
              <use href="#thumbs-down-icon"></use>
            </svg>
          </button>
        </div>
      </div>
  
    </div>
  </div>
  
  <!--SVGs-->
  <svg class="display_invisible">
    <defs>
      <style>
        .cls-1 {
          fill: #fff
        }
      </style>
      <symbol id="pause" viewBox="0 0 24 24">
        <path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>
      </symbol>
  
      <symbol id="play-icon" viewBox="0 0 24 24">
        <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
      </symbol>
  
      <symbol id="fullscreen" viewBox="0 0 24 24">
        <path
          d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z">
        </path>
      </symbol>
  
      <symbol id="fullscreen-exit" viewBox="0 0 24 24">
        <path
          d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z">
        </path>
      </symbol>
  
      <symbol id="thumbs-up-icon" viewBox="0 0 14.787 11.435">
        <path id="Caminho_2903"
          d="M535.762 68.123c0 1 .015 1.994-.008 2.99a.553.553 0 0 0 .443.641 11.766 11.766 0 0 0 4.349.606c.586-.019 1.173.009 1.759-.006a2.248 2.248 0 0 0 2.212-1.759c.241-1.041.454-2.091.63-3.145a2.294 2.294 0 0 0-2.509-2.516c-.754.05-1.508.1-2.259.176-.346.037-.425-.065-.387-.394.094-.821.176-1.644.227-2.468.064-1.028-.6-1.528-1.594-1.226a2.016 2.016 0 0 0-1.176.914c-.516.851-1.026 1.706-1.552 2.551a1.134 1.134 0 0 0-.134.647l-.001 2.989z"
          class="cls-1" transform="translate(-530.386 -60.934)" />
        <path id="Caminho_2904"
          d="M909.673 65.39v3.1c0 .8.256 1.052 1.04 1.056.623 0 1.247.009 1.87 0s.9-.281.906-.9q.013-3.3 0-6.591c0-.622-.271-.866-.918-.876s-1.284-.007-1.926 0c-.7.007-.961.248-.969.944-.013 1.089-.004 2.177-.003 3.267z"
          class="cls-1" transform="translate(-909.669 -58.122)" />
      </symbol>
  
      <symbol id="thumbs-down-icon" viewBox="0 0 14.787 11.435">
        <path id="Caminho_2903"
          d="M545.164 65.178c0-1-.015-1.994.008-2.99a.553.553 0 0 0-.443-.641 11.766 11.766 0 0 0-4.349-.606c-.586.019-1.173-.009-1.759.006a2.248 2.248 0 0 0-2.211 1.759c-.241 1.041-.454 2.09-.63 3.144a2.293 2.293 0 0 0 2.509 2.516c.754-.05 1.508-.1 2.259-.175.346-.037.425.065.387.394-.094.821-.176 1.644-.227 2.468-.064 1.028.6 1.527 1.594 1.226a2.016 2.016 0 0 0 1.176-.914c.516-.851 1.026-1.706 1.552-2.551a1.134 1.134 0 0 0 .134-.647v-2.989z"
          class="cls-1" transform="translate(-535.753 -60.934)" />
        <path id="Caminho_2904"
          d="M913.492 65.332v-3.1c0-.8-.256-1.052-1.04-1.056-.623 0-1.247-.009-1.87 0s-.9.281-.905.9q-.013 3.3 0 6.59c0 .622.271.866.918.876s1.284.007 1.926 0c.7-.007.961-.248.969-.944.01-1.088.003-2.177.002-3.266z"
          class="cls-1" transform="translate(-898.71 -61.166)" />
      </symbol>
  
      <symbol id="download" viewBox="0 0 24 24">
        <path d="M20.061 10.467h-6.145v6.981h-3.84l6.913 6.5 6.913-6.5h-3.84v-6.98z" transform="translate(-8.265 -10.467)"
          style="fill:#fff" />
        <path data-name="Retângulo 17745" transform="translate(0 16.658)" style="fill:#fff" d="M0 0h17v3H0z" />
      </symbol>
    </defs>
  </svg>
</div>