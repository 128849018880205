<div class="main-content background card-main-content" [ngStyle]="{'height': heightModal}">
    <div [hidden]="loadingPostDel" class="container-fluid">
        <div class="row col-12 position_close">
            <div class="need-help">
                <div class="container_tolltip_infor">
                    <span class="need_help tooltip_without_flag" nz-popover nzPopoverTitle="Detecção inteligente"
                          [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomRight"
                          [(nzPopoverVisible)]="showHelp" nzPopoverTrigger="click"
                          nzPopoverOverlayClassName="tooltip-container">
                        <span class="tooltiptexthelp">Preciso de ajuda <span nz-icon nzType="info-circle" nzTheme="outline"></span></span>
                    </span>
                </div>
            </div>
            <i (click)="closeModal()" style="margin-top: 2px; font-size: 18px; width: fit-content" nz-icon nzType="close" nzTheme="outline"></i>
        </div>
        <div class="row header">
            <div (click)="previousPage()" class="back-button">
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </div>
            <div class="row titles">
                <div class="col-md-12 title-button">
                    <span class="title-modal">{{ !analytic ? 'Nova detecção inteligente' : 'Detecção inteligente'}} de {{namePage}}</span>
                </div>
                <div class="col-md-12 title-button" style="padding-top: 3px;">
                    <span class="title_name_cams">{{cam.alias}}</span>
                </div>
            </div>
        </div>
        <div class="card card-img card_image">
            <div class="card-header">
                <div class="flex row card-responsive">
                    <!-- Imagem -->
                    <div class="image_position grid-draw">
                        <app-draw-analytic [optionsAnalytics]="optionsAnalytics" [camera]="cam" [analytic]="analytic"
                            [drawType]="changedOptionEvent.asObservable()"
                            [resetAnalytic]="resetAnalyticEvent.asObservable()"
                            [analiticFormInformation]="formInformation.asObservable()">
                        </app-draw-analytic>
                    </div>
                    <!-- INÍCIO PAINEL DE CONFIGURAÇÕES -->

                    <div class="image_position grid-controller">
                        <app-configure-analytic (setFullForm)="getAnaliticFormFull($event)"
                            [optionsAnalytics]="optionsAnalytics" [analytic]="analytic"
                            [resetAnalytic]="resetAnalyticEvent.asObservable()" [groupAnalytic]="groups"
                            (analyticDraw)="changedOption($event)" (choosedAnalytic)="getAnalyticChoosed($event)"
                            (cancelEmit)="previousPage()" (saveEmit)="addAnalytic()" [isHolidayActive]="hasHolidaysInCamera" [cameraData]="{id: cam.id, name: cam.alias}"></app-configure-analytic>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!loadingPostDel" class="loading-indicator">
        <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
    </div>
    <ng-template #contentTemplate class="tooltiptext" id="tooltip_help">
        <span class="tooltiptextblack">Detecção inteligente</span>
        <br>
        <span class="tooltiptextsoft">Tipo de detecção que deseja analisar na cena. Pode
                                            existir parâmetros específicos para cada tipo de detecção.</span>
        <br><br>
        <span class="tooltiptextblack">Objetos capturados</span>
        <br>
        <span class="tooltiptextsoft">Agentes causadores da detecção.</span>
        <br><br>
        <span class="tooltiptextblack">Intervalo de detecção</span>
        <br>
        <span class="tooltiptextsoft">Rotina de detecção estabelecida por
                                            horários e dias da semana. Essa configuração gera a regularidade de alertas no sistema.</span>
    </ng-template>
</div>
