import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from 'app/Login/Services/login.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoggedGuard  {
    constructor(private loginService: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const sub = localStorage.getItem('sub');
        if (sub) {
            return this.loginService.logout(sub).pipe(
                map(feedback => {
                    if (feedback.success) {
                        const storedUsers = localStorage.getItem('strdUsr');
                        localStorage.clear();
                        localStorage.setItem('strdUsr', storedUsers);
                        sessionStorage.clear();
                        this.loginService.navSideShowEmitter.emit(false);
                    }
                    return feedback.success;
                }),
                catchError(_error => {
                    const storedUsers = localStorage.getItem('strdUsr');
                    localStorage.clear();
                    localStorage.setItem('strdUsr', storedUsers);
                    sessionStorage.clear();
                    this.loginService.navSideShowEmitter.emit(false);
                    return of(true);
                })
            );
        }
        else {
            return of(true);
        }
    }
}
