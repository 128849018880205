<div style="padding:20px; overflow: auto; height: 500px;">
  <div class="flex justify-between items-center">
    <span class="title-modal">Solicitar {{manyKits ? 'Kit' : kit.alias}}</span>
    <div (click)="closeModal()" class="close-modal">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
  </div>
  
  <div class="form-container">
    <div *ngIf="manyKits && optionsKits" style="margin-left: -13px;">
      <nz-table #basicTable [nzData]="elementsKits" [nzShowPagination]="false" class="style-table">
        <thead>
        <tr>
          <th class="product_type">Kit</th>
          <th class="product_type">Quantidade</th>
          <th class="product_type"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data; index as i">
          <td class="name_type">
            <app-filter type="multiweight" [list]="optionsKits" placeholder="Selecionar Kit"
                        (filterStatusChange)="changeKit($event, i)" [showSelected]="true" [hideHover]="true" [hideActiveFill]="true">
            </app-filter>
          </td>
          <td>
            <nz-input-number class="label-container" [ngModel]="undefined" (ngModelChange)="changeAmount($event, i)" [nzMin]="1" [nzStep]="1"></nz-input-number>
          </td>
          <td>
              <span [hidden]="data.id == 1" nz-icon class="center" nzType="close" nzTheme="outline" (click)="removeKit(data.id)"></span>
          </td>
        </tr>
        </tbody>
      </nz-table>
      <button *ngIf="this.kitsList.length > this.elementsKits.length" type="button" class="btn btn-light add-button" (click)="newKits()">
        <i class="material-icons">add</i>
        Solicitar outro kit
      </button>
    </div>
  
    <form [formGroup]="ticketForm">
      <ng-container *ngIf="!manyKits">
        <div class="flex justify-start" style="margin-top: 1.7rem;">
          <span class="amount_type">Quantidade</span>
        </div>
  
        <nz-input-number class="textarea-container" [nzMin]="1" [nzStep]="1" formControlName="kitAmount"></nz-input-number>
      </ng-container>
  
      <div class="flex justify-start">
        <span class="amount_type">Observação</span>
      </div>
  
      <textarea formControlName="observation" class="textarea-container" rows="2" nz-input></textarea>
    </form>
  
    <div class="flex justify-center items-center">
      <button *ngIf="!loadingSubmit" (click)="createTicket()" class="btn btn-primary enter-button" [disabled]="!saveKit">
        Solicitar Kit
      </button>
      <div *ngIf="loadingSubmit">
        <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:30px"></span>
      </div>
    </div>
  
  </div>
</div>
