import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input() progress = 1;
    color: string;

    constructor() { }

    ngOnInit(): void {
        if (this.progress > 70) {
            this.color = '#F0896F';
        } else {
            if (localStorage.getItem('Envtag') === 'wecloud' || localStorage.getItem('Envtag') === null){
                this.color = '#326ABC';
            }
            else{
                this.color = '#21caec';
            }
        }
    }

}
