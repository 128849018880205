import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Client, UserInventory, Guest, PaginationGuests } from '../models';
import { Subscription, of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions } from 'app/Users/Services/actions';
import { AppState } from 'app/store/model';
import { SharedService } from 'app/Shared/Services/shared.service';
import { UntypedFormControl } from '@angular/forms';
@Component({
    selector: 'app-client-list',
    templateUrl: './user-client-list.component.html',
    styleUrls: ['./user-client-list.component.scss'],
})

export class ClientListComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly clients$ = this.store.select((state: AppState) => state.Users.client_list);
    readonly guests$ = this.store.select((state: AppState) => state.Users.guests_list);
    readonly userInventory$ = this.store.select((state: AppState) => state.Users.userInventory);

    loadingClientList: boolean;

    userSub: string;

    //Subs
    clients_sub: Subscription;
    guests_sub: Subscription;
    userInventory: Subscription;

    sortingTable = false;
    associate_id = environment.associateId;

    pageName = 'client-list';
    clientData: Client[];
    guestData: Guest[];

    clientTotal: number;
    clientLimit: number;
    clientIndex: number;

    guestTotal: number;
    guestLimit: number;
    guestIndex: number;

    searchInputControl = new UntypedFormControl('', []);
    queryClients: string;
    queryGuests: string;

    loadingClients: boolean;
    loadingGuests: boolean;
    hasClients = true;
    hasGuests = true;

    inventories: UserInventory;
    clientDetails: Client[];
    guestDetails: Guest[];
    isClientExpanded: boolean;
    isGuestExpanded: boolean;
    debounceSearch: ReturnType<typeof setTimeout>;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(private router: Router, private readonly store: Store<AppState>, private service: SharedService) { }

    ngOnInit() {
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        this.store.select((state: AppState) => state.Users.pageIndexClient);
        this.clientLimit = this.guestLimit = 50;
        this.store.select((state: AppState) => state.Users.pageIndexGuest).subscribe(page => { this.guestIndex = page; });
        this.store.select((state: AppState) => state.Users.pageIndexClient).subscribe(page => { this.clientIndex = page; });
        this.userSub = localStorage.getItem('sub');
        this.loadingClientList = true;
        this.clientData = [];
        this.guestData = [];
        this.isClientExpanded = false;
        this.isGuestExpanded = false;
        this.clientDetails = [];
        this.guestDetails = [];
        this.listExpandedHandler();

        this.getClients();
        this.getGuests();
        this.getUserInventory();
        localStorage.setItem('previousRouter', this.router.url);
    }

    ngOnDestroy() {
        if (this.clients_sub) {
            this.clients_sub.unsubscribe();
        }
        if (this.guests_sub) {
            this.guests_sub.unsubscribe();
        }
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        clearTimeout(this.debounceSearch);
        this.service.cleanSwal();
        // this.store.dispatch(UserActions.cleanup());
    }

    ngAfterViewInit() { }

    loadingEvent(event: boolean) {
        this.loadingClientList = event;
    }

    addClient() {
        const dataModal = {
            data: {
                cameras_avaiable: this.inventories.inventory.ci.total.available,
                analytics_avaiable: this.inventories.inventory.ai.available,
                kits: this.inventories.kits,
                plans: this.inventories.plans
            },
            tipoModal: 'adicionar_cliente',
            modalHeight: '85vh',
            modalWidth: '60em'
        };
        this.service.returnDataToModal(dataModal);
    }

    reloadComponent() {
        if (this.loadingClientList == true) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/clients/list']);
        }
    }

    getClients() {
        this.queryClients = this.searchInputControl.value;
        this.clients_sub = this.clients$.subscribe(data => {
            if (data && 'total' in data) {
                this.clientTotal = data.total;
                this.loadingClients = false;
                this.clientDetails = data.clients as Client[];
                this.clientData = this.clientDetails;
                this.listExpandedHandler();
                this.hasClients = this.clientDetails.length !== 0;
            } else {
                this.loadingClients = true;
                this.store.dispatch(UserActions.get_clients({
                    user_sub: this.userSub,
                    str_filter: this.queryClients,
                    page: 1,
                    limit: this.clientLimit
                }));
            }
        });
    }

    getGuests() {
        this.queryGuests = this.searchInputControl.value;
        this.guests_sub = this.guests$.subscribe(data => {
            if (data) {
                data = data[0] as PaginationGuests;
                this.guestTotal = data.total;
                this.loadingGuests = false;
                this.guestDetails = data.guests as Guest[];
                this.guestData = this.guestDetails;
                this.listExpandedHandler();
                this.hasGuests = this.guestDetails.length !== 0;
            } else {
                this.loadingGuests = true;
                this.store.dispatch(UserActions.get_all_guests({
                    user_sub: this.userSub,
                    str_filter: this.queryGuests,
                    page: 1,
                    limit: this.guestLimit
                }));
            }
        });
    }

    viewClient(cli: Client) {
        localStorage.setItem('clientView', cli.sub);
        localStorage.setItem('clientName', cli.name);
        localStorage.setItem('childProfile', cli.childProfile ? cli.childProfile : 'Cliente');
        localStorage.setItem('previousRouter', this.router.url);
        this.router.navigateByUrl('users/client/view');
    }

    viewGuest(guest: Guest) {
        localStorage.setItem('clientView', guest.guest_sub);
        localStorage.setItem('clientName', guest.guest_name);
        if ((guest.type_invite === 'account' || guest.type_invite === 'camera' || guest.type_invite === 'both')
             && guest.profile_alias != 'Cliente de Parceiro') {
            localStorage.setItem('childProfile', 'Convidado');
        } else {
            localStorage.setItem('childProfile', 'Cliente');
        }
        localStorage.setItem('previousRouter', this.router.url);
        this.router.navigateByUrl('users/client/view');
    }

    getUserInventory() {
        this.userInventory = this.userInventory$.subscribe(inventory => {
            if (inventory) {
                this.inventories = inventory[0];
            } else {
                this.store.dispatch(UserActions.get_user_inventory({
                    user_sub: this.userSub
                }));
            }
        });
    }

    usedAndAvailableSum(data) {
        return data.available + data.used;
    }

    toggleExpandedList(data: 'client' | 'guest') {
        if (data === 'client') {
            this.isClientExpanded = !this.isClientExpanded;
        } else {
            this.isGuestExpanded = !this.isGuestExpanded;
        }

        this.listExpandedHandler();
    }

    listExpandedHandler() {
        if (this.isClientExpanded) {
            this.clientData = this.clientDetails;
        } else {
            this.clientData = this.clientDetails.slice(0, 3);
        }
        if (this.isGuestExpanded) {
            this.guestData = this.guestDetails;
        } else {
            this.guestData = this.guestDetails.slice(0, 3);
        }
    }

    formatPlural(amount: number, word: string) {
        if (amount) {
            return amount.toString() + ' ' + word + (amount > 1 ? 's' : '');
        }
    }

    processClientName(clients: string[]) {
        const clientNames = clients.slice(0, 2).join(', ');
        return clientNames + (clients.length > 2 ? '...' : '');
    }

    format(type) {
        if (type == 'camera') {
            return ['Câmera'];
        }
        else if (type == 'account') {
            return ['Conta'];
        }
        else {
            return ['Câmera', 'Conta'];
        }
    }

    requestSearch() {
        this.loadingClients = true;
        this.loadingGuests = true;
        clearTimeout(this.debounceSearch);
        this.queryClients = this.searchInputControl.value;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(UserActions.get_clients({
                user_sub: this.userSub,
                str_filter: this.queryClients,
                page: 1,
                limit: this.clientLimit
            }));
            this.store.dispatch(UserActions.get_all_guests({
                user_sub: this.userSub,
                str_filter: this.queryClients,
                page: 1,
                limit: this.guestLimit
            }));
            this.clientDetails = [];
            this.listExpandedHandler();
        }, 300);
    }

    onPageIndexChangeClient() {
        clearTimeout(this.debounceSearch);
        this.queryClients = this.searchInputControl.value;
        this.scrollToTopClient();
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(UserActions.get_clients({
                user_sub: this.userSub,
                str_filter: this.queryClients,
                page: this.clientIndex,
                limit: this.clientLimit
            }));
            this.loadingClients = true;
        }, 300);
    }

    onPageIndexChangeGuest() {
        clearTimeout(this.debounceSearch);
        this.queryGuests = this.searchInputControl.value;
        this.scrollToTopGuest();
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(UserActions.get_all_guests({
                user_sub: this.userSub,
                str_filter: this.queryGuests,
                page: this.guestIndex,
                limit: this.guestLimit
            }));
            this.loadingGuests = true;
        }, 300);
    }

    scrollToTopClient() {
        document?.getElementById('top-list').scrollIntoView();
    }

    scrollToTopGuest() {
        document?.getElementById('guest-list').scrollIntoView();
    }
}
