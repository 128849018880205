<div class="row crop-container">
    <image-cropper [imageChangedEvent]="imageEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
        [roundCropper]="true" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()" [resizeToWidth]="500" [resizeToHeight]="500" [cropperMinWidth]="90"
        [cropperMinHeight]="90"></image-cropper>
</div>
<!--nice-->

<div class="row">
    <div class="col-6 buttons_col">
        <button class="btn btn-round buttons_margin" (click)="onClose()">Cancelar</button>
    </div>
    <div class="col-6 buttons_col">
        <button class="btn btn-primary btn-round buttons_margin" (click)="onImage()">Confirmar</button>
    </div>
</div>