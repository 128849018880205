import { Routes } from '@angular/router';
import { HomeComponent } from './home-selector/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';

export const homeRoutes: Routes = [
    {
        path: '',
        component: DashboardComponent,
    }
];
