<div style="padding: 30px;">
    <div class="main-content flex justify-between items-center background-container">
        <ng-container>
            <span class="title-modal" *ngIf="context !== 'summary'">Adicionar convidado a conta do cliente</span>
            <span class="title-modal" *ngIf="context === 'summary'">Adicionar convidado a conta do parceiro</span>
        </ng-container>
        <div class="close-modal" (click)="closeModal()">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </div>
    </div>
        <table mat-table [dataSource]="dataSource" class="style-table">
            <ng-container>
                <ng-container matColumnDef="nameTwo">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <div class="label">
                            <span>Nome</span>
                        </div>
                        <input nz-input class="label-container" [(ngModel)]="element.name" [ngModelOptions]="{standalone: true}">
                    </td>
                </ng-container>
                <ng-container matColumnDef="emailTwo">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <div class="label">
                            <span>Email</span>
                        </div>
                        <input nz-input class="label-container" [(ngModel)]="element.email" [ngModelOptions]="{standalone: true}">
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <div #info class="label">
                            <span>Tipo de usuário
                                <i
                                    class="tooltip_icon"
                                    nz-icon nzType="info-circle"
                                    nzTheme="outline"
                                    nz-popover
                                    [nzPopoverContent]="tooltip"
                                    nzPopoverTrigger="hover"
                                    nzPopoverPlacement="top"
                                    [nzPopoverOrigin]="info"
                                    nzPopoverMouseLeaveDelay="0.5"
                                    nzPopoverOverlayClassName="tooltip-partner"
                              ></i>
                            </span>
                        </div>
                        <nz-select [(ngModel)]="element.permission" nzBorderless nzPlaceHolder="Selecionar..." class="label-container-two">
                            <nz-option nzLabel="Comum" nzValue="common"></nz-option>
                            <nz-option nzLabel="Administrador" nzValue="admin"></nz-option>
                        </nz-select>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <i *ngIf="i > 0" nz-icon nzType="close-circle" nzTheme="outline" (click)="popGuest(i)" class="pop-guest"></i>
                    </td>
                </ng-container>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
        <span *ngIf="guestSelect()" class="add_guest" (click)="newGuests()">
            <i nz-icon nzType="plus" nzTheme="outline" class="prefix"></i>
            Adicionar outro convidado
        </span>
    <div class="container_button">
        <button type="button"
            class="btn btn-ant btn-primary save" [disabled]="loadingSubmit" (click)="submitForm()">Adicionar Convidado
            <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingSubmit" class="posfix"></span>
        </button>
    </div>
    
    <ng-template #tooltip>
        <div class="flex flex-col tooltip-partner">
            <table>
              <tr>
                  <td class="tooltip-title">Permissões do usuário</td>
                  <th class="no-border" scope="col">Comum</th>
                  <th class="no-border" scope="col">Admin</th>
              </tr>
              <tr>
                  <th scope="row">Administração dos dados da conta e dos meus usuários</th>
                  <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
                  <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
              </tr>
              <tr>
                <th scope="row">Administração dos clientes</th>
                <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
                <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
              </tr>
              <tr>
                <th scope="row">Administração de câmeras, analíticos e convidados</th>
                <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
                <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
              </tr>
              <tr>
                <th scope="row">Administração de estoque</th>
                <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
                <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
              </tr>
              <tr>
                <th class="no-border" scope="row">Administração de grupos de câmeras</th>
                <td class="no-border"><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
                <td class="no-border"><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i>
                </td>
              </tr>
          </table>
        </div>
    </ng-template>
</div>
