import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {validatePassword, confirmPassword, validateCPFCNPJ, validateCEP} from '../models';
import {ActivatedRoute, Router} from '@angular/router';
import { User } from 'app/Users/models';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { environment } from 'environments/environment';
import { LoginActions } from '../Services/actions';
import { Subscription, Observable } from 'rxjs';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';

export class ValidatorConfirmPass implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const exp = control && control.invalid && (control.dirty || control.touched);
        return exp || (form.errors != null);
    }
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    readonly result$ = this.store.select((state: AppState) => state.login.registerResult);
    newUser: User;
    resultSub: Subscription;
    public registerForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', [Validators.required]),
        cpf: new UntypedFormControl('', [Validators.required, validateCPFCNPJ]),
        cell: new UntypedFormControl('', [Validators.required]),
        CEP: new UntypedFormControl('', [Validators.required, validateCEP]),
        street: new UntypedFormControl('', [Validators.required]),
        bairro: new UntypedFormControl('', [Validators.required]),
        num: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
        cidade: new UntypedFormControl('', [Validators.required]),
        estado: new UntypedFormControl('', [Validators.required]),
    }, { validators: confirmPassword });
    loading = false;
    loadingCep = false;
    done = false;
    isNextVision = false;
    cepSub: Subscription;
    states: string[] = environment.estados;
    confirmation = false;
    matcher = new ValidatorConfirmPass();
    hide = true;
    currentStep = 0;
    get passwordInput() { return this.registerForm.get('password'); }
    get confirmPasswordInput() { return this.registerForm.get('confirm'); }
    constructor(private service: SharedService, private router: Router, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.getRegisterResult();
        const pressEnter = document.getElementById('pressEnter');
        pressEnter.addEventListener('keypress', this.checkPressEnter.bind(this));

        this.route.queryParams.subscribe(params => {
            if (params.toUse) {
                this.registerForm.get('email').setValue(params.toUse);
            }
            if (params.nameUse) {
                this.registerForm.get('name').setValue(params.nameUse);
            }
        });

        if (localStorage.getItem('Envtag') == 'nextvision') {
            this.isNextVision = true;
        }
    }

    ngOnDestroy() {
        if (this.resultSub) {
            this.resultSub.unsubscribe();
        }
        this.store.dispatch(LoginActions.cleanup());
        const pressEnter = document.getElementById('pressEnter');
        pressEnter.removeEventListener('keypress', this.checkPressEnter.bind(this));
    }

    getRegisterResult() {
        this.resultSub = this.result$.subscribe(result => {
            if (result != null) {
                if (result.success) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'register-client-user', result.message);
                    localStorage.setItem('username', this.registerForm.get('email').value);
                    setTimeout(() => {
                        this.router.navigateByUrl('/login/signin');
                    }, 1000);
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'register-client-user-fail', result.message);
                }
                this.loading = false;
            }
        });
    }

    checkPressEnter(event) {
        const keyName = event.key;
        if (keyName == 'Enter') {
            if (this.currentStep == 0) {
                this.currentStep++;
            }
            else if (this.currentStep == 1) {
                this.onRegister();
            }
        }
    }

    fillAddress() {
        this.loadingCep = true;
        let cep: string = this.registerForm.get('CEP').value;
        cep = cep.replace(/\D/g, '');
        this.service.getAddressFromCEP(cep).toPromise().then(result => {
            if (result.erro) {
                this.validationResponse.validationResponseHandler(404, 'camera-form', 'cep-not-found', 'cameras.cep_not_found');
            }
            else {
                this.registerForm.get('street').setValue(result.logradouro);
                this.registerForm.get('bairro').setValue(result.bairro);
                this.registerForm.get('cidade').setValue(result.localidade);
                this.registerForm.get('estado').setValue(result.uf);
            }
            this.loadingCep = false;
        }).catch(error => {
            this.loadingCep = false;
        });
    }

    goToPrevious() {
        if (this.currentStep == 1) {
            this.currentStep--;
        }
    }

    goToNext() {
        if (this.validateFirstStep()) {
            this.currentStep++;
        }
    }

    onRegister() {
        let errorPass = false;

        if (this.registerForm.get('cpf').invalid) {
            errorPass = true;
            this.registerForm.get('cpf').markAsTouched();
        }
        if (this.registerForm.get('cell').invalid) {
            errorPass = true;
            this.registerForm.get('cell').markAsTouched();
        }
        if (this.registerForm.get('CEP').invalid) {
            errorPass = true;
            this.registerForm.get('CEP').markAsTouched();
        }
        if (this.registerForm.get('street').invalid) {
            errorPass = true;
            this.registerForm.get('street').markAsTouched();
        }
        if (this.registerForm.get('num').invalid) {
            errorPass = true;
            this.registerForm.get('num').markAsTouched();
        }
        if (this.registerForm.get('cidade').invalid) {
            errorPass = true;
            this.registerForm.get('cidade').markAsTouched();
        }
        if (this.registerForm.get('estado').invalid) {
            errorPass = true;
            this.registerForm.get('estado').markAsTouched();
        }
        if (this.registerForm.get('bairro').invalid) {
            errorPass = true;
            this.registerForm.get('bairro').markAsTouched();
        }

        if (!errorPass) {
            // tslint:disable-next-line: no-shadowed-variable
            const user: User = {
                name: this.registerForm.get('name').value,
                email: this.registerForm.get('email').value,
                password: this.registerForm.get('password').value,
                cpf_cnpj: this.registerForm.get('cpf').value,
                telephone: this.registerForm.get('cell').value,
                address: {
                    state: this.registerForm.get('estado').value,
                    city: this.registerForm.get('cidade').value,
                    number: this.registerForm.get('num').value,
                    CEP: this.registerForm.get('CEP').value,
                    street: this.registerForm.get('street').value,
                    bairro: this.registerForm.get('bairro').value
                }
            };
            this.loading = true;
            this.store.dispatch(LoginActions.register({ user: user }));
        }
    }

    public validateFirstStep(): boolean {
        this.confirmation = false;
        let errorPass = true;
        if (this.registerForm.errors != null) {
            if (this.registerForm.errors.passwordMatch == null) {
                this.confirmation = true;
            }
            else {
                this.confirmation = this.registerForm.errors.passwordMatch;
            }
        }
        if (this.confirmation) {
            errorPass = false;
        }
        if (this.registerForm.get('name').invalid) {
            errorPass = false;
            this.registerForm.get('name').markAsTouched();
        }
        if (this.registerForm.get('email').invalid) {
            errorPass = false;
            this.registerForm.get('email').markAsTouched();
        }
        if (this.registerForm.get('password').invalid) {
            errorPass = false;
            this.registerForm.get('password').markAsTouched();
        }
        if (this.registerForm.get('confirm').invalid) {
            errorPass = false;
            this.registerForm.get('confirm').markAsTouched();
        }

        return errorPass;
    }
}
