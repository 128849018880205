<div class="main-dashboard">
    <span class="title">Dashboard</span>
   <div class="insights">
        <div class="component total-cameras">
            <div class="first-row">
                <div class="switch" *ngIf="!(isPartner || isAssociate || commomPrivGroup) && totalGroupCam > 0">
                    <input class="switch-input" type="checkbox" id="cam-toggle" (click)="dashboardSwitch()"
                           [checked]="dashboardCam == false" [disabled]="loadingCameras">
                    <label for="cam-toggle" [ngClass]="{'disabled': loadingCameras}">
                        <span nz-tooltip nzTooltipTitle="Minhas câmeras" nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="top">
                            <i class="switch-icon cam-icon" nz-icon nzType="video-camera" nzTheme="outline"></i>
                        </span>
                        <span nz-tooltip nzTooltipTitle="Grupo de câmeras" nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="top">
                            <img class="switch-icon group-cam-icon" src="../../../assets/img/group_cam.svg" alt="grupo-de-câmera"> 
                            <img class="switch-icon group-cam-icon-active" src="../../../assets/img/group_cam_active.svg" alt="grupo-de-câmera"> 
                        </span>
                    </label>
                </div>
                <span nz-tooltip nzTooltipTitle="Minhas câmeras" nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="top">
                    <i *ngIf="(isPartner || isAssociate || totalGroupCam == 0 ) && !commomPrivGroup" (click)="goToList()" class="dash-icon" nz-icon nzType="video-camera" nzTheme="outline"></i>
                </span>
                <span nz-tooltip nzTooltipTitle="Grupo de câmeras" nzTooltipOverlayClassName="cam-name" nzTooltipPlacement="top">
                    <div *ngIf="commomPrivGroup" class="dash-icon" (click)="goToList()">
                        <img class="group-cam-icon" src="../../../assets/img/group_cam.svg" alt="grupo-de-câmera"> 
                    </div>
                </span>
                  <div *ngIf="(isPartner || isAssociate) && dashboardCam" class="selector-container">
                      <app-filter 
                            [styles]="['multiselect-alternate-style']"
                            *ngIf="!loadingClients"
                            [list]="clientSimple" 
                            type="multiselect"
                            [search]="true"
                            placeholder="Selecionar Cliente"
                            (filterStatusChange)="selectClient($event)"
                            [showSelected]="true"
                            [dashboard]="true"
                            feedback="true"
                            [hideActiveFill]="true"
                            [hasIconPrefix]="true"
                            position="right"
                            iconType="team"
                        ></app-filter>
                  </div>
                  <div *ngIf="!(isPartner || isAssociate) && !dashboardCam" class="selector-container"
                       [ngClass]="{'cam-info--show': !loadingCameras || !loadingClients || !loadingFilter, 'cam-info--hidden': loadingCameras || loadingClients || loadingFilter}" >
                      <app-filter
                            [styles]="['multiselect-alternate-style']"
                            [list]="groupListSimple"
                            *ngIf="!loadingGroups"
                            type="multiselect"
                            placeholder="Selecionar Grupo"
                            (filterStatusChange)="selectGroup($event)"
                            [search]="true"
                            [showSelected]="true"
                            [hasIconPrefix]="true"
                            [hideActiveFill]="true"
                            [dashboard]="true"
                            feedback="true"
                            iconType="video-camera">
                    </app-filter>
                  </div>
            </div>
            <div [hidden]="!dashboardCam">
                <div [ngClass]="{'cam-info--show': !loadingCameras || !loadingClients || !loadingFilter, 'cam-info--hidden': loadingCameras || loadingClients || loadingFilter}" 
                    class="cam-info">
                    <div (click)="goToList()" class="total-cam">
                        <span class="sub-title">{{(isPartner || isAssociate) ? 'Câmeras Ativas' : 'Total de Câmeras'}}</span>
                        <span class="total-value">{{ totalCam }}</span>
                    </div>
                    <ul class="tag">
                        <li class="list" (click)="goToOnlineList()" ><span class="cam-title">Câmeras Online</span><span class="total online">{{camOnline}}</span></li>
                        <li class="list" (click)="goToOfflineList()"><span class="cam-title">Câmeras Offline</span><span class="total offline">{{camOffline}}</span></li>
                    </ul>
                    <div class="chart">
                        <canvas #MyChart id="MyChart" >{{chart}}</canvas>
                    </div>
                </div>
            </div>
            <div [hidden]="dashboardCam">
                <div [ngClass]="{'cam-info--show': !loadingCameras || !loadingGroups || !loadingFilter, 'cam-info--hidden': loadingCameras || loadingGroups || loadingFilter}" 
                    class="cam-info">
                    <div (click)="goToList()" class="total-cam">
                        <span class="sub-title">Total de Câmeras</span>
                        <span class="total-value">{{ totalGroupCam }}</span>
                    </div>
                    <ul class="tag">
                        <li class="list" (click)="goToOnlineList()" ><span class="cam-title">Câmeras Online</span><span class="total online">{{groupCamOnline}}</span></li>
                        <li class="list" (click)="goToOfflineList()"><span class="cam-title">Câmeras Offline</span><span class="total offline">{{groupCamOffline}}</span></li>
                    </ul>
                    <div class="chart">
                        <canvas #MyGroupChart id="MyGroupChart" >{{groupCamchart}}</canvas>
                    </div>
                </div>
            </div>
            <div *ngIf="loadingCameras || loadingGroups || loadingFilter" class="loading-container">
                <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
            </div>
        </div>
        <div class="component blocked-cameras" *ngIf="isPartner || isAssociate">
            <div class="first-row blocked">
                <i (click)="goToBlockedList()" class="dash-icon blocked" nz-icon nzType="stop" nzTheme="outline"></i>
                  <div class="selector-container blocked">
                      <app-filter
                            [blocked]="loadingBlockedCameras"
                            [styles]="['multiselect-alternate-style']"
                            *ngIf="!loadingClients"
                            [list]="clientSimple" 
                            type="multiselect"
                            [search]="true"
                            placeholder="Selecionar Cliente"
                            (filterStatusChange)="selectClientBlockedCam($event)"
                            [showSelected]="true"
                            [dashboard]="true"
                            feedback="true"
                            [hideActiveFill]="true"
                            [hasIconPrefix]="true"
                            iconType="team"
                        ></app-filter>
                  </div>
            </div>
            <div [ngClass]="{'cam-info--show': !loadingBlockedCameras, 'cam-info--hidden': loadingBlockedCameras}" class="cam-info">
                <div class="scroll" infinite-scroll [infiniteScrollDistance]="0.05" [infiniteScrollThrottle]="1"
                    [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
                    <div (click)="goToBlockedList()" class="total-blocked">
                        <span class="sub-title">Câmeras bloqueadas</span>
                        <span class="total-value blocked">{{ totalBlockedCam }}</span>
                    </div>
                    <nz-table *ngIf="blockedData?.length > 0" #blockedCamTable class="table antd-table">
                        <thead>
                            <tr>
                                <th class="table-cam">Câmera</th>
                                <th class="table-client">Cliente</th>
                                <th class="table-hour">Data/Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let camera of blockedData" (click)="goToBlockedList()">
                                <td>
                                    {{ camera.camera_name }}
                                </td>
                                <td>
                                    <span class="style-nameCam" nz-tooltip [nzTooltipTitle]="camera.client_name" nzTooltipOverlayClassName="cam-name"
                                    nzTooltipPlacement="topLeft" [nzTooltipOverlayStyle]="{'margim-bottom': '12px'}">
                                    {{ camera.client_name }}
                                    </span>
                                </td>
                                <td>
                                    <div *ngIf="!camera.changed_at">
                                        00/00/0000 00:00:00
                                    </div>
                                    {{ camera.changed_at }}
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <div *ngIf="blockedData?.length == 0" class="no-blocked-cameras">
                        <img src="../../../assets/img/no-blocked-cam.svg" alt="">
                        <span>Nenhuma câmera bloqueada</span>
                    </div>
                    <div *ngIf="loadingBlockedList" class="loading-container blocked">
                        <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="loadingBlockedCameras" class="loading-container">
                <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
            </div>
        </div>
    </div>
</div>