<div *ngIf="waiting" class="page-header container_spinner">
  <div class="container">
    <div class="col-md-6 spinner_col">
      <div class="row spinner_row">
        <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 80px"></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!waiting && !no_alerts" (window:resize)="onResize()">
  <div class="main-content" [ngClass]="{'clientView' : client_sub}">
    <div class="col-12" *ngIf="!client_sub">
      <span class="style-title">Alertas e detecções</span>
    </div>
    <form [formGroup]="detectionsForm" class="first-row">
      <div class="button-container">
        <button *ngFor="let types of types_analytic" mat-stroked-button class="alerts_button" [id]="types"
              (click)="selectType($event.target.innerText)">{{types}}</button>
      </div>

      <div class="camera-filter">
        <!-- Filtro de grupos -->
        <div class="filter-row">
          <div class="filter-container" style="margin-right: 20px;" *ngIf="hasGroups || groupFilterList.length > 0">
            <span hidden>{{groupFilterList.length}}{{hasGroups}}</span>
            <app-filter [list]="groupFilterList" #filterGroup
            (filterStatusChange)="updateGroupId($event)"
            [type]="'multiselect'"
            [placeholder]="'Filtrar por grupo'"></app-filter>
          </div>
          <!-- Filtro de cameras -->
          <div class="filter-container" *ngIf="list_cameras && list_cameras.length > 0">
            <app-filter [list]="camFilterList" #filterCam
            (filterStatusChange)="updateCamId($event)"
            (scrollItems)="onScrollCam($event)"
            [loading]="loadingCam"
            [type]="'multiselect'"
            [placeholder]="'Filtrar por câmera'"></app-filter>
          </div>
  
          <div style="display: flex; align-items: center; margin-bottom: 0px; z-index: 90;">
            <input matInput [matDatepicker]="picker" (dateInput)="dataSelection($event)" formControlName="dataAlert"
              class="calendar-color" style="cursor: default; visibility: hidden; width: 1px;" readonly>
            <mat-datepicker-toggle style="margin-bottom: 0px; margin-right: 0.5rem; margin-left: 0.7rem;" matSuffix
              [for]="picker" class="date_calender_edit" [class.selected]="date">
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
  
            <div class="filter-return" (click)="returnFilter()" *ngIf="(camId.length > 0) || (isDateSelected) || (typeSelected.length > 0)">
              <span>Limpar filtro</span>
            </div>
          </div>
      </div>
    </form>
    <div *ngIf="finished === false" class="row spinner_row">
      <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
    </div>
    <div *ngIf="finished === true" class="search-results" infinite-scroll [infiniteScrollDistance]="0.5"
      [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">

      <div class="no-alert" *ngIf="finished === true && no_alerts_filter">
        <p>Nenhum alerta encontrado</p>
      </div>

      <div *ngFor="let alertGroup of alerts_by_date" style="margin-top: -55px;">
        <div style="padding: 0 2.375rem 0px 3rem; border-top: 1px solid transparent;" class="container-fluid" id="video-cards">

          <div class="row dates">
            <p class="day_detections">{{alertGroup.date}}</p>
            <p class="count_detections"><span>{{alertGroup.total_result}}</span>resultados</p>
          </div>
          <div class="row" style="margin-bottom: 40px">
            <div [class]="getScreenSize()" *ngFor="let alert of alertGroup.alerts;">
              <div id="card-{{alert.id}}">
                <div [id]="alert.id" class="card">
                  <video class="video" preload="auto"
                         poster="{{alert.screenshotfile}}" (click)="playVideo(alert.id)">
                    <source src="{{alert.link_video}}">
                  </video>
                  <progress *ngIf="alert.link_video" id="progress" max="100" value="0" style="width: 100%;">Progress</progress>
                  <div class="classifiersList">
                    <div *ngFor="let classifier of getClassifiersList(alert.analytic_class)">
                      <div *ngIf="classifier" class="classifiers">{{classifier}}</div>
                    </div>
                    <div [ngStyle]="{'display': isSettingsEnable(alert) === true ? 'block' : 'none'}">
                      <i nz-icon [nzType]="loading_modal && alertOpened.id == alert.id ? 'loading' : 'setting'"
                         [nzTheme]="loading_modal && alertOpened.id == alert.id ? 'outline' : 'fill'" 
                         (click)="getAnalytics(alert)" class="alert-settings"></i>
                    </div>
                    <div>
                      <img *ngIf="alert.link_video" class="fullscreen-icon" src="../../../assets/img/Icons/fullscreen-white.svg" alt="fullscreen"
                        (click)="playFullscreen(alert)">
                    </div>
                  </div>
                  <div *ngIf="alert.link_video" class="playpause" (click)="playVideo(alert.id)"></div>
                  <div [id]='"imgDiv" + alert.id'>
                    <img class="user-image" [src]="getImage(alert, 'imgDiv' + alert.id)"
                      onerror="this.src='../../../assets/img/placeholder.jpg';">
                  </div>
                  <div class="flex justify-content-between card-body">
                    <div class="title_cam">
                      <h5 class="card-title type_detection">{{alert.analytic_type}}</h5>
                      <p class="card-text name_cam" style="align-items: center">
                        <i class="material-icons" style="margin-top: -3px">videocam</i> {{changeCameraName(alert)}}
                      </p>
                    </div>
                    <div class="date_cam">
                      <p class="card-text">{{alertGroup.date | titlecase}}, {{alert.date_occurrence |
                        date:'HH:mm'}}h</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading_alert === true" class="row spinner_row">
        <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
      </div>
      <div class="footer-amount">
        <p *ngIf="date === null && finished" class="card-text">{{actualAmount || 0}} de {{totalAmount || 0}} resultados
        </p>
        <p *ngIf="date !== null && finished" class="card-text">{{totalAmount || 0}} resultados encontrados</p>
      </div>
    </div>
  </div>
</div>
<!-- Tela de quando não há alertas -->
<div *ngIf="no_alerts">
  <app-feedback imageUrl="no_alerts.svg" type="alertas" [noAlerts]="true"></app-feedback>
</div>

<app-modal></app-modal>

<!-- <svg class="display_invisible">
  <defs>
    <style>
      .cls-1 {
        fill: #fff
      }
    </style>
  </defs>
</svg> -->
