<div class="wrapper wrapper-full-page">
  <div class="page-header register-page background">
    <div class="container">
      <div class="flex flex-row justify-center items-center">
        <div class="flex justify-center items-center w-full sm:w-8/10 md:w-8/10 lg:w-3/4 xl:w-8/10 mx-auto">
          <div class="wizard-container">
            <div class="card card-wizard card-style-wizard">
              <form [formGroup]="registerForm" class="example-form">
                <div class="card-style-header">
                  <span class="title_register">Criar conta de convidado</span>
                </div>
                <div class="row no-row">
                  <span class="title-style">A conta de convidado só dará acesso a todas as informações que foram compartilhadas com você.</span>
                  <span *ngIf="currentStep === 0" class="title-style">
                      Sua senha deve ser composta por pelo menos 1 letra maiúscula, 1 número e um caractere especial (ex: !&#64;#$%&*).
                  </span>
                </div>
                <div class="card-body">
                  <div id="pressEnter">
                    <div *ngIf="currentStep === 0">
                      <div>
                        <div class="block-one">
                          <div class="form-group has-default">
                            <div class="label-email">
                              <span>Nome</span>
                            </div>
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="name"
                             class="input-container" />
                            <mat-error *ngIf="registerForm.get('name').hasError('required') && registerForm.get('name').touched">
                              Nome <strong>é requerido</strong>
                            </mat-error>
                          </div>
                          <div class="form-group has-default-two">
                            <div class="label-email">
                              <span>E-mail</span>
                            </div>
                            <input nz-input type="email" placeholder="Digite aqui..." id="email" formControlName="email"
                             class="input-container" />
                            <mat-error
                              *ngIf="registerForm.get('email').hasError('email') && !registerForm.get('email').hasError('required')">
                              Por favor digite um formato de e-mail válido
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('email').hasError('required') && registerForm.get('email').touched">
                              Email <strong>é requerido</strong>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="block-two">
                          <div class="form-group has-default">
                            <div class="label-email">
                              <span>Senha</span>
                            </div>
                            <div class="input-group">
                              <nz-input-group [nzSuffix]="suffixTemplate" class="input-container">
                                <input class="input-value" [type]="hide ? 'password' : 'text'" nz-input placeholder="Digite aqui..." formControlName="password" />
                              </nz-input-group>
                              <ng-template #suffixTemplate>
                                <i class="input-eye" nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></i>
                              </ng-template>
                            </div>
                            <div class="error-code" *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                              <span>*Sua senha deve conter ao menos 
                                <span *ngIf="registerForm.get('password').errors.minlength">8 caracteres, </span>
                                <span *ngIf="registerForm.get('password').errors.lowerCaseError;">1 letra minúscula, </span>
                                <span *ngIf="registerForm.get('password').errors.upperCaseError;">1 letra maiúscula, </span>
                                <span *ngIf="registerForm.get('password').errors.numberError;">1 número, </span>
                                <span *ngIf="registerForm.get('password').errors.symbolError;">1 caractere especial (ex: !&#64;#$%&*)</span>.
                              </span>
                            </div>
                            <mat-error *ngIf="registerForm.get('password').hasError('required') && registerForm.get('password').touched">
                              Senha <strong>é requerido</strong>
                            </mat-error>
                          </div>
                          <div class="form-group has-default-two">
                            <div class="label-email">
                              <span>Confirmar senha</span>
                            </div>
                            <div class="input-group">
                              <nz-input-group [nzSuffix]="suffixTemplate" class="input-container">
                                <input class="input-value" [type]="hide ? 'password' : 'text'" nz-input placeholder="Digite aqui..." formControlName="confirm" onpaste="return false;"/>
                              </nz-input-group>
                              <ng-template #suffixTemplate>
                                <i class="input-eye" nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></i>
                              </ng-template>
                            </div>
                            <mat-error *ngIf="registerForm.get('confirm').hasError('required') && registerForm.get('confirm').touched">
                              Confirmar senha <strong>é requerido<br></strong>
                            </mat-error>
                            <mat-error *ngIf="confirmation">
                              Senhas <strong>não conferem!</strong>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div class="row steps-container">
                        <i class="material-icons steps-left-style">fiber_manual_record</i>
                        <i (click)="goToNext()"
                          class="material-icons-outlined steps-right-style">fiber_manual_record</i>
                        <button type="button" (click)="goToNext()"
                          class="btn btn-primary btn-md button-style">Próximo</button>
                      </div>
                    </div>
                    <div *ngIf="currentStep === 1 && validateFirstStep()" class="wizard_form_register">
                      <div class="row">
                        <div class="block-one step-two">
                          <div class="form-group has-default">
                            <div class="label-email">
                              <span>CPF/CNPJ</span>
                            </div>
                            <input nz-input type="text" mask="CPF_CNPJ" placeholder="Digite aqui..." 
                              formControlName="cpf" class="input-container" />
                            <mat-error *ngIf="registerForm.get('cpf').hasError('required') && registerForm.get('cpf').touched">
                              CPF/CNPJ <strong>é requerido<br></strong>
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('cpf').hasError('CPFInvalido')">
                              CPF <strong>inválido</strong>
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('cpf').hasError('CNPJInvalido')">
                              CNPJ <strong>inválido</strong>
                            </mat-error>
                          </div>
                          <div class="form-group has-default-two">
                            <div class="label-email">
                              <span>Celular</span>
                            </div>
                            <input nz-input type="text" mask="(00) 0000-0000||(00) 0 0000-0000" placeholder="Digite aqui..." 
                              formControlName="cell" class="input-container" />
                            <mat-error *ngIf="registerForm.get('cell').hasError('required') && registerForm.get('cell').touched">
                              Celular <strong>é requerido</strong>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="block-three">
                          <div class="form-group has-default">
                            <div class="label-email">
                              <span>CEP</span>
                            </div>
                            <input nz-input type="text" mask="00000-000" placeholder="Digite aqui..." formControlName="CEP"
                            class="input-container" showMaskTyped="true" shownMaskExpression="_____-___" />
                            <mat-error *ngIf="registerForm.get('CEP').hasError('required') && registerForm.get('CEP').touched">
                              CEP <strong>é requerido<br></strong>
                            </mat-error>
                            <mat-error *ngIf="registerForm.get('CEP').hasError('CEPInvalid')">
                              CEP <strong>inválido</strong>
                            </mat-error>
                          </div>
                          <div class="has-search">
                            <button *ngIf="!loadingCep" (click)="fillAddress()" class="btn btn-just-icon btn-accent button-search">
                              <i nz-icon nzType="search" nzTheme="outline"></i>
                            </button>
                            <span nz-icon *ngIf="loadingCep" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
                          </div>
                          <div class="form-group has-default-three">
                            <div class="label-email">
                              <span>UF</span>
                            </div>
                            <nz-select nzBorderless formControlName="estado" nzPlaceHolder="Selecionar" class="input-select">
                              <nz-option *ngFor="let state of states" [nzValue]="state" [nzLabel]="state"></nz-option>
                            </nz-select>
                            <mat-error *ngIf="registerForm.get('estado').hasError('required') && registerForm.get('estado').touched">
                              UF <strong>é requerido</strong>
                            </mat-error>
                          </div> 
                          <div class="form-group has-default-fuor">
                            <div class="label-email">
                              <span>Cidade</span>
                            </div>
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="cidade" 
                              class="input-container" />
                            <mat-error *ngIf="registerForm.get('cidade').hasError('required') && registerForm.get('cidade').touched">
                              Cidade <strong>é requerido</strong>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="block-one step-two">
                          <div class="form-group has-default" style="margin-right: 0px;">
                            <div class="label-email">
                              <span>Logradouro</span>
                            </div>
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="street" 
                              class="input-container" />
                            <mat-error *ngIf="registerForm.get('street').hasError('required') && registerForm.get('street').touched">
                              Logradouro <strong>é requerido</strong>
                            </mat-error>
                          </div>
                          <div class="form-group has-default-fuor">
                            <div class="label-email">
                              <span>Número</span>
                            </div>
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="num" 
                              class="input-container" />
                            <mat-error *ngIf="registerForm.get('num').hasError('required') && registerForm.get('num').touched">
                              Número <strong>é requerido</strong>
                            </mat-error>
                          </div>
                          <div class="form-group has-default-fuor">
                            <div class="label-email">
                              <span>Bairro</span>
                            </div>
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="bairro" 
                              class="input-container" />
                            <mat-error *ngIf="registerForm.get('bairro').hasError('required') && registerForm.get('bairro').touched">
                              Bairro <strong>é requerido</strong>
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!loading">
                        <div class="row steps-container">
                          <i (click)="goToPrevious()"
                            class="material-icons-outlined steps-left-style">fiber_manual_record</i>
                          <i class="material-icons steps-right-style">fiber_manual_record</i>
                          <button class="btn btn-primary btn-md button-style" type="button" (click)="onRegister()">
                            Criar conta
                          </button>
                        </div>
                      </ng-container>
                      <div *ngIf="loading" class="center-spinner">
                        <span nz-icon nzType="loading" nzTheme="outline" style="font-size:35px"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="footer-wesafer">
                <span>Powered by WeSafer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="logo-footer">
      <img src="../../../assets/img/logo_login.png" alt="" srcset="" *ngIf="isNextVision">
    </div>
  </div>
</div>
