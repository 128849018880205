<div class="card container_global">
    <div class="containerOutForMarkedOption" [ngStyle]="{'display':formRightComplete === false ? 'block' : 'none' }">
        <div class="conatinerInnerForMarkedOption">
            <div><span class="icone_graphic"></span></div>
            <span class="fontTitleMarkedOption">Clique na imagem para demarcar a área de detecção.</span>
        </div>
    </div>
    <canvas #plotImageDraw class="canvas-back canvasStyleOut" width="640" height="480"
        [ngClass]="{'setBlurOnScreenshot':  formRightComplete === false, 'removeBlurOnScreenshot':formRightComplete === true}"></canvas>
    <canvas #drawAnalytic class="canvas-front" width="640" height="480" (click)="validadePointers()"></canvas>
    <div class="container-objects">
        <img src="../../../assets/img/Icons/object_icon@3x.png" [height]="objectControl[1]*5" [width]="objectControl[1]*2" class="object-large-image" id="object-large">
        <img src="../../../assets/img/Icons/object_icon@3x.png" [height]="objectControl[0]*5" [width]="objectControl[0]*2" class="object-small-image" id="object-small">
    </div>
    <div class="card container_global" style="margin-top: 8px !important; margin-bottom: 8px !important;">
        <div class="card-body tooltiptoolbar">
            <span class="tooltiptext" [ngStyle]="{'display':sideRightComplete === true ? 'block' : 'none' }">Preencha todos
                os campos da configuração para habilitar esse recurso.</span>
            <div [ngStyle]="{'opacity':sideRightComplete === true ? '0.5' : '1.0' }">
                <div class="row">
                    <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xga-10 col-fhd-10">
                        <h5 class="title-config title_analitics">Configurar analítico</h5>
                    </div>
                    <div class="ccol-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xga-2 col-fhd-2">
                        <div *ngIf="newAnalytic.points.length > 0" class="container_analytics_Actions"
                            [ngClass]="{'disableDiv': sideRightComplete}">
                            <i class="buttons-draw" (click)="undoDrawCanvas()" nz-icon nzType="arrow-left" nzTheme="outline"></i>
                            <i class="buttons-draw" (click)="deleteDrawCanvas()" nz-icon nzType="delete" nzTheme="outline"></i>
                        </div>
                    </div>
                </div>
                <!-- Configuração -->
                <div class="row" [ngClass]="{'disableDiv': sideRightComplete}">
                    <div class="ol-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3">
                        <span class="label-config">Desenho</span>
                        <div class="containerColorPicker" id="color_dad">
                            <span class="dot dot-blue" style="background-color: #326ABC;"
                                (click)="getColor($event.target.attributes.style.value, 'color')" id="#326ABC"></span>
                            <label for="input-color" class="containerLabelColorInput">
                                <span class="color-picker-container-out iconColorPicker"></span>
                                <input #colorpicker class="inputNotVisible" (change)="getColor($event, 'picker')"
                                    id="input-color" type="color">
                            </label>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xga-2 col-fhd-2">
                        <span class="label-config">Iluminação</span>
                        <div class="onoffswitch config-column">
                            <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="analytics"
                                [attr.checked]="editAnalitic ? 'checked' : null" [formControl]="toogleLight">
                            <label class="onoffswitch-label switch_label" for="analytics">
                                <span class="onoffswitch-inner onoffswitch-inner-control"></span>
                                <span class="onoffswitch-switch"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 slider-analytic">
                        <span class="label-config">Redução de ruído ({{sensitivityDraw}}%)</span>
                        <nz-slider nzTooltipVisible="default" nzTooltipPlacement="bottom" [(ngModel)]="sensitivityDraw"
                                    [nzMarks]="marks" nzStep="1"></nz-slider>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 slider-analytic">
                        <span class="label-config">Tamanho do objeto ({{objectControl[0]}}-{{objectControl[1]}}%)</span>
                        <nz-slider nzRange nzTooltipVisible="default" nzTooltipPlacement="bottom" [(ngModel)]="objectControl"
                                    [nzMarks]="marks" nzStep="1"></nz-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
