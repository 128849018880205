import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
    @Input() context: string;
    @Input() type: string;
    @Input() imageUrl: string;
    @Input() transparent: boolean;
    @Input() noCameras: boolean;
    @Input() noFound: boolean;
    @Input() noUsers: boolean;
    @Input() noAlerts: boolean;
    @Input() noGroupCameras: boolean;
    @Input() noCalendar: boolean;

    is_partner: boolean;
    isGroup: boolean;
    client_sub: string;

    constructor() { }

    ngOnInit(): void {
        this.client_sub = localStorage.getItem('clientView');
        const userProfile = localStorage.getItem('profile_name');
        if (userProfile == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.is_partner = true;
        } else {
            this.is_partner = false;
        }

    }
}
