import { Store } from '@ngrx/store';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ModalState} from 'app/analytics/models';
import {AnalyticActions} from 'app/analytics/Services/actions';
import {Associate} from 'app/Login/models';
import {OperationStatus, ResultAlert} from 'app/Shared/models';
import {SharedService} from 'app/Shared/Services/shared.service';
import {ValidationResponseHandlerModule} from 'app/Shared/ValidationResponse/validation-response-handler';
import {AppState} from 'app/store/model';
import { UserInventory } from 'app/Users/models';
import {UserService} from 'app/Users/Services/user.service';
import {environment} from 'environments/environment';
import Hls from 'hls.js';
import {of, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {AnalyticTypes, Camera, CameraId, ListType, Mosaic, PaginationCameras} from '../models';
import {CameraActions} from '../Services/actions';
import {CameraService} from '../Services/camera.service';
import {ImageService} from 'app/Shared/Services/image.service';

@Component({
    selector: 'app-camera-view',
    templateUrl: './camera-view.component.html',
    styleUrls: ['./camera-view.component.scss']
})
export class CameraViewComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.camera.modal_open_invite_user);
    readonly modal_open_camera$ = this.store.select((state: AppState) => state.camera.modal_open_update_camera);
    readonly upCam$ = this.store.select((state: AppState) => state.camera.updateCam);
    readonly camera$ = this.store.select((state: AppState) => state.camera.camera_view);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly camerasShared$ = this.store.select((state: AppState) => state.camera.cameraShared);
    readonly delCam$ = this.store.select((state: AppState) => state.camera.deleteCam);
    readonly mosaics$ = this.store.select((state: AppState) => state.camera.mosaics);
    readonly userMosaics$ = this.store.select((state: AppState) => state.camera.userMosaics);
    readonly success$ = this.store.select((state: AppState) => state.camera.success);
    readonly delStatus$ = this.store.select((state: AppState) => state.camera.deleteStatus);
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupList$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupCameraID$ = this.store.select((state: AppState) => state.camera.groupCameraID);
    readonly clients$ = this.store.select((state: AppState) => state.camera.clientsGroup);

    @ViewChildren('video') queryVideos: QueryList<ElementRef>;

    mosaicFake = false;
    mosaicMenu = new UntypedFormControl('');
    maxMosaics = 4;

    menu: Subscription;

    waiting: boolean;
    hasCameras: boolean;

    modalClose: Subscription;

    camSub: Subscription;
    invSub: Subscription;
    groupCamSub: Subscription;
    querySub: Subscription;
    mosaicSub: Subscription;
    userMosaicSub: Subscription;
    cameraSub: Subscription;
    currentSub: Subscription;
    successSub: Subscription;
    clientSub: Subscription;
    groupSub: Subscription;
    groupListSub: Subscription;

    deleteCam: Subscription;
    updateCam: Subscription;


    getTypesAnalytic: Subscription;
    typesAnalytic: AnalyticTypes[] = null;

    returnSwal: Subscription;
    delMosaic: Subscription;
    opState: OperationStatus;

    cameras: Camera[] = [];
    camerasShared: Camera[] = [];

    mosaics: Mosaic[];
    userMosaics: Mosaic[] = [];
    currentMosaic: Mosaic;
    uniqueMosaic: Mosaic;
    statusMosaic: boolean[];

    elem: any[] = [];
    videos: HTMLVideoElement[] = [];
    videoRef: ElementRef[];
    hlsInstances: Hls[] = [];
    loading = false;

    scrollbarCameras: any;
    scrollbarShared: any;

    selectedVideo = -1;
    collapsed = false;
    statusCam = true;
    mqSmall: MediaQueryList;
    inventories: UserInventory;

    userSub: string;
    idCam: number;
    confirm: any;
    sizeChanges: number;
    informationMenu: string[];
    associate_id = environment.associateId;
    camerasOnMosaic: CameraId[];

    saveCameras = false;
    isFullscreen = false;
    activeList = [];

    firstCam: string;
    firstAccess = true;

    timeOutHandler: ReturnType<typeof setTimeout>;

    firstGrid = true;
    choosingMosaic = false;
    pageName = 'camera-view';

    isDropdownActive = false;
    isLayoutDropdownActive = false;
    loadingClients = false;
    isPartner: boolean;
    isGuest = false;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';
    adminClientAssociate: boolean;
    isPartnerAssociate: boolean = localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3';
    adminPartnerAssociate: boolean;

    clientListSimple: {
        id: number | string;
        text: string;
        client_sub: string;
    }[];
    selectedClient: string;
    loadingCamera: boolean;
    loadingScroll: boolean;
    associate: Associate;

    ownerName: string;
    hasGroups: boolean;
    groupListSimple: {
        id: number;
        text: string;
    }[];
    loadingGroup: boolean;
    initMosaic: boolean;
    selectedGroup: number;
    currentMosaicIndex: number;
    timeStatus: number;

    sum = 1;
    sumGroup = 1;
    camLimit = 9;

    actual: number;
    actualGroup: number;
    allPages = 0;
    allPagesGroup = 0;
    totalAmount = 0;
    totalAmountGroup = 0;
    actualAmount: number;
    actualAmountGroup: number;

    searchInputControl = new UntypedFormControl('', []);

    constructor(private service: CameraService, private router: Router, private validationResponse: ValidationResponseHandlerModule,
        private sanitizer: DomSanitizer, private readonly store: Store<AppState>, private sharedService: SharedService,
        private userService: UserService, private IS: ImageService) {
    }

    ngOnInit() {
        this.timeStatus = 15;
        this.initMosaic = true;
        this.loadingCamera = false;
        this.mqSmall = window.matchMedia('(max-width: 599px');
        this.waiting = true;
        this.loadingGroup = true;
        this.hasCameras = true;
        const sub = localStorage.getItem('sub');
        this.associate = JSON.parse(localStorage.getItem('associate'));
        this.firstCam = localStorage.getItem('firstMosaicCamera');
        this.userSub = sub;
        this.getProfile();
        if (this.isPartner || this.isPartnerAssociate) {
            this.getClients();
        }
        // this.store.dispatch(this.userActions.get_configurations_alarm({ user_sub: this.userSub }));
        // this.getActiveAnalytic();
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: this.userSub,
            type_request: 'list',
            active: true
        }));
        this.loadCameras();
        this.store.dispatch(CameraActions.get_mosaics());
        this.store.dispatch(CameraActions.get_user_mosaics({ user_sub: sub }));
        this.getCamerasToMosaic();

        document.addEventListener('fullscreenchange', this.changeFullscreen.bind(this));

        this.updateCameraEdit();
        this.confirmUpdateCamera();
        this.confirmDeleteFeedback();
        this.deleteMosaicResponse();
        this.getgroupCameraList();
        this.selectGroupResult();

        //cameras: Camera[] = [];
        // Recebe o retorno das informações do swal

        this.returnSwal = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        //thread para sempre deixar o mosaico no display limpo, sem interferências assíncronas em mudanças de mosaico
        //Tempo de espera para não quebrar quando abrir mosaico ao carregar uma câmera da lista de câmeras
        this.userService.userInventory(this.userSub, null).toPromise().then(res => {
            this.inventories = res[0];
        });
    }

    getCamerasToMosaic() {
        this.camSub = this.cameras$.subscribe(cameras => {
            this.loadingCamera = false;
            this.loadingGroup = false;
            this.loadingScroll = false;
            if (cameras && cameras.model === 'cameras') {
                const cams: any = cameras.cameras as PaginationCameras;
                this.totalAmount = cams.total;
                this.allPages = cams.pages;
                this.actual = cams.actual;
                this.camLimit = cams.limit;

                // Câmeras do usuário
                this.getUniqueCameras(cams.cameras);
                this.waiting = false;
            }
            this.hasCameras = this.cameras.length > 0 || this.camerasShared.length > 0;
        });

        this.invSub = this.camerasShared$.subscribe(shareCams => {
            this.loadingCamera = false;
            this.loadingScroll = false;
            if (shareCams) {
                if (shareCams.length >= 0) {
                    //  Câmeras Compartilhadas
                    // this.cameras = this.cameras.concat(cameraSharedActives);
                    this.camerasShared = shareCams.filter(function (elem) {
                        return elem.active != false;
                    });
                    this.hasCameras = this.hasCameras = this.cameras.length > 0 || this.camerasShared.length > 0;
                    this.camerasShared.forEach(async c => {
                        this.IS.saveCacheThumbs(c.thumbnail);
                        c.cache_thumb = of(
                            await this.IS.getCSSBackgroundImageURL(c.thumbnail)
                        );
                    });
                } else {
                    this.camerasShared = [];
                }
            }
        });

        this.groupCamSub = this.groupCamera$.subscribe(groupCamera => {
            this.loadingGroup = false;
            this.loadingScroll = false;
            if (groupCamera && 'cameras' in groupCamera && groupCamera.cameras) {
                if (groupCamera.cameras.length > 0) {
                    this.totalAmountGroup = groupCamera.total;
                    this.allPagesGroup = groupCamera.pages;
                    this.actualGroup = groupCamera.actual;
                    this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                    this.getUniqueCameras(groupCamera.cameras, true);
                }
            }

            this.hasCameras = this.cameras.length > 0 || this.camerasShared.length > 0;
        });

        this.userMosaicSub = this.userMosaics$.subscribe(userMosaics => {
            this.mosaicSub = this.mosaics$.subscribe(mosaic => {
                if (userMosaics && mosaic) {
                    this.mosaics = mosaic;
                    this.firstGrid = true;
                    this.mapUserMosaic(userMosaics, this.mosaics);
                }
                this.loadingCamera = false;
                this.loadingScroll = false;
            });
        });
    }

    getgroupCameraList() {
        this.groupListSub = this.groupList$.subscribe(data => {
            if (data && !('cameras' in data)) {
                this.groupListSimple = data.map(c => {
                    return {
                        id: c.id_camera_group,
                        text: c.alias
                    };
                });
                this.hasGroups = this.groupListSimple.length > 0;
                this.loadingGroup = false;
            }
        });
    }

    mapUserMosaic(userMosaics, mosaic) {
        this.mosaicFake = (this.currentMosaic && this.currentMosaic.name != null);
        if (!userMosaics) {
            userMosaics = [];
        }

        if (userMosaics.length == 1 && mosaic) {
            // !!!!!!!!!! +++++++++++ #####################################
            // PRECISA RECARREGAR OS MOSAICOS DO USUARIO AO REALIZAR ESSA ETAPA.
            //VAI DAR CERTo?? TALVEZ SEJA MELHOR CRIAR MOSAICO FAKE
            this.uniqueMosaic = userMosaics[0];
            this.loadPresetMosaic(userMosaics, mosaic);
            this.timeStatus = userMosaics[0].timeStatus;
        }

        // Fluxo caso não haja um mosaico criado, ele cria este mosaico Default
        else if (!this.mosaicFake && this.mosaics && mosaic.length > 1) {
            this.createMosaicFake(userMosaics);
            if (userMosaics.length > 1) {
                userMosaics.forEach(x => this.timeStatus = x.timeStatus <= this.timeStatus ? x.timeStatus : this.timeStatus);
            }
            userMosaics.forEach(x => this.timeStatus = x.timeStatus <= this.timeStatus ? x.timeStatus : this.timeStatus);
        } else if (userMosaics.length > 1) {
            this.userMosaics = userMosaics;
            userMosaics.forEach(x => this.timeStatus = x.timeStatus <= this.timeStatus ? x.timeStatus : this.timeStatus);

            if (this.currentMosaic) {
                const currentMosaicUpdated = this.userMosaics.find(m => m.id == this.currentMosaic.id);
                if (currentMosaicUpdated) {
                    this.changeMosaic(currentMosaicUpdated);
                }
            }
        }
    }

    defineMenuCam(byGroup: boolean) {
        if (this.isClientAssociate && !this.adminClientAssociate && byGroup) {
            return ['Adicionar analítico', 'Recuperar videos', 'Ver todos os alertas'];
        } else if (this.isClientAssociate && this.adminClientAssociate && byGroup) {
            if (byGroup) {
                return ['Adicionar analítico', 'Recuperar videos', 'Ver todos os alertas'];
            } else {
                return ['Adicionar analítico', 'Convidar', 'Recuperar videos', 'Ver todos os alertas', 'Configurações'];
            }
        } else if (!this.isGuest && !this.isPartner && !this.isClientAssociate && !this.isPartnerAssociate && byGroup){
            return ['Adicionar analítico', 'Recuperar videos', 'Ver todos os alertas'];
        } else {
            return ['Adicionar analítico', 'Convidar', 'Recuperar videos', 'Ver todos os alertas', 'Configurações'];
        }
    }

    getUniqueCameras(cameras: Camera[], byGroup = false): Camera[] {
        if (cameras) {
            const idOrdainedList = cameras.sort((a, b) => {
                return a.id - b.id;
            });

            idOrdainedList.forEach(async (current, index, list) => {
                if (current && current.active) {
                    if (this.cameras.length >= 0) {
                        const camExist = this.cameras.find(x => x.id === current.id || x.hashname === current.hashname);
                        if (!camExist) {
                            current.informationMenu = this.defineMenuCam(byGroup);
                            this.cameras.push(current);
                            this.addThumbInCache(current);
                        }
                    } else {
                        this.cameras.push(current);
                        this.addThumbInCache(current);
                    }
                }
            });

            return this.cameras;
        }
        return [];
    }

    async addThumbInCache(c: Camera) {
        this.IS.saveCacheThumbs(c.thumbnail);
        c.cache_thumb = of(await this.IS.getCSSBackgroundImageURL(c.thumbnail));
    }

    brokenImageHandler(element) {
        element.error = false;
        element.target.src = '../../../assets/img/broken_link.svg';
    }

    getProfile() {
        const profile = localStorage.getItem('profile_name');
        this.isPartner = (profile == 'cd343bfc-17e8-11ec-9621-0242ac130002');
        this.isGuest = (profile == '419bea06-5d4e-4a56-b8b5-04b3ad566d59');
        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
        this.adminPartnerAssociate = this.isPartnerAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
    }

    getClients() {
        this.loadingClients = true;
        this.clientSub = this.clients$.subscribe(data => {
            if (data) {
                this.clientListSimple = data.map(e => {
                    return {
                        id: e.client_id,
                        text: e.client_name,
                        client_sub: e.client_sub
                    };
                });
                this.loadingClients = false;
            } else {
                this.store.dispatch(CameraActions.get_clients_group({
                    user_sub: this.userSub
                }));
            }
        });
    }

    selectClient(id) {
        const query = this.searchInputControl.value;
        this.loadingCamera = true;
        this.loadingGroup = true;
        this.initCameras();
        if (id) {
            const item = this.clientListSimple.find(element => element.id == id);
            this.selectedClient = item ? item.client_sub : undefined;
            if (!this.isPartner && !this.isPartnerAssociate) {
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    type_request: this.selectedGroup > 0 ? 'data' : 'cameras',
                    page: this.sumGroup,
                    group_id: this.selectedGroup,
                    limit: this.camLimit,
                    str_filter: query,
                    active: true
                }));
            }
            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.userSub,
                child_sub: this.selectedClient,
                group_id: this.selectedGroup,
                page: this.sum,
                limit: this.camLimit,
                str_filter: query,
                short: false,
                active: true
            }));
        } else {
            this.selectedClient = null;
            this.loadCameras();
        }
    }

    selectGroup(id) {
        const query = this.searchInputControl.value;
        this.loadingGroup = true;
        this.initCameras();
        if (id.length > 0) {
            this.selectedGroup = id[0];
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: localStorage.getItem('sub'),
                type_request: 'data',
                child_sub: this.selectedClient,
                group_id: this.selectedGroup,
                page: this.sumGroup,
                limit: this.camLimit,
                str_filter: query,
                active: true
            }));
        } else {
            this.selectedGroup = null;
            this.loadCameras();
        }
    }

    selectGroupResult() {
        this.groupSub = this.groupCameraID$.subscribe(result => {
            if (result && result.length > 0 && this.selectedGroup) {
                const pagCams = result[0].cameras_info_group;
                this.totalAmountGroup = pagCams.total;
                this.allPagesGroup = pagCams.pages;
                this.actualGroup = pagCams.actual;
                this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                this.getUniqueCameras(pagCams.cameras);
            }
            else {
                this.cameras = [];
            }
            this.loadingGroup = false;
        });
    }

    toAddCamera() {
        this.router.navigateByUrl('/cameras/cadastro');
    }

    ngAfterViewInit() {
        this.querySub = this.queryVideos.changes.subscribe(r => {
            if (this.elem.length > 0 && this.initMosaic) {
                this.hlsInstances = [];
                this.videos = [];
                this.videoRef = [];
                this.videoRef = this.queryVideos.toArray();
                this.elem.forEach((value, index) => {
                    const video = this.videoRef[index] ? this.videoRef[index].nativeElement as HTMLVideoElement : null;
                    //Override de css no elemento de vídeo
                    if (video) {
                        video.style.pointerEvents = 'none';
                        video.style.height = '100%';
                        video.style.width = '100%';
                        this.videos.push(video);
                        this.hlsInstances.push(new Hls());
                    }
                });
                this.initPlayer();
                this.initMosaic = false;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.camSub) {
            this.camSub.unsubscribe();
        }
        if (this.invSub) {
            this.invSub.unsubscribe();
        }
        if (this.querySub) {
            this.querySub.unsubscribe();
        }
        if (this.mosaicSub) {
            this.mosaicSub.unsubscribe();
        }
        if (this.userMosaicSub) {
            this.userMosaicSub.unsubscribe();
        }
        if (this.successSub) {
            this.successSub.unsubscribe();
        }
        if (this.cameraSub) {
            this.cameraSub.unsubscribe();
        }
        if (this.menu) {
            this.menu.unsubscribe();
        }
        if (this.modalClose) {
            this.modalClose.unsubscribe();
        }
        if (this.updateCam) {
            this.updateCam.unsubscribe();
        }
        if (this.deleteCam) {
            this.deleteCam.unsubscribe();
        }
        if (this.returnSwal) {
            this.returnSwal.unsubscribe();
        }
        if (this.getTypesAnalytic) {
            this.getTypesAnalytic.unsubscribe();
        }
        if (this.clientSub) {
            this.clientSub.unsubscribe();
        }
        if (this.groupSub) {
            this.groupSub.unsubscribe();
        }
        if (this.groupListSub) {
            this.groupListSub.unsubscribe();
        }
        if (this.delMosaic) {
            this.delMosaic.unsubscribe();
        }
        // this.queryVideos.destroy();
        this.currentMosaic = null;
        this.stopVideos();
        document.removeEventListener('fullscreenchange', this.changeFullscreen.bind(this));
        this.store.dispatch(CameraActions.cleanup());
        // this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(CameraActions.reset_return());
        localStorage.removeItem('idCameraChoice');
    // location.reload();
    }
    // Valida o carregamento da tela
    loadingEvent(event: boolean) {
        this.waiting = event;
    }

    loadCameras() {
        const query = this.searchInputControl.value;
        const sub = localStorage.getItem('sub');
        this.loadingCamera = true;
        this.initCameras();
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            child_sub: this.selectedClient,
            page: this.sum,
            limit: this.camLimit,
            group_id: this.selectedGroup,
            str_filter: query,
            short: false,
            active: true
        }));
        if (!this.isPartner && !this.isPartnerAssociate) {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: this.userSub,
                child_sub: this.selectedClient,
                type_request: this.selectedGroup > 0 ? 'data' : 'cameras',
                page: this.sumGroup,
                limit: this.camLimit,
                group_id: this.selectedGroup,
                str_filter: query,
                active: true
            }));
        }
        this.store.dispatch(CameraActions.get_invited_cameras({
            user_sub: sub,
            list_type: ListType.Live
        }));
        this.loadingGroup = true;
    }

    loadPresetMosaic(userMosaics: Mosaic[], mosaic: Mosaic[]) {
        if (this.firstGrid) {
            this.currentMosaic = {
                cameras: [],
                fixed: false,
                id: 0,
                id_mosaic_template: 2,
                name: null,
                height: 2,
                width: 2
            };
        }
        if (mosaic.length > 0 && this.currentMosaic && this.firstGrid) {
            this.createGrid();
            this.firstGrid = false;
        }
        if (this.currentMosaic && userMosaics[0].id != this.currentMosaic.id) {
            this.userMosaics = userMosaics;
            this.changeMosaic(userMosaics[0]);
        }
    }

    createMosaicFake(userMosaics: Mosaic[]) {
        if (this.mosaics.length != 0) {
            const oldUserMosaics = this.userMosaics;
            this.mosaicFake = true;
            this.currentMosaic = {
                cameras: [],
                fixed: false,
                id: 0,
                id_mosaic_template: 2,
                name: null,
                height: 2,
                width: 2
            };
            // this.currentMosaic = userMosaics.length > 0 ? userMosaics[0] : null;
            if (this.mosaics.length > 0 && this.currentMosaic) {
                this.createGrid();
            }
            // Limpa a lista de mosaicos do usuário, definindo que o mesmo não possui mosaicos pré-criados alem do default
            const indiceFake = userMosaics.find(element => element.name == null);
            if (indiceFake && this.userMosaics.length == 1) {
                this.userMosaics.splice(this.userMosaics.indexOf(indiceFake), 1);
            }

            if (this.userMosaics) {
                this.userMosaics = userMosaics;
                if ((oldUserMosaics.length + 1) == userMosaics.length) {
                    this.changeMosaic(userMosaics[userMosaics.length - 1]);
                }
            }
        } else {
            this.store.dispatch(CameraActions.get_mosaics());
        }

    }

    // Fecha as barras laterais de minhas câmeras e compartilhadas comigo
    collapseSidebar() {
        this.collapsed = !this.collapsed;
    }

    createGrid() {
        const mosaic: Mosaic = this.mosaics.find(m => m.id_mosaic_template === this.currentMosaic.id_mosaic_template);
        if (mosaic) {
            this.currentMosaic.height = mosaic.height;
            this.currentMosaic.width = mosaic.width;
            const oldSize = this.currentMosaic.cameras.length;
            this.elem = Array.apply(0, Array(mosaic.height * mosaic.width)).map(function () { return { 'loading': false }; });
            this.statusMosaic = new Array(mosaic.height * mosaic.width);
            const newSize = mosaic.width * mosaic.height;
            if (newSize > oldSize) {
                for (let index = oldSize; index < newSize; index++) {
                    this.currentMosaic.cameras.push({ id_camera: -1 });
                }
            }
            else {
                this.currentMosaic.cameras = this.currentMosaic.cameras.slice(0, newSize);
            }
            if (!this.firstCam) {
                this.waiting = false;
            }
        }

    }
    //Limpa o mosaico de visualização.
    //Caso receba um mosaico de parâmetro, limpa todas as câmeras que não são desse mosaico
    clearMosaic(mosaic?: Mosaic) {
        this.currentMosaic.cameras.forEach((cam, i) => {
            if (mosaic) {
                const isCameraInMosaic = this.isCameraInMosaic(cam, mosaic);
                if (!isCameraInMosaic || cam.id_camera == -1) {
                    this.removeCamera(i);
                }
            } else if (cam.id_camera !== -1) {
                this.removeCamera(i);
            }
        });
    }

    changeMosaic(mosaic: Mosaic) {
        mosaic = this.userMosaics.length == 1 ? this.uniqueMosaic : mosaic;
        const isEqual = this.compareMosaics(mosaic, this.currentMosaic);
        if (!isEqual) {
            this.waiting = true;
            //condicional para não haver interferências entre 2 trocas de mosaico,
            //pois ele limpa o visualizador e pode haver inconsistências
            //caso tenha alguma troca sendo efetivada, será repetido a tentativa em 0,5s
            if (!this.choosingMosaic) {
                this.choosingMosaic = true;
                setTimeout(() => {
                    //limpando o mosaico atual antes de escolher o novo tamanho
                    this.clearMosaic();
                    setTimeout(() => {
                        this.change(mosaic.id);
                        this.chooseMosaic(mosaic.id_mosaic_template);
                        this.currentMosaic.id = mosaic.id;
                        this.currentMosaic.name = mosaic.name;
                        this.waiting = false;
                        this.chooseMosaicCameras(mosaic);
                    }, 350);
                }, 300);
                //Caso o mosaico esteja sendo trocado, entrará em delay para realizar a nova troca
            }
        } else {
            if (isEqual) {
                this.clearMosaic();
                this.activeList = [];
                this.currentMosaic.name = null;
            }
            else {
                this.currentMosaic.id = null;
                this.currentMosaic.name = null;
                this.currentMosaic.cameras = [];
                if (this.userMosaics.length > 1) {
                    this.mapUserMosaic(this.userMosaics, this.mosaics);
                } else {
                    this.clearMosaic();
                }
            }
        }
    }

    compareMosaics(mosaic_1: Mosaic, mosaic_2: Mosaic) {
        if (mosaic_1.id_mosaic_template != mosaic_2.id_mosaic_template) {
            return false;
        }

        if (mosaic_1.id !== mosaic_2.id || mosaic_1.name !== mosaic_2.name) {
            return false;
        }

        //check if all mosaic_1 cameras is in mosaic_2
        for (const camId of mosaic_1.cameras) {
            if (camId.id_camera != -1) {
                if (!this.isCameraInMosaic(camId, mosaic_2)) {
                    return false;
                }
            }
        }
        //check if all mosaic_2 cameras is in mosaic_1
        for (const camId of mosaic_2.cameras) {
            if (camId.id_camera != -1) {
                if (!this.isCameraInMosaic(camId, mosaic_1)) {
                    return false;
                }
            }
        }

        return true;

    }

    isCameraInMosaic(cams_id: CameraId, mosaic: Mosaic) {
        let isCameraInMosaic = false;
        for (const currentCamId of mosaic.cameras) {
            if (currentCamId.id_camera == cams_id.id_camera) {
                isCameraInMosaic = true;
            }
        }
        return isCameraInMosaic;
    }

    chooseMosaicCameras(mosaic: Mosaic) {
    //função para escolher todas as cameras de um dado mosaico
        const allcameras = this.cameras.concat(this.camerasShared);
        for (const camId of mosaic.cameras) {
            if (camId.id_camera != -1) {
                const camera = allcameras.find(cam => cam.id === camId.id_camera);
                this.chooseCam(camera ? camera : camId as Camera);
            }
        }
        this.choosingMosaic = false;
    }

    openCreateMosaic() {
        //this.currentMosaic = null;
        this.pauseVideos(this.currentMosaic);
        this.store.dispatch(CameraActions.open_modal_mosaic());
    }

    openEditMosaic(mosaic: Mosaic) {
        //this.currentMosaic = null;
        this.pauseVideos(this.currentMosaic);
        this.store.dispatch(CameraActions.open_edit_modal_mosaic({ payload: mosaic }));
    }

    deleteMosaic(mosaic: Mosaic) {
        this.store.dispatch(CameraActions.delete_mosaic({
            idMosaic: mosaic.id
        }));
        this.loading = true;
    }

    deleteMosaicResponse() {
        this.delMosaic = this.delStatus$.subscribe(feedback => {
            if (feedback) {
                this.loading = false;
                if (feedback.success) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-mosaico', 'cameras.deleteMosaic');
                    if (this.userMosaics.length == 1) {
                        this.currentMosaic.name = null;
                        this.currentMosaic.id = null;
                        this.toggleDropdown();
                        this.userMosaics.pop();
                    }
                    this.loadCameras();
                    this.store.dispatch(CameraActions.get_mosaics());
                    this.store.dispatch(CameraActions.get_user_mosaics({ user_sub: this.userSub }));
                }
                else {
                    Swal.fire({
                        title: 'Erro',
                        icon: 'error',
                        timer: 5000,
                        text: feedback.message
                    });
                }
                // this.store.dispatch(this.actions.reset_return());
                // if (this.userMosaics.length == 1) {
                //   this.loading = false;
                //   location.reload();
                // }
            }
        });
    }

    getContainerWidth(cols: number): string {
        return `calc(100% / ${cols});`;
    }

    getHeight(cols: number): any {
        const fullScreenElement = document['fullscreenElement'];
        let style = '';
        if (fullScreenElement && fullScreenElement.id === 'videoGrid') {
            style = `calc(100vh/${cols})`;
        }
        else {
            if (this.mqSmall.matches) {
                style = `calc( (60vh - 140px)/${cols})`;
            }
            else {
                style = `calc( (100vh - 140px)/${cols})`;
            }
        }
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    chooseMosaic(type: number) {
        this.initMosaic = this.initMosaic != true ? true : this.initMosaic;
        if (this.mosaics) {
            if (this.mosaics.length != 0) {
                const newMosaic = this.mosaics.find(m => m.id_mosaic_template === type);
                this.currentMosaic.height = newMosaic.height;
                this.currentMosaic.width = newMosaic.width;
                this.currentMosaic.id_mosaic_template = newMosaic.id_mosaic_template;
                this.createGrid();
            } else {
                this.store.dispatch(CameraActions.get_mosaics());
            }
        } else {
            this.store.dispatch(CameraActions.get_mosaics());
        }
    }

    waitGridToFocus() {
        if (document.getElementById('buttonGrid') == null) {

        } else {
            setTimeout(() => {
                document.getElementById('buttonGrid').focus();
            }, 100);
        }
    }

    chooseCam(camera: Camera, clicked?: boolean, replace?: number) {
        const index = replace === undefined ? this.findNextIndex() : replace;
        if (index < 0) {
            if (!this.choosingMosaic) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'dimension-mosaic', 'cameras.dimension_mosaic');
            }
            return;
        }
        if (clicked) {
            this.change(null);
            this.currentMosaic.name = null;
        }
        this.currentMosaic.cameras[index] = camera as CameraId;
        this.elem[index].loading = true;
        if (this.currentMosaic.cameras[index].id_camera !== -1) {
            this.initPlayer(camera, index);
        }
    }

    findNextIndex(): number {
        return this.currentMosaic.cameras.findIndex(camera => camera.id_camera === -1);
    }

    removeCamera(index, clicked?: boolean) {
        if (document.getElementById('videos' + index) != null) {
            document.getElementById('videos' + index).removeAttribute('class');
            document.getElementById('play' + index).classList.remove('video_opacity');

            this.stopVideos(index);
            this.currentMosaic.cameras[index] = { id_camera: -1 };
            this.selectedVideo = -1;
            document.getElementById('videos' + index);
            if (clicked) {
                this.change(null);
                this.currentMosaic.name = null;
            }

            //removendo status de camera ativa
            const indicador = document.getElementById('indicador' + index);
            if (indicador) {
                indicador.removeAttribute('class');
            }

        } else {
            setTimeout(() => {
                this.removeCamera(index);
            }, 100);
        }

    }

    validatePermission(index) {
        const camId = this.currentMosaic.cameras[index].id ? this.currentMosaic.cameras[index].id : this.currentMosaic.cameras[index].id_camera;
        const myCams = this.cameras ? this.cameras.find(camera => camera.id === camId) : this.currentMosaic.cameras[index] as Camera;
        const sharedCams = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
        if (sharedCams) {
            this.informationMenu = [];
            if (sharedCams['view_alert_history'] == true) {
                this.informationMenu.push('Ver todos os alertas');
            }
            if (sharedCams['view_recording'] == true) {
                this.informationMenu.push('Ver gravações');
            }
            if (sharedCams['active'] == true) {
                this.informationMenu.push('Excluir câmera');
            }
        } else if (myCams) {
            this.informationMenu = myCams.informationMenu;
            if (myCams.integrated_camera != null) {
                if (this.informationMenu.includes('Adicionar analítico')) {
                    this.informationMenu.shift();
                }
            }
        } else {
            this.informationMenu = ['Adicionar analítico', 'Recuperar videos', 'Ver todos os alertas'];
        }
    }

    // Mêtodo usado para validar a exclusão de uma câmera de convidado
    deleteGuestCam(camerasShared: Camera) {
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-guest-camera', 'cameras.delete_guest_camera');
    }

    async loadPlayer(camera: Camera, index: number) {
    // Create a Player instance.
        const mosaicSize = localStorage.getItem('mosaicSize');
        if (Hls.isSupported()) {
            const hls = this.hlsInstances[index];
            const context = this;
            // const url_chunklist = await this.service.getChunklist(camera.url_view).toPromise();
            hls.loadSource(camera.url_view);
            hls.attachMedia(this.videos[index]);

            // Evento acionado quando o arquivo m3u8 é baixado com sucesso e o player está pronto para tocar.
            hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                console.log(`Manifest loaded, found ${data.levels.length} quality level(s).`);
                camera.status = true;
            });

            // Evento acionado sempre um novo fragmento de vídeo é carregado pelo player
            hls.on(Hls.Events.FRAG_LOADED, function (event, data) {
                if (camera.status === false) {
                    context.removeCamera(index);
                    // setTimeout(() => { context.chooseCam(camera); }, 100);
                    // context.chooseCam(camera);
                    // hls.loadSource(url_chunklist);
                    // hls.attachMedia(context.videos[index]);
                    // context.videos[index].play();
                    hls.startLoad(-1);
                }
                context.cameraActive(index, camera);
                camera.status = true;
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                if (context.currentMosaic.cameras != null) {
                    if (context.currentMosaic.cameras[index].id_camera > 0) {
                        if (data.fatal) {
                            this.validationResponse.validationResponseHandler(400, this.pageName, 'failure-stream', 'cameras.failure_stream');
                            context.cameraInative(index, camera, mosaicSize);
                            context.hlsInstances[index].stopLoad();
                            context.hlsInstances[index].detachMedia();
                        }
                        else {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    camera.status = false;
                                    context.cameraInative(index, camera, mosaicSize);
                                    hls.startLoad();
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    context.cameraInative(index, camera, mosaicSize);
                                    if (data.details === 'bufferStalledError') {
                                        hls.startLoad();
                                    } else {
                                        hls.recoverMediaError();
                                    }
                                    break;
                                default:
                                    hls.destroy();
                                    break;
                            }
                        }
                    }
                }
            });
        }
        else if (this.videos[index].canPlayType('application/vnd.apple.mpegurl')) {
            this.cameraActive(index, camera);
            this.videos[index].src = camera.url_view;
            this.videos[index].addEventListener('loadedmetadata', this.playVideo.bind(this, index));
        }
    }

    // Valida as classes visuais para quando a câmera for inativa
    cameraInative(index, cam: Camera, size) {
        setTimeout(() => {
            const videos = document.getElementById('videos' + index);
            const videoIndex = document.getElementById('play' + index);
            const indicator = document.getElementById('indicator' + index);
            const camerasLateras = document.getElementById(cam.id.toString());
            this.statusMosaic[index] = false;
            let endClasse = '';
            videoIndex.classList.add('video_opacity');
            if (indicator) {
                switch (size) {
                    case '1':
                        endClasse = 'span_content_1';
                        break;
                    case '2':
                        endClasse = 'span_content_2';
                        break;
                    case '3':
                        endClasse = 'span_content_3';
                        break;
                }
                videos.classList.add(endClasse);
                if (indicator.classList != null || indicator.classList === undefined || indicator.classList.length > 0) {
                    indicator.removeAttribute('class');
                }
                if (camerasLateras.classList != null || camerasLateras.classList === undefined || camerasLateras.classList.length > 0) {
                    camerasLateras.removeAttribute('class');
                }
                camerasLateras.classList.add('image_thumbnail');
                indicator.classList.add('nomeCam_statusCam', 'camera_status_false');
            }
        }, this.timeStatus * 1000);
    }

    // Valida as classes visuais para quando a câmera for ativa
    cameraActive(index, camera: Camera) {
        const videoIndex = document.getElementById('play' + index);
        const videos = document.getElementById('videos' + index);
        const indicator = document.getElementById('indicator' + index);
        const camerasLateras = document.getElementById(camera.id.toString());
        this.statusMosaic[index] = true;
        this.elem[index].loading = false;
        if (videoIndex != null && videos != null && indicator != null && camerasLateras != null) {
            if (camerasLateras.classList != null && camerasLateras.classList != undefined && camerasLateras.classList.length > 0) {
                camerasLateras.removeAttribute('class');
            }
            if (videos.classList != null && videos.classList != undefined && videos.classList.length > 0) {
                videos.removeAttribute('class');
            }
            if (indicator.classList != null && indicator.classList != undefined && indicator.classList.length > 0) {
                indicator.removeAttribute('class');
            }
            videoIndex.classList.remove('video_opacity');
            if (camera.id != -1) {
                indicator.classList.add('nomeCam_statusCam', 'camera_status_true');
            }

        }
    }

    // Valida as opções no menu de cada camera do mosaico
    managingCameras(menuInfor, index: number) {
        const camId = this.currentMosaic.cameras[index].id ? this.currentMosaic.cameras[index].id : this.currentMosaic.cameras[index].id_camera;
        this.idCam = camId;
        let myCams = this.cameras ? this.cameras.find(camera => camera.id === camId) : this.currentMosaic.cameras[index] as Camera;
        myCams = myCams ? myCams : this.currentMosaic.cameras[index] as Camera;
        const sharedCams = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
        this.saveCameras = true;

        switch (menuInfor) {
            // Abre o modal de adiciona um novo analítico
            case 'Adicionar analítico':
                this.openModalAnalytics(myCams);
                break;
                // Abre o modal de convidar novos usuários para a câmera
            case 'Convidar':
                if (myCams) {
                    this.openModalInviteUser(myCams);
                }
                break;
                // Abre a tela de Gravações das câmeras
            case 'Recuperar videos':
                if (camId) {
                    localStorage.setItem('idCameraChoice', String(camId));
                    this.router.navigateByUrl('/cameras/retrieve');
                }
                break;
                // Abre a tela de Gravações das câmeras de um convidado
            case 'Ver gravações':
                if (camId) {
                    localStorage.setItem('idCameraChoice', String(camId));
                    this.router.navigateByUrl('/cameras/retrieve');
                }
                break;
                // Abre a tela de relatório de alertas
            case 'Ver todos os alertas':
                if (camId) {
                    localStorage.setItem('idCameraChoice', String(camId));
                    this.router.navigateByUrl('/cameras/alert');
                }
                break;
                // Abre o modal de configurações da câmera apenas se a câmera for do usuario
            case 'Configurações':
                if (myCams) {
                    this.openModalCameraUpdate(myCams);
                }
                break;
                // Deleta a câmera de um convidado
            case 'Excluir câmera':
                if (sharedCams) {
                    this.currentMosaicIndex = index;
                    this.deleteGuestCam(sharedCams);
                }
                break;
        }
    }

    // Retorna a classe do status da camera para o retorno do banco
    getClassCamera(index: number, status, size): string {
        localStorage.setItem('mosaicSize', String(size));
        if (this.currentMosaic.cameras.length != 0 && (this.currentMosaic.cameras[index] != undefined || this.currentMosaic.cameras[index].id_camera != -1)) {
            const camId = this.currentMosaic.cameras[index].id_camera;
            let endClasse = '';
            let camIndice = this.cameras ? this.cameras.find(camera => camera.id === camId) : null;
            if (camIndice && this.currentMosaic.cameras[index].hashname) {
                camIndice = camIndice ? camIndice : this.currentMosaic.cameras[index] as Camera;
                if (camIndice.status === false || status === false) {
                    switch (size) {
                        case '1':
                            endClasse = 'span_content_1';
                            break;
                        case '2':
                            endClasse = 'span_content_2';
                            break;
                        case '3':
                            endClasse = 'span_content_3';
                            break;
                    }
                    return endClasse;
                } else {
                    return '';
                }
            } else {
                camIndice = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
                if (camIndice) {
                    if (camIndice.status === false || status === false) {
                        switch (size) {
                            case '1':
                                endClasse = 'span_content_1';
                                break;

                            case '2':
                                endClasse = 'span_content_2';
                                break;

                            case '3':
                                endClasse = 'span_content_3';
                                break;
                        }
                        return endClasse;
                    }
                    else {
                        return '';
                    }
                }
            }
        }
        else {
            return '';
        }
    }

    initPlayer(cam?: Camera, index?: number): void {
        this.statusCam = true;
        if (cam) {
            let camera = this.cameras.find(c => c.id === cam.id);
            if (!camera) {
                camera = this.camerasShared.find(c => c.id === cam.id);
            }
            this.loadPlayer(camera ? camera : cam, index);
        }
        else {
            if (this.currentMosaic) {
                this.currentMosaic.cameras.forEach((cameraId, i) => {
                    if (cameraId.id_camera != -1) {
                        let camera = this.cameras.find(c => c.id === cameraId.id_camera);
                        if (!camera) {
                            camera = this.camerasShared.find(c => c.id === cameraId.id_camera);
                        }
                        // Create a Player instance.
                        //Solução alternativa para override de css do Mat-Video
                        camera = camera ? camera : cameraId as Camera;
                        this.loadPlayer(camera, i);
                    }
                });
            }
        }
    }

    playVideo(index: number) {
        this.videos[index].play();
    }

    openFullscreen(value) {
        const idMosaico = document.getElementById(value);
        if (!this.isFullscreen && idMosaico.requestFullscreen) {
            idMosaico.requestFullscreen();
        }
    }

    changeFullscreen() {
        this.isFullscreen = this.isFullscreen ? false : true;
    }

    isMosaicEmpty(): boolean {
        return this.currentMosaic.cameras.map(function (value) {
            return value.id_camera < 0;
        }).reduce(function (curr, prev) {
            return curr && prev;
        });
    }

    saveMosaic() {
        this.loading = true;
        this.store.dispatch(CameraActions.save_mosaic({ mosaic: this.currentMosaic }));
        this.successSub = this.success$.subscribe(success => {
            if (success) {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'save-mosaic', 'cameras.save_mosaic');
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'saveMosaic') {
            if (returnResultSwal.isConfirmed) {
                this.loading = false;
            }
            else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'deleteGuestCam') {
            if (returnResultSwal.isConfirmed) {
                this.service.closeModals(true);
                this.store.dispatch(CameraActions.delete_guest_camera({ user_sub: this.userSub, camera_id: this.idCam }));
            } else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'deleteCam') {
            if (returnResultSwal.isConfirmed) {
                this.loading = true;
                this.service.closeModals(true);
                this.store.dispatch(CameraActions.delete_camera({ camera_id: this.idCam, child_sub: localStorage.getItem('clientView') }));
            } else if (returnResultSwal.isDismissed) {
            }
        } else if (returnResultSwal.methodCalled === 'deleteCam') {
            if (returnResultSwal.isConfirmed) {
                this.loading = true;
                this.service.closeModals(true);
                this.store.dispatch(CameraActions.delete_camera({ camera_id: this.idCam, child_sub: localStorage.getItem('clientView') }));
            } else if (returnResultSwal.isDismissed) {
            }
        }
    }

    destroyVideo(index) {
        if (this.hlsInstances[index]) {
            this.hlsInstances[index].stopLoad();
            this.hlsInstances[index].detachMedia();
            this.hlsInstances[index].destroy();
            this.hlsInstances[index] = new Hls();
        }
        if (this.videos[index]) {
            this.videos[index].pause();
            this.videos[index].currentTime = 0;
            this.videos[index].src = '';
            // this.videos[index].remove();
            // this.videos[index].load();
        }
    }

    pauseVideos(mosaic: Mosaic) {
        if (mosaic) {
            mosaic.cameras.forEach((cameraId, i) => {
                if (this.videos && this.videos[i]) {
                    this.videos[i].pause();
                }
                else if (this.hlsInstances && this.hlsInstances[i]) {
                    this.hlsInstances[i].stopLoad();
                    // this.hlsInstances[i].detachMedia();
                    this.hlsInstances[i] = new Hls();
                }
            });
        }
    }

    stopVideos(index?: number, mosaic?: Mosaic) {
        if (index !== null && index !== undefined) {
            this.destroyVideo(index);
        } else {
            if (mosaic) {
                mosaic.cameras.forEach((cameraId, i) => {
                    this.destroyVideo(i);
                    // if (this.videos[i]) {
                    //   this.videos[i].remove();
                    // }
                });
            } else {
                this.hlsInstances.forEach((hls, i) => {
                    hls.stopLoad();
                    hls.detachMedia();
                    hls.destroy();
                    this.videos[i].pause();
                    this.videos[i].currentTime = 0;
                    this.videos[i].src = '';
                    this.videos[i].remove();
                });

                this.videoRef = undefined;
                this.hlsInstances = [];
                this.videos = undefined;
            }
        }
    }

    getCameraName(index: number): string {
        if (this.currentMosaic.cameras.length != 0 && this.currentMosaic.cameras[index] != undefined) {
            const camId = this.currentMosaic.cameras[index].id_camera;
            let cam = this.cameras ? this.cameras.find(camera => camera.id === camId) : null;
            if (cam) {
                return cam ? cam.alias : this.currentMosaic.cameras[index].alias;
            } else {
                cam = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
                return cam ? cam.alias : this.currentMosaic.cameras[index].alias;
            }
        } else {
            return null;
        }
    }

    getUserImage(index: number): string {
        if (this.currentMosaic.cameras.length != 0 && this.currentMosaic.cameras[index] != undefined) {
            const camId = this.currentMosaic.cameras[index].id_camera;
            const cam = this.cameras ? this.cameras.find(camera => camera.id === camId) : null;
            const camShared = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
            if (cam && !camShared) {
                return cam ? cam.user_picture : null;
            } else if (!cam && camShared) {
                return camShared ? camShared.user_picture : '../../../assets/img/placeholder.jpg';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getUserInviteName(index: number): string {
        if (this.currentMosaic.cameras.length != 0 && this.currentMosaic.cameras[index] != undefined) {
            const camId = this.currentMosaic.cameras[index].id_camera;
            let cam = this.cameras ? this.cameras.find(camera => camera.id === camId) : null;
            if (cam) {
                return cam ? cam.name : '';
            }
            else {
                cam = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
                return cam ? cam.name : '';
            }
        }
        else {
            return null;
        }
    }
    // Busca o status da conexão da câmera para retornar ao junto a câmera
    getCameraStatus(index: number): boolean {
        if (this.currentMosaic.cameras.length != 0 && this.currentMosaic.cameras[index] != undefined && this.statusMosaic[index] != undefined) {
            if (this.currentMosaic.cameras[index].id_camera != -1) {
                const statusDefault = this.currentMosaic.cameras[index].status && !this.statusMosaic[index];
                const camId = this.currentMosaic.cameras[index].id_camera;
                let cam = this.cameras ? this.cameras.find(camera => camera.id === camId) : null;
                if (cam) {
                    return cam ? (cam.status && this.statusMosaic[index]) : statusDefault;
                } else if (!cam && this.currentMosaic.cameras[index].id_camera != -1) {
                    return this.statusMosaic[index];
                } else {
                    cam = this.camerasShared ? this.camerasShared.find(camera => camera.id === camId) : null;
                    return cam ? (cam.status && this.statusMosaic[index]) : statusDefault;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    selectVideo(index: number) {
        this.selectedVideo = this.selectedVideo === index ? -1 : index;
    }

    isSelected(index: number, status): boolean {
        const backgroundStyle = document.getElementById('backgroundStyle' + index);
        if (backgroundStyle) {
            if (status === null) {
                backgroundStyle.classList.add('with-image');
            } else {
                backgroundStyle.classList.add('without-image');
            }
        }
        return index === this.selectedVideo;
    }

    inMosaic(camera: Camera): boolean {
        if (camera !== null && camera != undefined) {
            if (this.currentMosaic && this.currentMosaic.cameras == null) {
                this.currentMosaic.cameras = [];
            }
            if (this.currentMosaic) {
                return this.currentMosaic.cameras.find(cameraId => cameraId.id_camera == camera.id || cameraId.id == camera.id) != null;
            }
            else {
                return false;
            }
        } else {
            return false;
        }

    }

    formatOwnerName(element) {
        const owner = element.split(' ');
        if (owner.length > 1) {
            this.ownerName = owner[0] + ' ' + owner.slice(-1);
        } else {
            this.ownerName = owner[0];
        }
        return this.ownerName;
    }

    displayCamName(name: string): string {
        if (name != null) {
            let displayName = name;
            // Valida o tamanho da string, de modo que pelo tamanho, insere o elipsis no fim, não quebrando visualmente o tamanho da div
            if (displayName.length > 13) {
                const formatString = displayName.substr(0, 13);
                const stringFormated = formatString + '...';
                displayName = stringFormated;
            }
            return displayName;
        }
        else {
            return '';
        }
    }

    // Abre o modal de edição de informações da câmera
    openModalCameraUpdate(cam: Camera) {
        this.pauseVideos(this.currentMosaic);
        this.camerasOnMosaic = this.currentMosaic.cameras;
        this.store.dispatch(CameraActions.open_modal_update_camera({ payload: cam }));
    }

    // Mêtodo usado para salvar alterações no mosaico
    whenModalClose(close) {
        if (this.saveCameras && !close) {
            this.loadCameras();
            this.camerasOnMosaic = this.currentMosaic.cameras;
            this.currentMosaic.cameras = this.camerasOnMosaic;
            this.createGrid();
        } else {
            this.restartStreamings(true);
        }
    }

    restartStreamings(close: boolean) {
        this.initMosaic = close;
        if (this.currentMosaic) {
            this.currentMosaic.cameras.forEach((cameraId, i) => {
                if (this.videos && this.videos[i]) {
                    this.videos[i].play();
                }
            });
        }
        else if (this.queryVideos) {
            this.queryVideos.notifyOnChanges();
        }
    }

    updateCameraEdit() {
        this.modalClose = this.modal_open_camera$.subscribe(modalStatus => {
            if (Number(modalStatus) == OperationStatus.Success) {
                this.store.dispatch(CameraActions.get_cameras({ user_sub: this.userSub, short: false, active: true }));
                this.waiting = true;
                this.store.dispatch(CameraActions.update_camera_infor_change_return(null));
            }
        });
    }

    confirmUpdateCamera() {
        this.updateCam = this.upCam$.subscribe(upStatus => {
            if (upStatus) {
                if (upStatus.status == OperationStatus.Success) {
                    this.store.dispatch(CameraActions.close_modal_update_camera_confirm());
                    this.validationResponse.validationResponseHandler(200, 'camera-list', 'success-update-camera', 'cameras.success_update');
                    // showAlert('Os dados da sua câmera foram alterados com sucesso.', 'success');
                    this.store.dispatch(CameraActions.get_cameras({ user_sub: this.userSub, short: false, active: true }));
                    this.waiting = true;
                }
                else if (upStatus.status == OperationStatus.Fail) {
                    this.validationResponse.validationResponseHandler(400, 'camera-list', 'failure-update-camera', 'cameras.failure_update');
                // showAlert('Não foi possível alterar os dados da sua câmera.', 'danger');
                }
                this.store.dispatch(CameraActions.update_camera_change_feedback({ payload: OperationStatus.Unloaded }));
            }
        });
    }

    updateInfoCam(cam: Camera) {
        const userSub = localStorage.getItem('sub');
        const camera: Camera = {
            id: cam.id,
            alias: cam.alias,
            id_plan: cam.id_plan,
            address: {
                rua: cam['numero'],
                numero: cam['rua'],
                complemento: cam['complemento'],
                cep: cam['cep'],
                bairro: cam['bairro'],
                estado: cam['estado'],
                cidade: cam['cidade'],
                latitude: cam['latitude'],
                longitude: cam['longitude']
            },
            link_rtsp: cam.link_rtsp,
        };
        this.store.dispatch(CameraActions.update_camera_infor({
            camera: camera,
            user_sub: userSub
        }));
        this.waiting = true;
    }

    confirmDeleteFeedback() {
        this.deleteCam = this.delCam$.subscribe(delStatus => {
            if (delStatus) {
                if (delStatus.status == OperationStatus.Success) {
                    this.validationResponse.validationResponseHandler(200, 'camera-list', 'success-delete-camera', delStatus.message);
                    // showAlert('Sua câmera foi deletada.', 'success');
                    this.removeCamera(this.currentMosaicIndex);
                    this.currentMosaicIndex = null;
                    this.loadCameras();
                    this.loading = false;
                    this.waiting = true;
                }
                else if (delStatus.status == OperationStatus.Fail) {
                    this.validationResponse.validationResponseHandler(400, 'camera-list', 'failure-delete-camera', delStatus.message);
                    this.waiting = false;
                // showAlert('Não foi possível excluir sua Câmera.', 'danger');
                }
                this.store.dispatch(CameraActions.delete_camera_change_feedback({ payload: OperationStatus.Unloaded }));
            }
        });
    }

    // Abre modal de convidados para a câmera
    openModalInviteUser(cam: Camera) {
        localStorage.setItem('idCameraChoice', String(cam.id));
        this.store.dispatch(CameraActions.put_plan_max_invite_user({ payload: cam.plan_details[0]?.max_invites }));
        this.store.dispatch(CameraActions.put_id_invite_user({ payload: cam.id }));
        localStorage.setItem('cameraIdInvite', String(cam.id));
        this.store.dispatch(CameraActions.open_modal_invite_user());
        localStorage.setItem('inventoryClient', JSON.stringify(this.inventories));
        this.pauseVideos(this.currentMosaic);
    }

    updateNumberGuests() {
        this.modalClose = this.modal_open$.subscribe(modalStatus => {
            if (Number(modalStatus) == ModalState.Closed) {
                this.store.dispatch(CameraActions.get_cameras({ user_sub: this.userSub, short: false, active: true }));
                this.waiting = true;
                this.store.dispatch(CameraActions.close_modal_invite_user_cancel());
            }
        });
    }

    // Abre modal de analíticos da câmera
    openModalAnalytics(cam: Camera) {
        this.pauseVideos(this.currentMosaic);
        localStorage.setItem('idCameraChoice', String(cam.id));
        this.camerasOnMosaic = this.currentMosaic.cameras;
        this.store.dispatch(CameraActions.put_analytic_camera({ payload: cam }));
        this.store.dispatch(AnalyticActions.update_modal_state({ payload: ModalState.List }));
    }

    toggleDropdown() {
        this.isDropdownActive = !this.isDropdownActive;
    }

    getDimentionsMosaic(mosaic: Mosaic) {
        const mosaicFind = this.mosaics.find(m => m.id_mosaic_template === mosaic.id_mosaic_template);
        return mosaicFind ? '../../../assets/img/Icons/grid' + mosaicFind.height + 'x' + mosaicFind.width + '.svg' : '../../../assets/img/Icons/grid2x2.svg';
    }

    searchCameras() {
        const query = this.searchInputControl.value;
        if (!this.loadingCamera && !this.loadingGroup) {
            clearTimeout(this.timeOutHandler);
            this.timeOutHandler = setTimeout(() => {
                this.initCameras();
                if (!this.isPartner && !this.isPartnerAssociate) {
                    this.store.dispatch(CameraActions.get_group_cameras({
                        user_sub: this.userSub,
                        child_sub: this.selectedClient,
                        group_id: this.selectedGroup,
                        type_request: this.selectedGroup > 0 ? 'data' : 'cameras',
                        page: this.sumGroup,
                        limit: this.camLimit,
                        str_filter: query,
                        active: true
                    }));
                }
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    group_id: this.selectedGroup,
                    page: this.sum,
                    limit: this.camLimit,
                    str_filter: query,
                    short: false,
                    active: true
                }));
                this.store.dispatch(CameraActions.get_invited_cameras({
                    user_sub: this.userSub,
                    str_filter: query,
                    list_type: ListType.Live
                }));
                this.loadingCamera = true;
                this.loadingGroup = true;
            }, 800);
            setInterval(() => {
                this.loadingCamera = false;
                this.loadingGroup = false;
            }, 5000);
        }
    }

    onScroll() {
        const query = this.searchInputControl.value;
        if (!this.loadingScroll) {
            if (this.sumGroup <= this.allPagesGroup && (!this.isPartner && !this.isPartnerAssociate)) {
                this.loadingScroll = true;
                this.sumGroup = this.actualGroup <= this.allPagesGroup ? this.sumGroup += 1 : this.sumGroup;
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    type_request: this.selectedGroup > 0 ? 'data' : 'cameras',
                    page: this.sumGroup,
                    limit: this.camLimit,
                    group_id: this.selectedGroup,
                    str_filter: query,
                    active: true
                }));
            }
            if (this.sum <= this.allPages) {
                this.loadingScroll = true;
                this.sum = this.actual <= this.allPages ? this.sum += 1 : this.sum;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    page: this.sum,
                    limit: this.camLimit,
                    group_id: this.selectedGroup,
                    str_filter: query,
                    short: false,
                    active: true
                }));
            }

            setInterval(() => {
                this.loadingScroll = false;
            }, 15000);
        }
    }

    initCameras() {
        this.cameras = [];
        this.sum = 1;
        this.sumGroup = 1;
        this.totalAmount = 0;
        this.totalAmountGroup = 0;
        this.actual = 1;
        this.actualGroup = 1;
    }

    change(id: number | string) {
        if (this.activeList.findIndex(element => element == id) >= 0) {
            this.activeList = [];
        } else if (id == null) {
            this.activeList = [];
        }
        else {
            this.activeList = [id];
        }
    }
}
