import { Store } from '@ngrx/store';
import { EventEmitter, Inject, Output } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { Camera, ModalState, Mosaic, PaginationCameras, ListType } from '../models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CameraActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mosaic-modal',
    templateUrl: './modal-mosaic.component.html',
    styleUrls: ['./camera-modal-mosaic.component.scss']
})
export class ModalMosaicComponent implements OnInit, OnDestroy {
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly camShared$ = this.store.select((state: AppState) => state.camera.cameraShared);
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupList$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly groupCameraID$ = this.store.select((state: AppState) => state.camera.groupCameraID);
    readonly mosaics$ = this.store.select((state: AppState) => state.camera.mosaics);
    readonly newMosaicSuccess$ = this.store.select((state: AppState) => state.camera.success);
    readonly editMosaicSuccess$ = this.store.select((state: AppState) => state.camera.editStatus);
    readonly clients$ = this.store.select((state: AppState) => state.camera.clientsGroup);

    mosaicAlias = new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(25), Validators.pattern(/^(?!\s{1})((?!\s{2,}).)*$/)]);
    camSub: Subscription;
    mosaicSub: Subscription;
    querySub: Subscription;
    newMosaicSub: Subscription;
    editMosaicSub: Subscription;
    valueChanges: Subscription;
    clientSub: Subscription;
    groupSub: Subscription;
    sharedCamSub: Subscription;
    destroy = new Subject<any>();

    clientListSimple: {
        id: number | string;
        text: string;
        client_sub: string;
    }[];
    mosaicButton: HTMLElement;
    allCameras: Camera[];
    grid = [];
    mosaicTemplates: Mosaic[];
    imgArray: any[];
    newMosaic: Mosaic;
    isEdit: boolean;
    loading = false;
    pageName = 'camera-modal-mosaic';
    searchText;
    debouncer: ReturnType<typeof setTimeout>;
    loadingSearch = false;
    loadingClients = false;
    isPartner: boolean;
    isAssociate: boolean;
    adminAssociate: boolean;
    isGuest = false;
    userSub: string;
    selectedClient: string;
    loadingCameras: boolean;
    loadingGroup: boolean;
    loadingScroll: boolean;

    sum = 1;
    sumGroup = 1;
    camLimit = 7;

    actual: number;
    actualGroup: number;
    allPages = 0;
    allPagesGroup = 0;
    totalAmount = 0;
    totalAmountGroup = 0;
    actualAmount: number;
    actualAmountGroup: number;

    constructor(public dialogRef: MatDialogRef<ModalMosaicComponent>, @Inject(MAT_DIALOG_DATA) public mosaic: Mosaic, private validationResponse: ValidationResponseHandlerModule,
        private readonly store: Store<AppState>, private service: SharedService, private router: Router) { }

    ngOnInit() {
        this.userSub = localStorage.getItem('sub');
        this.isEdit = this.mosaic.id != null;
        this.getProfile();
        if (this.isPartner) {
            this.getClients();
        }
        this.loadCameras();
        this.getCameras();
        this.getNewMosaicResult();
        this.getEditMosaicResult();
    }


    // ngAfterViewInit() {
    //   // const counter = document.getElementById('counter');
    //   // this.valueChanges = this.mosaicAlias.valueChanges.subscribe(() => {
    //   //   counter.textContent = `${this.mosaicAlias.value.length}/25`;
    //   // });
    //   this.querySub = this.queryGrid.changes.subscribe(_ => {
    //     this.imgArray = this.queryGrid.toArray();
    //     this.grid.forEach((value, index) => {
    //       const image = this.imgArray[index].nativeElement as HTMLImageElement;
    //       //Override de css no elemento de vídeo
    //       image.style.pointerEvents = 'none';
    //       image.style.height = '100%';
    //       image.style.width = '100%';
    //     });
    //   });
    // }

    ngOnDestroy() {
        if (this.camSub) {
            this.camSub.unsubscribe();
        }
        if (this.valueChanges) {
            this.valueChanges.unsubscribe();
        }
        if (this.newMosaicSub) {
            this.newMosaicSub.unsubscribe();
        }
        if (this.editMosaicSub) {
            this.editMosaicSub.unsubscribe();
        }
        if (this.querySub) {
            this.querySub.unsubscribe();
        }
        if (this.clientSub) {
            this.clientSub.unsubscribe();
        }
        if (this.groupSub) {
            this.groupSub.unsubscribe();
        }
        if (this.sharedCamSub) {
            this.sharedCamSub.unsubscribe();
        }
        if (this.mosaicSub) {
            this.mosaicSub.unsubscribe();
        }

        this.store.dispatch(CameraActions.cancel_modal_mosaic());
        this.store.dispatch(CameraActions.close_modal_mosaic());

        this.destroy.next(true);
        clearTimeout(this.debouncer);
        this.closeCancel();
    }

    loadCameras() {
        const sub = localStorage.getItem('sub');
        this.loadingCameras = true;
        this.initCameras();
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            child_sub: this.selectedClient,
            page: this.sum,
            limit: this.camLimit,
            str_filter: this.searchText,
            short: true,
            active: true
        }));
        if (!this.isPartner && !this.isAssociate) {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: this.userSub,
                child_sub: this.selectedClient,
                type_request: 'cameras',
                page: this.sumGroup,
                limit: this.camLimit,
                str_filter: this.searchText,
                active: true
            }));
        }
        this.store.dispatch(CameraActions.get_invited_cameras({
            user_sub: sub,
            list_type: ListType.Live
        }));
        this.loadingGroup = true;
    }

    getProfile() {
        const profile = localStorage.getItem('profile_name');
        this.isGuest = (profile == '419bea06-5d4e-4a56-b8b5-04b3ad566d59');
        this.isAssociate = (profile === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3');
        this.isPartner = (profile == 'cd343bfc-17e8-11ec-9621-0242ac130002');
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
    }

    getClients() {
        this.loadingClients = true;
        this.clientSub = this.clients$.subscribe(data => {
            if (data) {
                this.clientListSimple = data.map(e => {
                    return {
                        id: e.client_id,
                        text: e.client_name,
                        client_sub: e.client_sub
                    };
                });
                this.loadingClients = false;
            } else {
                this.store.dispatch(CameraActions.get_clients_group({
                    user_sub: this.userSub
                }));
            }
        });
    }

    selectClient(id) {
        this.loadingCameras = true;
        this.loadingGroup = true;
        this.initCameras();
        if (id) {
            const item = this.clientListSimple.find(element => element.id == id);
            this.selectedClient = item ? item.client_sub : undefined;
            if (!this.isPartner && !this.isAssociate) {
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    type_request: 'cameras',
                    page: this.sumGroup,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    active: true
                }));
            }
            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.userSub,
                child_sub: this.selectedClient,
                page: this.sum,
                limit: this.camLimit,
                str_filter: this.searchText,
                short: true,
                active: true
            }));
        } else {
            this.selectedClient = null;
            this.loadCameras();
            this.loadingCameras = false;
            this.loadingGroup = false;
        }
    }

    searchCameras() {
        if (!this.loadingCameras && !this.loadingGroup) {
            this.initCameras();
            clearTimeout(this.debouncer);
            this.debouncer = setTimeout(() => {
                if (!this.isPartner && !this.isAssociate) {
                    this.store.dispatch(CameraActions.get_group_cameras({
                        user_sub: this.userSub,
                        child_sub: this.selectedClient,
                        type_request: 'cameras',
                        page: this.sumGroup,
                        limit: this.camLimit,
                        str_filter: this.searchText,
                        active: true
                    }));
                }
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    page: this.sum,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    short: true,
                    active: true
                }));
                this.loadingCameras = true;
                this.loadingGroup = true;
            }, 200);
            setInterval(() => {
                this.loadingCameras = false;
                this.loadingGroup = false;
            }, 5000);
        }
    }

    onScroll() {
        if (!this.loadingScroll) {
            if (this.sumGroup <= this.allPagesGroup && (!this.isPartner && !this.isAssociate)) {
                this.loadingScroll = true;
                this.sumGroup = this.actualGroup <= this.allPagesGroup ? this.sumGroup += 1 : this.sumGroup;
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    type_request: 'cameras',
                    page: this.sumGroup,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    active: true
                }));
            }
            if (this.sum <= this.allPages) {
                this.loadingScroll = true;
                this.sum = this.actual <= this.allPages ? this.sum += 1 : this.sum;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    page: this.sum,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    short: true,
                    active: true
                }));
            }

            setInterval(() => {
                this.loadingScroll = false;
            }, 10000);
        }
    }

    closeCancel() {
        this.dialogRef.close();
    }

    chooseGrid() {
        switch (this.newMosaic.width) {
            case 1:
                this.mosaicButton = document.getElementById('oneCamMosaic');
                if (this.mosaicButton) {
                    this.mosaicButton.tabIndex = 0;
                }
                break;
            case 2:
                this.mosaicButton = document.getElementById('twoCamsMosaic');
                if (this.mosaicButton) {
                    this.mosaicButton.tabIndex = 0;
                }
                break;
            case 3:
                this.mosaicButton = document.getElementById('threeCamsMosaic');
                if (this.mosaicButton) {
                    this.mosaicButton.tabIndex = 0;
                }
                break;
        }
    }

    createGrid() {
        const mosaic: Mosaic = this.mosaicTemplates.find(m => m.id_mosaic_template === this.newMosaic.id_mosaic_template);
        if (mosaic) {
            this.newMosaic.height = mosaic.height;
            this.newMosaic.width = mosaic.width;
            const oldSize = this.newMosaic.cameras.length;
            this.grid = Array.apply(0, Array(mosaic.height * mosaic.width)).map(function () { return 1; });
            const newSize = mosaic.width * mosaic.height;
            if (newSize > oldSize) {
                for (let index = oldSize; index < newSize; index++) {
                    this.newMosaic.cameras.push({ id_camera: -1 });
                }
            }
            else {
                this.newMosaic.cameras = this.newMosaic.cameras.slice(0, newSize);
            }
        }
    }

    getContainerWidth(cols: number): string {
        return cols == 1 ? 'w-full' : (cols == 3 ? 'w-2/6' : `w-1/${cols}`);
    }

    getHeight(cols: number): string {
        return `calc(50vh / ${cols})`;
    }

    getCameras() {
        this.groupSub = this.groupCamera$.subscribe(groupCamera => {
            groupCamera = groupCamera as PaginationCameras;
            if (groupCamera && groupCamera.cameras && groupCamera.cameras.length > 0) {
                this.totalAmountGroup = groupCamera.total;
                this.allPagesGroup = groupCamera.pages;
                this.actualGroup = groupCamera.actual;
                this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                this.getUniqueCameras(groupCamera.cameras);
                this.loadingGroup = false;
            }
            else {
                this.loadingGroup = false;
            }
        });

        this.sharedCamSub = this.camShared$.subscribe(camShared => {
            if (camShared && camShared.length > 0) {
                this.getUniqueCameras(camShared);
            }
        });

        this.mosaicSub = this.mosaics$.subscribe(mosaicTemplates => {
            if (mosaicTemplates) {
                this.mosaicTemplates = mosaicTemplates;
                if (this.isEdit) {
                    this.newMosaic = JSON.parse(JSON.stringify(this.mosaic));
                    this.mosaicAlias.setValue(this.mosaic.name);
                }
                else {
                    if (this.newMosaic === undefined || this.newMosaic.cameras[0].id_camera == -1) {
                        this.newMosaic = {
                            id_mosaic_template: this.mosaicTemplates.find(m => m.height === 1).id_mosaic_template,
                            cameras: [{ id_camera: -1 }],
                        };
                    }
                }
                this.createGrid();
            }
        });

        this.camSub = this.cameras$.subscribe(cameras => {
            if (cameras && cameras.model === 'cameras') {
                this.loadingScroll = false;
                this.loadingCameras = false;
                //pegando apenas as câmeras ativas a partir da concatenação entre cameras do usuario e cameras compartilhadas
                const cams: any = cameras.cameras as PaginationCameras;
                this.totalAmount = cams.total;
                this.allPages = cams.pages;
                this.actual = cams.actual;
                this.camLimit = cams.limit;
                this.getUniqueCameras(cams.cameras);
            }
            else {
                this.loadingScroll = false;
                this.loadingCameras = false;
            }
        });
    }

    getCameraThumbnail(index: number): string {
        const camId = this.newMosaic.cameras[index].id_camera;
        const thumb = this.newMosaic.cameras[index].thumbnail;
        let camThumb: string;
        if (camId > 0) {
            const cam = this.allCameras.find(c => c.id === camId);
            camThumb = cam ? cam.thumbnail : (thumb ? thumb : '../../../assets/img/no_image.svg');
        } else {
            camThumb = '../../../assets/img/no_image.svg';
        }
        return camThumb;
    }

    displayCamName(name: string): string {
        if (name != null) {
            let displayName = name;
            // Valida o tamanho da string, de modo que pelo tamanho, insere o elipsis no fim, não quebrando visualmente o tamanho da div
            if (displayName.length > 13) {
                const formatString = displayName.substr(0, 13);
                const stringFormated = formatString + '...';
                displayName = stringFormated;
            }
            return displayName;
        }
        else {
            return '';
        }
    }

    formatOwnerName(element) {
        const owner = element.split(' ');
        if (owner.length > 1) {
            return owner[0] + ' ' + owner.slice(-1);
        } else {
            return owner[0];
        }
        return owner;
    }

    removeFromMosaic(index: number) {
        this.newMosaic.cameras[index].id_camera = -1;
    }

    chooseCam(camera: Camera) {
        const index = this.findNextIndex();
        if (index < 0) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'close-cam-current', 'cameras.close_current');
            return;
        }
        this.newMosaic.cameras[index].id_camera = camera.id;
    }

    inMosaic(camera: Camera): boolean {
        if (this.newMosaic) {
            return this.newMosaic.cameras.find(cameraId => cameraId.id_camera === camera.id) != null;
        }
        else {
            return false;
        }
    }

    findNextIndex(): number {
        return this.newMosaic.cameras.findIndex(camera => camera.id_camera === -1);
    }

    chooseMosaic(type: number) {
        const newMosaic = this.mosaicTemplates.find(m => m.id_mosaic_template === type);
        this.newMosaic.height = newMosaic.height;
        this.newMosaic.width = newMosaic.width;
        this.newMosaic.id_mosaic_template = newMosaic.id_mosaic_template;
        this.createGrid();
    }

    isEmptyMosaic() {
        return !this.newMosaic.cameras.find(m => m.id_camera != -1);
    }

    createMosaic() {
        if (this.mosaicAlias.invalid || this.isEmptyMosaic()) {
            if (this.isEmptyMosaic()) {
                this.validationResponse.validationResponseHandler(400, 'camera-list', 'not-cameras', 'cameras.not_cams');
            }
            else if (this.mosaicAlias.errors.required) {
                this.validationResponse.validationResponseHandler(400, 'camera-list', 'not-name-camera', 'cameras.not_name');
            }
            else if (this.mosaicAlias.errors.maxlength) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'camera-name-max', 'cameras.camera_name_max', this.mosaicAlias.errors.maxlength.requiredLength);
            }
            else if (this.mosaicAlias.errors.pattern) {
                this.validationResponse.validationResponseHandler(400, this.pageName, 'input-empty-name', 'cameras.input_empty_name');
            }
            return;
        }
        else {
            this.loading = true;
            this.newMosaic.name = this.mosaicAlias.value;
            this.newMosaic.fixed = false;
            if (this.isEdit) {
                this.store.dispatch(CameraActions.edit_mosaic({ mosaic: this.newMosaic }));
            }
            else {
                this.store.dispatch(CameraActions.save_mosaic({ mosaic: this.newMosaic }));
            }
        }
    }

    goToMosaic() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/cameras/view']);
    }

    getNewMosaicResult() {
        this.newMosaicSub = this.newMosaicSuccess$.subscribe(result => {
            if (result && this.loading) {
                this.loading = false;
                this.chooseGrid();
                this.validationResponse.validationResponseHandler(200, 'mosaic-create', 'mosaic-create-success', 'cameras.mosaic_create_success');
                this.store.dispatch(CameraActions.get_user_mosaics({ user_sub: this.userSub }));
                this.store.dispatch(CameraActions.get_mosaics());
                this.closeCancel();
            } else {
                this.loading = false;
            }
        });
    }

    getEditMosaicResult() {
        this.editMosaicSub = this.editMosaicSuccess$.subscribe(feedback => {
            if (feedback && this.loading) {
                this.loading = false;
                this.validationResponse.validationResponseHandler(200, 'mosaic-create', 'mosaic-create-success', 'cameras.editMosaic');
                this.store.dispatch(CameraActions.get_user_mosaics({ user_sub: this.userSub }));
                this.store.dispatch(CameraActions.get_mosaics());
                this.closeCancel();
            } else {
                this.loading = false;
            }
        });
    }

    brokenImageHandler(element) {
        element.error = false;
        element.target.src = '../../../assets/img/broken_link.svg';
    }

    getUniqueCameras(cameras: Camera[]): Camera[] {
        if (cameras) {
            const idOrdainedList = cameras.sort((a, b) => {
                return a.id - b.id;
            });

            idOrdainedList.forEach((current, index, list) => {
                if (current && current.active) {
                    if (this.allCameras.length > 0) {
                        const camExist = this.allCameras.find(x => x.id === current.id || x.hashname === current.hashname);
                        if (!camExist) {
                            this.allCameras.push(current);
                        }
                    } else {
                        this.allCameras.push(current);
                    }
                }
            });

            return this.allCameras;
        }
        return [];
    }

    initCameras() {
        this.allCameras = [];
        this.sum = 1;
        this.sumGroup = 1;
        this.totalAmount = 0;
        this.totalAmountGroup = 0;
        this.actual = 1;
        this.actualGroup = 1;
    }
}

@Component({
    selector: 'app-camera-modal-mosaic',
    templateUrl: './camera-modal-mosaic.component.html',
    styleUrls: ['./camera-modal-mosaic.component.scss']
})
export class CameraModalMosaicComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.camera.modal_create_mosaic);
    readonly mosaic$ = this.store.select((state: AppState) => state.camera.editMosaic);

    @Output() exitModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentDialog: MatDialogRef<any, any> = null;
    destroy = new Subject<any>();
    modalSub: Subscription;
    mosaicSub: Subscription;
    modalStateSub: Subscription;

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        this.modalStateSub = this.modal_open$.subscribe(modalState => {
            this.mosaicSub = this.mosaic$.subscribe(mosaic => {
                modalState = modalState as ModalState;
                if (modalState == ModalState.Open) {
                    if (!this.currentDialog && mosaic) {
                        this.currentDialog = this.dialog.open(ModalMosaicComponent, {
                            panelClass: 'mosaic-modal-container',
                            data: mosaic
                        });
                        if (this.modalSub) {
                            this.modalSub.unsubscribe();
                        }
                        this.modalSub = this.currentDialog.afterClosed().subscribe(() => {
                            this.exitModal.emit(true);
                            this.dialog.closeAll();
                            this.currentDialog = null;
                            this.store.dispatch(CameraActions.close_modal_mosaic());
                        });
                    }
                }
            });
        });
    }

    ngOnDestroy() {
        if (this.modalStateSub) {
            this.modalStateSub.unsubscribe();
        }
        if (this.modalSub) {
            this.modalSub.unsubscribe();
        }
    }
}
