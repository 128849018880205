import { Feedback, OperationStatus } from 'app/Shared/models';
import { createReducer, on } from '@ngrx/store';
import { GLPIState, SessionGLPI, GLPIFile } from './glpi.models';
import { GLPIActions, GLPIAction } from './glpi.actions';

const INITIAL_STATE: GLPIState = {
    createTicket: null,
    getSession: null,
    uploadDocument: null
};


export const glpiReducer = createReducer(
    INITIAL_STATE,
    on(
        GLPIActions.create_ticket, state => state
    ),
    on(
        GLPIActions.create_ticket_clear, state => ({
            ...state,
            createTicket: {
                model: 'feedback',
                status: OperationStatus.Unloaded,
                message: ''
            } as Feedback
        })
    ),
    on(
        GLPIActions.create_ticket_return, (state, { payload }) => ({
            ...state,
            createTicket: payload as Feedback
        })
    ),
    on(
        GLPIActions.get_session, state => state
    ),
    on(
        GLPIActions.get_session_return, (state, { payload }) => ({
            ...state,
            getSession: payload as SessionGLPI
        })
    ),
    on(
        GLPIActions.upload_document, state => state
    ),
    on(
        GLPIActions.upload_document_return, (state, { payload }) => ({
            ...state,
            uploadDocument: payload as GLPIFile
        })
    ),
    on(
        GLPIActions.upload_document_clear, state => ({
            ...state,
            uploadDocument: null
        })
    ),
    on(
        GLPIActions.cleanup, state => ({
            ...state,
            createTicket: null,
            getSession: null,
            uploadDocument: null
        })
    ),
);
