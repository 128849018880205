import { SharedService } from 'app/Shared/Services/shared.service';
import Swal from 'sweetalert2';
import { ResultAlert } from '../models';
import { showAlert } from '../Helpers/showAlert';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
export class ValidationResponseReports {
    resultAlert: ResultAlert;
    constructor() {
    }

    swalDanger(title: string) {
        showAlert(title, 'danger');
    }

    swalWarning(title: string) {
        showAlert(title, 'warning');
    }

    swalSuccess(title: string) {
        showAlert(title, 'success');
    }


    without_register_for_this_data_200(title: string) {
        Swal.fire({
            text: title
        });
    }


    erro_return_report_400(title: string, swalParameters: any) {
        const textWithParameters = title['text'].replace('{}', String(swalParameters));
        Swal.fire({
            title: title['title'],
            text: textWithParameters,
            icon: 'error',
        });
    }


    not_selected_alert_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
        });
    }

    selected_alert_200(title: string, swalParameters: any) {
        const textWithParameters = title['text'].replace('{}', String(swalParameters));
        Swal.fire({
            title: title['title'],
            text: textWithParameters,
            icon: 'error',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: title['cancelButton'],
            confirmButtonText: title['deleteButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteAlerts');
        });
    }

    remove_selected_alert_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: title['cancelButton'],
            confirmButtonText: title['deleteButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteAlert');
        });
    }

    alert_feedback_success_200(title: string, swalParameters: any) {
        Swal.fire({
            icon: 'success',
            text: swalParameters,
        });
    }

    alert_feedback_error_400(title: string, swalParameters: any) {
        Swal.fire({
            icon: 'error',
            text: swalParameters,
        });
    }

    login_message_400(swalParameters: any) {
        showAlert(swalParameters, 'danger');
    }

}
