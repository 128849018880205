import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Camera } from 'app/cameras/models';
import { TypeAnalytic, Analytics, DrawType, DrawData } from '../models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NzMarks } from 'ng-zorro-antd/slider';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { drawOnCanvas, writingOnCanvas } from 'app/Shared/Helpers/canvas';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AnalyticCreateComponent } from '../analytic-create/analytic-create.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-draw-analytic',
    templateUrl: './draw-analytic.component.html',
    styleUrls: ['./draw-analytic.component.scss', '../styles.scss']
})
export class DrawAnalyticComponent implements OnInit, AfterViewInit, OnDestroy {
    //Elementos html do canvas
    @ViewChild('drawAnalytic', { static: true }) drawAnalytic: ElementRef;
    @ViewChild('plotImageDraw', { static: true }) plotImageDraw: ElementRef;
    // Componente de colorpicker
    @ViewChild('colorpicker') colorpicker: ElementRef;
    objectControl = [15, 85];
    marks: NzMarks = {
        0: {
            style: {
                color: '#002D59'
            },
            label: '<span style="left: 1%; position: relative;">0%</span>'
        },
        100: {
            style: {
                color: '#002D59'
            },
            label: '<span style="left: 95%; position: relative;">100%</span>'
        }
    };
    //Dados que irão compor o novo analítico
    newAnalytic: DrawData = {
        points: [],
        blur: 1,
        color: '#41bfd1'
    };
    //Opções de analítico de determinado grupo
    @Input() optionsAnalytics: TypeAnalytic[];
    //Câmera em que será inserido o analítico
    @Input() camera: Camera;
    //Analítico a ser editado
    @Input() analytic: Analytics;
    //Evento de resetar valores
    @Input() resetAnalytic: Observable<any>;
    //Draw Type
    @Input() drawType: Observable<DrawType>;
    sub: Subscription;
    //Elementos do canvas
    canvasDraw: HTMLCanvasElement;
    canvasDrawPlotImage: HTMLCanvasElement;
    ctxDraw: CanvasRenderingContext2D;
    ctxText: CanvasRenderingContext2D;
    ctxShadow: CanvasRenderingContext2D;
    ctxDrawPlotImage: CanvasRenderingContext2D;
    imgCam: HTMLImageElement;
    //Variáveis de controle dos polígono
    prevPos: { x: number; y: number };
    currentPos: { x: number; y: number };
    //variáveis de controle
    activeButtons: boolean;
    foundPoint: boolean;
    editAnalitic: boolean;
    //Form control e configuração do slider para sensibilidade do analítico
    toogleLight = new UntypedFormControl('', [Validators.required]);
    sensitivityDraw = 10;
    subSensitivityDraw: Subscription;
    //Seleção do colorpicker
    selectedColor: string;
    //Tipo de desenho do analítico
    newAnalyticDraw: DrawType = { id: 0, alias: '-x-', value: 'polygon' };
    //Index do ponto do desenho que está sendo editado no momento
    activePoint: number;
    //Subscriptions dos eventos de mouse
    moveSubscription: Subscription;
    clickSubscription: Subscription;
    resetSub: Subscription;
    changeDrawSub: Subscription;
    formRightComplete = true;
    sideRightComplete = true;
    // sideLeftComplete = true;
    @Input() analiticFormInformation: Observable<boolean>;
    analiticFormInformationSub: Subscription;
    constructor(private dialogRef: MatDialogRef<AnalyticCreateComponent>) { }

    ngOnInit(): void {
        this.newAnalytic.points = [];
        if (this.analytic) {
            this.editAnalitic = true;
        } else {
            this.editAnalitic = false;
        }
        // Retorna os filtros marcados ou não marcados
        this.analiticFormInformationSub = this.analiticFormInformation.subscribe(value => {
            this.formRightComplete = this.newAnalytic.points.length !== 0;
        });

        this.dialogRef.afterOpened().subscribe(result => {
            window.setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        });
    }

    ngOnDestroy() {
    //Unsubscribes
        if (this.moveSubscription) {
            this.moveSubscription.unsubscribe();
        }
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
        }
        if (this.subSensitivityDraw) {
            this.subSensitivityDraw.unsubscribe();
        }
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.colorpicker.nativeElement.value = '';
        this.canvasDraw = this.drawAnalytic.nativeElement;
        this.ctxDraw = this.canvasDraw.getContext('2d');
        this.ctxText = this.canvasDraw.getContext('2d');
        this.ctxShadow = this.canvasDraw.getContext('2d');
        this.canvasDrawPlotImage = this.plotImageDraw.nativeElement;
        this.ctxDrawPlotImage = this.canvasDrawPlotImage.getContext('2d');
        this.imgCam = new Image();
        // definir a imagem que vai aparecer para desenho do analítico
        this.imgCam.src = this.camera.thumbnail;
        this.imgCam.onload = () => {
            this.ctxDrawPlotImage.drawImage(this.imgCam, 0, 0, this.imgCam.width, this.imgCam.height, 0, 0, 640, 480);
        };
        this.ctxDraw.fillStyle = 'rgb(255,255,255)';
        this.ctxText.fillStyle = 'rgb(255,255,255)';
        this.ctxShadow.fillStyle = 'rgb(255,255,255)';
        this.foundPoint = false;
        this.activeButtons = false;
        this.resetSub = this.resetAnalytic.subscribe(event => {
            this.resetDraw();
        });
        this.changeDrawSub = this.drawType.subscribe(draw => {
            this.resetDraw();
            this.newAnalyticDraw = draw;
        });
        if (this.analytic) {
            this.addDrawInfo();
            this.sideRightComplete = false;
        }
    }

    // Valida os pontos e se o formulario direito esta full, valida também a criação do analitico apenas se tudo estiver okay
    validadePointers() {
        if (this.newAnalytic.points.length === 0 && this.formRightComplete === false && this.newAnalyticDraw) {
            this.formRightComplete = true;
            this.sideRightComplete = false;
            this.captureEvents();
        }
    }

    // Retorna o analitico desenhado quando for uma edição
    addDrawInfo() {
        const i = this.optionsAnalytics.findIndex(element => element.alias === this.analytic.alias);
        this.newAnalyticDraw = this.optionsAnalytics[i].draw_type;
        this.selectedColor = this.analytic.border_color;
        this.newAnalytic.color = this.analytic.border_color;
        this.newAnalytic.blur = this.analytic.blur;
        this.formRightComplete = true;
        this.newAnalytic.points = JSON.parse(this.analytic.metadata).points;
        this.sensitivityDraw = this.analytic.blur < 1 ? this.analytic.blur * 100 : this.analytic.blur;
        this.toogleLight.setValue(this.analytic.toogle_light);
        this.objectControl = [
            this.analytic.smaller_object < 1 ? this.analytic.smaller_object * 100 : this.analytic.smaller_object,
            this.analytic.larger_object < 1 ? this.analytic.larger_object * 100 : this.analytic.larger_object
        ];
        this.captureEvents(); // habilitar edição de pontos do draw
        drawOnCanvas(this.ctxDraw, this.newAnalytic.color, this.newAnalytic.points, this.newAnalyticDraw.value);
        if (this.newAnalytic.points.length <= 2 && this.newAnalyticDraw.value == 'line') {
            writingOnCanvas(this.ctxShadow, this.ctxText, this.selectedColor, this.newAnalytic.points);
        }
    }

    resetDraw() {
        this.selectedColor = '#41bfd1';
        this.sensitivityDraw = 10;
        if (this.newAnalytic.points) {
            this.newAnalytic.points = [];
            drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
        }
        if (this.clickSubscription && this.moveSubscription) {
            this.clickSubscription.unsubscribe();
            this.moveSubscription.unsubscribe();
        }
    }

    //Desfaz última ação
    undoDrawCanvas() {
        this.newAnalytic.points.pop();
        drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
    }

    //Deleta o desenho no canvas
    deleteDrawCanvas() {
        this.newAnalytic.points = [];
        drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
    }

    insertPointListAndDraw(pointX: number, pointY: number) {
        this.newAnalytic.points.push({ x: pointX, y: pointY });
        drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
    }

    changePointListAndDraw(previous: number, next: number, x: number, y: number) {
        if ((this.newAnalytic.points[previous].x != x) && (this.newAnalytic.points[previous].y != y) &&
      (this.newAnalytic.points[next].x != x && this.newAnalytic.points[next].y != y)) {
            if (this.newAnalytic.points[this.activePoint].y == this.newAnalytic.points[previous].y) {
                this.newAnalytic.points[previous].y = y;
            } else {
                this.newAnalytic.points[previous].x = x;
            }
            if (this.newAnalytic.points[this.activePoint].y == this.newAnalytic.points[next].y) {
                this.newAnalytic.points[next].y = y;
            } else {
                this.newAnalytic.points[next].x = x;
            }
            this.newAnalytic.points[this.activePoint].x = x;
            this.newAnalytic.points[this.activePoint].y = y;
            drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
        }
    }

    // Captura os eventos e desenha os analiticos
    captureEvents() {
    //verifica eventos de clicar e arrastar o cursor
        this.moveSubscription = fromEvent(this.canvasDraw, 'mousedown')
            .pipe(
                switchMap(e => {
                    return fromEvent(this.canvasDraw, 'mousemove').pipe(
                        takeUntil(fromEvent(this.canvasDraw, 'mouseup')),
                        takeUntil(fromEvent(this.canvasDraw, 'mouseleave'))
                    );
                })
            )
            .subscribe((res: MouseEvent) => {
                const rect = this.canvasDraw.getBoundingClientRect();

                const currentPos = {
                    x: res.clientX - rect.left,
                    y: res.clientY - rect.top
                };

                if (!this.foundPoint) {
                    //Checa se o usuário clicou em um vértice do desenho
                    if (this.enableActivePoint({ x: Math.trunc(currentPos.x), y: Math.trunc(currentPos.y) })) {
                        this.foundPoint = true;
                    }
                }
                if (this.foundPoint) {
                    if (this.newAnalyticDraw.value == 'rectangle') {
                        if (this.activePoint == 0) {
                            this.changePointListAndDraw(3, 1, Math.trunc(currentPos.x), Math.trunc(currentPos.y));
                        } else if (this.activePoint == 1) {
                            this.changePointListAndDraw(0, 2, Math.trunc(currentPos.x), Math.trunc(currentPos.y));
                        } else if (this.activePoint == 2) {
                            this.changePointListAndDraw(1, 3, Math.trunc(currentPos.x), Math.trunc(currentPos.y));
                        } else if (this.activePoint == 3) {
                            this.changePointListAndDraw(2, 0, Math.trunc(currentPos.x), Math.trunc(currentPos.y));
                        }
                    } else {
                        this.newAnalytic.points[this.activePoint].x = Math.trunc(currentPos.x);
                        this.newAnalytic.points[this.activePoint].y = Math.trunc(currentPos.y);
                        drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
                        if (this.newAnalytic.points.length <= 2 && this.newAnalyticDraw.value == 'line') {
                            writingOnCanvas(this.ctxShadow, this.ctxText, this.selectedColor, this.newAnalytic.points);
                        }
                    }
                }
            });

        this.clickSubscription = fromEvent(this.canvasDraw, 'click').subscribe((res: MouseEvent) => {
            const rect = this.canvasDraw.getBoundingClientRect();

            const point = {
                x: res.clientX - rect.left,
                y: res.clientY - rect.top
            };
            if (this.newAnalyticDraw.value == 'line') {
                if (!this.enableActivePoint({ x: Math.trunc(point.x), y: Math.trunc(point.y) })) {
                    if (this.newAnalytic.points.length < 2) {
                        this.insertPointListAndDraw(Math.trunc(point.x), Math.trunc(point.y));
                        writingOnCanvas(this.ctxShadow, this.ctxText, this.selectedColor, this.newAnalytic.points);
                    }
                }
            }
            else if (this.newAnalyticDraw.value == 'rectangle') {
                if (!this.enableActivePoint({ x: Math.trunc(point.x), y: Math.trunc(point.y) })) {
                    if (this.newAnalytic.points.length < 3) {
                        const sizeList = this.newAnalytic.points.length;
                        if (sizeList == 0) {
                            this.insertPointListAndDraw(Math.trunc(point.x), Math.trunc(point.y));
                        } else if (sizeList == 1) {
                            if (Math.abs(point.x - this.newAnalytic.points[sizeList - 1].x) < Math.abs(point.y - this.newAnalytic.points[sizeList - 1].y)) {
                                this.insertPointListAndDraw(this.newAnalytic.points[sizeList - 1].x, Math.trunc(point.y));
                            } else {
                                this.insertPointListAndDraw(Math.trunc(point.x), this.newAnalytic.points[sizeList - 1].y);
                            }
                        } else if (sizeList == 2) {
                            if (this.newAnalytic.points[0].x != this.newAnalytic.points[1].x) {
                                this.insertPointListAndDraw(this.newAnalytic.points[sizeList - 1].x, Math.trunc(point.y));
                                this.insertPointListAndDraw(this.newAnalytic.points[0].x, this.newAnalytic.points[2].y);
                            } else {
                                this.insertPointListAndDraw(Math.trunc(point.x), this.newAnalytic.points[sizeList - 1].y);
                                this.insertPointListAndDraw(this.newAnalytic.points[2].x, this.newAnalytic.points[0].y);
                            }
                        }
                    }
                }
            }
            else if (this.newAnalyticDraw.value == 'polygon') {
                if (!this.enableActivePoint({ x: Math.trunc(point.x), y: Math.trunc(point.y) })) {
                    this.newAnalytic.points.push({ x: Math.trunc(point.x), y: Math.trunc(point.y) });
                    drawOnCanvas(this.ctxDraw, this.selectedColor, this.newAnalytic.points, this.newAnalyticDraw.value);
                }
            }
            this.foundPoint = false;
        });
    }

    //Checa se o usuário está com o mouse em algum vértice do desenho
    enableActivePoint(point: { x: number; y: number }) {
        let minDis = 0;
        let minDisIndex = -1;
        for (let i = 0; i < this.newAnalytic.points.length; i++) {
            const dis = Math.sqrt(Math.pow(point.x - this.newAnalytic.points[i].x, 2) + Math.pow(point.y - this.newAnalytic.points[i].y, 2));
            if (minDisIndex == -1 || minDis > dis) {
                minDis = dis;
                minDisIndex = i;
            }
        }

        if (minDis < 6 && minDisIndex >= 0) {
            this.activePoint = minDisIndex;
            return true;
        }
        return false;
    }

    updateColor() {
        drawOnCanvas(this.ctxDraw, this.newAnalytic.color, this.newAnalytic.points, this.newAnalyticDraw.value);
    }

    // Retorna a cor no novo componente de color picker
    getColor(background, type) {
        const colorButton = document.querySelector('.dot-blue');
        switch (type) {
            case 'color':
                const backgroundNew = background.replace('background-color: ', '');
                const color = backgroundNew.replace(';', '');
                const checked = document.getElementById('#326ABC');
                checked.classList.add('dot-active');
                this.newAnalytic.color = color;
                this.selectedColor = color;
                this.updateColor();
                break;
            case 'picker':
                this.newAnalytic.color = this.colorpicker.nativeElement.value;
                this.selectedColor = this.colorpicker.nativeElement.value;
                colorButton.setAttribute('style', `background-color: ${this.colorpicker.nativeElement.value}`);
                this.updateColor();
                break;
        }
    }
}
