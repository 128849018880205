<div class="filter-container" [ngClass]="styles">
    <app-filter #filterList
    [list]="list"
    [selected]="selected"
    [selectedList]="selectedList"
    [type]="type"
    [placeholder]="placeholder"
    [feedback]="false"
    [showSelected]="type === 'simple' ? true : false"
    [hideActiveFill]="true"
    [hasToggle]="type === 'simple' ? false : false"
    [blocked]="blocked"
    (filterStatusChange)="changeList($event)"
    [styles]="styles"
    [search]="search"
    ></app-filter>
</div>

<div class="select-all" *ngIf="hasSelectAll" (click)="markAll(filterList)">
    <span nz-icon nzType="{{selectAllIcon}}" nzTheme="outline" class="icon"></span>
    <span>{{selectAll}}</span>
</div>

<div class="row list-container" *ngIf="type === 'multiselect'">
    <div class="card" *ngFor="let element of selection">
        <span class="title">{{element.text}}</span>
        <span class="close" (click)="removeItem(element, filterList)"><span nz-icon nzType="close" nzTheme="outline"></span></span>
    </div>
</div>
