<nav class="navbar navbar-expand-lg navbar-light" [ngStyle]="{'z-index': 900}">
  <div class="container-fluid">
    <a class="navbar-brand">
      <div class="logo">
        <div class="logo-nuv">
          <img class="tam-img-logo" src="/assets/img/logo.svg">
        </div>
      </div>
    </a>
    <ul class="navbar-nav">
      <li class="nav-item">
        <ul class="dropdown font-text-nav cursor_menu">
          <img class="profile_pic dropdown-toggle" data-toggle="dropdown" src="../../../assets/img/placeholder.jpg"
            height="48" width="48">
          <ul class="dropdown-menu dropdown-left-manual cursor_menu">
            <li><a (click)="logout()">Sair</a></li>
          </ul>
        </ul>
      </li>
    </ul>
  </div>
</nav>

<div class="main-content">
  <div class="user_blocked_container">
    <div class="row">
      <div class="user_blocked_message_title">
        <span>Sua conta está bloqueada</span>
      </div>
    </div>
    <div class="row">
      <div class="user_blocked_message_text">
        <p>Entre em contato com seu fornecedor para reativar sua conta.</p>
      </div>
    </div>
    <img src="../../../assets/img/conta_bloqueada.svg" alt="user_blocked">
  </div>
</div>