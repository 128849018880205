import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home-selector/home.component';
import { UsersModule } from 'app/Users/users.module';
import { CamerasModule } from 'app/cameras/cameras.module';
import { homeRoutes } from './home.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModulesModule } from 'app/Shared/shared-modules/shared-modules.module';
import { ComponentsModule } from '../components/components.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(homeRoutes),
        UsersModule,
        CamerasModule,
        SharedModulesModule,
        ComponentsModule,
        NzToolTipModule,
        NzPopoverModule,
        InfiniteScrollModule
    ],
    declarations: [
        HomeComponent,
        DashboardComponent,
    ],
    exports: [
        HomeComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
