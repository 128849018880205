import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypeAnalytic } from 'app/analytics/models';
import { Camera } from 'app/cameras/models';
import { getToken } from 'app/Shared/Helpers/getToken';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetectionHistory, Detections } from '../models';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class DetectionService {

    constructor(private http: HttpClient) { }

    private backend_url = environment.apiUrl;

    getDetections(userSub: string, child_sub: string, current_data?: string, list_cameras?: Camera[], analytics_type?: TypeAnalytic[],
        page?: number, limit?: number, recording_filter?: string, group_id?: number[]): Observable<Detections | Feedback> {
        const url = `${this.backend_url}/api/resources/report/alerthistory`;
        const headers = {
            headers: new HttpHeaders ({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const data = {
            sub: userSub,
            child_sub: child_sub,
            date: current_data ? current_data : null,
            list_cameras: list_cameras ? list_cameras : [],
            analytics_type: analytics_type ? analytics_type : [],
            page: page,
            limit: limit,
            recording_filter: recording_filter,
            list_cameras_group: group_id
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: false,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const detections: Detections = {
                        model: 'detections',
                        detections: event['results'][0].data ? event['results'][0].data : [] as DetectionHistory[],
                        status: OperationStatus.Success
                    };
                    return detections;
                }
            })
        );
    }

    reviewAlert(sub: string, alert_id: number, evaluation: boolean, comment?: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/report/review`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            alert_id: alert_id,
            thumb_up: evaluation,
            ...(comment != null && { comment: comment })
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: false,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: evaluation ? 'Obrigado pelo feedback' : 'Obrigado por contribuir com o Avanuv!',
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    deleteAlerts(sub: string, idAlerts: number[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/report/deletealert`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };

        const data = {
            sub: sub,
            idAlert: idAlerts
        };

        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: false,
                        status: OperationStatus.Fail
                    };
                    return feedback;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: 'Alertas deletados com sucesso',
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }
}
