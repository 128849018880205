<div [ngClass]="{'main-content-cv': ((is_partner || isAssociate) && !dashboardList)}" 
  class="xs:min-w-[466px] overflow-x-auto xs:overflow-x-scroll main-content background" [ngStyle]="{'min-height': hasCameras ? null : '0'}" 
  [class]="{'client' : partnerDashboard}">
  <div class="title-header" *ngIf="!(is_partner || isAssociate)">
    <div class="page-title">
      <span class="style-title" *ngIf="!is_guest && !isClientAssociate">Minhas Câmeras</span>
      <span class="style-title" *ngIf="is_guest || isClientAssociate">Câmeras de {{ associate.name }}</span>
    </div>
    <div class="partner-table-metadata" style="display: flex; align-items: center;"
        *ngIf="!loadingCameraList && inventories && hasCameras">
      <span>
        Câmeras: {{inventories.inventory.ci.total.used}}/{{inventories.inventory.ci.total.used +
        inventories.inventory.ci.total.available}}
        <i nz-icon class="loading_deletion" nzType="loading" nzTheme="outline"
            *ngIf="hasCameraDeleted()" nz-popover nzPopoverContent="O seu estoque de câmeras está sendo atualizado."
            nzPopoverPlacement="top" nzPopoverOverlayClassName="popover-container" 
            [nzPopoverOverlayStyle]="{'text-align': 'center', 'max-width': '240px'}"></i>
      </span>
      <span style="margin-left: 10px;">
        Detecções: {{inventories.inventory.ai.used}}/{{inventories.inventory.ai.used +
        inventories.inventory.ai.available}}
      </span>
      <span style="margin-left: 10px;">
        Convidados: {{inventories.inventory.guests.used}}/{{inventories.inventory.guests.used +
        inventories.inventory.guests.available}}
      </span>
      <i *ngIf="(is_partner || adminAssociate) && (this.client && this.inventories)" style="margin-left: 10px; cursor: pointer; color: #a1a2a6; font-size: 18px;"
        class="material-icons" (click)="addCredit()">edit</i>
    </div>
  </div>
  
  <div class="camera-amount" *ngIf="(is_partner || isAssociate) && !dashboardList">
    
    <div class="flex-line cam-info">
      <div class="open-more clickable" (click)="toggleCameraList()" [hidden]="ciList?.length == 0">
        <i class="material-icons" *ngIf="!openExpandedCameraList">expand_more</i>
        <i class="material-icons" *ngIf="openExpandedCameraList">expand_less</i>
      </div>

      <div class="cameras">
        <span class="bold loading_deletion_container">
          Câmeras 
          <i nz-icon class="loading_deletion" nzType="loading" nzTheme="outline"
            *ngIf="hasCameraDeleted()" nz-popover nzPopoverContent="O estoque de câmeras do cliente está sendo atualizado."
            nzPopoverPlacement="top" nzPopoverOverlayClassName="popover-container" 
            [nzPopoverOverlayStyle]="{'text-align': 'center', 'max-width': '240px'}"></i>
        </span>
        <div class="flex-line section" *ngIf="inventories">
          <span class="usage">Em uso: <strong>{{inventories.inventory.ci.total.used}}</strong></span>
          <div class="progress-container">
            <app-progress-bar [progress]="(inventories.inventory.ci.total.used / (inventories.inventory.ci.total.used + inventories.inventory.ci.total.available)) * 100"></app-progress-bar>
          </div>
          <span class="total">Total: <strong>{{inventories.inventory.ci.total.used + inventories.inventory.ci.total.available}}</strong></span>
        </div>
      </div>
    </div>

    <div class="analytics">
      <span class="bold">Detecções Inteligentes</span>
      <div class="flex-line section" *ngIf="inventories">
        <span class="usage">Em uso: <strong>{{inventories.inventory.ai.used}}</strong></span>
        <div class="progress-container">
          <app-progress-bar [progress]="(inventories.inventory.ai.used / (inventories.inventory.ai.available + inventories.inventory.ai.used)) * 100"></app-progress-bar>
        </div>
        <span class="total">Total: <strong>{{inventories.inventory.ai.available + inventories.inventory.ai.used}}</strong></span>
      </div>
    </div>

    <div class="guests" #infoicon>
      <span class="bold">Convidados
          <i
              class="tooltip_icon"
              nz-icon nzType="info-circle"
              nzTheme="outline"
              nz-popover
              [nzPopoverContent]="tooltip"
              nzPopoverTrigger="hover"
              [(nzPopoverVisible)]="isTooltipVisible"
              nzPopoverPlacement="top"
              [nzPopoverOrigin]="infoicon"
              nzPopoverMouseLeaveDelay="0.5"
              nzPopoverOverlayClassName="tooltip-container"
          ></i>
      </span>
      <ng-template #tooltip>
        <div class="flex flex-col tooltip-container">
          <div class="tooltip-text">
            <p>Os convidados do cliente podem estar cadastrados <br> como <b>convidados da conta</b> e <b>convidados por câmera</b>.</p><br>
            <p><b>Convidado por câmera</b> <br> Pode ser adicionado através da <a>lista de câmeras</a></p><br>
            <p><b>Convidado da Conta</b> <br> Listagem completa na aba de <a>convidados do cliente</a></p>
          </div>
        </div>
      </ng-template>
      <div class="flex-line section" *ngIf="inventories">
        <span class="usage">Em uso: <strong>{{inventories.inventory.guests.used}}</strong></span>
        <div class="progress-container">
          <app-progress-bar [progress]="(inventories.inventory.guests.used / (inventories.inventory.guests.available + inventories.inventory.guests.used)) * 100"></app-progress-bar>
        </div>
        <span class="total">Total: <strong>{{inventories.inventory.guests.available + inventories.inventory.guests.used}}</strong></span>
      </div>
    </div>

    <div class="edit clickable" (click)="addCredit()" *ngIf="is_partner || adminAssociate">
      <span>Editar</span>
      <span nz-icon nzType="edit" nzTheme="outline"></span>
    </div>

  </div>
  
  <div class="expanded" [ngClass]="{'active' : openExpandedCameraList}">
    <div class="flex-line" *ngFor="let camera of ciList">
      <span class="name">
        <strong>{{getPlanProperty(camera.id_plan, 'name').toUpperCase()}} </strong>{{getPlanProperty(camera.id_plan, 'alias')}}
      </span>

      <div class="usage">
        <span>Em uso: <strong>{{camera.used}}</strong></span>
      </div>

      <div class="progress-container">
        <app-progress-bar [progress]="((camera.used/(camera.used + camera.available)) * 100)"></app-progress-bar>
      </div>
      
      <div class="available">
        <span>disponível: <strong>{{camera.available}}</strong></span>
      </div>
    </div>
  </div>

  <div class="title-header" *ngIf="(is_partner || isAssociate) && dashboardList">
    <div class="page-title">
      <img class="arrow-left" (click)="goToDashboard()" src="../../../assets/img/arrow_left.svg" alt="arrow-left">
      <span class="dashboard-list-title">Lista de Câmeras</span>
    </div>
  </div>

  <div class="flex-line between second-row" [class]="{'disabled-div': client && !client.active}">
    <div class="filter">
      <div class="search-input">
        <input type="text" placeholder="Pesquisar"
        [formControl]="searchInputControl" (keyup)="requestSearch()">
        <i nz-icon nzType="search" nzTheme="outline" class="clickable" (click)="requestSearch()"></i>
      </div>

      <div *ngIf="partnerDashboard && clientSimple?.length" class="select-client-container">
        <app-filter
              [styles]="['multiselect-alternate-style']"
              [list]="clientSimple"
              type="multiselect"
              [search]="true"
              placeholder="Selecionar Cliente"
              (filterStatusChange)="selectClient($event)"
              [showSelected]="true"
              [selectedList]="clientPlaceholder"
              feedback="true"
              [hideActiveFill]="true"
              [hasIconPrefix]="true"
              [dashboard]="true"
              iconType="team"
          ></app-filter>
    </div>
      
      <div class="status-container" *ngIf="!is_partner">
        <app-filter
        [styles]="['simple-alternate-style']"
        [list]="statusOptions"
        [selected]="placeholder"
        placeholder="Status da câmera"
        [type]="'simple'"
        [showSelected]="true"
        (filterStatusChange)="changeStatusFilter($event)"></app-filter>
      </div>

      <div class="status-container" *ngIf="is_partner">
        <app-filter
        [styles]="['simple-alternate-style']"
        [list]="statusOptionsPartner"
        [selected]="placeholder"
        placeholder="Status da câmera"
        [type]="'simple'"
        [showSelected]="true"
        (filterStatusChange)="changeStatusFilter($event)"></app-filter>
      </div>

      <div class="selector-container">
        <app-filter [list]="sortOptions"
        [styles]="['simple-alternate-style']"
        [selected]="1"
        [type]="'simple'"
        (filterStatusChange)="changeSort($event)"></app-filter>
      </div>
    </div>

    <div id="cam-table" *ngIf="inventories != null && !is_guest">
      <div class="tooltip-camera" matTooltip="Limite de câmeras atingido." [matTooltipDisabled]="inventories.inventory.ci.total.available !== 0">
        <ng-container *ngIf="!(is_partner || adminAssociate)">
          <button (click)="addCamera()" *ngIf="(!isAssociate || adminAssociate || adminClientAssociate)" class="btn btn-primary enter-button lt-sm:btn-sm" [ngClass]="{'disabled': inventories.inventory.ci.total.available === 0}">
            <i class="material-icons">add</i>Adicionar Câmera
          </button>
          <span *ngIf="client && !client.active" class="btn btn-primary enter-button lt-sm:btn-sm">Cliente bloqueado</span>
        </ng-container>
        <ng-container *ngIf="(is_partner || adminAssociate) && !dashboardList">
          <button class="btn btn-primary enter-button" *ngIf="(!isAssociate || adminAssociate || adminClientAssociate) && (client && client.active)"
          [ngClass]="{'disabled': inventories.inventory.ci.total.available === 0}" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
            <i class="material-icons">add</i>Adicionar
            Câmera
          </button>
          <span *ngIf="client && !client.active" class="btn btn-primary enter-button lt-sm:btn-sm">Cliente bloqueado</span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu class="dropdown-menu-group">
              <li nz-menu-item class="drop-group" (click)="addCamera()"><i nz-icon nzType="video-camera" nzTheme="outline"></i>Adicionar uma câmera</li>
              <li nz-menu-item class="drop-group" (click)="addCameraCSV()"><span style="display: flex;"><img src="../../../assets/img/group_cam.svg" alt="grupo-de-câmera"> Adicionar bloco de câmeras</span></li>
            </ul>
          </nz-dropdown-menu>
        </ng-container>
      </div>
    </div>
  </div>

  <div [hidden]="!hasGroups || partnerDashboard" [class]="{'disabled-div': client && !client.active}">
    <app-camera-group context="camera-list"></app-camera-group>
  </div>
  
  <div [class]="{'disabled-div': client && !client.active}" *ngIf="hasCameras || loadingCameraList || loadingDashCameras">
    
    <nz-table #camTable
    [ngClass]="{'table-client-view': ((is_partner || isAssociate) && !dashboardList)}"
    class="table style-table antd-table"
    [nzData]="cameras" 
    [nzPaginationPosition]="'both'"
    [nzFrontPagination]="false"
    [nzSize]="'small'"
    [nzTotal]="camTotal"
    [(nzPageSize)]="camLimit"
    [(nzPageIndex)]="pageIndex"
    (nzPageIndexChange)="onPageIndexChange()"
    [nzTableLayout]="'auto'"
    [nzLoading]="loadingCameraList || loading || loadingDashCameras" [nzLoadingIndicator]="loadingCameraTemplate">
      <thead>
        <tr>
          <th class="table-thumb">Câmera</th>
          <th class="table-name">Nome</th>
          <th class="table-plan lt-md:hidden">Plano</th>
          <th class="table-detec lt-md:hidden" [hidden]="partnerDashboard">
            <!-- <div class="inicio-block" *ngIf="is_processing">
              <div class="tooltip_flag">
                <span class="tooltiptext tooltipStep" id="0">
                  <div class="container_out_tolltip">
                    <div class="tooltiptextclosecontainer" (click)="closeToolTip(0)">
                      <span> <i class="material-icons tooltiptextclose">close</i></span>
                    </div>
                    <div>
                      <span class="tooltipTextInformation">Nas câmeras abaixo há pelo menos um analitico em
                        configuração,
                        clique para ver mais detalhes.</span>
                    </div>
                  </div>
                </span>
              </div>
            </div> -->
            <section style="text-align:center;">
              {{generalList ? 'Analíticos' : 'Detecções'}}
            </section>
          <th class="table-conv lt-md:hidden" [hidden]="partnerDashboard">Convidados</th>
          <th class="table-client lt-md:hidden" [hidden]="!partnerDashboard">Cliente</th>
          <th class="status-header" [hidden]="generalList">Bloqueio</th>
          <!-- Ações -->
          <th class="table-actions lt-md:hidden"></th>
          <!-- Câmera bloqueada -->
          <th class="table-block lt-md:hidden" class="header-bloqueado" [hidden]="!generalList"></th>
        </tr>
      </thead>
      <tbody [hidden]="loading">
        <tr *ngFor="let camera of camTable.data; let index = index">
          <!-- Thumbnail -->
          <td [class.deleting]="camera.deleted" [class.offline]="!camera.active">
            <div class="refresh-thumbnail">
              <div class="style-thumbnail" [ngClass]="{'clickable': camera.active}">
                <img class="image-img" [src]="camera.cache_thumb | async | safeHtml" (error)="brokenImageHandler($event)">
                <div *ngIf="camera.active" class="image-overlay" (click)="viewLiveCamera(camera)">
                  <img src="/assets/img/fullscreen-hover.svg">
                </div>
              </div>
              <span class="reload-thumb" (click)="updateThumbnail(index)" nz-tooltip nzTooltipOverlayClassName="cam-name"
                    [nzTooltipTitle]="camera.active ? 'Atualizar screenshot' : 'Câmera bloqueada'"
                      [nzTooltipOverlayStyle]="{'margim-bottom': '12px'}" [class.offline]="!camera.active">
                <i nz-icon [nzType]="camera.refresh_thumb ? 'loading' : 'reload'" nzTheme="outline"></i>
              </span>
            </div>
          </td>
          <td [nzBreakWord]="true" [class.deleting]="camera.deleted" [class.offline]="!camera.active">
            <!-- Nome -->
            <span class="style-nameCam" nz-tooltip [nzTooltipTitle]="camera.alias" nzTooltipOverlayClassName="cam-name"
            nzTooltipPlacement="topLeft" [nzTooltipOverlayStyle]="{'margim-bottom': '12px'}">
              {{camera.alias}}
            </span>
            <p class="id-cam">
              #{{camera.id}}
            </p>
            <!-- Analíticos -->
            <div class="hidden row gt-sm:hidden xs:block">
              <div class="flex flex-row items-start justify-center gap-1">
                <div class="flex w-1/6">
                  <img src="../../../assets/img/Icons/analytics.svg" class="circle analitycs_name"
                    (click)="openModalAnalytics(camera)"><br>
                </div>
                <div class="flex w-3/4">
                  <span class="style-count" (click)="openModalAnalytics(camera)">{{camera.analytics}} analíticos</span>
                </div>
                <div class="flex w-2/12">
                  <button [disabled]="!camera.active" (click)="openModalAnalytics(camera)" mat-raised-button
                    class="btn btn-sm btn-just-icon style-icon-add mr-auto button_add_analytics"
                    [class.disabled_cam]="camera.analytic_status && camera.analytic_status == 'disabled'">
                    <div *ngIf="camera.analytic_status == 'disabled'">
                      <span nz-icon nzType="plus" nzTheme="outline"></span>
                    </div>
                    <div *ngIf="!camera.is_processing_analytic && camera.analytic_status != 'disabled'">
                      <span nz-icon nzType="plus" nzTheme="outline"></span>
                    </div>
                    <div *ngIf="camera.is_processing_analytic && camera.analytic_status != 'disabled'">
                      <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 20px"></span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <!-- Convidados -->
            <div class="row gt-sm:hidden xs:block md:hidden">
              <div class="flex flex-row items-start justify-center gap-1">
                <div class="flex w-2/12">
                  <img src="../../../assets/img/Icons/guests.svg" class="circle image_invites"
                    (click)="openModalInviteUser(camera, index)"><br>
                </div>
                <div class="flex w-3/4">
                  <span class="style-count" (click)="openModalInviteUser(camera, index)">{{camera.guests}}
                    convidados</span>
                </div>
                <div class="flex w-2/12">
                  <button [disabled]="!camera.active" (click)="openModalInviteUser(camera, index)" mat-raised-button
                    class="btn btn-sm btn-just-icon style-icon-add mr-auto button_add_invite">
                    <span nz-icon nzType="plus" nzTheme="outline"></span>
                  </button>
                </div>
              </div>
            </div>
            <!-- Planos -->
            <form class="md:hidden container_plan" *ngIf="camera.plan_details">
              <button disabled class="btn btn-sm btn-round button_plan"
                [ngStyle]="{'background-color': camera.plan_details[0].color}">
                <span class="big-name">{{ camera.plan_details[0].alias + ' dias' }}
                  <i *ngIf="!(is_partner || is_partner_client || isAssociate)" class="priceStyle">R${{ camera.plan_details[0].price.toString() }}
                  </i>
                </span>
              </button>
            </form>
          </td>
          <!-- Plano -->
          <td class="lt-md:hidden plan-width">
            <span [class.deleting]="camera.deleted" [class.offline]="!camera.active" *ngIf="camera.plan_details">
              {{'Câmera ' + camera.plan_details[0].res }} <span class="days">{{camera.plan_details[0].alias + ' dias'}}</span>
            </span>
            <span class="tooltip_without_flag" (mouseenter)="openTooltipHelp(camera.id)" (mouseleave)="closeTooltipHelp(camera.id)"
              *ngIf="(camera.status == false && camera.minutes_inactive >= 5) || (camera.created_off && !camera.status)">
              <i nz-icon nzType="exclamation-circle" nzTheme="fill" style="vertical-align: 0.1em !important;"></i>
              Off-line
            </span>
            <div class="tooltiptext" [id]="'tooltip-' + camera.id" (mouseenter)="openTooltipHelp(camera.id)" (mouseleave)="closeTooltipHelp(camera.id)">
              <ng-container *ngIf="!camera.created_off">
                <span class="tooltiptextsoft">Sua câmera está off-line, caso queira <b>exclui-lá</b> do WeCloud acesse
                  o passo a passo de exclusão.</span>
                <br><br>
                <button *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate)"
                  class="btn btn-primary stock-button" (click)="deleteCamera(camera)">Excluir câmera</button>
              </ng-container>
              <ng-container *ngIf="camera.created_off">
                <span class="tooltiptextsoft">Sua câmera ainda não estabeleceu conexão com o WeCloud</span>
              </ng-container>
            </div>
            <div [hidden]="!hasGroups" *ngFor="let group of camera.camera_group | slice:0:1;" class="group-list">
              <span [ngClass]="group.type+'-label'"  [class.offline]="!camera.active || camera.created_off">
                <i nz-icon [nzType]="group.type == 'public' ? 'global' : 'user'"
                  nzTheme="outline" style="margin-right: 12px;"></i>{{group.name}}</span>
              <span class="groupLength" *ngIf="camera.camera_group.length > 1" (click)="openTooltipGroup(camera)"
                [class.offline]="!camera.active || camera.created_off">
                +{{camera.camera_group.length - 1}} <i nz-icon [nzType]="camera.show_groups ? 'up' : 'down'"
                  nzTheme="outline" style="font-size: 12px;"></i>
              </span>
              <div [hidden]="!camera.show_groups" class="tooltiptext-group" [id]="'tooltip-group-' + camera.id">
                <ng-container *ngFor="let group of camera.camera_group | slice:1">
                  <span [ngClass]="group.type+'-label'" class="span-group" [class.offline]="!camera.active || camera.created_off">
                    <i nz-icon [nzType]="group.type == 'public' ? 'global' : 'user'" nzTheme="outline"
                      style="margin-right: 12px;"></i>{{group.name}}</span>
                </ng-container>
              </div>
            </div>
          </td>
          <!-- Analíticos -->
          <td [class.offline]="!camera.active || camera.created_off" class="lt-md:hidden"
            [hidden]="(!camera.active && !(is_partner || isAssociate)) || partnerDashboard">
            <div *ngIf="camera.integrated_camera" class="integrated">Analítico<br>integrado</div>
            <div *ngIf="!camera.deleted && !camera.integrated_camera" class="analiticos-container center-item">
              <ng-container *ngIf="camera.analytics; else noAnalitics">
                <span (click)="openModalAnalytics(camera)" class="cursor_choice">{{camera.analytics}}</span>
              </ng-container>
              <ng-template #noAnalitics>
                <span (click)="openModalAnalytics(camera)" class="cursor_choice">0</span>
              </ng-template>
              <div class="button-container">
                <button [disabled]="!camera.active" (click)="openModalAnalytics(camera)" mat-raised-button
                  class="btn btn-sm btn-just-icon style-icon-add button_add_analytics"
                  [class.disabled_cam]="camera.analytic_status && camera.analytic_status == 'disabled'">
                  <scan *ngIf="camera.analytic_status == 'disabled'" nz-icon nzType="exclamation" nzTheme="outline" nz-tooltip
                        nzTooltipTitle="Detecções desativadas por excesso de alertas, edite o(s) analítico(s) para voltar a receber alertas"
                        nzTooltipOverlayClassName="camera-actived-date" class="icon-table"></scan>
                  <scan *ngIf="camera.analytic_status != 'disabled' && !camera.is_processing_analytic"
                        nz-icon nzType="plus" nzTheme="outline"  class="icon-table"></scan>
                  <scan *ngIf="camera.analytic_status != 'disabled' && camera.is_processing_analytic"
                      nz-icon nzType="loading" nzTheme="outline" class="icon-table"></scan>
                </button>
                <img class="test" *ngIf="camera.is_testing_analytics" src="../../../assets/img/Icons/testanalytic.svg" alt="">
              </div>
            </div>
          </td>
          <!-- Convidados -->
          <td [class.offline]="(!camera.active && !camera.deleted) || camera.created_off" class="lt-md:hidden"
            [hidden]="(!camera.active && !(is_partner || isAssociate)) || partnerDashboard">
            <div class="label-partner" *ngIf="camera.deleted && (is_partner || isAssociate)">
              <i class="camera-spinner-partner" *ngIf="camera.deleted" nz-icon nzType="loading" nzTheme="outline"></i>
              <span class="text-deleting">Câmera sendo apagada </span>
            </div>
            <div *ngIf="!camera.deleted" class="analiticos-container center-item">
              <span (click)="openModalInviteUser(camera, index)" class="cursor_choice">{{camera.guests}}</span>
              <button [disabled]="!camera.active" (click)="openModalInviteUser(camera, index)" mat-raised-button
                class="btn btn-sm btn-just-icon style-icon-add button_add_analytics">
                <span nz-icon nzType="plus" nzTheme="outline"></span>
              </button>
            </div>
          </td>
          <!-- Cliente -->
          <td class="lt-md:hidden" [class.offline]="!camera.active" [hidden]="!partnerDashboard">
            <div class="name_cam_edit">
              <span>{{camera.owner}}</span>
            </div>
          </td>
          <!-- Bloqueio -->
          <td [hidden]="generalList">
            <div class="flex center-item">
              <nz-switch [nzDisabled]="!(!is_guest && (!isAssociate || adminAssociate || adminClientAssociate))" [nzControl]="true"
                        [nzLoading]="loadingUpdateActive[index]" [ngModel]="!camera.active" (click)="updateActive(camera, index)"
                        class="gt-xs:onoffswitch xs:onoffswitch-small status_style"></nz-switch>
              <span *ngIf="is_partner && camera.active == false" 
                    class="info-blocked-icon clickable" nz-icon nzType="exclamation-circle" nzTheme="outline" 
                    nz-tooltip [nzTooltipTitle]="camera.observation" nzTooltipOverlayClassName="camera_playlist_processing"
                    nzTooltipPlacement="top" [nzTooltipOverlayStyle]="{'margim-bottom': '12px'}"></span>
              <div class="gt-sm:hidden md:hidden" id="tabletTrash">
                <button (click)="openModalCameraUpdate(camera)" mat-raised-button
                  class="btn btn-sm btn-just-icon btn-round cancel-exclude button_exclude_mobile">
                  <i class="material-icons" nz-icon nzType="setting" nzTheme="outline"></i>
                </button>
              </div>
            </div>
            <div class="row gt-sm:hidden md:hidden" id="desktopTrash">
              <button (click)="openModalCameraUpdate(camera)" mat-raised-button
                class="btn btn-sm btn-just-icon btn-round cancel-exclude">
                <i class="material-icons" nz-icon nzType="setting" nzTheme="outline"></i>
              </button>
            </div>
          </td>
          <!-- Ações -->
          <td class="lt-md:hidden cam-acoes"
            [hidden]="!camera.active && !(is_partner || isAssociate)">
            <button *ngIf="!camera.deleted && (!camera.integrated_camera || camera.integrated_camera.status_camera_integrated == 'running'
                    || camera.integrated_camera.status_camera_integrated == 'processing')"
              (click)="openModalCameraUpdate(camera)" class="btn btn-just-icon btn-round btn-settings">
              <i nz-icon nzType="setting" nzTheme="outline"></i>
            </button>
            <div *ngIf="camera.integrated_camera && (camera.integrated_camera.status_camera_integrated == 'warning' || camera.integrated_camera.status_camera_integrated == 'disabled')"  
              nzTheme="outline" nz-tooltip nzTooltipTitle="Foram encontrados problemas em relação a integração dos analíticos. Revise as configurações."
              nzTooltipOverlayClassName="camera_playlist_processing integrated" nzTooltipPlacement="top">
              <button *ngIf="!camera.deleted" (click)="openModalCameraUpdate(camera)"
              class="btn btn-just-icon btn-round btn-settings">
              <i nz-icon nzType="setting" nzTheme="outline"></i></button>
              <span class="integrated-info" nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
            </div>
          </td>
          <!-- Câmera bloqueada -->
          <td [colSpan]="(camera.active) ? '0' : '5'" class="lt-md:hidden xs:block" [hidden]="!generalList">
            <div class="card-bloqueado" *ngIf="!camera.active">
              <div class="deleting-label" *ngIf="camera.deleted && !(is_partner || isAssociate)">
                <i class="camera-spinner" nz-icon nzType="loading" nzTheme="outline"></i>
                <span class="text-deleting">Câmera sendo apagada </span>
              </div>
              <p class="card-title" *ngIf="!camera.deleted" style="text-align: center;">Câmera bloqueada</p>
              <p class="card-text" *ngIf="!camera.deleted" style="text-align: center;">Entre em contato com seu
                fornecedor para reativar a sua câmera</p>
            </div>
          </td>
          <!-- 
            <ng-container matColumnDef="timestamp" class="action_style">
            <th class="lt-md:hidden" mat-header-cell *matHeaderCellDef></th>
            <td [class.offline]="!camera.active" class="action_style" mat-cell *matCellDef="let camera">
              {{camera.last_modify}}
          </ng-container>
            -->
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<!-- Componente responsável por aparecer quando não houver câmeras cadastradas -->
<app-feedback imageUrl="nocamera.png" type="câmeras" [noCameras]="true" [hidden]="filtersOn() || hasCameras || hasGroups || loadingCameraList || (dashboardList && (hasCameras || loadingDashCameras))"></app-feedback>
<app-feedback [noFound]="true" imageUrl="nofound.png" [transparent]="true" *ngIf="filtersOn() && !hasCameras && !loadingDashCameras"></app-feedback>
<ng-template #loadingCameraTemplate>
  <div class="loadingCamera" style="margin-top: 60px;">
      <span nz-icon nzType="loading" nzTheme="outline"></span>
  </div>
</ng-template>
<app-camera-invite (loadingCameraList)="loadingEvent($event)"></app-camera-invite>
<app-camera-edit (loadingCameraList)="loadingEvent($event)"></app-camera-edit>
<app-analytics-controller [analytics_available]="analytics_available" (loadingCameraList)="loadingEvent($event)" (exitModal)="callUserInventory()"></app-analytics-controller>
<app-modal></app-modal>
