export enum OperationStatus {
    Unloaded,
    Loading,
    Success,
    Fail
}

export interface Feedback {
    model: 'feedback';
    success?: boolean;
    message?: string;
    final?: boolean;
    status: OperationStatus;
    data?: any[];
    statusCode?: any;
}


export interface ResultAlert {
    dismiss?: any;
    value?: boolean;
    isConfirmed?: boolean;
    isDenied?: boolean;
    isDismissed?: boolean;
    methodCalled?: string;
    parameterValue?: string;
}


export interface ModalData {
    data: any;
    tipoModal: string;
    modalHeight: string;
    modalWidth: string;
    backdropClass?: string;
}

export class TypesModals {
    readonly editPayments = 'editar_pagamentos';
    readonly manageCameras = 'gerenciar_cameras';
    readonly videoDetections = 'video_alertas';
    readonly editPaymentMethod = 'editar_metodo_pagamento';
    readonly addPartner = 'adicionar_parceiro';
    readonly addDetections = 'adicionar_camera_deteccoes';
    readonly addKits = 'adicionar_kits';
    readonly addClient = 'adicionar_cliente';
    readonly editClient = 'editar_cliente';
    readonly addKitMaster = 'add_kit_master';
    readonly requestKit = 'solicitar_kit';
    readonly deleteRTMP = 'delete_rtmp';
    readonly editKit = 'edit_kit';
    readonly addGroupCamera = 'adicionar_grupo';
    readonly configGroupCamera = 'configurar_grupo';
    readonly addInfoGroupCamera = 'adicionar_info_grupo';
    readonly viewLiveCamera = 'live_camera';
    readonly inviteCamGuests = 'adicionar_cameras_convidados';
    readonly viewGLPI = 'modal_glpi';
    readonly configHolidayCalendar = 'configurar_calendario';
    readonly createHolidayCalendar = 'criar_calendario';
    readonly allHolidayCalendar = 'listar_calendarios';
}

export class BlobImage {
    constructor(
        public url: string,
        public data: Blob,
        public mimeType?: string,
        public tag?: string,
        public width?: string,
        public height?: string
    ) {}
}

export class Base64Image {
    constructor(
        public url: string,
        public data: string,
        public mimeType?: string,
        public tag?: string,
        public width?: string,
        public height?: string
    ) {}
}
