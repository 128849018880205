import Swal from 'sweetalert2';
import { ResultAlert } from '../models';
import { showAlert } from '../Helpers/showAlert';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
export class ValidationResponseLogin {
    resultAlert: ResultAlert;
    constructor() {
    }

    swalDanger(title: string) {
        showAlert(title, 'danger');
    }

    swalWarning(title: string) {
        showAlert(title, 'warning');
    }

    swalSuccess(title: string) {
        showAlert(title, 'success');
    }

    login_message_400(title: string, swalParameters: any) {
        showAlert(swalParameters, 'danger');
    }

    authentication_error_400(title: string, swalParameters: any) {
        Swal.fire({
            title: title['title'],
            text: swalParameters,
            icon: 'error'
        });
    }

    authentication_logout_error_400(title: string, swalParameters: any) {
        Swal.fire({
            title: title['title'],
            text: swalParameters,
            icon: 'warning'
        });
    }


}
