import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzUploadModule } from 'ng-zorro-antd/upload';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NzUploadModule
    ]
})
export class GlpiModule { }
