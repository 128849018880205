<!-- Primeira linha - Mapa -->
<div class="flex" style="max-height: 70vh;">
    <div class="col-span-5 map_style">
        <div id="map" class="map_size"></div>
        <div *ngIf="!mapShow" class="mapSpinner">
            <span nz-icon  nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
        </div>
    </div>
    <ng-template #spinnerSm>
        <div class="col-4 searchLoading">
            <span nz-icon  nzType="loading" nzTheme="outline" style="font-size: 36px"></span>
        </div>
    </ng-template>
    <div class="w-[60%] container_address">
        <form [formGroup]="addressForm">
            <!-- Primeira linha: CEP, BUSCAR -->
            <div class="input-row">
                <div class="input-group cep">
                    <span class="input-legend">CEP:</span>
                    <input type="text" class="input-container" formControlName="CEP" mask="00000-000" showMaskTyped="true" shownMaskExpression="_____-___">
                    <mat-error>
                        <span *ngIf="addressForm.get('CEP').hasError('required') && addressForm.get('CEP').touched">
                            O campo CEP é <strong>obrigatório</strong>
                        </span>
                    </mat-error>
                </div>
                <div class="search_cep">
                    <span (click)="getAddress()" class="get-cep"><i nz-icon nzType="search" nzTheme="outline"></i>Buscar</span>
                </div>
            </div>
            <!-- Segunda linha: ENDEREÇO -->
            <div class="input-row">
                <div class="input-group endereco">
                    <span class="input-legend">Endereço:</span>
                    <input type="text" class="input-container" formControlName="street">
                    <mat-error>
                        <span *ngIf="addressForm.get('street').hasError('required') && addressForm.get('street').touched">
                            O campo endereço é <strong>obrigatório</strong>
                        </span>
                    </mat-error>
                </div>
            </div>
            <!-- Terceira linha: NUMERO, BAIRRO -->
            <div class="input-row">
                <div class="input-group numero">
                    <span class="input-legend">Número:</span>
                    <input class="input-container" formControlName="num" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        maxlength="5">
                    <div style="width: 1px; height: 22px; display: block;" *ngIf="addressForm.get('bairro').hasError('required') && addressForm.get('bairro').touched"></div>
                </div>
                <div class="input-group bairro">
                    <span class="input-legend">Bairro:</span>
                    <input type="text" class="input-container" formControlName="bairro">
                    <mat-error>
                        <span *ngIf="addressForm.get('bairro').hasError('required') && addressForm.get('bairro').touched">
                            O campo bairro é <strong>obrigatório</strong>
                        </span>
                    </mat-error>
                </div>
            </div>
            <!-- Quarta linha: CIDADE, ESTADO -->
            <div class="input-row">
                <div class="input-group cidade">
                    <span class="input-legend">Cidade:</span>
                    <input type="text" class="input-container" formControlName="cidade">
                    <mat-error>
                        <span *ngIf="addressForm.get('cidade').hasError('required') && addressForm.get('cidade').touched">
                            O campo cidade é <strong>obrigatório</strong>
                        </span>
                    </mat-error>
                </div>
                <div class="input-group estado">
                    <span class="input-legend">Estado:</span>
                    <input type="text" class="input-container" formControlName="estado">
                    <mat-error>
                        <span *ngIf="addressForm.get('estado').hasError('required') && addressForm.get('estado').touched">
                            O campo estado é <strong>obrigatório</strong>
                        </span>
                    </mat-error>
                </div>
            </div>
            <!-- Quinta linha: CONFIRMA, CANCELA -->
            <div class="button-row">
                <button type="button" class="btn btn-md tamButton button_cancel_camera"
                (click)="closeModal()"><span>Cancelar</span></button>
                <button type="button" class="btn btn-md btn-primary tamButton button_save_permission"
                [disabled]="mapShow && (!save_changes || loading)" (click)="saveChanges()">
                <span>Salvar Alterações</span><i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loading"></i></button>
            </div>
        </form>
    </div>
</div>
