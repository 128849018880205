import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter/filter.component';

interface Simple {
    id: number | string;
    text: string;
}

interface MultiWeight {
    id: number | string;
    title: string;
    description: string;
}

@Component({
    selector: 'app-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss'],
})

export class FilterListComponent implements OnInit {

    //Lista de opções
    @Input() list: Simple[];
    //Elemento pre-selecionado (funciona apenas com type = 'simple')
    @Input() selected: number | string;
    //Elementos pre-selecionado (funciona apenas com type = 'multiselect')
    @Input() selectedList: number[];
    //Tipo do select
    @Input() type: 'simple' | 'multiselect';
    //Texto de placeholder
    @Input() placeholder: string;
    //Indica se o filtro está bloqueado
    @Input() blocked: boolean;
    //Indica se há seleção de todos, juntamente com o texto que será usado
    @Input() selectAll: string;
    //Indica icone da seleção de todos
    @Input() selectAllIcon: string;
    //Indica alterações no estilo
    @Input() styles: string[];
    //Indica se o filtro precisa de pesquisa
    @Input() search: boolean;

    @Output() filterListStatusChange = new EventEmitter<(number | string)[]>();

    selection: Simple[];
    hasSelectAll: boolean;

    constructor() { }

    ngOnInit(): void {
        if (this.selectAll) {
            this.hasSelectAll = true;
        }
    }

    changeList(event) {
        this.selection = this.list.filter(element => event.includes(element.id));
        this.filterListStatusChange.emit(event);
    }

    removeItem(element: Simple, filter: FilterComponent) {
        filter.remove(element.id);
    }

    markAll(filter: FilterComponent) {
        if (!this.blocked) {
            filter.markAll();
        }
    }

    unmarkAll(filter: FilterComponent) {
        if (!this.blocked) {
            filter.unmarkAll();
        }
    }
}
