import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor() { }

    pageHit(pagePath: string)
    {
        gtag('event', 'page_view', {
            page_path: pagePath
        });
    }

    eventEmitter(eventName: string, eventCategory?: string, eventLabel?: string, eventValue?: number)
    {
        gtag('event', eventName, {
            ...(eventCategory != null && {event_category: eventCategory}),
            ...(eventLabel != null && {event_label: eventLabel}),
            ...(eventValue != null && {value: eventValue})
        });
    }
}
