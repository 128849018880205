import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { Kit, UserInventory } from '../models';
import { UserActions } from '../Services/actions';

export interface KitsElements {
    kit: string;
    amount: number;
    id: number;
}

@Component({
    selector: 'app-user-kit-request',
    templateUrl: './user-kit-request.component.html',
    styleUrls: ['./user-kit-request.component.scss']
})
export class UserKitRequestComponent implements OnInit, OnDestroy {
    readonly createTicketKit$ = this.store.select((state: AppState) => state.Users.createTicketKit);
    @Output() modalClose = new EventEmitter<string>();

    createTicketKitSub: Subscription;

    ticketForm = new UntypedFormGroup({
        kitAmount: new UntypedFormControl('', [Validators.required]),
        observation: new UntypedFormControl('', [Validators.required])
    });

    elementsKits: KitsElements[] = [
        { kit: '', amount: 0, id: 1 },
    ];
    optionsKits: any;

    newKitsElement: KitsElements = {
        kit: '',
        amount: 0,
        id: 2
    };

    public kitsValues: any = [];

    inventories: UserInventory;
    kitsList = [];
    kit: Kit;
    bodyTicket: any = [];
    loadingSubmit = false;
    manyKits = false;
    saveKit = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,  private router: Router, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.manyKits = !!this.data.data.manyKits;
        if (this.manyKits) {
            this.inventories = this.data.data.inventories;
            this.kitsList = this.data.data.inventories.kits;
            this.optionsKits = this.processList(this.filterList());
        } else {
            this.kit = this.data.data.kit;
            this.inventories = this.data.data.inventories;
        }
        this.ticketForm.valueChanges.subscribe(value => {
            if (this.manyKits) {
                this.saveKit = (this.manyKits && this.elementsKits.length > 0 && this.kitsFulled());
            } else {
                this.saveKit = this.ticketForm.valid;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.createTicketKitSub) {
            this.createTicketKitSub.unsubscribe();
        }
        this.elementsKits = [{kit: '', amount: 0, id: 1}];
        this.store.dispatch(UserActions.create_ticket_kit_cleanup());
        this.closeModal();
    }

    closeModal() {
        this.modalClose.emit();
        this.redirect();
    }

    removeKit(id) {
        this.elementsKits = this.elementsKits.filter(d => d.id !== id);
    }

    newKits() {
        const newKit = { kit: this.newKitsElement.kit, amount: this.newKitsElement.amount, id: this.newKitsElement.id };
        this.elementsKits = [...this.elementsKits, newKit];
        this.newKitsElement.id = this.elementsKits.length + 1;
        this.saveKit = false;
    }

    redirect() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/cameras/stock']);
    }

    createTicket() {
        this.loadingSubmit = true;
        const kitTicket: any = [];

        if (this.manyKits && this.elementsKits.length > 0) {
            this.elementsKits.map((value) => {
                if (value && value.amount > 0 && value.kit) {
                    kitTicket.push({
                        kit_id: value.kit,
                        kit_amount: value.amount,
                        observation: this.ticketForm.get('observation').value
                    });
                }
            });
        } else {
            if (this.ticketForm.valid && this.kit.id) {
                this.bodyTicket = [{
                    kit_id: this.kit.id,
                    kit_amount: this.ticketForm.get('kitAmount').value,
                    observation: this.ticketForm.get('observation').value
                }];
            } else {
                this.bodyTicket = null;
            }
        }

        if (this.bodyTicket.length > 0 || kitTicket.length > 0) {
            this.store.dispatch(UserActions.create_ticket_kit({
                sub: localStorage.getItem('sub'),
                bodyTicket: !this.manyKits ? this.bodyTicket : kitTicket
            }));
        } else {
            this.loadingSubmit = false;
            this.validationResponse.validationResponseHandler(400, 'user', 'create-ticket-fail', 'Preencha as informações completamente.');
        }
        this.createTicketResult();
    }

    createTicketResult() {
        this.createTicketKitSub = this.createTicketKit$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'create-ticket', result.message);
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'create-ticket-fail', result.message);
                }
            }
        });
    }

    processList(list) {
        return list.map(element => {
            const d = `${element.cameras_amount} ${this.processPlural('câmera', element.cameras_amount)} / ${element.analytics_amount} ${this.processPlural('analítico', element.analytics_amount)}`;
            return {
                id: element.id,
                title: `Câmera ${element.plan.resolution.alias} - ${element.plan.days_storage} ${this.processPlural('dia', element.plan.days_storage)}`,
                description: d
            };
        });
    }

    processPlural(text, number) {
        return text + (number > 1 ? 's' : '');
    }

    changeKit(event, i) {
        this.elementsKits[i].kit = event[0];
        this.optionsKits = this.optionsKits.filter(k => k.id != event[0]);
        this.saveKit = (this.manyKits && this.elementsKits.length > 0 && this.kitsFulled());
    }

    changeAmount(event, i) {
        this.elementsKits[i].amount = event;
        this.saveKit = (this.manyKits && this.elementsKits.length > 0 && this.kitsFulled());
    }

    filterList() {
    //CRIA ARRAY COM TODOS OS KITS PRESENTES NA TABELA
        const kitsOnTable = this.elementsKits.map(element => element.kit);
        //RETORNO A LISTA FILTRADA, REMOVENDO OS KITS DA TABELA
        return this.kitsList.filter(element => {
            return !kitsOnTable.some((kit) => { return kit == element.id; });
        });
    }

    kitsFulled() {
        return !this.elementsKits.find(k => k.kit == '' || k.amount == 0);
    }
}
