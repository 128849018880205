//Função que desenha uma linha ou polígono na tela com a cor escolhida
//Parâmetros:
/*
    ctx: Contexto do Canvas 2D
    analyticColor: Cor de preenchimento e stroke
    points: array de pontos x,y que correspondem às coordenadas de cada ponto do polígono ou linha
 */

export function colorHexToRgb(selectColor: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(selectColor);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function drawOnCanvas(ctx: CanvasRenderingContext2D, analyticColor: string, points: { x: number; y: number }[], drawType: string) {
    if (!ctx) {
        return;
    }
    ctx.clearRect(0, 0, 640, 480);
    ctx.fillStyle = 'rgb(255,255,255)';
    ctx.lineWidth = 1;
    ctx.lineCap = 'round';
    ctx.strokeStyle = analyticColor;

    if (points.length == 1) {
        ctx.fillRect(points[0].x - 2, points[0].y - 2, 4, 4);
    }
    else if (points.length >= 2) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 0; i < points.length - 1; i++) {
            ctx.fillRect(points[i].x - 2, points[i].y - 2, 4, 4);
            ctx.strokeRect(points[i].x - 2, points[i].y - 2, 4, 4);
            ctx.fillRect(points[i + 1].x - 2, points[i + 1].y - 2, 4, 4);
            ctx.strokeRect(points[i + 1].x - 2, points[i + 1].y - 2, 4, 4);
            ctx.lineTo(points[i + 1].x, points[i + 1].y);
        }

        if ((points.length > 2 && drawType == 'polygon') ||
            (points.length == 4 && drawType == 'rectangle')) {
            ctx.lineTo(points[0].x, points[0].y);
        }

        ctx.closePath();
        const fillColor = colorHexToRgb(analyticColor);
        ctx.fillStyle = 'rgba(' + fillColor.r + ',' + fillColor.g + ',' + fillColor.b + ',0.3)';
        ctx.fill();
        ctx.stroke();
    }
}

export function shadowOnCanvas(ctxShadow: CanvasRenderingContext2D, points: { x: number; y: number }[]) {
    // ctxFont.clearRect(0, 0, 640, 480);
    ctxShadow.beginPath();
    const style = colorHexToRgb('#002D59');
    ctxShadow.fillStyle = `rgb(${style.r},${style.g},${style.b}, 0.6)`;

    if (points.length == 1) {
        // Shadow to A
        ctxShadow.arc(points[0].x, points[0].y + 45, 10, 0, 2 * Math.PI, false);
        ctxShadow.fill();
    } else {
        const pointA = points[0];
        const pointB = points[1];
        // Shadow to A
        ctxShadow.arc(pointA.x, pointA.y - 5, 10, 0, 2 * Math.PI, false);
        ctxShadow.fill();
        ctxShadow.stroke();

        // Shadow to B
        ctxShadow.beginPath();
        ctxShadow.arc(pointB.x, pointB.y - 6, 10, 0, 2 * Math.PI, false);
        ctxShadow.fill();
    }

    ctxShadow.stroke();
}

export function writingOnCanvas(ctxShadow: CanvasRenderingContext2D, ctxFont: CanvasRenderingContext2D, analyticColor: string, points: { x: number; y: number }[]) {
    // ctxFont.clearRect(0, 0, 640, 480);
    //tamanho dos pontos
    const dis = points.length == 1 ? 0 : Math.sqrt(Math.pow(points[0].x - points[1].x, 2) + Math.pow(points[0].y - points[1].y, 2));
    const alfa = points.length == 1 ? 1 : Math.atan(Math.abs(points[0].y - points[1].y) / Math.abs(points[0].x - points[1].x));
    const diffByAlfa = (alfa > 0.8 && alfa <= 1.1 ? 20 : 0);
    const midX = points.length == 1 ? points[0].x : ((points[0].x + points[1].x) / 2);
    const midY = points.length == 1 ? points[0].y : ((points[0].y + points[1].y) / 2);
    const pointA = points.length == 1 ? points[0] : { x: midX - ((dis / 620) * 100) - diffByAlfa, y: midY - ((dis / 465) * 100)};
    const pointB = { x: midX + ((dis / 620) * 100) + diffByAlfa, y: midY + ((dis / 465) * 100)};
    shadowOnCanvas(ctxShadow, points.length == 1 ? [pointA] : [pointA, pointB]);
    const style = colorHexToRgb(analyticColor);

    ctxFont.font = '16px Open Sans';
    ctxFont.textAlign = 'center';
    ctxFont.fillStyle = `rgb(${style.r},${style.g},${style.b})`;

    if (points.length == 1) {
        ctxFont.fillText('A', points[0].x, points[0].y + 50);
    } else {
        // ctx.clearRect(0, 0, 640, 480);
        // ctxFont.clearRect(0, 0, 640, 480);
        ctxFont.fillText('B', pointA.x, pointA.y);
        ctxFont.fillText('A', pointB.x, pointB.y);
    }

    ctxFont.stroke();
}
