import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ResultAlert } from 'app/Shared/models';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { AddInfoGroupData, ClientsToGroup } from '../models';
import { CameraActions } from '../Services/actions';

@Component({
    selector: 'app-add-info-modal',
    templateUrl: './modal-add-info-group.component.html',
    styleUrls: ['./add-info-group.component.scss']
})
export class AddInfoGroupModalComponent implements OnInit, OnDestroy {
    @Input() data: AddInfoGroupData;
    @Output() modalClose = new EventEmitter<string>();

    readonly clientsGroup$ = this.store.select((state: AppState) => state.camera.clientsGroup);
    readonly updateCameraGroup$ = this.store.select((state: AppState) => state.camera.updateCameraGroup);

    clientGroupSub: Subscription;
    updateCameraGroupSub: Subscription;
    swalSub: Subscription;

    groupForm = new UntypedFormGroup({
        list: new UntypedFormControl('', [Validators.required])
    });

    pageName: string;
    mode: string;
    group_name: string;
    simpleList: {
        id: number|string;
        text: string;
    }[];

    selectedIds: number[];

    loading: boolean;
    loadingSubmit: boolean;

    constructor(private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule,
        private sharedService: SharedService) {}

    ngOnInit(): void {
        this.pageName = 'cameras-group';
        this.mode = this.data.mode;
        this.group_name = this.data.name;

        switch (this.data.mode){
            case 'cameras':
                this.mode = 'câmeras';
                this.selectedIds = this.data.camera_list;
                break;
            case 'associates':
                this.mode = 'convidados';
                this.selectedIds = this.data.associate_list;
                break;
            case 'clients':
                this.mode = 'clientes';
                this.selectedIds = this.data.client_list;
                break;
        }

        if (!this.selectedIds) {
            this.selectedIds = [];
        }
        this.getFilterData();
    }

    ngOnDestroy(): void {
        if (this.clientGroupSub) {
            this.clientGroupSub.unsubscribe();
        }
        if (this.updateCameraGroupSub) {
            this.updateCameraGroupSub.unsubscribe();
        }
        if (this.swalSub) {
            this.swalSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.update_camera_group_clear());
    }

    closeModal() {
        this.modalClose.emit();
    }

    getFilterData() {
        this.loading = true;
        this.store.dispatch(CameraActions.get_clients_group({
            user_sub: localStorage.getItem('sub')
        }));

        this.clientGroupSub = this.clientsGroup$.subscribe(result => {
            if (result && result.length > 0) {
                this.simpleList = this.selectContextData(result).filter(element => !this.selectedIds.includes(element.id));
                if (this.simpleList.length > 0) {
                    this.loading = false;
                } else {
                    this.validationResponse.validationResponseHandler(204, this.pageName, 'no-item', this.mode);
                    this.swalResponseValidation();
                }
            }
        });
    }

    swalResponseValidation() {
        this.swalSub = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'confirmNoItem') {
            this.closeModal();
        }
    }

    selectContextData(result: ClientsToGroup[]) {
        let simple = [];
        switch (this.mode) {
            case 'câmeras':
                result.forEach(client => {
                    if (this.data.client_list.includes(client.client_id)) {
                        simple.push(...client.cameras.map(camera => {
                            return {
                                id: camera.id,
                                text: camera.name
                            };
                        }));
                    }
                });
                break;
            case 'convidados':
                result.forEach(client => {
                    if (this.data.client_list.includes(client.client_id)) {
                        simple.push(...client.associates.map(associate => {
                            return {
                                id: associate.id,
                                text: associate.name
                            };
                        }));
                    }
                });
                break;
            case 'clientes':
                simple = result.map(element => {
                    return {
                        id: element.client_id,
                        text: element.client_name
                    };
                });
                break;
        }
        return simple;
    }

    changeFilter(event) {
        this.groupForm.get('list').setValue(event);
    }

    addToGroup() {
        if (this.groupForm.get('list').invalid) {
            this.groupForm.get('list').markAsTouched();
            return false;
        }
        this.loadingSubmit = true;

        const payload = {
            nameCameraGroup: this.group_name,
            user_sub: localStorage.getItem('sub'),
            group_id: this.data.id,
            listAddCameras: this.mode === 'câmeras' ? this.groupForm.get('list').value : [],
            listAddAssociates: this.mode === 'convidados' ? this.groupForm.get('list').value : [],
            listAddClients: this.mode === 'clientes' ? this.groupForm.get('list').value : [],
        };

        this.store.dispatch(CameraActions.update_camera_group(payload));

        this.updateCameraGroupSub = this.updateCameraGroup$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete-item-error', 'camerasGroups.update_error');
                }
            }
        });
    }
}
