import { Injectable } from '@angular/core';
import { HandleError } from 'app/Shared/Helpers/HandleError';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionPayload } from '../models';
import { DetectionAction, DetectionActions, Metadata } from './actions';
import { DetectionService } from './detection.service';

@Injectable()
export class DetectionEffects {
    constructor(private service: DetectionService, private actions: Actions, private error: HandleError) { }

    getDetections$ = createEffect(() =>
        this.actions.pipe(
            ofType(DetectionActions.GET_DETECTIONS),
            mergeMap((action: Metadata) => this.service.getDetections(action.userSub, action.child_sub, action.current_data,
                action.list_cameras, action.analytics_type, action.page, action.limit, action.recording_filter, action.group_id).pipe(
                map(result => {
                    return DetectionActions.get_detections_return({ payload: { result: result } as ActionPayload });
                }),
                catchError(this.error.handleError<DetectionAction>('getDetections', DetectionActions.get_detections_return({
                    payload: {
                        result: {
                            model: 'feedback',
                            success: false,
                            message: 'Falha de rede',
                            status: OperationStatus.Fail
                        } as Feedback,
                    }
                }),
                DetectionActions.get_detections(action)))
            )
            )
        )
    );

    reviewAlert$ = createEffect(() =>
        this.actions.pipe(
            ofType(DetectionActions.REVIEW_ALERT),
            mergeMap((action: Metadata) => this.service.reviewAlert(action.userSub, action.id_alert, action.evaluation, action.comment).pipe(
                map(result => {
                    return DetectionActions.review_alerts_return({ payload: { result: result } as ActionPayload });
                }),
                catchError(this.error.handleError<DetectionAction>('reviewAlert', DetectionActions.review_alerts_return({
                    payload: {
                        result: {
                            model: 'feedback',
                            success: false,
                            message: 'Falha de rede',
                            status: OperationStatus.Fail
                        } as Feedback,
                    }
                }),
                DetectionActions.delete_alerts(action)))
            )
            )
        )
    );

    deleteAlerts$ = createEffect(() =>
        this.actions.pipe(
            ofType(DetectionActions.DELETE_ALERTS),
            mergeMap((action: Metadata) => this.service.deleteAlerts(action.userSub, action.list_alert).pipe(
                map(result => {
                    return DetectionActions.delete_alerts_return({ payload: { result: result } as ActionPayload });
                }),
                catchError(this.error.handleError<DetectionAction>('deleteAlert', DetectionActions.delete_alerts_return({
                    payload: {
                        result: {
                            model: 'feedback',
                            success: false,
                            message: 'Falha de rede',
                            status: OperationStatus.Fail
                        } as Feedback,
                    }
                }),
                DetectionActions.delete_alerts(action)))
            )
            )
        )
    );
}
