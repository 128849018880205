import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { NgModule } from '@angular/core';
import { ConfirmRegisterComponent } from './confirm-register/confirm-register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MapsComponent } from 'app/maps/maps.component';
import { AuthGuard } from 'app/Shared/Guards/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { LoggedGuard } from 'app/Shared/Guards/logged.guard';
import { ConfirmGuard } from 'app/Shared/Guards/confirm.guard';

export const loginRoutes: Routes = [
    {
        path: 'login/signin',
        component: LoginComponent,
        canActivate: [LoggedGuard]
    },
    {
        path: 'login/register',
        component: RegisterComponent,
        canActivate: [LoggedGuard]
    },
    {
        path: 'login/confirm',
        component: ConfirmRegisterComponent,
        canActivate: [ConfirmGuard]
    },
    {
        path: 'login/forgot',
        component: ForgotPasswordComponent,
        canActivate: [LoggedGuard]
    },
    {
        path: 'login/logout',
        component: LogoutComponent,
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'maps/home',
    //     component: MapsComponent,
    //     canActivate: [AuthGuard]
    // }
];

@NgModule({
    imports: [
        RouterModule.forRoot(loginRoutes, { useHash: true })
    ],
    exports: [
        RouterModule
    ]
})
export class LoginRoutingModule { }
