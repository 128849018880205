import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Plan } from 'app/cameras/models';
import { CameraActions } from 'app/cameras/Services/actions';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Kit, KitAmount, UserInventory } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-kit-list',
    templateUrl: './user-kit-list.component.html',
    styleUrls: ['./user-kit-list.component.scss']
})
export class UserKitListComponent implements OnInit, OnDestroy {
    readonly userInventory$ = this.store.select((state: AppState) => state.Users.userInventory);
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);

    userInventory: Subscription;
    getPlan: Subscription;
    inventories: UserInventory;
    inventoryPartner: UserInventory;
    plans: Plan[] = [];
    userSub: string;
    buyers_sub: string;
    associate_id = environment.associateId;
    user_storage: KitAmount[];

    loading: boolean;
    isMaster: boolean;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(private sharedService: SharedService, private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        this.userSub = localStorage.getItem('sub');
        this.loading = true;
        this.buyers_sub = localStorage.getItem('clientView');
        if (localStorage.getItem('profile_name') !== '7d4e9f24-17e8-11ec-9621-0242ac130002') {
            this.user_storage = JSON.parse(localStorage.getItem('storageKit'));
            this.inventoryPartner = JSON.parse(localStorage.getItem('inventoryPartner'));
        } else {
            this.user_storage = null;
            this.inventoryPartner = null;
            this.isMaster = true;
        }
        this.getPlans();
        this.getUserInventory();
    }

    ngOnDestroy() {
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        if (this.getPlan) {
            this.getPlan.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(CameraActions.cleanup());
    }

    addKitToUser() {
        const dataModal = {
            data: {
                buyers_sub: localStorage.getItem('clientView'),
                userInventory:  this.inventoryPartner,
                user_storage: this.user_storage,
                master: this.isMaster,
                plans: this.plans
            },
            tipoModal: 'adicionar_kits',
            modalHeight: '75vh',
            modalWidth: '65vw'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

    getPlans() {
        this.getPlan = this.plans$.subscribe(resultPlans => {
            if (resultPlans) {
                this.plans = resultPlans;
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
            } else {
                this.store.dispatch(CameraActions.get_plans({ associateId: this.associate_id }));
            }
        });
    }

    getUserInventory() {
        this.userInventory = this.userInventory$.subscribe(inventory => {
            if (inventory) {
                this.inventories = inventory[0];
            } else {
                this.store.dispatch(UserActions.get_user_inventory({
                    user_sub: this.userSub,
                    child_sub: this.buyers_sub
                }));
            }
        });
    }

    filterOwnedKits(kits: Kit[]) {
        return kits.filter(kit => kit.kits_amount != null);
    }
}
