import { Camera } from 'app/cameras/models';
import { Feedback } from 'app/Shared/models';

export interface ScheduleAnalytic {
    id?: number;
    start_time: string;
    end_time: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

export interface TypeClass {
    id?: number;
    alias?: string;
    hashname: string;
}

export interface DrawType {
    id?: number;
    alias?: string;
    value?: string;
}

export interface DrawData {
    color: string;
    points: { x: number; y: number }[];
    blur: number;
}

export interface ConfigureData {
    alias: string;
    maxTime?: number;
    maxPeople?: number;
    schedules: ScheduleAnalytic[];
    classifier?: any[];
}

export interface TypeAnalytic {
    id_analytic_type: number;
    alias: string;
    draw_type: DrawType;
    events: AnalyticTypeEvents[];
}

export interface AnalyticTypeEvents {
    id: number;
    value: string;
    text: string;
}

export interface GroupAnalytics {
    id: number;
    alias: string;
    analytic_types: TypeAnalytic[];
}

export interface AnalyticMetadata {
    points: { x: number; y: number }[];
    extra_info: {
        maxTime?: number;
        maxPeople?: number;
        minPeople?: number;
        agglomeration?: boolean;
        dispersion?: boolean;
        withoutmask?: boolean;
        verticesQuantity: number;
        type?: string;
        permanceTime?: number;
        toleranceTime?: number;
        dissimilarity?: number;
        tampering_duration?: number;
        analysistype?: string;
    };
}

export interface Analytics {
    alias?: string;
    id_analytic?: number;
    id_group?: number;
    id_analytic_type?: number;
    blur?: number;
    border_color?: string;
    smaller_object?: number;
    larger_object?: number;
    classifier?: any[];
    metadata?: string;
    schedules?: ScheduleAnalytic[];
    schedules_flag?: string;
    status?: string;
    toogle_light?: boolean;
    category_id?: number;
    analytic_category?: string;
    events?: string[];
}

export interface AnalyticsByCamera {
    idCamera?: number;
    alias?: string;
    analytic_status?: 'warning' | 'disabled' | 'running';
    analytics?: Analytics[];
    hasHolidaysInCamera?: boolean;
}

export enum ModalState {
    List,
    Create,
    Edit,
    Closed
}

export interface AnalyticState {
    camera_view_analytics?: Camera;
    camera_analytics?: AnalyticsByCamera[];
    class_type_analytic?: TypeClass[];
    statusPutAnalytic?: Feedback;
    statusTotalCreatesAnalytics?: Feedback;
    statusPostAnalytic?: Feedback;
    statusDeleteAnalytic?: Feedback;
    groupAnalytics?: GroupAnalytics[];
    typeAnalytics?: TypeAnalytic[];
    modal_open_analytics: ModalState;
    modalData?: {
        analytic: Analytics;
        groupId: number;
        groups: GroupAnalytics[];
        hasHolidaysInCamera?: boolean;
    };
}

export interface ScheduleHours {
    id: number;
    day: string;
    concatTime: string;
    close: boolean;
}

export interface WeekList {
    day: string;
    active: boolean;
    schedules: ScheduleHours[];
}

export interface Loitering {
    value: string;
    viewValue: string;
}
