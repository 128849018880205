import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { OperationStatus, ResultAlert } from '../../Shared/models';
import { UserActions } from '../Services/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { AssociatedUsers } from '../models';
import { AppState } from 'app/store/model';
import { SharedService } from 'app/Shared/Services/shared.service';

@Component({
    selector: 'app-guest-settings-modal',
    templateUrl: './modal-guest-settings.component.html',
    styleUrls: ['./user-guest-settings.component.scss']
})

export class GuestSettingsModalComponent implements OnInit, OnDestroy {
    @Input() guest: AssociatedUsers[];
    readonly associatedUsers$ = this.store.select((state: AppState) => state.Users.associatedUsers);
    readonly deleteAssociated$ = this.store.select((state: AppState) => state.Users.deleteAssociated);
    readonly updateAssociated$ = this.store.select((state: AppState) => state.Users.updateAssociated);

    deleteAssociatedSub: Subscription;
    swalSub: Subscription;
    associatedUsersSub: Subscription;
    updateAssociatedSub: Subscription;
    pageName: string;
    loading: boolean;
    isPartner: Boolean;
    isClientAssociated: boolean;
    id: number;
    guestName: string;
    guestSub: string;
    guestEmail: string;
    guestPhone: string;
    guestDate: string;
    guestLastAccess: string;
    guestDevice: string;
    guestPermissionsAlias: string;
    guestProfileAlias: string;
    isClientGuest: boolean;
    selectedValue: string;
    context: string;
    save_changes: boolean;
    loadingUpdate: boolean;
    blockDelete: boolean;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(public dialogRef: MatDialogRef<GuestSettingsModalComponent>,
        private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule,
        private sharedService: SharedService,
        private router: Router) { }

    ngOnInit(): void {

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        this.isPartner = localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002';
        this.context = localStorage.getItem('context');
        this.save_changes = false;
        this.getAssociatedUsers();
        this.pageName = 'user-associated';
        this.blockDelete = true;
        this.confirmDeleteAssociated();
        this.deleteAssociatedResult();
        this.loading = false;
        this.loadingUpdate = false;
        this.saveChangesResult();
    }

    ngOnDestroy(): void {
        if (this.swalSub) {
            this.swalSub.unsubscribe();
        }
        if (this.deleteAssociatedSub) {
            this.deleteAssociatedSub.unsubscribe();
        }
        if (this.updateAssociatedSub) {
            this.updateAssociatedSub.unsubscribe();
        }
        if (this.associatedUsersSub) {
            this.associatedUsersSub.unsubscribe();
        }
        this.store.dispatch(UserActions.invite_associated_cleanup());
        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteAssociated') {
            if (returnResultSwal.isConfirmed && !this.blockDelete) {
                this.store.dispatch(UserActions.delete_associated({
                    sub: localStorage.getItem('sub'),
                    child_sub: this.guest[this.id].sub
                }));
                this.loading = true;
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    deleteAssociated() {
        this.blockDelete = false;
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-guest', 'user.delete_associated');
    }

    confirmDeleteAssociated() {
        this.swalSub = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    deleteAssociatedResult() {
        this.deleteAssociatedSub = this.deleteAssociated$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-guest-success', result.message);
                    this.closeModal();
                    setTimeout(() => { this.reload(); }, 1000);
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete-guest-error', result.message);
                }
            }
        });
    }

    closeModal() {
        this.dialogRef.close(OperationStatus.Unloaded);
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (this.context == 'summary') {
            this.router.navigate(['/users/profile']);
        } else {
            this.router.navigate(['/users/client/view']);
        }
    }

    getAssociatedUsers() {
        this.store.dispatch(UserActions.get_associated_users({
            user_sub: localStorage.getItem('sub'),
            child_sub: this.context == 'summary' ? '' : localStorage.getItem('clientView')
        }));
        this.associatedUsersSub = this.associatedUsers$.subscribe(result => {
            if (result) {
                this.guest = result as AssociatedUsers[];
                this.id = Number(localStorage.getItem('index_guest'));
                this.guestInformations(this.id);
            }
        });
    }

    guestInformations(id) {
        this.selectedValue = this.guest[id].permissions[0].alias;
        this.guestPermissionsAlias = this.guest[id].permissions[0].alias;
        this.guestName = this.guest[id].name;
        this.guestEmail = this.guest[id].email;
        this.guestSub = this.guest[id].sub;
        this.guestDevice = this.firstLetterUpperCase(this.guest[id].last_device);
        this.guestPhone = this.processPhone(this.guest[id].phone_number);
        this.guestProfileAlias = this.guest[id].profile_alias;
        this.isClientGuest = this.guestProfileAlias == 'Cliente Associado';

        const date = new Date(String(this.guest[id].created_at));
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        this.guestDate = day + '/' + month + '/' + year;

        const lastAccess = new Date(String(this.guest[id].last_access));
        const dia = lastAccess.getDate().toString().padStart(2, '0');
        const mes = (lastAccess.getMonth() + 1).toString().padStart(2, '0');
        const ano = lastAccess.getFullYear();
        const hora = lastAccess.getHours();
        const minutos = lastAccess.getMinutes().toString().padStart(2, '0');
        if (this.guest[id].last_access) {
            this.guestLastAccess = dia + '/' + mes + '/' + ano + ' às ' + hora + ':' + minutos;
        }

    }

    processPhone(initial_number: string) {
        let finalNumber = '';
        if (initial_number.includes('(')) {
            finalNumber = initial_number;
        } else {
            if (initial_number.length == 14 || initial_number.length == 13) {
                initial_number = initial_number.slice(3, initial_number.length);
            }
            if (initial_number.length == 11 || initial_number.length == 10) {
                let firstRange;
                if (initial_number.length == 11) {
                    firstRange = 7;
                } else {
                    firstRange = 6;
                }
                initial_number = '(' + initial_number.slice(0, 2) + ') ' + initial_number.slice(2, firstRange)
          + '-' + initial_number.slice(firstRange, initial_number.length);
            }
            finalNumber = initial_number;
        }
        return finalNumber;
    }

    firstLetterUpperCase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    saveChanges() {
        this.store.dispatch(UserActions.update_associated({
            user_sub: localStorage.getItem('sub'),
            associateUser: {
                name: this.guestName,
                email: this.guestEmail,
                sub: this.guestSub,
                phone_number: this.guestPhone,
                associate_permission: this.selectedValue,
                profile_alias: this.guestProfileAlias
            }
        }));
        this.loadingUpdate = true;
    }

    saveChangesResult() {
        this.updateAssociatedSub = this.updateAssociated$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-guest-success', result.message);
                    this.closeModal();
                    setTimeout(() => { this.reload(); }, 1000);
                } else {
                    this.loadingUpdate = false;
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete-guest-error', result.message);
                }
            }
        });
    }

}

@Component({
    selector: 'app-user-guest-settings',
    templateUrl: './user-guest-settings.component.html',
    styleUrls: ['./user-guest-settings.component.scss']
})

export class UserGuestSettingsComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.Users.modal_guest_settings);

    currentDialog: MatDialogRef<any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subCombined: Subscription;
    @Output() loadGuestSettingsEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        this.subCombined = this.modal_open$.subscribe(modal => {
            if (modal == OperationStatus.Loading) {
                this.currentDialog = this.dialog.open(GuestSettingsModalComponent, {
                    width: 'fit-content',
                    height: 'fit-content',
                    disableClose: true,
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                    if (result == OperationStatus.Success) {
                        this.loadGuestSettingsEmitter.emit(true);
                        this.store.dispatch(UserActions.guest_settings_modal({ payload: OperationStatus.Success }));
                    } else {
                        this.store.dispatch(UserActions.guest_settings_modal({ payload: OperationStatus.Unloaded }));
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
    }

}
