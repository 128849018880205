import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppState } from 'app/store/model';
import { ModalState } from '../models';
import { CameraActions } from 'app/cameras/Services/actions';
import { AnalyticsListComponent } from '../analytics-list/analytics-list.component';
import { AnalyticCreateComponent } from '../analytic-create/analytic-create.component';
import { AnalyticActions } from '../Services/actions';

@Component({
    selector: 'app-analytics-controller',
    templateUrl: './analytics-controller.component.html',
    styleUrls: ['./analytics-controller.component.scss']
})
export class AnalyticsControllerComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.analytic.modal_open_analytics);
    readonly camera$ = this.store.select((state: AppState) => state.camera.camera_view_analytics);
    @Output() loadingCameraList: EventEmitter<boolean> = new EventEmitter;
    @Output() exitModal: EventEmitter<boolean> = new EventEmitter;

    currentDialog: MatDialogRef<any, any> = null;
    currentModal: ModalState;
    destroy = new Subject<any>();
    subDialog: Subscription;
    analytic_available: number;
    subModal: Subscription;
    subCamera: Subscription;
    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }
    @Input()
    set analytics_available(analytics_available: number) {
        this.analytic_available = analytics_available;
    }

    ngOnInit() {
        this.subModal = this.modal_open$.subscribe(modal => {
            this.subCamera = this.camera$.subscribe(cam => {
                switch (modal) {
                    case ModalState.List:
                        cam.analytic_available = this.analytic_available;
                        if (this.currentModal != modal) {
                            this.dialog.closeAll();
                            this.currentDialog = this.dialog.open(AnalyticsListComponent, {
                                minWidth: '91vw',
                                maxWidth: '99vw',
                                height: 'fit-content',
                                minHeight: '68vh',
                                maxHeight: '92vh',
                                panelClass: 'custom-modalbox-analytics-list',
                                disableClose: true,
                                data: cam
                            });
                            this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                                if (result) {
                                }
                                else {
                                    this.loadingCameraList.emit(true);
                                }
                            });
                            this.currentModal = modal;
                        }
                        break;

                    case ModalState.Create:
                        if (this.currentModal != modal) {
                            this.dialog.closeAll();
                            this.currentDialog = this.dialog.open(AnalyticCreateComponent, {
                                minWidth: '91vw',
                                maxWidth: '99vw',
                                height: 'fit-content',
                                minHeight: '68vh',
                                maxHeight: '92vh',
                                panelClass: 'create-modalbox-analytics',
                                disableClose: true,
                                data: {
                                    camera: cam,
                                }
                            });
                            this.currentModal = modal;
                        }
                        break;

                    case ModalState.Edit:
                        if (this.currentModal != modal) {
                            this.dialog.closeAll();
                            this.currentDialog = this.dialog.open(AnalyticCreateComponent, {
                                minWidth: '91vw',
                                maxWidth: '99vw',
                                height: 'fit-content',
                                minHeight: '68vh',
                                maxHeight: '92vh',
                                panelClass: 'create-modalbox-analytics',
                                disableClose: true,
                                data: {
                                    camera: cam,
                                }
                            });
                            this.currentModal = modal;
                        }
                        break;
                    case ModalState.Closed:
                        this.dialog.closeAll();
                        this.currentModal = modal;
                        this.exitModal.emit(true);
                        break;
                }
            });
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        if (this.subModal) {
            this.subModal.unsubscribe();
        }
        if (this.subCamera) {
            this.subCamera.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
        this.store.dispatch(AnalyticActions.cleanup_controller_analytics());
        this.store.dispatch(CameraActions.clear_analytic_camera());
    }

}
