import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Plan } from 'app/cameras/models';
import { CameraActions } from 'app/cameras/Services/actions';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { AlarmModalState, Kit } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-kits',
    templateUrl: './user-kits.component.html',
    styleUrls: ['./user-kits.component.scss']
})
export class UserKitsComponent implements OnInit, OnDestroy {
    readonly kits$ = this.store.select((state: AppState) => state.Users.kitsList);
    readonly updateKit$ = this.store.select((state: AppState) => state.Users.updateKitResult);
    readonly editModalStatus$ = this.store.select((state: AppState) => state.Users.editKitModalStatus);
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);

    newNameKit = new UntypedFormControl('', [Validators.required]);
    displayedColumns: string[] = ['kit', 'product', 'cameras', 'analytics', 'cancel'];
    dataSource: MatTableDataSource<Kit> = new MatTableDataSource([] as Kit[]);
    kitsDetail: Kit[];
    plans: Plan[] = [];

    editModalStatus: Subscription;
    kits: Subscription;
    getPlan: Subscription;
    updateKit: Subscription;
    loadingKitsList: boolean;
    loadingKits: boolean;
    associate_id = environment.associateId;

    pageName = 'user-kits';

    constructor(private router: Router, private readonly store: Store<AppState>,
        private service: SharedService, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.dataSource.data = [];
        this.loadingKits = true;

        this.getKits();
        this.getPlans();
        this.getKitsResult();
        this.getEditModalStatus();
    }

    ngOnDestroy(): void {
        if (this.kits) {
            this.kits.unsubscribe();
        }
        if (this.updateKit) {
            this.updateKit.unsubscribe();
        }
        if (this.editModalStatus) {
            this.editModalStatus.unsubscribe();
        }
        // this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(CameraActions.cleanup());
        this.service.cleanSwal();
    }

    getKits() {
        this.store.dispatch(UserActions.get_kits({
            sub: localStorage.getItem('sub')
        }));
    }

    getKitsResult() {
        this.kits = this.kits$.subscribe(kit => {
            if (kit) {
                this.kitsDetail = kit;
                this.dataSource.data = this.kitsDetail;
                setTimeout(() => {
                    this.loadingKits = false;
                }, 2000);
            } else {
                this.getKits();
            }
        });
    }

    getPlans() {
        this.getPlan = this.plans$.subscribe(resultPlans => {
            if (resultPlans) {
                this.plans = resultPlans;
            } else {
                this.store.dispatch(CameraActions.get_plans({ associateId: this.associate_id }));
            }
        });
    }

    addKit() {
        const dataModal = {
            data: {
                plans: this.plans
            },
            tipoModal: 'add_kit_master',
            modalHeight: '65vh',
            modalWidth: 'fit-content',
        };
        this.service.returnDataToModal(dataModal);
    }

    updateKitInfo(kits: Kit) {
        const sub = localStorage.getItem('sub');
        const kit = {
            active: kits.active,
            alias: kits.alias,
            id: kits.id
        };
        this.store.dispatch(UserActions.update_kit({
            sub: sub,
            kit: kit
        }));
        this.updateKit = this.updateKit$.subscribe(result => {
            if (result) {
                this.loadingKitsList = true;
                if (result.success) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'edit-kit', result.message);
                    this.reloadComponent();
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'edit-kit-fail', result.message);
                }
            }
        });
    }

    reloadComponent() {
        if (this.loadingKitsList == true) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/users/kits']);
        }
    }

    editKit(kit) {
        if (this.plans.length > 0) {
            const dataModal = {
                data: {
                    plans: this.plans,
                    kit: kit
                },
                modalHeight: '90vh',
                modalWidth: 'fit-content',
                tipoModal: 'edit_kit'
            };
            this.service.returnDataToModal(dataModal);
        }
    }

    getEditModalStatus() {
        this.editModalStatus = this.editModalStatus$.subscribe(result => {
            if (result === AlarmModalState.Confirm) {
                this.loadingKits = true;
                this.dataSource.data = [];
                this.getKits();
            }
        });
    }
}
