<div class="page-header map-front background">
    <div class="container">
        <div class="col-md-4 container_logout">
            <div class="row row_logout">
                <img style="margin-bottom: 30px; width: 60%;" src="../../../assets/img/splash_logo.svg">
                <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>
</div>
