<div class="main-content background">
  <mat-paginator [pageSize]="5"></mat-paginator>
  <table mat-table [dataSource]="tableRows" class="table mat-elevation-z8">
    <!-- Thumbnail da câmera -->
    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell *matHeaderCellDef>CÂMERA</th>
      <td mat-cell (click)="viewCamera(camera)" *matCellDef="let camera">
        <img class="clickable" src="{{camera.thumbnail}}" width="128" height="72">
      </td>
    </ng-container>
    <!-- Nome da câmera -->
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>NOME</th>
      <td mat-cell *matCellDef="let camera" class="cam-name">
        {{camera.alias}}
      </td>
    </ng-container>
    <ng-container matColumnDef="proprietario">
      <th mat-header-cell *matHeaderCellDef>PROPRIETÁRIO</th>
      <td mat-cell *matCellDef="let camera" class="cam-name">
        {{camera.name}}
      </td>
    </ng-container>
    <!-- Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>STATUS</th>
      <td mat-cell *matCellDef="let camera">
        <button disabled *ngIf="camera.active; else inactive" class="btn btn-sm btn-round active">
          <span class="text-active"><b>Ativo</b></span>
        </button>
        <ng-template #inactive>
          <button disabled class="btn btn-sm btn-round inactive"><span
              class="text-inactive"><b>Inativo</b></span></button>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
    <tr mat-row *matRowDef="let row; columns: tableHeaders;"></tr>
  </table>
</div>
<app-camera-invite></app-camera-invite>