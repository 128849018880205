<div class="wrapper wrapper-full-page">
  <div class="page-header register-page background">
    <div class="container">
      <div class="flex flex-row items-center justify-center">
        <div *ngIf="!msgSuccess; else msgSucess" class="flex w-1/2">
          <div class="card">
            <div class="card-header card-header-icon card-header-info">
              <div class="card-icon card-style-email">
                <i class="material-icons">email</i>
              </div>
              <h2 class="card-title title-style">Verifique seu e-mail!</h2>
            </div>
            <div class="flex flex-col items-center justify-center card-body card_form_email">
              <span class="flex w-full title2-style">Acesse seu e-mail para obter seu código de verificação</span>
              <div class="flex w-2/6 form-group has-default">
                <mat-form-field class="example-full-width form_field_email">
                  <input matInput mask="000000" type="text" placeholder="Código" [formControl]="code">
                  <mat-error *ngIf="code.hasError('required')">
                    Código <strong>é requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex w-full text-center center-spinner confirm_button">
                <button *ngIf="!loading" class="btn btn-primary btn-md enter-button" type="button"
                  (click)="onConfirm()">
                  Confirmar
                </button>
                <div *ngIf="loading">
                  <span nz-icon nzType="loading" nzTheme="outline" style="font-size:40px"></span>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div *ngIf="!loadingSend" class="card_bottom">
                <span class="tab-right">Não recebeu seu código de confirmação? Clique <span class="small-text"
                    (click)="resendCode()">aqui</span> para reenviar o código</span>
              </div>
              <div *ngIf="loadingSend" class="col-md-12 text-center center-spinner">
                <span nz-icon nzType="loading" nzTheme="outline" style="font-size:40px"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #msgSucess>
        <div class="flex w-1/2">
          <div class="flex flex-col items-center justify-center">
            <div class="flex">
              <img src="../../assets/img/Icons/check_email.svg" width="120" height="120">
            </div>
            <ng-container *ngIf="!loading">
              <div class="flex text_margin_1">
                <span class="text-success">Verifique seu e-mail</span>
              </div>
              <div class="flex text_margin_2">
                <span class="text-success">Cadastro com sucesso!</span>
              </div>
            </ng-container>
            <ng-container *ngIf="loading">
              <div class="flex text_margin_1">
                <span class="text-success">Enviando informações...</span>
              </div>
            </ng-container>
            <div class="flex login_container">
              <button *ngIf="!loading" type="button" (click)="goToLogin()"
                class="btn btn-primary btn-md button-style">Efetuar Login</button>
              <div *ngIf="loading" class="spin">
                <span nz-icon nzType="loading" nzTheme="outline" style="font-size:50px"></span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>