<div class="main-content bg">
  <div class="card-login">
    <div class="header-card">
      <div class="back-container">
        <div class="back clickable" *ngIf="step === 'password'" (click)="prevStep()">
          <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
        </div>
      </div>
      <div class="logo">
        <img src="../../../assets/img/Icons/login_logo.svg" alt="" srcset="">
      </div>
      <div class="spacer"></div>
    </div>

    <div class="card-body email" *ngIf="step === 'email'">
      <p class="title-style">Bem vindo de volta!</p>
      <p class="subtitle-style">Faça login na sua conta</p>

      <div class="input-container">
          <span>Login:</span>
          <div class="input-field">
            <input type="text" placeholder="Digite seu e-mail" [formControl]="emailFormControl" (keyup.enter)="nextStep()">
            <i nz-icon nzType="user" nzTheme="outline" class="input-icon"></i>
          </div>
          <span class="input-error" *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Insira um e-mail válido para continuar
          </span>
          <span class="input-error" *ngIf="emailFormControl.hasError('required') && emailFormControl.touched">
            Email é <strong>obrigatório!</strong>
          </span>
          <button (click)="nextStep()">Continuar</button>
      </div>
    </div>

    <div class="card-body password" *ngIf="step === 'password'">
      <div class="email-selector">
        <div class="dropdown-selector clickable" (click)="isEmailListOpen = !isEmailListOpen">
          <i nz-icon nzType="user" nzTheme="outline"></i>
          <span class="cam-name" nz-tooltip [nzTooltipTitle]="emailFormControl.value" nzTooltipOverlayClassName="cam-name">
            {{displayCamName(emailFormControl.value)}}</span>
          <i nz-icon [nzType]="isEmailListOpen ? 'up' : 'down'" nzTheme="outline" class="dropdown-indicator"></i>
        </div>
        <div class="dropdown-open" *ngIf="isEmailListOpen">
          <div class="user clickable" *ngFor="let user of storedUsers; let index = index" (click)="changeUser(index)">
            <div class="icon">
              <img [src]="user.image" (error)="user.image = null" alt="" *ngIf="user.image;else userImagePlaceholder">
              <ng-template #userImagePlaceholder>
                <div class="placeholder">
                  <span>{{user.email.charAt(0).toUpperCase() }}</span>
                </div>
              </ng-template>
            </div>
            <div class="name-group">
              <span class="main" *ngIf="user.name">{{user.name}}</span>
              <span class="secondary">{{user.email}}</span>
            </div>
            <span class="button-close" (click)="clearOneUser(index)">
              <i nz-icon nzType="close" nzTheme="outline"></i>
            </span>
          </div>
          
          <div class="user clickable" (click)="prevStep(true)">
            <div class="icon-add">
              <i nz-icon nzType="user-add" nzTheme="outline"></i>
            </div>
            <div class="name-group">
              <span class="main">Adicionar outra conta</span>
            </div>
          </div>
          <div class="dropdown-footer" (click)="clearUserStore()">
            <button>Sair de todas as contas</button>
          </div>
        </div>
      </div>
      <div class="input-container">
        <span>Senha:</span>
        <div class="input-field">
          <input [type]="hide ? 'password' : 'text'" [formControl]="passwordFormControl" placeholder="Digite sua senha" (keyup.enter)="login()">
          <i nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" nzTheme="outline" (click)="hide = !hide" class="clickable input-icon"></i>
        </div>
        <span class="input-error" *ngIf="passwordFormControl.hasError('required') && passwordFormControl.touched">
          Senha é <strong>obrigatório!</strong>
        </span>
        <button (click)="login()">Entrar <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loading" class="loading-icon"></span></button>
        <a (click)="goToForgot()" class="forget clickable">Esqueceu sua senha?</a>
      </div>
    </div>

    <div class="footer-card">
      <p class="footer-style">Powered by WeSafer</p>
    </div>
  </div>
  <div class="logo-footer">
    <img src="../../../assets/img/logo_login.png" alt="" srcset="" *ngIf="isNextVision">
  </div>
</div>
