// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    userPoolID: 'us-east-2_zzY3lD2mw',
    clientId: '2bv9rku6oj9oivvgp4bb0mstem',
    awsRegion: 'us-east-1',
    googleAnalyticsId: 'G-HJ0RJX66SQ',
    associateId: 1,
    apiUrl: '.',
    translate: {
        camera: 'Câmera',
        cameras: 'Câmeras',
        view: 'Visualização',
        list: 'Lista',
        retrieve: 'Recuperação',
        invited: 'Convidados',
        signin: 'Entrar',
        register: 'Cadastrar',
        confirm: 'Confirmação',
        forgot: 'Esqueci a senha',
        users: 'Usuário',
        profile: 'Perfil',
        maps: 'Mapa',
        home: 'Home',
        cadastro: 'Cadastrar câmera'
    },
    estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
    Estados: [{ estado: 'Alagoas', uf: 'AL' },
        { estado: 'Amapá', uf: 'AP' },
        { estado: 'Amazonas', uf: 'AM' },
        { estado: 'Bahia', uf: 'BA' },
        { estado: 'Ceará', uf: 'CE' },
        { estado: 'Distrito Federal', uf: 'DF' },
        { estado: 'Espírito Santo', uf: 'ES' },
        { estado: 'Goiás', uf: 'GO' },
        { estado: 'Maranhão', uf: 'MA' },
        { estado: 'Mato Grosso', uf: 'MT' },
        { estado: 'Mato Grosso do Su', uf: 'MS' },
        { estado: 'Minas Gerais', uf: 'MG' },
        { estado: 'Pará', uf: 'PA' },
        { estado: 'Paraíba', uf: 'PB' },
        { estado: 'Pernambuco', uf: 'PE' },
        { estado: 'Piauí', uf: 'PI' },
        { estado: 'Rio de Janeiro', uf: 'RJ' },
        { estado: 'Rio Grande do Norte', uf: 'RN' },
        { estado: 'Rio Grande do Sul', uf: 'RS' },
        { estado: 'Rondônia', uf: 'RO' },
        { estado: 'Roraima', uf: 'RR' },
        { estado: 'Santa Catarina', uf: 'SC' },
        { estado: 'São Paulo', uf: 'SP' },
        { estado: 'Sergipe', uf: 'SE' },
        { estado: 'Tocantins', uf: 'TO' }],
    firebase: {
        apiKey: 'AIzaSyAwv5UgF2uut9qGuM2hEewiO7tc4s8-eDg',
        authDomain: 'avanuv-256f4.firebaseapp.com',
        databaseURL: 'https://avanuv-256f4.firebaseio.com',
        projectId: 'avanuv-256f4',
        storageBucket: 'avanuv-256f4.appspot.com',
        messagingSenderId: '728802310012',
        appId: '1:728802310012:web:59bc9a38482cced2f896f9',
        measurementId: 'G-8MF8PQS5V6'
    }
};
