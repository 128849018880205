<div class="main-content background">
    <div class="row style-header">
        <div class="mr-auto xs:mr-0 sm:mr-0 md:mr-0 lg:mr-0 xl:mr-auto col-8 style-title">
            <span>Meu Estoque</span>
        </div>
        <div class="ml-auto xs:ml-0 sm:ml-0 md:ml-0 lg:ml-0 xl:ml-auto col-4 style-subtitle">
            <button *ngIf="!inventoryLoading && (!isAssociate || adminAssociate)" class="btn lg:btn-sm xl:btn-sm btn-primary add-button"
                (click)="requestKits()">
                <i class="material-icons">add</i> Solicitar Kit
            </button>
        </div>
    </div>
    
    <div class="tabs">
        <div class="tabs-header">
            <button class="btn btn-tab active" (click)="changeTab($event)" id="cams">
                Câmeras e detecções
            </button>
            <button class="btn btn-tab" (click)="changeTab($event)" id="kits">
                Kits para venda
            </button>
        </div>
        <div class="tabs-content" *ngIf="!inventoryLoading && !plansLoading">
            <app-camera-detections-stock [inventory]="inventory" [plans]="plans" [hidden]="activeTab != 'cams'"></app-camera-detections-stock>
            <app-camera-kits-stock [inventory]="inventory" [plans]="plans" [hidden]="activeTab != 'kits'"></app-camera-kits-stock>
        </div>
        <div class="tabs-content" *ngIf="inventoryLoading && plansLoading">
            <div class="center-spinner">
                <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 80px"></span>
            </div>
        </div>
    </div>
</div>

<app-modal></app-modal>