export function getToken(): string
{
    const idToken = localStorage.getItem('id-token') ? localStorage.getItem('id-token') : sessionStorage.getItem('id-token');
    return idToken ? idToken : '';
}

export function getAccessToken(): string
{
    const accessToken = localStorage.getItem('access-token') ? localStorage.getItem('access-token') : sessionStorage.getItem('access-token');
    return accessToken ? accessToken : '';
}

export function getRefreshToken(): string
{
    const refreshToken = localStorage.getItem('refresh-token') ? localStorage.getItem('refresh-token') : sessionStorage.getItem('refresh-token');
    return refreshToken ? refreshToken : '';
}

export function updateToken(token: string)
{
    localStorage.setItem('id-token', token);
    sessionStorage.setItem('id-token', token);
}
