<div class="main-content background container_password">
    <div class="flex flex-col">
        <div class="flex w-11/12">
            <h3 class="tam-title">ALTERAR SENHA</h3>
        </div>
        <div class="flex w-1/12" class="button-close">
            <i class="material-icons hover-close" (click)="closeCancel()">close</i>
        </div>
    </div>
    <div class="card custom-card">
        <div class="card-header">
            <form [formGroup]="passwordForm">
                <div class="flex flex-col flex-wrap gap-1 perfil">
                    <div class="flex flex-col">
                        <div class="form-group has-default">
                            <mat-form-field class="flex w-full">
                                <input matInput type="password" placeholder="Senha atual"
                                    formControlName="currentPass" id="cp">
                                <i matSuffix (click)="showPassword('cp')" class="material-icons custom-icon-view-pass"
                                    id="cpicon">visibility</i>
                                <mat-error *ngIf="passwordForm.get('currentPass').hasError('required') && viewError">
                                    Senha atual <strong>é obrigatório!</strong>
                                </mat-error>
                                <mat-error *ngIf="passwordForm.get('currentPass').invalid && (passwordForm.get('currentPass').dirty 
                                || passwordForm.get('currentPass').touched) && (passwordForm.get('currentPass').value != '')">
                                    Formato <strong>inválido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group has-default">
                            <mat-form-field class="flex w-full">
                                <input matInput type="password" placeholder="Nova senha"
                                    formControlName="newPass" id="np" onpaste="return false;">
                                <i matSuffix (click)="showPassword('np')" class="material-icons custom-icon-view-pass"
                                    id="npicon">visibility</i>
                                <mat-error *ngIf="passwordForm.get('newPass').hasError('required') && viewError">
                                    Nova senha <strong>é obrigatório!</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="(passwordForm.get('newPass').dirty || passwordForm.get('newPass').touched) && 
                                    (passwordForm.get('newPass').value != '')" class="form-group has-default">
                            <mat-form-field class="flex w-full">
                                <input matInput type="password"
                                    placeholder="Confirmar nova senha" formControlName="confirmNewPass"
                                    [errorStateMatcher]="matcher" id="cnp" onpaste="return false;">
                                <i matSuffix (click)="showPassword('cnp')" class="material-icons custom-icon-view-pass"
                                    id="cnpicon">visibility</i>
                                <mat-error *ngIf="passwordForm.get('confirmNewPass').hasError('required') && viewError">
                                    Confirmar nova Senha <strong>é obrigatório!</strong>
                                </mat-error>
                                <mat-error *ngIf="passwordForm.get('confirmNewPass').hasError('unequal') || matcher || confirmation">
                                    Senhas <strong>não conferem!</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="font-feedback feedback_color" *ngIf="passwordForm.get('newPass').invalid && (passwordForm.get('newPass').dirty || passwordForm.get('newPass').touched)
                            && (passwordForm.get('newPass').value != '')">
                            Sua senha deve ter:
                            <div *ngIf="passwordForm.get('newPass').errors.numberError; else badNumber">
                                <span class="invalid font-feedback">&#9888; Pelo menos um número</span>
                            </div>
                            <ng-template #badNumber>
                                <div>
                                    <span class="valid font-feedback">&#10003; Pelo menos um número</span>
                                </div>
                            </ng-template>
                            <div *ngIf="passwordForm.get('newPass').errors.upperCaseError; else badUpper">
                                <span class="invalid font-feedback">&#9888; Pelo menos uma letra maiúscula</span>
                            </div>
                            <ng-template #badUpper>
                                <div>
                                    <span class="valid font-feedback">&#10003; Pelo menos uma letra maiúscula</span>
                                </div>
                            </ng-template>
                            <div *ngIf="passwordForm.get('newPass').errors.lowerCaseError; else badLower">
                                <span class="invalid font-feedback">&#9888; Pelo menos uma letra minúscula</span>
                            </div>
                            <ng-template #badLower>
                                <div>
                                    <span class="valid font-feedback">&#10003; Pelo menos uma letra minúscula</span>
                                </div>
                            </ng-template>
                            <div *ngIf="passwordForm.get('newPass').errors.symbolError; else badSymbol">
                                <span class="invalid font-feedback">&#9888; Pelo menos um caractere especial</span>
                            </div>
                            <ng-template #badSymbol>
                                <div>
                                    <span class="valid font-feedback">&#10003; Pelo menos um caractere especial</span>
                                </div>
                            </ng-template>
                            <div
                                *ngIf="passwordForm.get('newPass').errors.minlength || passwordForm.get('newPass').errors.required; else badLength">
                                <span class="invalid font-feedback">&#9888; Pelo menos 8 caracteres</span>
                            </div>
                            <ng-template #badLength>
                                <div>
                                    <span class="valid font-feedback">&#10003; Pelo menos 8 caracteres</span>
                                </div>
                            </ng-template>
                        </div>
                        <div class="font-feedback password_color" *ngIf="passwordForm.get('newPass').valid">
                            Sua senha deve ter:
                            <div class="valid font-feedback">&#10003; Pelo menos um número</div>
                            <div class="valid font-feedback">&#10003; Pelo menos uma letra maiúscula</div>
                            <div class="valid font-feedback">&#10003; Pelo menos uma letra minúscula</div>
                            <div class="valid font-feedback">&#10003; Pelo menos um caractere especial</div>
                            <div class="valid font-feedback">&#10003; Pelo menos 8 caracteres</div>
                        </div>
                    </div>
                    <div class="flex w-full container_buttons">
                        <button (click)="closeCancel()" type="button"
                            class="btn btn-md btn-default tamButton cancel">CANCELAR</button>
                        <button (click)=" closeConfirm()" type="button"
                            class="btn btn-md btn-primary tamButton change">ALTERAR</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
