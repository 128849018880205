import { Feedback } from 'app/Shared/models';
import { LoginState, Session } from '../models';
import { LoginActions } from './actions';
import { createReducer, on } from '@ngrx/store';

const INITIAL_STATE: LoginState = {
    confirmResult: null,
    loginResult: null,
    logoutResult: null,
    registerResult: null,
    resetResult: null
    // error: false
};


export const loginReducer = createReducer(
    INITIAL_STATE,
    on(
        LoginActions.register, state => state
    ),
    on(
        LoginActions.register_result, (state, { payload }) => ({
            ...state,
            registerResult: payload as Feedback
        })
    ),
    on(
        LoginActions.confirm_register, state => state
    ),
    on(
        LoginActions.confirm_register_result, (state, { payload }) => ({
            ...state,
            confirmResult: payload as Feedback
        })
    ),
    on(
        LoginActions.signin, state => state
    ),
    on(
        LoginActions.signin_status, (state, { payload }) => ({
            ...state,
            loginResult: payload as Session | Feedback
        })
    ),
    on(
        LoginActions.signout, state => state
    ),
    on(
        LoginActions.signout_feedback, (state, { payload }) => ({
            ...state,
            logoutResult: payload as Feedback
        })
    ),
    on(
        LoginActions.reset_password, state => state
    ),
    on(
        LoginActions.reset_password_result, (state, { payload }) => ({
            ...state,
            resetResult: payload as Feedback
        })
    ),
    on(
        LoginActions.cleanup, state => ({
            ...state,
            loginResult: null,
            logoutResult: null,
            confirmResult: null,
            resetResult: null,
            registerResult: null
        })
    ),
);
