import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Client, CognitoAttributes } from '../models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions } from 'app/Users/Services/actions';
import { CameraActions } from 'app/cameras/Services/actions';
import { AppState } from 'app/store/model';
import { ResultAlert } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { UserService } from '../Services/user.service';
import { SharedService } from 'app/Shared/Services/shared.service';


import { Camera } from '../models';

export interface PeriodicCameras {
    name: string;
    detections: number;
    guests: number;
    active: boolean;
}

@Component({
    selector: 'app-client-view',
    templateUrl: './user-client-view.component.html',
    styleUrls: ['./user-client-view.component.scss'],
})

export class ClientViewComponent implements OnInit, OnDestroy, AfterViewInit {
    // thumbUrl = environment.wowzaThumbNailUrl;
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly groupView$ = this.store.select((state: AppState) => state.camera.OpenViewGroup);
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);
    readonly resendWelcomeEmail$ = this.store.select((state: AppState) => state.Users.resendEmail);
    readonly deleteResult$ = this.store.select((state: AppState) => state.Users.deleteResult);
    readonly blockClient$ = this.store.select((state: AppState) => state.Users.blockClient);

    loadingClientView: boolean;
    hasClients: boolean;
    loadingMail: boolean;
    loadingBlock: boolean;
    blockClientActive: boolean;
    loading: boolean;
    activeTab = localStorage.getItem('childProfile') == 'Convidado' ? 'info' : 'cams';

    user_sub: string;
    sub: Subscription;
    client_name: string;

    groupViewSub: Subscription;
    user_data_sub: Subscription;
    resend_welcome_email_sub: Subscription;
    delete_client_sub: Subscription;
    block_client_sub: Subscription;

    associate_id = environment.associateId;
    tableHeaders = ['thumbnail', 'nome', 'editar', 'analiticos', 'convidados', 'plano', 'status', 'acoes', 'timestamp'];

    pageName = 'client-view';

    cameras_table: any;
    /* cameras_table: MatTableDataSource<Camera> = new MatTableDataSource([] as Camera[]);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    */
    no_table = false;

    total_cameras = 10;
    used_cameras = 5;
    displayedColumns: string[] = ['name', 'detections', 'guests', 'active'];
    total_analytics = 6;
    used_analytics = 3;

    showCli = false;
    client_sub: string;
    client: Client;
    client_edit: CognitoAttributes;
    client_cameras: Camera[];
    hasCameras: boolean;
    is_partner = false;
    isAssociate: boolean;
    adminAssociate: boolean;

    loadingClient = true;

    swalDecisionSub: Subscription;

    constructor(private router: Router, private userService: UserService,
        private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule,
        private service: SharedService) {
    }

    ngOnInit() {
        localStorage.setItem('context', 'clientview');
        this.client_name = localStorage.getItem('clientName');
        this.user_sub = localStorage.getItem('sub');

        this.client_sub = localStorage.getItem('clientView');

        this.getUserData();

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        const userProfile = localStorage.getItem('profile_name');
        if (userProfile == '61902d2b-3ada-49f3-b42a-1775bc064bb0') {
            this.is_partner = true;
        }
        // this.store.dispatch(this.analActions.get_analytics_group());
        //this.store.dispatch(this.actions.get_clients({ user_sub: this.user_sub }));
        localStorage.setItem('previousRouter', this.router.url);

        this.loadingMail = false;
        this.loadingBlock = false;

        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        this.delete_client_sub = this.deleteResult$.subscribe(delete_result => {
            if (delete_result) {
                if (delete_result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'delete-client-result', delete_result.message);
                    this.goToList();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'delete-client-result-fail', delete_result.message);
                    this.store.dispatch(UserActions.cleanup());
                }
            }
        });

        this.block_client_sub = this.blockClient$.subscribe(block_client => {
            if (block_client) {
                if (block_client.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'block-client-result', block_client.message);
                    this.loadingBlock = false;
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'block-client-result-fail', block_client.message);
                    this.store.dispatch(UserActions.cleanup());
                    this.loadingBlock = false;
                }
            }
        });

        this.groupViewSub = this.groupView$.subscribe(status => {
            if (status) {
                this.activeTab = 'view_group';
            } else {
                if (this.activeTab == 'cams') {
                    this.activeTab = localStorage.getItem('childProfile') == 'Convidado' ? 'info' : 'cams';
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.user_data_sub) {
            this.user_data_sub.unsubscribe();
        }
        if (this.delete_client_sub) {
            this.delete_client_sub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        if (this.groupViewSub) {
            this.groupViewSub.unsubscribe();
        }

        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(CameraActions.open_view_group_clear());
        this.service.cleanSwal();
        //this.store.dispatch(this.actions.get_clients_change_return());
    }

    ngAfterViewInit() {
        if (this.client) {
            this.client.cnpj = '';
            this.client.email = '';
            this.client.phonenumber = '';
        }
    }

    getUserData() {
        this.store.dispatch(UserActions.get_user_data({
            user_sub: this.user_sub,
            child_sub: this.client_sub
        }));
        this.user_data_sub = this.userData$.subscribe(client_data => {
            if (client_data) {
                if (client_data.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'return-data-error', 'user.client_cognito_error', client_data.message);
                }
                else {
                    if (client_data.sub != this.user_sub) {

                        this.fillClientInfo(client_data);
                        this.client_edit = client_data;
                        this.loadingClient = false;
                        this.client_name = client_data.name;
                        this.blockClientActive = client_data.active;
                    }
                }
            }
        });
    }

    fillClientInfo(client_attr: CognitoAttributes) {
        let cpfCnpj = client_attr['custom:CPF'];
        if (!cpfCnpj) {
            cpfCnpj = client_attr['custom:CNPJ'];
        }
        this.client = {
            'name': client_attr.name,
            'email': client_attr.email,
            'sub': client_attr.name,
            'id': -1,
            'profilePicture': client_attr.name,
            'invitation_time': client_attr.name,
            'cnpj': cpfCnpj,
            'ci': {
                'available': 0,
                'used': 0
            },
            'ai': {
                'available': 0,
                'used': 0
            },
            'phonenumber': this.processPhone(client_attr.phone_number),
            'childProfile': client_attr.child_profile
        };

    }

    processPhone(initial_number: string) {
        let finalNumber = '';
        if (initial_number.includes('(')) {
            finalNumber = initial_number;
        } else {
            if (initial_number.length == 14 || initial_number.length == 13) {
                initial_number = initial_number.slice(3, initial_number.length);
            }
            if (initial_number.length == 11 || initial_number.length == 10) {
                initial_number = '(' + initial_number.slice(0, 2) + ') ' + initial_number.slice(2, initial_number.length);
            }
            finalNumber = initial_number;
        }
        return finalNumber;
    }

    viewClientList() {
        this.router.navigateByUrl('users/clients/list');
    }

    reloadComponent() {

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/client/view']);

    }

    resendEmail() {
        this.loadingMail = true;
        this.store.dispatch(UserActions.resend_welcome_email({
            sub: this.user_sub,
            child_sub: this.client_sub
        }));
        this.resend_welcome_email_sub = this.resendWelcomeEmail$.subscribe(email_feedback => {
            if (email_feedback) {
                if (email_feedback.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'alert-resend-email', email_feedback.message);
                    this.loadingMail = false;
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'alert-resend-email-fail', email_feedback.message);
                    this.loadingMail = false;
                }
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteClient') {
            if (returnResultSwal.isConfirmed) {
                this.store.dispatch(UserActions.delete_client({
                    sub: this.user_sub,
                    child_sub: this.client_sub
                }));
            }
        }
    }

    goToList() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/clients/list']);
    }

    editClient() {
        const dataModal = {
            data: {
                client: this.client_edit
            },
            tipoModal: 'editar_cliente',
            modalHeight: '85vh',
            modalWidth: '35vw'
        };
        this.service.returnDataToModal(dataModal);
    }

    blockClient() {
        this.loadingBlock = true;
        if (this.blockClientActive) {
            this.store.dispatch(UserActions.block_client({
                sub: this.user_sub,
                child_sub: this.client_sub,
                active: false
            }));
            this.blockClientActive = false;
        } else {
            this.store.dispatch(UserActions.block_client({
                sub: this.user_sub,
                child_sub: this.client_sub,
                active: true
            }));
            this.blockClientActive = true;
        }
    }

    changeTab(event) {
        const tabs = document.querySelectorAll('.btn-tab');
        tabs.forEach(element => {
            element.classList.remove('active');
        });
        this.activeTab = event.currentTarget.id;
        event.currentTarget.classList.add('active');
    }
}
