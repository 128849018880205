import { Component, OnInit, OnDestroy, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Store, select } from '@ngrx/store';
import {AppState} from '../../store/model';

@Component({
    selector: 'app-modal-crop',
    templateUrl: './modal-crop.component.html',
    styleUrls: ['./user-crop-image.component.scss']
})
export class ModalCropComponent implements OnInit, OnDestroy {
    imageEvent: HTMLInputElement;
    imageCroppedEvent: ImageCroppedEvent;
    constructor(public dialogRef: MatDialogRef<ModalCropComponent>, @Inject(MAT_DIALOG_DATA) public croppedEvent: HTMLInputElement) { }
    ngOnInit() {
        this.imageEvent = this.croppedEvent;
    }

    ngOnDestroy() {

    }

    onImage() {
        this.dialogRef.close(this.imageCroppedEvent);
    }

    onClose() {
        this.dialogRef.close();
    }

    async imageCropped(event: ImageCroppedEvent) {
        this.imageCroppedEvent = event;
    }

    imageLoaded() {

    }

    loadImageFailed() {
    }
}

@Component({
    selector: 'app-user-crop-image',
    templateUrl: './user-crop-image.component.html',
    styleUrls: ['./user-crop-image.component.scss']
})
export class UserCropImageComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.pipe(select((state: AppState) => state.Users.modal_crop_image));
    readonly cropEvent$ = this.store.pipe(select((state: AppState) => state.Users.crop_event));
    currentDialog: MatDialogRef<any, any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subModal: Subscription;
    subCropEvent: Subscription;
    @Output() imageEmitter: EventEmitter<ImageCroppedEvent> = new EventEmitter();
    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit() {
        this.subModal = this.modal_open$.subscribe(modal => {
            this.subCropEvent = this.cropEvent$.subscribe(cropEvent => {
                if (modal) {
                    this.currentDialog = this.dialog.open(ModalCropComponent, {
                        width: 'fit-content',
                        height: '80%%',
                        disableClose: true,
                        data: cropEvent
                    });
                    this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                        this.imageEmitter.emit(result);
                    });
                }
                else {
                    if (this.currentDialog) {
                        this.currentDialog.close();
                    }
                }
            });
        });
    }

    ngOnDestroy() {
        this.subModal.unsubscribe();
    }

}
