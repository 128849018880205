import { Component, OnInit, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { getToken } from 'app/Shared/Helpers/getToken';
import { LoginActions } from 'app/Login/Services/actions';
import { AppState } from 'app/store/model';
import { SharedService } from 'app/Shared/Services/shared.service';
import {UserNotification, NotificationDays } from 'app/Users/models';
import { UserActions } from 'app/Users/Services/actions';
import { OperationStatus } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import {OneSignal} from 'onesignal-ngx';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare let $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, OnDestroy {
    readonly userNotification$ = this.store.select((state: AppState) => state.Users.userNotification);
    readonly notificationVisibility$ = this.store.select((state: AppState) => state.Users.notificationVisibility);

    subscription: Subscription;
    userNotification: Subscription;
    notificationVisibility: Subscription;

    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    profilePic: string = null;
    validSession = false;
    currentPath: string[];
    dictionary = environment.translate;
    isSidebarOpen = false;
    down = false;
    notificationInterval = null;
    timeNotification = 120000;

    loading: boolean;
    notifyWarning: boolean;
    clickNotify: boolean;

    todayNotification: UserNotification[] = [];
    lastWeekNotification: UserNotification[] = [];
    lastMonthNotification: UserNotification[] = [];

    email: string;
    senha: string;
    name: string;

    isMarkAll: boolean;

    constructor(location: Location, private element: ElementRef, private router: Router, private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule, private oneSignal: OneSignal) {
        this.location = location;
        this.sidebarVisible = false;
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.logStatus();
            }
            else if (event instanceof NavigationEnd) {
                const urlSplit = this.router.url.split('/');
                urlSplit.splice(0, 1);
                this.currentPath = urlSplit;
            }
        });
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        this.profilePic = localStorage.getItem('picture');
        const navbar: HTMLElement = this.element.nativeElement;
        this.notifyWarning = false;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.getUserNotificationResult();
        this.logStatus();
        this.minimizeSidebar();
        this.oneSignalEvent();
    }

    checkIfLast(term: string): boolean {
        return (this.currentPath.findIndex(item => item === term) == this.currentPath.length - 1);
    }

    getPlaceholder() {
        const url = localStorage.getItem('picture');
        if (url.indexOf('undefined') != -1) {
            return '../../../assets/img/placeholder.jpg';
        } else {
            return (url && (url != String('undefined') && url != '')) ? url : '../../../assets/img/placeholder.jpg';
        }
    }

    returnPath(index: string): string {
        return this.dictionary[index];
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.userNotification) {
            this.userNotification.unsubscribe();
        }
        if (this.notificationVisibility) {
            this.notificationVisibility.unsubscribe();
        }
        if (this.notificationInterval) {
            clearInterval(this.notificationInterval);
        }
        this.store.dispatch(UserActions.cleanup());
    }

    login() {
        this.router.navigateByUrl('/login/signin');
    }

    userSummary() {
        this.router.navigateByUrl('/users/profile');
    }

    logout() {
        this.store.dispatch(LoginActions.signout({
            userSub: localStorage.getItem('sub')
        }));
        this.router.navigateByUrl('/login/logout');
    }

    logStatus() {
        this.validSession = getToken() != null;
        if (this.validSession) {
            this.name = localStorage.getItem('name');
        }
    }

    selectIconSvg(notificationType: string): string {
        switch (notificationType) {
            case 'Analítico Configurado': return '../../../assets/img/CheckCircle.svg';
            case 'Câmera offline': return '../../../assets/img/NoCamCircle.svg';
            case 'Retomada de câmera RTMP': return '../../../assets/img/CamCircle.svg';
            case 'Câmera RTMP excluído': return '../../../assets/img/NoCamCircle.svg';
            case 'Camera reconectada': return '../../../assets/img/CamCircle.svg';
            case 'Status de câmera alterado': return '../../../assets/img/CamCircle.svg';
            default: return '../../../assets/img/CheckCircle.svg';
        }
    }

    toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
        SharedService.instance.setSidebarStatus(this.isSidebarOpen);
    }

    toggleNotify() {
        const box = document.getElementById('box');
        if (this.down) {
            box.style.height  = '0px';
            box.style.opacity = '0';
            box.style['padding-top'] = '0px';
            this.down = false;
        }else {
            this.getUserNotification();
            box.style.height  = '90vh';
            box.style.opacity = '1';
            box.style['padding-top'] = '50px';
            this.down = true;
        }
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            const box = document.getElementById('box');
            box.style.height  = '0px';
            box.style.opacity = '0';
            this.down = false;
        }
    }

    getUserNotification() {
        if (localStorage.getItem('sub') && getToken() != '') {
            this.loading = true;
            this.store.dispatch(UserActions.get_user_notification({
                user_sub: localStorage.getItem('sub')
            }));
        } else {
            clearInterval(this.notificationInterval);
        }
    }

    isEmptyNotification() {
        return this.todayNotification.length == 0 && this.lastMonthNotification.length == 0 && this.lastWeekNotification.length == 0;
    }

    getUserNotificationResult() {
        this.userNotification = this.userNotification$.subscribe(result => {
            if (result) {
                this.loading = false;
                result = result as NotificationDays[];
                if (result.length > 0) {
                    this.notifyWarning = result[0].notification_status;
                    const array = result[0];
                    for (const key in array) {
                        if (Object.prototype.hasOwnProperty.call(array, key)) {
                            switch (key) {
                                case 'today':
                                    this.todayNotification = array[key];
                                    break;
                                case 'last_week':
                                    this.lastWeekNotification = array[key];
                                    break;
                                case 'last_month':
                                    this.lastMonthNotification = array[key];
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
                this.store.dispatch(UserActions.clear_notification());
            }
        });
    }

    markAllAsRead() {
        if (!this.notifyWarning) {
            return 0;
        }
        let notifications: string[] = [];
        notifications = notifications.concat(this.todayNotification.map(element => {
            if (!element.visibility) {
                return element.hashnotification;
            }
        }));
        notifications = notifications.concat(this.lastWeekNotification.map(element => {
            if (!element.visibility) {
                return element.hashnotification;
            }
        }));
        notifications = notifications.concat(this.lastMonthNotification.map(element => {
            if (!element.visibility) {
                return element.hashnotification;
            }
        }));
        this.isMarkAll = true;
        this.store.dispatch(UserActions.notification_visibility({
            sub: localStorage.getItem('sub'),
            hashNotification: notifications.filter(n => n)
        }));
        this.notifyRedirectResult();
    }

    notifyRedirect(notification: UserNotification) {
        if (notification.visibility == false && !this.clickNotify) {
            this.clickNotify = true;
            this.store.dispatch(UserActions.notification_visibility({
                sub: localStorage.getItem('sub'),
                hashNotification: notification.hashnotification
            }));
            this.notifyRedirectResult();
        }
    }

    notifyRedirectResult() {
        this.notificationVisibility = this.notificationVisibility$.subscribe(result => {
            if (result) {
                this.clickNotify = false;
                if (result.status == OperationStatus.Success) {
                    if (!this.isMarkAll) {
                        this.toggleNotify();
                    } else {
                        this.getUserNotification();
                    }
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'notify-fail', result.message);
                }
                this.isMarkAll = false;
            }
        });
    }

    routerNotify() {
        switch (localStorage.getItem('profile_name')) {
            case 'cd343bfc-17e8-11ec-9621-0242ac130002': //Parceiro
                this.router.navigateByUrl('/users/clients/list');
                break;
            default: //Cliente
                this.router.navigateByUrl('/cameras/list');
                break;
        }
    }

    oneSignalEvent() {
        // For when the app is opened from a notification click (optional)
        this.oneSignal.Notifications.addEventListener('foregroundWillDisplay', (event) => {
            console.log('The notification was dismissed!', event);
            this.getUserNotification();
        });
    }

}
