import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router, NavigationStart } from '@angular/router';
import { SharedService } from 'app/Shared/Services/shared.service';

export const profiles: string[] = [
    'Comum',
    'Cliente',
    'Associado',
    'Financeiro',
    'MasterAdmin',
    'Comercial',
    'Convidado',
    'Operador'
];

declare interface RouteInfo {
    path: string; //caminho
    title: string; //nome de exibição
    icon: string; //classe do ícone Material
    type: string; //Tipo de item, se for 'link' redireciona direto pra uma página, se for 'sub' contém sub-itens
    active: string; //classe css ou scss pra renderizar item quando estiver ativo
    profile: string[]; //Perfis que podem acessar a rota
    collapse?: string;
    children?: DropDownItem[]; //lista de sub-itens
    permission?: string;
    activeURLs: string[];
}

declare interface DropDownItem{
    path: string;
    title: string;
    ab: string;
}

declare interface PartnerPermission {
    view_live: boolean;
    view_recording: boolean;
}

export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'Entrar',  icon:'person', class: '' },
    // {
    //     path: 'maps/home',
    //     title: 'Mapa de câmeras',
    //     icon: 'map',
    //     type: 'link',
    //     active: 'active',
    //     profile: ['Cliente']
    // },
    {
        path: '/home',
        title: 'Dashboard',
        icon: 'home',
        type: 'link',
        active: 'active',
        profile: ['Cliente', 'Partner', 'AssociatePartner', 'PartnerViewer', 'AssociatePartnerViewer'],
        activeURLs: ['/home']
    },
    {
        path: '/cameras/view',
        title: 'Mosaico',
        icon: 'mosaic',
        type: 'link',
        active: 'active',
        profile: ['Cliente', 'Convidado', 'PartnerViewer', 'AssociatePartnerViewer'],
        permission: 'view_live',
        activeURLs: ['/cameras/view']
    },
    {
        path: '/cameras/retrieve',
        title: 'Gravações',
        icon: 'retrieve',
        type: 'link',
        active: 'active',
        profile: ['Cliente', 'Convidado', 'PartnerViewer', 'AssociatePartnerViewer'],
        permission: 'view_recording',
        activeURLs: ['/cameras/retrieve']
    },
    {
        path: '/cameras/list',
        title: 'Minhas câmeras',
        icon: 'list',
        type: 'link',
        active: 'active',
        profile: ['Cliente'],
        activeURLs: ['/cameras/list', '/cameras/cadastro']
    },
    {
        path: '/cameras/alert',
        title: 'Alertas e Detecções',
        icon: 'danger',
        type: 'link',
        active: 'active',
        profile: ['Cliente', 'Convidado'],
        permission: 'view_alert_history',
        activeURLs: ['/cameras/alert']
    },
    {
        path: '/users/clients/list',
        title: 'Clientes',
        icon: 'clients',
        type: 'link',
        active: 'active',
        profile: ['Partner', 'AssociatePartner', 'PartnerViewer', 'AssociatePartnerViewer'],
        activeURLs: ['/users/clients/list']
    },
    {
        path: '/users/partners',
        title: 'Parceiros',
        icon: 'partners',
        type: 'link',
        active: 'active',
        profile: ['MasterAdmin', 'Partner', 'AssociatePartner', 'PartnerViewer', 'AssociatePartnerViewer'],
        activeURLs: ['/users/partners']
    },
    {
        path: '/cameras/stock',
        title: 'Estoque',
        icon: 'kits',
        type: 'link',
        active: 'active',
        profile: ['Partner', 'AssociatePartner', 'PartnerViewer', 'AssociatePartnerViewer'],
        activeURLs: ['/cameras/stock']
    },
    {
        path: '/cameras/groups',
        title: 'Grupo de Câmeras',
        icon: 'groups',
        type: 'link',
        active: 'active',
        profile: ['Partner', 'AssociatePartner', 'PartnerViewer', 'AssociatePartnerViewer'],
        activeURLs: ['/cameras/groups']
    },
    {
        path: '/users/kits',
        title: 'Lista de Kits',
        icon: 'kits',
        type: 'link',
        active: 'active',
        profile: ['MasterAdmin'],
        activeURLs: ['/users/kits']
    }
    /*{
        path: '/master/deals',
        title: 'Negociações',
        icon: 'people',
        type: 'sub',
        active: 'active',
        collapse: 'deals',
        children: [
            { path: 'dashboard', title: 'Visão Geral', ab: 'V'},
            { path: 'pending', title: 'Pendentes', ab: 'P'},
            { path: 'history', title: 'Histórico', ab: 'H'}
        ]
    },
    {
        path: '/master/cadastros',
        title: 'Cadastros',
        icon: 'create',
        type: 'link',
        active: 'active',
        collapse: 'cadastros',
        children: [
            {path: 'planos', title: 'Planos', ab: 'Pl'}
        ]
    },
    {
        path: '/master/settings',
        title: 'Configurações',
        icon: 'settings',
        type: 'sub',
        active: 'active',
        collapse: 'settings',
        children: [
            {path: 'user', title: 'Usuários', ab: 'U'},
            {path: 'profile', title: 'Perfis', ab: 'P'}
        ]
    }*/
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    loggedIn = false;
    isMobile: boolean;
    isActive: boolean;
    profileType: string;
    clientPerms: string[] = [];
    actualPath: string;
    userSession: PartnerPermission;
    collapsed = false;

    constructor(private router: Router) {
        router.events.forEach((event) => {
            if (event instanceof NavigationStart)
            {
                this.loggedIn = (localStorage.getItem('id-token') != null);
            }
        });
    }

    ngOnInit() {
        this.sidebarStatus();
        this.clientPermissions();
        this.router.events.subscribe(el => {
            this.actualPath = this.router.url;
        });
        this.isMobile = false;
        this.isActive = false;
        switch (localStorage.getItem('profile_name')) {
            case 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3':
                this.profileType = this.associatePermission();
                break;
            case 'cd343bfc-17e8-11ec-9621-0242ac130002': //Parceiro
                this.profileType = this.partnerPermission();
                break;
            case '7d4e9f24-17e8-11ec-9621-0242ac130002': //Master
                this.profileType = 'MasterAdmin';
                break;
            case 'bb653b3a-fdb3-4438-bce6-012585b5268f': //Convidado
                this.profileType = 'Convidado';
                break;
            case '61902d2b-3ada-49f3-b42a-1775bc064bb0': //Cliente de Parceiro
            case 'e50483b8-17e8-11ec-9621-0242ac130002': //Cliente Avantia
            default:
                this.profileType = 'Cliente';
                break;
        }
        this.menuItems = ROUTES.filter((menuItem) => {
            if ((menuItem.profile.includes(this.profileType) && !(this.profileType == 'Convidado' && !this.clientPerms.includes(menuItem.permission)))) {
                return menuItem;
            }
        });
    }

    onMouseEnter() {
        this.collapsed = true;
    }
    onMouseLeave() {
        this.collapsed = false;
    }

    partnerPermission(): any {
        this.userSession = JSON.parse(localStorage.getItem('partner_permission'));
        if (this.userSession.view_live && this.userSession.view_recording) {
            return 'PartnerViewer';
        } else {
            return 'Partner';
        }
    }

    associatePermission(): any {
        this.userSession = JSON.parse(localStorage.getItem('partner_permission'));
        if (this.userSession.view_live && this.userSession.view_recording) {
            return 'AssociatePartnerViewer';
        } else {
            return 'AssociatePartner';
        }
    }

    goToHome() {
    //this.router.navigateByUrl('maps/home');
        window.open('maps/home', '_blank');
    }

    onResize(event) {
        if (this.isMobileMenu()) {
            if (!this.isMobile) {
                SharedService.instance.setSidebarStatus(false);
                this.isMobile = true;
            }
        } else {
            if (this.isMobile){
                SharedService.instance.setSidebarStatus(false);
                this.isMobile = false;
            }
        }
    }

    isMobileMenu() {
        if (window.innerWidth > 599) {
            return false;
        } else {
            return true;
        }
    }

    updatePS(): void{
        if (window.matchMedia('(min-width: 960px)').matches && !this.isMac())
        {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, {wheelSpeed: 2, suppressScrollX: true});
        }
    }

    isMac(): boolean{
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0)
        {
            bool = true;
        }
        return bool;
    }

    show(menuItem: RouteInfo): boolean
    {
        const expression = (menuItem.title != 'Câmeras') || (menuItem.title == 'Câmeras' && this.loggedIn);
        return expression;
    }

    openReleaseNotes()
    {
        window.open('https://avantialabs.com.br/release-notes/avanuv/');
    }

    sidebarStatus() {
        SharedService.instance.getSidebarStatus().subscribe( (value) => {
            this.isActive = value;
            const sidebar = document.querySelector('.sidebar');
            if (this.isActive) {
                sidebar.classList.add('active-sidebar');
            } else {
                sidebar.classList.remove('active-sidebar');
            }
        });
    }

    clientPermissions() {
        if (localStorage.getItem('guest_permission')) {
            this.clientPerms = localStorage.getItem('guest_permission').split('.');
        }
    }
}
