import { Component, OnInit, OnDestroy, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UntypedFormControl, Validators, UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { UserActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { validatePassword , confirmPassword, UserChangePassword} from '../models';
import { AppState } from 'app/store/model';
import { OperationStatus } from 'app/Shared/models';

export class ValidatorConfirmPass implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const exp = control && control.invalid && (control.dirty || control.touched);
        return exp || (form.errors != null);
    }
}

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './modal-change-password.component.html',
    styleUrls: ['./user-change-password.component.scss']
})

export class ChangePasswordModalComponent implements OnInit, OnDestroy {

    public passwordForm = new UntypedFormGroup({
        currentPass: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        newPass: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        confirmNewPass: new UntypedFormControl('', [Validators.required])
    }, { validators: confirmPassword });
    matcher = new ValidatorConfirmPass();
    confirmation = false;
    isActive = false;
    viewError = false;
    outsideBoolean = false;
    upPassword: UserChangePassword = { lastPassword: '', newPassword: ''};

    constructor(public dialogRef: MatDialogRef<ChangePasswordModalComponent>, private readonly store: Store<AppState>, private element: ElementRef) { }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            if (this.outsideBoolean) {
                this.closeCancel();
            } else {
                this.outsideBoolean = true;
            }
        }
    }

    showPassword (id: string) {
        const x = document.getElementById(id);
        if (x.getAttribute('type') === 'password') {
            x.setAttribute('type', 'text');
        } else {
            x.setAttribute('type', 'password');
        }
        const y = document.getElementById(id + 'icon');
        if (y.innerText === 'visibility') {
            y.innerText = 'visibility_off';
        } else {
            y.innerText = 'visibility';
        }
    }

    closeCancel()
    {
        this.dialogRef.close(OperationStatus.Unloaded);
    }

    closeConfirm() {
        this.confirmation = false;
        let errorPass = false;
        this.viewError = true;
        if (this.passwordForm.errors != null) {
            if (this.passwordForm.errors.passwordMatch == null) {
                this.confirmation = true;
            }
            else {
                this.confirmation = this.passwordForm.errors.passwordMatch;
            }
        }
        if (this.confirmation) {
            errorPass = true;
        }
        if (this.passwordForm.get('currentPass').invalid) {
            errorPass = true;
            this.passwordForm.get('currentPass').markAsTouched();
        }
        if (this.passwordForm.get('newPass').invalid) {
            errorPass = true;
            this.passwordForm.get('newPass').markAsTouched();
        }
        if (this.passwordForm.get('confirmNewPass').invalid) {
            errorPass = true;
            this.passwordForm.get('confirmNewPass').markAsTouched();
        }
        if (!errorPass) {
            this.upPassword.lastPassword = this.passwordForm.get('currentPass').value;
            this.upPassword.newPassword = this.passwordForm.get('newPass').value;
            this.store.dispatch(UserActions.change_password({ updatePassword: this.upPassword }));
            this.dialogRef.close(OperationStatus.Success);
        }
    }
}

@Component({
    selector: 'app-user-change-password',
    templateUrl: './user-change-password.component.html',
    styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.Users.modal_change_password);

    currentDialog: MatDialogRef<any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subCombined: Subscription;
    @Output() loadChangePassEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit() {
        this.subCombined = this.modal_open$.subscribe(modal => {
            if (modal == OperationStatus.Loading) {
                this.currentDialog = this.dialog.open(ChangePasswordModalComponent, {
                    width: 'fit-content',
                    height: 'fit-content',
                    disableClose: true,
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                    if (result === OperationStatus.Success)
                    {
                        this.loadChangePassEmitter.emit(true);
                        this.store.dispatch(UserActions.change_password_modal({ payload: OperationStatus.Success }));
                    } else {
                        this.store.dispatch(UserActions.change_password_modal({ payload: OperationStatus.Unloaded }));
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }

        this.store.dispatch(UserActions.cleanup());
    }

}
