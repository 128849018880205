import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetectionComponent } from './base-detection/base-detection.component';
import { detectionRoutes } from './detections.routing';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModulesModule } from 'app/Shared/shared-modules/shared-modules.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'app/components/components.module';

@NgModule({
    declarations: [
        BaseDetectionComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModulesModule,
        RouterModule.forChild(detectionRoutes),
        ComponentsModule
    ],
    exports: [
        BaseDetectionComponent
    ]
})
export class DetectionsModule { }
