import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Camera } from 'app/Users/models';

@Component({
    selector: 'app-rtmp-deletion-tutorial',
    templateUrl: './camera-rtmp-deletion-tutorial.component.html',
    styleUrls: ['./camera-rtmp-deletion-tutorial.component.scss']
})
export class CameraRtmpDeletionTutorialComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();

    camera: Camera;
    currentStep: number;
    rtmpWarning: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<CameraRtmpDeletionTutorialComponent>,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.currentStep = 1;
        if (this.data.data.camera.rtmp_warning == true){
            this.rtmpWarning = true;
        }
        else{
            this.camera = this.data.data.camera;
            this.rtmpWarning = false;
        }
    }

    ngOnDestroy () {
        this.closeModal();
    }

    nextStep(): void {
        this.currentStep++;
    }

    prevStep(): void {
        this.currentStep--;
    }

    closeModal(): void {
        this.modalClose.emit();
    }

    submitDelete(): void {
        this.sharedService.statusConfirmationSwalValidation({isConfirmed: true}, 'deleteCam');
        this.closeModal();
    }
    close(): void {
        this.dialogRef.close();
    }
}
