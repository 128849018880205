<nz-spin [nzSpinning]="loading" [nzSize]="'large'" [nzIndicator]="loadingCameraTemplate" class="spin-mosaic">
<div class="main-content container-fluid">
    <div class="flex flex-row flex-wrap">
        <div class="w-full title_row">
            <span class="title-text">
                {{isEdit ? 'Editar' : 'Criar'}} mosaico
            </span>
            <span nz-icon nzType="close" nzTheme="outline" id="btn-close" (click)="closeCancel()"></span>
        </div>
        <div class="sidebar-wrapper">
            <div class="filter-mosaic">
                <div *ngIf="isPartner && !loadingClients" class="client_select">
                    <app-filter [list]="clientListSimple" type="simple" placeholder="Selecionar cliente"
                     (filterStatusChange)="selectClient($event)" [showSelected]="true" [hasIconPrefix]="true"
                     iconType="user" [hasToggle]="true" [search]="true"></app-filter>
                </div>
                <div class="camera_filter">
                    <nz-input-group [nzSuffix]="suffixIconSearch" class="input-container">
                        <input type="text" nz-input placeholder="Pesquisar" [(ngModel)]="searchText" class="input-value" (keyup)="searchCameras()"/>
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <i nz-icon nzType="search" (click)="searchCameras()"></i>
                    </ng-template>
                </div>
            </div>
            <div class="camera-card">
                <div id="container" class="camera-body search-results" *ngIf="(!loadingCameras || !loadingGroup);else loadingCameraTemplate"
                        infinite-scroll [infiniteScrollDistance]="0.05" [infiniteScrollThrottle]="1"
                        [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
                    <ng-container *ngFor="let camera of allCameras; index as indice">
                        <div class="restrain-width">
                            <div class="cam-row screenshot_cams">
                                <div [ngClass]="camera.status === false ? 'image_thumbnail' : ''"
                                    [id]="camera.id">
                                    <img class="round flex-size" [ngClass]="{'thumb': inMosaic(camera)}"
                                        (click)="chooseCam(camera)" src="{{camera.thumbnail}}" (error)="brokenImageHandler($event)">
                                    <span></span>
                                </div>
                            </div>
                            <div class="row cam-row cam-name alias_cams xs:min-w-[90px]">
                                <span nz-tooltip [nzTooltipTitle]="camera.alias" nzTooltipOverlayClassName="cam-name"
                                nzTooltipPlacement="top" [nzTooltipOverlayStyle]="{'top': '6px'}">
                                    {{displayCamName(camera.alias)}}
                                </span>
                                <div class="id-cam-container">
                                    <span class="id-cam">#{{camera.id}}</span>
                                </div>
                                <div class="guest_name_container">
                                    <i nz-icon nzType="user" nzTheme="outline"></i>
                                    <span class="guest_name">{{formatOwnerName(camera.owner ? camera.owner : camera.name)}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="loading-cam-modal" *ngIf="loadingScroll">
                        <span nz-icon nzType="loading" nzTheme="outline"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mosaic-card">
            <div class="sidebar-wrapper align-end">
                <div nz-row class="mosaic_types">
                    <div nz-col [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }" class="type">
                        <button id="oneCamMosaic" class="btn-mosaic" (click)="chooseMosaic(1)" tabindex="-1">
                            <img src="../../../assets/img/mosaic_1.svg" alt="">
                        </button>
                    </div>
                    <div nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }" class="type">
                        <button id="twoCamsMosaic" class="btn-mosaic" (click)="chooseMosaic(2)" tabindex="-1">
                            <img src="../../../assets/img/mosaic_4.svg" alt="">
                        </button>
                    </div>
                    <div nz-col [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }" class="type">
                        <button id="threeCamsMosaic" class="btn-mosaic" (click)="chooseMosaic(3)" tabindex="-1">
                            <img src="../../../assets/img/mosaic_9.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div *ngFor="let elem of grid; index as i" class="{{getContainerWidth(newMosaic.width)}}">
                    <img class="cam-mosaic" src="{{getCameraThumbnail(i)}}" (error)="brokenImageHandler($event)">
                    <button (click)="removeFromMosaic(i)" class="btn btn-sm btn-round btn-just-icon top-right-corner">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="flex w-2/3">
                <input nz-input type="text" id="mosaicName" matInput [formControl]="mosaicAlias" class="mosaic-form"
                placeholder="Dê um nome ao mosaico" maxlength="25">
            </div>
        </div>
        <div class="flex w-full justify-end gap-2">
            <button (click)="closeCancel()" class="flex w-2/12 btn btn-ant btn-secondary">Cancelar</button>
            <button (click)="createMosaic()" class="flex w-2/12 btn btn-ant btn-primary">Salvar</button>
        </div>
    </div>
    <!-- <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></span> -->
</div>
</nz-spin>
<ng-template #loadingCameraTemplate>
    <div class="loadingCamera">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</ng-template>
<!-- <ng-template #loadingMosaic [show]="loading">
    <div class="loadingCamera">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</ng-template> -->
