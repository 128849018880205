import { Component, OnDestroy, OnInit, EventEmitter, Output, HostListener, ElementRef, Inject  } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserActions } from '../Services/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { OperationStatus } from 'app/Shared/models';
import { UserInventory } from '../models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';

export interface GuestElements {
    name: string;
    email: string;
    permission: 'common' | 'admin';
}

let ELEMENT_DATA: GuestElements[] = [
    {name: '', email: '', permission: 'common'},
];

@Component({
    selector: 'app-user-invite-guests-modal',
    templateUrl: './modal-user-invite-guests.component.html',
    styleUrls: ['./user-invite-guests.component.scss']
})
export class UserInviteGuestsModalComponent implements OnInit, OnDestroy {
    readonly inviteAssociatedResult$ = this.store.select((state: AppState) => state.Users.inviteAssociatedResult);

    guestSubscription: Subscription;
    selectedValue: string;
    loading: boolean;
    outsideBoolean = false;
    inventories: UserInventory;
    context = localStorage.getItem('context');
    dataSource = new MatTableDataSource(ELEMENT_DATA);
    displayedColumns: string[] = ['nameTwo', 'emailTwo', 'type', 'action'];

    guestsElements: GuestElements = {
        name: '',
        email: '',
        permission: 'common',
    };

    loadingSubmit: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UserInviteGuestsModalComponent>,
        private element: ElementRef, private validationResponse: ValidationResponseHandlerModule,
        private readonly store: Store<AppState>, private router: Router) { }

    ngOnInit(): void {
        this.loadingSubmit = false;
    }

    ngOnDestroy(): void {
        this.store.dispatch(UserActions.invite_associated_cleanup());
        if (this.guestSubscription) {
            this.guestSubscription.unsubscribe();
        }
        ELEMENT_DATA = [{name: '', email: '', permission: 'common'}];
        this.closeModal();
    }

    closeModal(){
        this.dialogRef.close(OperationStatus.Unloaded);
        localStorage.setItem('context', '');
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (this.context == 'summary') {
            this.router.navigate(['/users/profile']);
        } else {
            this.router.navigate(['/users/client/view']);
        }
    }

    newGuests() {
        ELEMENT_DATA.push(this.guestsElements);
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.guestsElements = {
            name: '',
            email: '',
            permission: 'common'
        };
    }

    popGuest(i) {
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        ELEMENT_DATA.splice(i, 1);
        this.guestsElements = {
            name: '',
            email: '',
            permission: 'common'
        };
    }

    guestSelect(): boolean {
        return (ELEMENT_DATA.length < 5 && this.context != 'summary') || (ELEMENT_DATA.length < 3 && this.context === 'summary');
    }

    submitForm() {
        if (this.loadingSubmit) {
            return;
        }

        this.loadingSubmit = true;

        let validate = true;
        const guests = this.dataSource.filteredData;
        guests.forEach((element) => {
            if (validate) {
                if (element.email !== '' && element.name !== '' && this.validateEmail(element.email)) {
                    validate = true;
                } else {
                    validate = false;
                }
            }
        });

        if (validate) {
            if (this.context == 'summary') {
                this.store.dispatch(UserActions.invite_associated({
                    sub: localStorage.getItem('sub'),
                    resend: false,
                    associates: guests
                }));
            } else {
                this.store.dispatch(UserActions.invite_associated({
                    sub: localStorage.getItem('sub'),
                    child_sub: localStorage.getItem('clientView'),
                    resend: false,
                    associates: guests
                }));
            }
            this.subscribeGuestResponse();
        } else {
            this.loadingSubmit = false;
            this.validationResponse.validationResponseHandler(400, 'user', 'error-associated-form', 'user.error_associated_form');
        }
    }

    subscribeGuestResponse() {
        this.guestSubscription = this.inviteAssociatedResult$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'invite-associated', result.message);
                    this.closeModal();
                    setTimeout(() => { this.reload(); }, 1000);
                } else {
                    this.store.dispatch(UserActions.invite_associated_cleanup());
                    this.validationResponse.validationResponseHandler(400, 'user', 'invite-associated-fail', result.message);
                }
            }
        });
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    /*  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.element.nativeElement.contains(event.target)) {
      if (this.outsideBoolean) {
        this.closeModal();
      } else {
        this.outsideBoolean = true;
      }
    }
  } */

}

@Component({
    selector: 'app-user-invite-guests',
    templateUrl: './user-invite-guests.component.html',
    styleUrls: ['./user-invite-guests.component.scss']
})
export class UserInviteGuestsComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.Users.modal_add_guest);

    currentDialog: MatDialogRef<any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subCombined: Subscription;
    @Output() loadAddGuestEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit() {
        this.subCombined = this.modal_open$.subscribe(modal => {
            if (modal == OperationStatus.Loading) {
                this.currentDialog = this.dialog.open(UserInviteGuestsModalComponent, {
                    width: 'fit-content',
                    height: 'fit-content',
                    disableClose: true,
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                    if (result === OperationStatus.Success)
                    {
                        this.loadAddGuestEmitter.emit(true);
                        this.store.dispatch(UserActions.add_guest_modal({ payload: OperationStatus.Success }));
                    } else {
                        this.store.dispatch(UserActions.add_guest_modal({ payload: OperationStatus.Unloaded }));
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
    }

}
