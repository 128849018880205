<!-- Modal de Gerenciamento de câmera -->
<app-camera-manege *ngIf="manegeComponent === false" (modalClose)="onNoClick()"></app-camera-manege>
<!-- Modal de Video de um alerta -->
<app-video-detection *ngIf="alertComponent === false" (modalClose)="onNoClick()"></app-video-detection>
<!-- Modal de Adicionar um parceiro -->
<app-user-partners-form *ngIf="partnerComponent === false" (modalClose)="onNoClick()"></app-user-partners-form>
<!-- Modal de Adicionar cameras e detecções a um cliente -->
<app-user-add-detections *ngIf="addDetections === false" (modalClose)="onNoClick()"></app-user-add-detections>
<!-- Modal de atribuir kits a um parceiro -->
<app-user-add-kit-form *ngIf="kitComponent === false" (modalClose)="onNoClick()"></app-user-add-kit-form>
<!-- Modal de Adicionar Clientes -->
<app-user-client-form *ngIf="addClient === false" (modalClose)="onNoClick()"></app-user-client-form>
<!-- Modal de Editar Clientes -->
<app-user-client-edit *ngIf="editClient === false" (modalClose)="onNoClick()"></app-user-client-edit>
<!-- Modal de Criar um novo Kit -->
<app-user-kits-add *ngIf="addKit === false" (modalClose)="onNoClick()"></app-user-kits-add>
<!-- Modal de solicitar um Kit -->
<app-user-kit-request *ngIf="requestKits === false" (modalClose)="onNoClick()"></app-user-kit-request>
<!-- Modal de tutorial de deleção de câmera RTMP -->
<app-rtmp-deletion-tutorial *ngIf="deleteRTMP === false" (modalClose)="onNoClick()"></app-rtmp-deletion-tutorial>
<!-- Modal de edição de KIT -->
<app-kit-edit-modal *ngIf="editKit === false" (modalClose)="onNoClick()"></app-kit-edit-modal>
<!-- Modal de adcicionar Grupo de Câmeras -->
<app-camera-group-create [isPublic]="modalData.data" *ngIf="addGroup === false" (modalClose)="onNoClick()"></app-camera-group-create>
<!-- Modal de configuração de Grupo de Câmeras -->
<app-camera-group-config *ngIf="configGroup === false" (modalClose)="onNoClick()"></app-camera-group-config>
<!-- Modal de adição de assets em Grupo de Câmeras -->
<app-add-info-modal [data]="modalData.data" *ngIf="addInfoGroup === false" (modalClose)="onNoClick()"></app-add-info-modal>
<!-- Modal de visualização da câmera ao vivo -->
<app-camera-live *ngIf="viewCamera == false" (modalClose)="onNoClick()"></app-camera-live>
<!-- Modal de adição de mais câmeras a um convidado -->
<app-add-cam-guest-modal [data]="modalData.data" *ngIf="addCamGuest === false" (modalClose)="onNoClick()"></app-add-cam-guest-modal>
<!-- Modal de configuração do Calendário de Feriados -->
<app-user-calendar-config *ngIf="configCalendar === false" (modalClose)="onNoClick()"></app-user-calendar-config>
<!-- Modal de criação de Calendário de Feriados -->
<app-user-calendar-create *ngIf="createCalendar === false" (modalClose)="onNoClick()"></app-user-calendar-create>
<!-- Modal de GLPI -->
<!-- <app-glpi [data]="modalData.data" *ngIf="viewGLPI === false" (modalClose)="onNoClick()"></app-glpi> -->
<!-- Modal de listagem do Calendário de Feriados -->
<app-user-calendar-list *ngIf="allHolidayCalendar === false" (modalClose)="onNoClick()"></app-user-calendar-list>