<div class="main-content background-container">
    <!-- Formulario para preenchimento do email do convidado -->
    <div class="col-md-12 button-close">
        <i class="material-icons hover-close" (click)="closeCancel()">close</i>
    </div>
    <div class="row title_style_1">
        <span class="style-title">Convites e permissões de acessos da câmera</span>
    </div>
    <div class="row">
        <span class="style-title cam-title">{{cameraName}}</span>
    </div>
    <form id="pressEnter">
        <div class="row container_form_top" *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate)">
            <div class="col-md-5 col-sm-6 col-6 email_container">
                <div class="has-default">
                    <span class="input-title">E-mail</span>
                    <ng-template #prefixTemplateUser><i class="input-icon" nz-icon nzType="user" nzTheme="outline"></i></ng-template>
                    <nz-input-group [nzPrefix]="prefixTemplateUser" class="email-input">
                        <input class="input-placeholder" type="email" nz-input [formControl]="emailFormControl" placeholder="Digite aqui...">
                    </nz-input-group>
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Insira um email válido para realizar o convite
                    </mat-error>
                    <mat-error *ngIf="userEmail && emailFormControl.value && (emailFormControl.value == userEmail)">
                        *Não é possível compartilhar a câmera com si mesmo
                    </mat-error>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-6">
                <div class="form-group permissions_container">
                    <span class="input-title">Autorizar acesso a</span>
                    <!-- Fazer implementação da api de app-filter -->
                    <app-filter [list]="permissionLabels" #filterPermission
                    [styles]="['multiselect-alternate-style']"
                    type="multiselect"
                    placeholder="Selecione aqui..."
                    [feedback]="true"
                    [hideActiveFill]="true"
                    (filterStatusChange)="changePermissions($event)"></app-filter>
                    <!-- <mat-error *ngIf="permissoes">
                       Permissão é <strong>obrigatório!</strong>
                    </mat-error> -->
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-6 button_invite" style="margin-top: 35px;"
                *ngIf="inventories?.inventory.guests.available != 0">
                <button (click)="inviteUser(filterPermission)" type="button"
                    [ngClass]="{'disabled': inventories?.inventory.guests.available === 0 || tableGuestsCam.data.length >= camera_max_invites}" 
                    class="btn btn-md btn-primary tamButton">Convidar para câmera
                </button>
            </div>
            <div *ngIf="inventories?.inventory.guests.available === 0 || tableGuestsCam.data.length >= camera_max_invites"
                class="col-md-3 col-sm-6 col-6 button_invite" style="margin-top: 35px;" nz-tooltip nzTooltipPlacement="topRight"
                nzTooltipTitle="Limite de convidados atingido" nzTooltipOverlayClassName="cam-name">
                <button (click)="inviteUser(filterPermission)" type="button"
                    [ngClass]="{'disabled': inventories?.inventory.guests.available === 0 || tableGuestsCam.data.length >= camera_max_invites}" 
                    class="btn btn-md btn-primary tamButton">Convidar para câmera
                </button>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="emailFormControl.valid">
        <span (click)="openModalCamGuests()" class="more-cameras"> <i class="add-cam-icon" nz-icon nzType="video-camera-add" nzTheme="outline"></i> Convidar para outras câmeras</span>
    </div>
    <div *ngIf="!loadingModalInvite; else matSpinner" class="guest-table container_table_bottom">
        <ng-container *ngIf="tableGuestsCam.data.length == 0">
            <span>Não há convites feitos para essa câmera</span>
        </ng-container>
        <div *ngIf="tableGuestsCam.data.length > 0">
            <nz-table #guestsTable [nzData]="tableGuestsCam.data" class="table style-table antd-table" [nzFrontPagination]="false">
                <thead>
                  <tr>
                    <th style="width: 9%"></th>
                    <th>Nome</th>
                    <th class="hide-email">E-mail</th>
                    <th style="width: 21%;">Permissões</th>
                    <th>Situação</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of guestsTable?.data">
                    <!-- Imagem -->
                    <td>
                        <img #picture class="profile_pic" [src]="getUrl(data)"
                            onerror="this.onerror=null;this.src='../../../assets/img/user_invited.svg'" height="48"
                            width="48">
                    </td>
                    <!-- Nome -->
                    <td>
                        <div  class="style-title-column-name">
                            {{data.name}}
                        </div>
                    </td>
                     <!-- E-mail -->
                    <td class="style-title-column-email">
                        {{data.email}}
                    </td>
                     <!-- Permissões -->
                    <ng-container [formGroup]="permissionForm">
                        <td>
                            <app-filter [list]="permissionLabels" *ngIf="data && (!is_guest && (!isAssociate || adminAssociate || adminClientAssociate))"
                                type="multiselect"
                                [styles]="['multiselect-alternate-style']"
                                placeholder="Selecione aqui..."
                                [feedback]="true"
                                [hasSubmit]="true"
                                [hideActiveFill]="false"
                                [selectedList]="parseGuestPermissions(data)"
                                (submitChanges)="submitPermissionChange($event, data)"></app-filter>
                            <div *ngIf="(is_guest || (isAssociate && !adminAssociate && !adminClientAssociate))" class="style-title-column-name">
                                Permissões concedidas: {{parseGuestPermissions(data).length}}
                            </div>
                        </td>
                    </ng-container>
                     <!-- Situação -->
                    <td [ngClass]="{
                        'member':checkUserStatus(data) === true,
                        'not-member':checkUserStatus(data) === false,
                        'not-member-vinte':checkUserStatusDate(data) === 'vinte_cinco',
                        'not-member-trinta':checkUserStatusDate(data) === 'trinta'
                    }"></td>
                    <!-- Botão de excluir -->
                    <td>
                        <ng-container *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate)">
                            <button class="delete-button" (click)="delete(data)">
                                <i nz-icon nz-popover nzPopoverContent="Deletar convidado" nzType="delete" nzTheme="outline" class="delete-icon"></i>
                            </button>
                        </ng-container>
                    </td>
                    <!-- Botão de excluir -->    
                    <td>
                        <ng-container *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate)">
                            <ng-container *ngIf="checkUserStatus(data) === false">
                                <button *ngIf="!showSpinner(data) && !checkUserStatus(data)"
                                (click)="resendEmail(data)" class="delete-button">
                                    <i nz-icon nz-popover nzPopoverContent="Reenviar convite" nzType="mail" nzTheme="outline" class="delete-icon"></i>
                                </button>
                                <span nz-icon *ngIf="showSpinner(data)" nzType="loading" nzTheme="outline" style="font-size: 30px"></span>
                            </ng-container>
                        </ng-container>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
        </div>
    </div>
    <ng-template #matSpinner>
        <div class="row">
            <div class="col-md-10 ml-auto mr-auto">
                <span nz-icon class="loading-indicator" nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
            </div>
        </div>
    </ng-template>
</div>
