import { Action, createReducer, on } from '@ngrx/store';
import {
    User, UserState, ConfigAlarm, AlarmModalState, CognitoAttributes,
    UserBillings, UserBillingsHistory, UserBillingsDetails, CreditCard,
    Partners, UserInventory, Kit, UserNotification, Guest, UserClients, AssociatedUsers, PaginationGuests, PaginationClients, NotificationDays,
    Holidays
} from '../models';
import { Notification } from '../../Shared/Enums/Notification';
import { UserActions } from './actions';
import { Feedback, OperationStatus } from 'app/Shared/models';

const INITIAL_STATE: UserState = {
    userList: [],
    modal_open_config_alarm: AlarmModalState.Closed,
    upConfigAlarm: OperationStatus.Unloaded,
    delConfigAlarm: OperationStatus.Unloaded,
    upPassword: OperationStatus.Unloaded,
    modal_crop_image: 0,
    modal_change_password: OperationStatus.Unloaded,
    modal_change_link: OperationStatus.Unloaded,
    modal_add_guest: OperationStatus.Unloaded,
    modal_guest_settings: OperationStatus.Unloaded,
    userData: null,
    client_list: null,
    kitsList: [],
    deleteClientInventory: null,
    inviteAssociatedResult: null,
    updateResult: null,
    updateAssociated: null,
    updatePartnerResult: null,
    convertResult: null,
    convertGuestResult: null,
    profile_url: '',
    notificationVisibility: null,
    user_billings: null,
    user_billings_history: null,
    user_payment_method: null,
    registerPartnerUser: null,
    transferItemsFeedback: null,
    deleteResult: null,
    blockClient: null,
    updateClientResult: null,
    userInventory: null,
    userNotification: null,
    associatedUsers: null,
    deleteAssociated: null,
    createTicketKit: null,
    deletePartner: null,
    pageIndexClient: 1,
    pageIndexGuest: 1,
    nationalHolidays: null,
    holidays: null,
};


export const userReducer = createReducer(
    INITIAL_STATE,
    on(
        UserActions.get_users, state => state
    ),
    on(
        UserActions.get_users_success, (state, { payload }) => ({
            ...state,
            userList: payload as User[],
            req_success: Notification.Sucess,
        })
    ),
    on(
        UserActions.get_user, state => state
    ),
    on(
        UserActions.get_user_success, (state, { payload }) => ({
            ...state,
            user_detail: payload.meta.detail ? payload.payload as User : state.user_detail,
            user_handler: !payload.meta.detail ? payload.payload as User : state.user_handler,
            req_success: Notification.Sucess,
        })
    ),
    on(
        UserActions.get_user_data, state => state
    ),
    on(
        UserActions.get_user_data_result, (state, { payload }) => ({
            ...state,
            userData: payload as CognitoAttributes | Feedback
        })
    ),
    on(
        UserActions.get_user_billings, state => state
    ),
    on(
        UserActions.get_user_billings_result, (state, { payload }) => ({
            ...state,
            user_billings: payload as UserBillings | Feedback
        })
    ),
    on(
        UserActions.get_user_billings_history, state => state
    ),
    on(
        UserActions.get_user_billings_history_result, (state, { payload }) => ({
            ...state,
            user_billings_history: payload as UserBillingsHistory | Feedback
        })
    ),
    on(
        UserActions.get_user_billings_datails, state => state
    ),
    on(
        UserActions.get_user_billings_datails_result, (state, { payload }) => ({
            ...state,
            user_billings_details: payload as UserBillingsDetails | Feedback
        })
    ),
    on(
        UserActions.get_user_payment_method, state => state
    ),
    on(
        UserActions.get_user_payment_method_result, (state, { payload }) => ({
            ...state,
            user_payment_method: payload as CreditCard | Feedback
        })
    ),
    on(
        UserActions.create_partners, state => state
    ),
    on(
        UserActions.create_partners_status, (state, { payload }) => ({
            ...state,
            registerPartnerUser: payload as Feedback
        })
    ),
    on(
        UserActions.update_partner, state => state
    ),
    on(
        UserActions.update_partner_return, (state, { payload }) => ({
            ...state,
            updatePartnerResult: payload as Feedback
        })
    ),
    on(
        UserActions.get_partners, state => state
    ),
    on(
        UserActions.get_partners_result, (state, { payload }) => ({
            ...state,
            partnersList: payload as Partners | Feedback
        })
    ),
    on(
        UserActions.update_user, state => state
    ),
    on(
        UserActions.update_user_result, (state, { payload }) => ({
            ...state,
            updateResult: payload as Feedback
        })
    ),
    on(
        UserActions.update_associated, state => state
    ),
    on(
        UserActions.update_associated_result, (state, { payload }) => ({
            ...state,
            updateAssociated: payload as Feedback
        })
    ),
    on(
        UserActions.update_payment, state => state
    ),
    on(
        UserActions.update_payment_result, (state, { payload }) => ({
            ...state,
            updatePayment: payload as Feedback
        })
    ),
    on(
        UserActions.update_payment_result_clear, state => ({
            ...state,
            updatePayment: null,
            user_billings: null,
            user_billings_history: null,
            user_billings_details: null,
            user_payment_method: null
        })
    ),
    on(
        UserActions.publish_picture, state => state
    ),
    on(
        UserActions.publish_picture_success, (state, { payload }) => ({
            ...state,
            profile_url: payload as string
        })
    ),
    on(
        UserActions.notification_visibility, state => state
    ),
    on(
        UserActions.notification_visibility_success, (state, { payload }) => ({
            ...state,
            notificationVisibility: payload as Feedback
        })
    ),
    on(
        UserActions.create_config_alarm, state => state
    ),
    on(
        UserActions.create_config_alarm_return, (state, { payload }) => ({
            ...state,
            upConfigAlarm: payload as number
        })
    ),
    on(
        UserActions.get_configurations_alarm, state => state
    ),
    on(
        UserActions.get_configurations_alarm_return, (state, { payload }) => ({
            ...state,
            config_alarm: payload as ConfigAlarm
        })
    ),
    on(
        UserActions.set_configurations_alarm, (state, { payload }) => ({
            ...state,
            config_alarm: payload as ConfigAlarm
        })
    ),
    on(
        UserActions.update_modal_alarm, (state, { payload }) => ({
            ...state,
            modal_open_config_alarm: payload as number
        })
    ),
    on(
        UserActions.update_configurations_alarm, state => state
    ),
    on(
        UserActions.update_configurations_alarm_return, (state, { payload }) => ({
            ...state,
            upConfigAlarm: payload as number
        })
    ),
    on(
        UserActions.update_configurations_alarm_change_return, (state, { payload }) => ({
            ...state,
            upConfigAlarm: payload as number
        })
    ),
    on(
        UserActions.delete_config_alarm, state => state
    ),
    on(
        UserActions.delete_config_alarm_return, (state, { payload }) => ({
            ...state,
            delConfigAlarm: payload as number
        })
    ),
    on(
        UserActions.cleanup, state => ({
            ...state,
            userData: null,
            resendEmail: null,
            updateResult: null,
            updateAssociated: null,
            updatePartnerResult: null,
            convertResult: null,
            convertGuestResult: null,
            profile_url: '',
            notificationVisibility: null,
            user_billings: null,
            user_billings_history: null,
            user_payment_method: null,
            delConfigAlarm: OperationStatus.Unloaded,
            upConfigAlarm: OperationStatus.Unloaded,
            modal_open_config_alarm: AlarmModalState.Closed,
            modal_change_password: OperationStatus.Unloaded,
            registerPartnerUser: null,
            transferItemsFeedback: null,
            deleteResult: null,
            blockClient: null,
            kitsList: null,
            updateClientResult: null,
            userInventory: null,
            userNotification: null,
            deleteClientInventory: null,
            associatedUsers: null,
            deleteAssociated: null,
            createTicketKit: null,
            client_list: null,
            guests_list: null,
            pageIndexClient: 1,
            pageIndexGuest: 1,
            nationalHolidays: null,
            holidays: null,
        })
    ),
    on(
        UserActions.clear_notification, state => ({
            ...state,
            userNotification: null,
            notificationVisibility: null
        })
    ),
    on(
        UserActions.open_modal_crop, (state, { payload }) => ({
            ...state,
            modal_crop_image: 1,
            crop_event: payload as any
        })
    ),
    on(
        UserActions.close_modal_crop, state => ({
            ...state,
            modal_crop_image: 0,
            crop_event: null
        })
    ),
    on(
        UserActions.change_password, state => state
    ),
    on(
        UserActions.change_password_result, (state, { payload }) => ({
            ...state,
            upPassword: payload as OperationStatus,
        })
    ),
    on(
        UserActions.change_password_modal, (state, { payload }) => ({
            ...state,
            modal_change_password: payload as OperationStatus,
        })
    ),
    on(
        UserActions.change_link_modal, (state, { payload }) => ({
            ...state,
            modal_change_link: payload as OperationStatus,
        })
    ),
    on(
        UserActions.add_guest_modal, (state, { payload }) => ({
            ...state,
            modal_add_guest: payload as OperationStatus,
        })
    ),
    on(
        UserActions.guest_settings_modal, (state, { payload }) => ({
            ...state,
            modal_guest_settings: payload as OperationStatus,
        })
    ),
    on(
        UserActions.change_billing_address, state => state
    ),
    on(
        UserActions.change_billing_address_result, (state, { payload }) => ({
            ...state,
            upAddress: payload as Feedback,
        })
    ),
    on(
        UserActions.change_billing_address_result_clear, state => ({
            ...state,
            upAddress: null
        })
    ),
    on(
        UserActions.get_clients, state => state
    ),
    on(
        UserActions.get_clients_return, (state, { payload, pageIndex }) => ({
            ...state,
            client_list: payload as PaginationClients | UserClients,
            pageIndexClient: pageIndex ?? 1
        })
    ),
    on(
        UserActions.get_all_guests, state => state
    ),
    on(
        UserActions.get_all_guests_return, (state, { payload, pageIndex }) => ({
            ...state,
            guests_list: payload as PaginationGuests | Guest[],
            pageIndexGuest: pageIndex ?? 1
        })
    ),
    on(
        UserActions.resend_welcome_email, state => state
    ),
    on(
        UserActions.resend_welcome_email_return, (state, { payload }) => ({
            ...state,
            resendEmail: payload as Feedback
        })
    ),
    on(
        UserActions.get_user_inventory, state => state
    ),
    on(
        UserActions.get_user_inventory_result, (state, { payload }) => ({
            ...state,
            userInventory: payload as UserInventory | Feedback
        })
    ),
    on(
        UserActions.get_user_notification, state => state
    ),
    on(
        UserActions.get_user_notification_result, (state, { payload }) => ({
            ...state,
            userNotification: payload as NotificationDays[] | Feedback
        })
    ),
    on(
        UserActions.get_associated_users, state => state
    ),
    on(
        UserActions.get_associated_users_success, (state, { payload }) => ({
            ...state,
            associatedUsers: payload as AssociatedUsers[] | Feedback
        })
    ),
    on(
        UserActions.transfer_items, state => state
    ),
    on(
        UserActions.transfer_items_result, (state, { payload }) => ({
            ...state,
            transferItemsFeedback: payload as Feedback
        })
    ),
    on(
        UserActions.add_client, state => state
    ),
    on(
        UserActions.add_client_return, (state, { payload }) => ({
            ...state,
            updateResult: payload as Feedback
        })
    ),
    on(
        UserActions.add_client_convert, state => state
    ),
    on(
        UserActions.add_client_convert_return, (state, { payload }) => ({
            ...state,
            convertResult: payload as Feedback
        })
    ),
    on(
        UserActions.add_client_guest_convert, state => state
    ),
    on(
        UserActions.add_client_guest_convert_return, (state, { payload }) => ({
            ...state,
            convertGuestResult: payload as Feedback
        })
    ),
    on(
        UserActions.edit_client, state => state
    ),
    on(
        UserActions.edit_client_return, (state, { payload }) => ({
            ...state,
            updateClientResult: payload as Feedback
        })
    ),
    on(
        UserActions.delete_client, state => state
    ),
    on(
        UserActions.delete_client_result, (state, { payload }) => ({
            ...state,
            deleteResult: payload as Feedback
        })
    ),
    on(
        UserActions.block_client, state => state
    ),
    on(
        UserActions.block_client_result, (state, { payload }) => ({
            ...state,
            blockClient: payload as Feedback
        })
    ),
    on(
        UserActions.get_kits, state => state
    ),
    on(
        UserActions.get_kits_return, (state, { payload }) => ({
            ...state,
            kitsList: payload as Kit[],
        })
    ),
    on(
        UserActions.update_kit, state => state
    ),
    on(
        UserActions.update_kit_result, (state, { payload }) => ({
            ...state,
            updateKitResult: payload as Feedback
        })
    ),
    on(
        UserActions.update_kit_cleanup, state => ({
            ...state,
            updateKitResult: null
        })
    ),
    on(
        UserActions.delete_kit, state => state
    ),
    on(
        UserActions.delete_kit_result, (state, { payload }) => ({
            ...state,
            deleteKitResult: payload as Feedback
        })
    ),
    on(
        UserActions.delete_kit_cleanup, state => ({
            ...state,
            deleteKitResult: null
        })
    ),
    on(
        UserActions.create_kit, state => state
    ),
    on(
        UserActions.create_kit_return, (state, { payload }) => ({
            ...state,
            createKitResult: payload as Feedback
        })
    ),
    on(
        UserActions.create_kit_cleanup, state => ({
            ...state,
            createKitResult: null
        })
    ),
    on(
        UserActions.delete_client_inventory, state => state
    ),
    on(
        UserActions.delete_client_inventory_return, (state, { payload }) => ({
            ...state,
            deleteClientInventory: payload as Feedback
        })
    ),
    on(
        UserActions.edit_kit_modal_update, state => ({
            ...state,
            editKitModalStatus: AlarmModalState.Confirm
        })
    ),
    on(
        UserActions.edit_kit_modal_cleanup, state => ({
            ...state,
            editKitModalStatus: null,
            updateKitResult: null,
            deleteKitResult: null
        })
    ),
    on(
        UserActions.invite_associated, state => state
    ),
    on(
        UserActions.invite_associated_return, (state, { payload }) => ({
            ...state,
            inviteAssociatedResult: payload as Feedback
        })
    ),
    on(
        UserActions.invite_associated_cleanup, state => ({
            ...state,
            inviteAssociatedResult: null
        })
    ),
    on(
        UserActions.delete_associated, state => state
    ),
    on(
        UserActions.delete_associated_return, (state, { payload }) => ({
            ...state,
            deleteAssociated: payload as Feedback
        })
    ),
    on(
        UserActions.create_ticket_kit, state => state
    ),
    on(
        UserActions.create_ticket_kit_return, (state, { payload }) => ({
            ...state,
            createTicketKit: payload as Feedback
        })
    ),
    on(
        UserActions.create_ticket_kit_cleanup, state => ({
            ...state,
            createTicketKit: null
        })
    ),
    on(
        UserActions.delete_partner, state => state
    ),
    on(
        UserActions.delete_partner_return, (state, { payload }) => ({
            ...state,
            deletePartner: payload as Feedback
        })
    ),
    on(
        UserActions.delete_partner_clear, state => ({
            ...state,
            deletePartner: null
        })
    ),
    on(
        UserActions.get_national_holidays, state => state
    ),
    on(
        UserActions.get_national_holidays_return, (state, { payload }) => ({
            ...state,
            nationalHolidays: payload as Feedback

        })
    ),
    on(
        UserActions.get_national_holidays_clear, state => ({
            ...state,
            nationalHolidays: null
        })
    ),
    on(
        UserActions.get_calendar_list, state => state
    ),
    on(
        UserActions.get_calendar_list_return, (state, { payload }) => ({
            ...state,
            calendarList: payload
        })
    ),
    on(
        UserActions.get_calendar_list_clear, state => ({
            ...state,
            calendarList: null
        })),
    on(
        UserActions.create_calendar, state => state
    ),
    on(
        UserActions.create_calendar_return, (state, { payload }) => ({
            ...state,
            holidays: payload as Feedback
        })
    ),
    on(
        UserActions.create_calendar_clear, state => ({
            ...state,
            holidays: null
        })
    ),
    on(
        UserActions.update_calendar, state => state
    ),
    on(
        UserActions.update_calendar_return, (state, { payload }) => ({
            ...state,
            holidays: payload as Feedback
        })
    ),
    on(
        UserActions.update_calendar_clear, state => ({
            ...state,
            holidays: null
        })
    ),
    on(
        UserActions.delete_calendar, state => state
    ),
    on(
        UserActions.delete_calendar_clear, (state) => ({
            ...state,
            deleteHoliday: null,
        })
    ),
    on(
        UserActions.delete_calendar_return, (state, { payload }) => ({
            ...state,
            deleteHoliday: payload as Feedback
        })
    ),
);
