//Angular imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

//App modules
import { CamerasModule } from './cameras/cameras.module';
import { LoginModule } from './Login/login.module';
import { UsersModule } from './Users/users.module';
import { DetectionsModule } from './detections/detections.module';
import { HomeModule } from './home/home.module';
import { GlpiModule } from './glpi/glpi.module';
import { ComponentsModule } from './components/components.module';
import { SharedModulesModule } from './Shared/shared-modules/shared-modules.module';

//Angular-redux imports
//High level reducer, epic and State model
import { userReducer } from 'app/Users/Services/reducer';
import { UserEffects } from 'app/Users/Services/effects';
//Login imports
import { loginReducer } from 'app/Login/Services/reducer';
import { LoginEffects } from 'app/Login/Services/effects';
//Camera imports
import { cameraReducer } from 'app/cameras/Services/reducer';
import { CameraEffects } from 'app/cameras/Services/effects';
//Analytic imports
import { AnalyticEffects } from 'app/analytics/Services/effects';
import { analyticReducer } from 'app/analytics/Services/reducer';
//Detection imports
import { detectionReducer } from 'app/detections/Services/reducer';
import { DetectionEffects } from 'app/detections/Services/effects';
// GLPI - Support Maganement
import { glpiReducer } from 'app/glpi/glpi.reducer';
import { GlpiEffects } from 'app/glpi/glpi.effects';

//Module imports
import { AppRoutingModule } from './app.routing';

//Imports dos layouts do projeto
import { AppComponent } from './app.component';

//Importe do sweetAlert
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './Shared/Interceptors';

//Imports do Datatable.net
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ValidationResponseHandlerModule } from './Shared/ValidationResponse/validation-response-handler';
import { ValidationResponseCamera } from './Shared/ValidationResponse/validation-response-camera';

//Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/compat/analytics';
import { environment } from 'environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import {CommonModule} from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
        LoginModule,
        DetectionsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        NzIconModule,
        SharedModulesModule,
        CamerasModule,
        UsersModule,
        HomeModule,
        GlpiModule,
        NzPopoverModule,
        StoreModule.forRoot({
            routes: routerReducer,
            analytic: analyticReducer,
            camera: cameraReducer,
            detection: detectionReducer,
            Users: userReducer,
            login: loginReducer,
            glpi: glpiReducer
        }, {}),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
        EffectsModule.forRoot([
            UserEffects,
            LoginEffects,
            CameraEffects,
            AnalyticEffects,
            DetectionEffects,
            GlpiEffects
        ]),
        RouterModule.forRoot([
            // routes
        ]),
        StoreRouterConnectingModule.forRoot(),
    ],
    declarations: [
        AppComponent
    ],
    providers: [...httpInterceptorProviders, ValidationResponseHandlerModule,
        ValidationResponseCamera,
        ScreenTrackingService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
