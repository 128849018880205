<div  [ngClass]="context == 'client_view' ? 'main-content-clientView' : 'main-content background'">
  <div class="first-row">
    <div class="container-header">
        <span class="style-title2 clickable" (click)="toPreviousPath()" *ngIf="context !== 'client_view'">{{previousRouterName}}</span>
        <span class="material-icons style-icon-title" nz-icon nzType="right" nzTheme="outline" *ngIf="context !== 'client_view'"></span>
        <span class="back-button" *ngIf="context === 'client_view'" (click)="returnToList()">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
        </span>
        <span class="style-title">Câmeras {{groupName}}</span>
    </div>
  
    <div class="group_data">
        <span class="camera_data">Câmeras: {{ totalAmountGroup }}</span>
        <span>Detecções: {{ totalAmountAnalytic }}</span>
        <span>Clientes: {{ groupData ? groupData[0].clients_info_group.length : 0}}</span>
        <span>Convidados: {{ groupData ? groupData[0].associateds_info_group.length : 0}}</span>
    </div>
  </div>

  <div class="filter">
    <div class="search-input">
      <input type="text" placeholder="Pesquisar"
      [formControl]="searchInputControl" (keyup)="requestSearch()">
      <i nz-icon nzType="search" nzTheme="outline" class="clickable" (click)="requestSearch()"></i>
    </div>
    <div class="selector-container">
        <app-filter [list]="sortOptions"
        [selected]="1"
        [type]="'simple'"
        (filterStatusChange)="changeSort($event)"></app-filter>
    </div>
  </div>

  <div *ngIf="hasCameras">
    <nz-table #camTable class="table style-table antd-table" [nzData]="cameras" [hidden]="loading"
    [nzFrontPagination]="'false'" showTitle="false" 
    [nzPaginationPosition]="'both'"
    [nzSize]="'small'"
    [nzTotal]="totalAmountGroup"
    [(nzPageSize)]="camLimit"
    [(nzPageIndex)]="pageIndex"
    (nzPageIndexChange)="onPageIndexChange()"
    [nzTableLayout]="'auto'">
      <thead>
        <tr>
          <th class="table-thumb">Câmera</th>
          <th class="table-name">Nome</th>
          <th class="table-client">Cliente</th>
          <th class="table-plan">Plano</th>
          <th class="table-detec">Detecções</th>
          <th class="table-detec"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let camera of camTable.data; let index = index">
          <!-- Câmera -->
          <td [class.deleting]="camera.deleted" [class.offline]="!camera.active">
            <div class="style-thumbnail clickable">
              <img class="image-img" [src]="camera.cache_thumb | async | safeHtml" (error)="brokenImageHandler($event)">
              <div *ngIf="camera.active" class="image-overlay" (click)="viewLiveCamera(camera)">
                <img src="/assets/img/fullscreen-hover.svg">
              </div>
            </div>
          </td>
          <!-- Nome -->
          <td [nzBreakWord]="true">{{camera.alias}}
            <p class="id-cam">
              #{{camera.id}}
            </p>
          </td>
          <!-- Cliente -->
          <td>{{returnClientName(camera.id_user)}}</td>
          <!-- Plano -->
          <td [hidden]="!camera.active">
            <span class="plan-title" *ngIf="!loading">Câmera {{returnPlanFromId(camera.id_plan).resolution.alias}}</span>
            <span class="plan-days" *ngIf="!loading">{{processPlural(returnPlanFromId(camera.id_plan).days_storage, 'dia')}}</span>
          </td>
          <!-- Detecções -->
          <td [hidden]="!camera.active">
            <div *ngIf="camera.integrated_camera[0]" class="integrated">Analítico<br>integrado</div>
            <div *ngIf="camera.integrated_camera[0] == null">
              <span class="analyticsAmount">{{camera.analytics}}</span>
              <button mat-raised-button class="btn btn-sm btn-just-icon btn-round btn-settings" (click)="openModalAnalytics(camera)">
                <svg class="button_detections" viewBox="0 0 24 24" fill="svg" xmlns="http://www.w3.org/2000/svg">
                    <path fill="svg" d="M16.3125 11.25H12.75V7.6875C12.75 7.58437 12.6656 7.5 12.5625 7.5H11.4375C11.3344 7.5 11.25 7.58437 11.25 7.6875V11.25H7.6875C7.58437 11.25 7.5 11.3344 7.5 11.4375V12.5625C7.5 12.6656 7.58437 12.75 7.6875 12.75H11.25V16.3125C11.25 16.4156 11.3344 16.5 11.4375 16.5H12.5625C12.6656 16.5 12.75 16.4156 12.75 16.3125V12.75H16.3125C16.4156 12.75 16.5 12.6656 16.5 12.5625V11.4375C16.5 11.3344 16.4156 11.25 16.3125 11.25Z"/>
                    <path fill="svg" d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" />
                </svg>
              </button>
            </div>
          </td>
          <!-- Câmera bloqueada -->
          <td [colSpan]="(camera.active) ? '0' : '5'" class="lt-md:hidden xs:block" [hidden]="camera.active">
            <div class="card-bloqueado" *ngIf="!camera.active">
              <div class="deleting-label" *ngIf="camera.deleted">
                <i class="camera-spinner" nz-icon nzType="loading" nzTheme="outline"></i>
                <span class="text-deleting">Câmera sendo apagada </span>
              </div>
              <p class="card-title" *ngIf="!camera.deleted" style="text-align: center;">Câmera bloqueada</p>
              <p class="card-text" *ngIf="!camera.deleted" style="text-align: center;">Entre em contato com seu
                fornecedor para reativar a sua câmera</p>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>  
  </div>
    
  <app-feedback [noFound]="true" imageUrl="nofound.png" [transparent]="true" *ngIf="!loading && !hasCameras"></app-feedback>
  
    <div *ngIf="loading" class="loading_container">
      <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
    </div>
</div>

<app-analytics-controller (loadingCameraList)="loadingEvent($event)" (exitModal)="dispatchGroupData()"></app-analytics-controller>
<app-modal></app-modal>
