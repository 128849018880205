<div class="padding">
    <div class="flex modal_header justify-content-between align-items-center">
        <div class="previous_btn" (click)="prevStep()" [hidden]="currentStep < 2 || currentStep >= 6">
            <i nz-icon nzType="left" nzTheme="outline"></i>
        </div>
        <div class="placeholder" [hidden]="currentStep >= 2 && currentStep <= 5"></div>
        <div class="close_btn" (click)="closeModal()">
            <i nz-icon nzType="close" nzTheme="outline"></i>
        </div>
    </div>
    
    <div class="slider">
        <div class="step_slide slide_1" [hidden]="currentStep !== 1">
            <div class="step_image">
                <img src="./../../../assets/img/Icons/rtmp_deletion_1.svg" alt="">
            </div>
            <span *ngIf="!rtmpWarning" class="step_description">
                1. Preencha a URL do seu navegador com o IP da sua câmera e faça Login na plataforma de network
            </span>
            <span *ngIf="rtmpWarning" class="step_description_new">
                1. Peça ao seu cliente para preencher a URL do seu navegador com o IP da câmera e <br> faça Login na plataforma de network
            </span>
        </div>
        <div class="step_slide slide_2" [hidden]="currentStep !== 2">
            <div class="step_image">
                <img src="./../../../assets/img/Icons/rtmp_deletion_2.svg" alt="">
            </div>
            <span class="step_description">
                2. Acesse a aba "configurações"
            </span>
        </div>
        <div class="step_slide slide_3" [hidden]="currentStep !== 3">
            <div class="step_image">
                <img src="./../../../assets/img/Icons/rtmp_deletion_3.svg" alt="">
            </div>
            <span class="step_description">
                3. Clique na opção “serviços”
            </span>
        </div>
        <div class="step_slide slide_4" [hidden]="currentStep !== 4">
            <div class="step_image">
                <img src="./../../../assets/img/Icons/rtmp_deletion_4.svg" alt="">
            </div>
            <span class="step_description">
                4. Selecione "RTMP"
            </span>
        </div>
        <div class="step_slide slide_5" [hidden]="currentStep !== 5">
            <div class="step_image">
                <img src="./../../../assets/img/Icons/rtmp_deletion_5.svg" alt="">
            </div>
            <span class="step_description">
                5. Delete o link do formulário e clique em “salvar”
            </span>
        </div>
        <div class="step_slide slide_6" [hidden]="currentStep !== 6">
            <div class="icon">
                <i nz-icon nzType="delete" nzTheme="outline"></i>
            </div>
            <span class="step_description">
                Confirmar a exclusão da sua câmera no WeCloud?
            </span>

            <div class="buttons">
                <button class="dismiss" (click)="closeModal()">Cancelar</button>
                <button class="confirm" (click)="submitDelete()">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                    Excluir
                </button>
            </div>
        </div>
    </div>
    
    <div class="controls" *ngIf="currentStep <= 5">
        <div class="steps">
            <div class="dot" [ngClass]="{'active': (currentStep === 1)}"></div>
            <div class="dot" [ngClass]="{'active': (currentStep === 2)}"></div>
            <div class="dot" [ngClass]="{'active': (currentStep === 3)}"></div>
            <div class="dot" [ngClass]="{'active': (currentStep === 4)}"></div>
            <div class="dot" [ngClass]="{'active': (currentStep === 5)}"></div>
        </div>
        <div class="next_btn">
            <button [hidden]="currentStep === 5 && rtmpWarning" (click)="nextStep()">Próximo</button>
            <button *ngIf="rtmpWarning" [hidden]="currentStep !== 5" (click)="close()">Entendi</button>
        </div>
    </div>
</div>