import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from 'app/Login/Services/login.service';
import { ImageService } from '../Services/image.service';

@Injectable()
export class DuplicateSessionInterceptor implements HttpInterceptor {
    constructor(private router: Router, private loginService: LoginService, private imageService: ImageService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse)
                {
                    if (event.body['statusCode'] == 401 && localStorage.getItem('sub') !== null)
                    {
                        Swal.fire({
                            title: 'Sessão Expirada',
                            text: 'Sua sessão expirou. Inicie uma nova sessão para fazer seu login!',
                            icon: 'error'
                        });
                        const storedUsers = localStorage.getItem('strdUsr');
                        localStorage.clear();
                        // this.imageService.deleteCacheThumb();
                        localStorage.setItem('strdUsr', storedUsers);
                        sessionStorage.clear();
                        this.loginService.navSideShowEmitter.emit(false);
                        this.router.navigateByUrl('/login/signin');
                    }
                }
            })
        );
    }
}
