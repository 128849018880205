import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { AppState } from 'app/store/model';
import { CameraActions } from 'app/cameras/Services/actions';
import { environment } from 'environments/environment';
import { Plan } from '../models';
import { Store } from '@ngrx/store';
import { UserInventory } from 'app/Users/models';
import { UserService } from 'app/Users/Services/user.service';

@Component({
    selector: 'app-camera-plans',
    templateUrl: './camera-plans.component.html',
    styleUrls: ['./camera-plans.component.scss']
})
export class CameraPlansComponent implements OnInit, OnDestroy, OnChanges {
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);

    @Input() plan_id: number;
    getPlans: Subscription;
    userInventory: Subscription;

    outId: Subject<number> = new Subject<number>();
    associate_id = environment.associateId;
    plansList: Plan[] = [];
    save_changes;
    activePlan: number;
    isAssociate: boolean;
    adminAssociate: boolean;
    isPartner: boolean;
    availableInventory = [];
    inventory: UserInventory;
    loadingPlans: boolean;
    userSub: string;

    @Input() loading: boolean;
    @Output() planId = new EventEmitter<number>();
    @Output() camEditData = new EventEmitter<boolean>();
    @Output() cancelEmmiter = new EventEmitter<void>();
    @Output() saveEmmiter = new EventEmitter<Object>();

    constructor(private readonly store: Store<AppState>, private service: UserService) { }

    ngOnInit(): void {
        this.userSub = localStorage.getItem('sub');
        this.loadingPlans = true;
        this.plans();
        this.getInventory();
        this.saveEmmiter.emit(this.planId);

        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

    }

    ngOnDestroy() {
        if (this.getPlans) {
            this.getPlans.unsubscribe();
        }
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        this.store.dispatch(CameraActions.get_plans_cleanup());
    }

    ngOnChanges(): void {
        if (this.activePlan === undefined) {
            this.activePlan = this.plan_id;
        }
    }

    // Retorna as informações da lista de planos
    plans() {
        this.getPlans = this.plans$.subscribe(result => {
            if (result) {
                this.plansList = result;
            }
            else {
                this.store.dispatch(CameraActions.get_plans({ associateId: this.associate_id }));
            }
        });
    }

    getInventory() {
        const clientSub = localStorage.getItem('clientView') ? localStorage.getItem('clientView') : null;
        this.service.userInventory(this.userSub, clientSub).subscribe(result => {
            if (result) {
                this.inventory = result[0];
                this.loadingPlans = false;

                const camerasInfoPartner = this.inventory.inventory.ci;
                const planLength = this.inventory.plans.length;

                for (let i = 0; i < planLength; i++) {
                    const plan = this.inventory.plans[i];
                    const idPlan = plan.id_plan;
                    if (camerasInfoPartner[idPlan].available > 0 || camerasInfoPartner[idPlan].id_plan === this.activePlan) {
                        camerasInfoPartner[idPlan].id = camerasInfoPartner[idPlan].id_plan;
                        this.availableInventory.push(camerasInfoPartner[idPlan]);
                    }
                }
            }
        });
    }

    changePlan(id_plan) {
        if (this.adminAssociate || this.isPartner) {
            this.activePlan = id_plan;
            this.planId.emit(id_plan);
            this.save_changes = true;
        }
    }

    closeModal() {
        this.cancelEmmiter.emit();
    }

    saveChanges() {
        if (this.planId) {
            this.camEditData.emit(true);
        }
    }

    processPlural(palavra: string, amount: number) {
        return palavra + (amount > 1 ? 's' : '');
    }
}
