import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { FilterComponent } from './filter/filter.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatRippleModule,
        MatInputModule,
        MatTooltipModule,
        HttpClientModule,
        NzCheckboxModule,
        NzSelectModule,
        NzIconModule,
        NzPopoverModule,
        NzInputModule,
        NzToolTipModule,
        NzDatePickerModule,
        NzDropDownModule,
        InfiniteScrollModule
    ],
    declarations: [
        NavbarComponent,
        SidebarComponent,
        ProgressBarComponent,
        FilterComponent,
        FilterListComponent
    ],
    exports: [
        NavbarComponent,
        SidebarComponent,
        ProgressBarComponent,
        FilterComponent,
        FilterListComponent
    ],
    providers: [
    // AuthenticationService,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ComponentsModule { }
