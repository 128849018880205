import { Store } from '@ngrx/store';
import { Component, EventEmitter, OnInit, Output, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { confirmPassword, validateCPFCNPJ, validatePassword } from 'app/Login/models';
import { Client, ConfigAlarm } from '../models';
import { CameraService } from 'app/cameras/Services/camera.service';
import { environment } from 'environments/environment';
import { AppState } from 'app/store/model';
import { UserActions } from '../Services/actions';
import { ModalData } from 'app/Shared/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedService } from 'app/Shared/Services/shared.service';
import { Plan } from 'app/cameras/models';
import {CameraActions} from '../../cameras/Services/actions';

@Component({
    selector: 'app-user-client-form',
    templateUrl: './user-client-form.component.html',
    styleUrls: ['./user-client-form.component.scss']
})

export class ClientFormComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();
    readonly addClient$ = this.store.select((state: AppState) => state.Users.updateResult);
    readonly convertClient$ = this.store.select((state: AppState) => state.Users.convertResult);
    readonly convertGuest$ = this.store.select((state: AppState) => state.Users.convertGuestResult);

    public registerForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        cpf_cnpj: new UntypedFormControl('', [Validators.required, validateCPFCNPJ]),
        cell: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', [Validators.required]),
        cameraAmount: new UntypedFormControl(0, [Validators.required]),
        detectionsAmount: new UntypedFormControl(0, [Validators.required]),
        guestsAmount: new UntypedFormControl(0, [Validators.required]),
        plan: new UntypedFormControl('', [Validators.required]),
        botaoPanico: new UntypedFormControl('', []),
        centralMonitoramento: new UntypedFormControl('', []),
        contactEmergency: new UntypedFormControl('', [Validators.required]),
        phoneEmergency: new UntypedFormControl('', [Validators.required]),
        costCenter: new UntypedFormControl('', [Validators.required])
    }, { validators: confirmPassword });

    add_client: Subscription;
    convert_client: Subscription;
    convert_guest: Subscription;
    childProfile: string;
    guestProfile: string;
    confirmation: Boolean;
    costCenterMark: boolean;
    costCenter = '';
    planList: Plan[];
    idAssociate = environment.associateId;
    loading: boolean;
    loadingPlans: boolean;
    cameraOnLimit: boolean;
    detectionsOnLimit: boolean;
    plan_id: number;
    cfgReturn: ConfigAlarm = { checkApp: true, checkAva: false, checkSms: true, numbersCell: ['+55999999999'] };
    currentStep = 0;


    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalData, private router: Router, private validationResponse: ValidationResponseHandlerModule, private camService: CameraService,
        private readonly store: Store<AppState>, private sharedService: SharedService) { }

    ngOnInit(): void {
        this.loadingPlans = true;
        this.childProfile = this.data.data.childProfile;
        this.guestProfile = this.data.data.guestProfile;
        this.costCenterMark = localStorage.getItem('cost_center_mark') == 'true';
        this.costCenter = localStorage.getItem('cost_center');
        this.getPlans();
        this.getRegisterResult();
        this.convertClientResult();
        this.convertGuestResult();
    }

    ngOnDestroy() {
        if (this.add_client) {
            this.add_client.unsubscribe();
        }
        if (this.convert_client) {
            this.convert_client.unsubscribe();
        }
        if (this.convert_guest) {
            this.convert_guest.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    closeModal() {
        this.store.dispatch(CameraActions.get_clients_group_clear());
        this.modalClose.emit();
        if (this.childProfile != 'Convidado') {
            this.goToClientList();
        }
    }

    onRegister() {
        this.loading = true;
        let errorPass = false;
        const plan = this.registerForm.get('plan').value;
        this.plan_id = plan.id_plan;

        this.validateEmergencyContact();
        this.validateEmergencyNumber();
        this.validatePlan();

        if (this.registerForm.get('cameraAmount').invalid) {
            errorPass = true;
            this.registerForm.get('cameraAmount').markAsTouched();
        }
        if (this.registerForm.get('detectionsAmount').invalid) {
            errorPass = true;
            this.registerForm.get('detectionsAmount').markAsTouched();
        }
        if (this.registerForm.get('guestsAmount').invalid) {
            errorPass = true;
            this.registerForm.get('guestsAmount').markAsTouched();
        }
        if (this.registerForm.get('contactEmergency').invalid && this.registerForm.get('centralMonitoramento').value) {
            errorPass = true;
            this.registerForm.get('contactEmergency').markAsTouched();
        }
        if (this.registerForm.get('phoneEmergency').invalid && this.registerForm.get('centralMonitoramento').value) {
            errorPass = true;
            this.registerForm.get('phoneEmergency').markAsTouched();
        }
        if (this.validatePlan()) {
            errorPass = true;
        }

        if (!errorPass) {
            // tslint:disable-next-line: no-shadowed-variable
            const client: Client = {
                name: this.registerForm.get('name').value,
                email: this.registerForm.get('email').value,
                password: this.registerForm.get('password').value,
                cnpj: this.registerForm.get('cpf_cnpj').value,
                phonenumber: this.registerForm.get('cell').value,
                analytcsAmount: Number(this.registerForm.get('detectionsAmount').value),
                guests_count: Number(this.registerForm.get('guestsAmount').value),
                costCenter: this.costCenterMark ? this.costCenter : this.registerForm.get('costCenter').value,
                cameras: [{
                    plan_id: this.plan_id,
                    amount: Number(this.registerForm.get('cameraAmount').value)
                }],
            };

            this.store.dispatch(UserActions.add_client({
                user_sub: localStorage.getItem('sub'),
                client: client
            }));
        } else {
            this.loading = false;
        }
    }

    convertClient() {
        this.loading = true;
        let errorPass = false;
        const plan = this.registerForm.get('plan').value;
        this.plan_id = plan.id_plan;

        this.validateEmergencyContact();
        this.validateEmergencyNumber();
        this.validatePlan();

        if (this.registerForm.get('cameraAmount').invalid) {
            errorPass = true;
            this.registerForm.get('cameraAmount').markAsTouched();
        }
        if (this.registerForm.get('detectionsAmount').invalid) {
            errorPass = true;
            this.registerForm.get('detectionsAmount').markAsTouched();
        }
        if (this.registerForm.get('contactEmergency').invalid && this.registerForm.get('centralMonitoramento').value) {
            errorPass = true;
            this.registerForm.get('contactEmergency').markAsTouched();
        }
        if (this.registerForm.get('phoneEmergency').invalid && this.registerForm.get('centralMonitoramento').value) {
            errorPass = true;
            this.registerForm.get('phoneEmergency').markAsTouched();
        }
        if (this.validatePlan()) {
            errorPass = true;
        }

        if (!errorPass) {
            const client: Client = {
                analytcsAmount: Number(this.registerForm.get('detectionsAmount').value),
                cameras: [{
                    plan_id: this.plan_id,
                    amount: Number(this.registerForm.get('cameraAmount').value)
                }],
            };

            if (this.guestProfile == 'Cliente Associado') {
                this.store.dispatch(UserActions.add_client_guest_convert({
                    sub: localStorage.getItem('sub'),
                    child_sub: localStorage.getItem('clientView'),
                    client: client
                }));
            } else {
                this.store.dispatch(UserActions.add_client_convert({
                    sub: localStorage.getItem('sub'),
                    child_sub: localStorage.getItem('clientView'),
                    client: client
                }));
            }

        } else {
            this.loading = false;
        }
    }

    convertClientResult() {
        this.convert_client = this.convertClient$.subscribe(register_feedback => {
            if (register_feedback) {
                this.loading = false;
                if (register_feedback.status === 2) {
                    const centralMonitoramento = this.registerForm.get('centralMonitoramento').value === true ? true : false;
                    const botaoPanico = this.registerForm.get('botaoPanico').value === true ? true : false;
                    this.cfgReturn.checkAva = centralMonitoramento;
                    this.cfgReturn.checkDevice = centralMonitoramento ? botaoPanico : false;
                    this.cfgReturn.contactEmergency = centralMonitoramento ? this.registerForm.get('contactEmergency').value : null;
                    this.cfgReturn.phoneEmergency = centralMonitoramento ? this.registerForm.get('phoneEmergency').value : null;
                    this.store.dispatch(UserActions.create_config_alarm({ user_sub: localStorage.getItem('sub'), configList: this.cfgReturn, child_sub: localStorage.getItem('clientView') }));
                    this.validationResponse.validationResponseHandler(200, 'user', 'register-client-user', register_feedback.message);
                    this.modalClose.emit();
                    this.goToClientList();
                } else if (register_feedback.statusCode != 500) {
                    this.validationResponse.validationResponseHandler(400, 'user', 'register-client-user-fail', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                } else {
                    this.validationResponse.validationResponseHandler(500, 'user', 'register-client-user-not-mail', register_feedback.message);
                    this.closeModal();
                }
            }
        });
    }

    convertGuestResult() {
        this.convert_guest = this.convertGuest$.subscribe(register_feedback => {
            if (register_feedback) {
                this.loading = false;
                if (register_feedback.status === 2) {
                    const centralMonitoramento = this.registerForm.get('centralMonitoramento').value === true ? true : false;
                    const botaoPanico = this.registerForm.get('botaoPanico').value === true ? true : false;
                    this.cfgReturn.checkAva = centralMonitoramento;
                    this.cfgReturn.checkDevice = centralMonitoramento ? botaoPanico : false;
                    this.cfgReturn.contactEmergency = centralMonitoramento ? this.registerForm.get('contactEmergency').value : null;
                    this.cfgReturn.phoneEmergency = centralMonitoramento ? this.registerForm.get('phoneEmergency').value : null;
                    this.store.dispatch(UserActions.create_config_alarm({ user_sub: localStorage.getItem('sub'), configList: this.cfgReturn, child_sub: localStorage.getItem('clientView') }));
                    this.validationResponse.validationResponseHandler(200, 'user', 'register-client-user', register_feedback.message);
                    this.modalClose.emit();
                    this.goToClientList();
                } else if (register_feedback.statusCode != 500) {
                    this.validationResponse.validationResponseHandler(400, 'user', 'register-client-user-fail', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                } else {
                    this.validationResponse.validationResponseHandler(500, 'user', 'register-client-user-not-mail', register_feedback.message);
                    this.closeModal();
                }
            }
        });
    }

    getPlans() {
        this.loadingPlans = false;
        this.planList = this.data.data.plans;
    }

    getRegisterResult() {
        this.add_client = this.addClient$.subscribe(register_feedback => {
            if (register_feedback) {
                this.loading = false;
                if (register_feedback.status === 2 && register_feedback.data) {
                    const clientSub = register_feedback.data[0].sub;
                    const centralMonitoramento = this.registerForm.get('centralMonitoramento').value === true ? true : false;
                    const botaoPanico = this.registerForm.get('botaoPanico').value === true ? true : false;
                    this.cfgReturn.checkAva = centralMonitoramento;
                    this.cfgReturn.checkDevice = centralMonitoramento ? botaoPanico : false;
                    this.cfgReturn.contactEmergency = centralMonitoramento ? this.registerForm.get('contactEmergency').value : null;
                    this.cfgReturn.phoneEmergency = centralMonitoramento ? this.registerForm.get('phoneEmergency').value : null;
                    this.store.dispatch(UserActions.create_config_alarm({ user_sub: localStorage.getItem('sub'), configList: this.cfgReturn, child_sub: clientSub }));
                    this.validationResponse.validationResponseHandler(200, 'user', 'register-client-user', register_feedback.message);
                    this.closeModal();
                } else if (register_feedback.statusCode != 500) {
                    this.validationResponse.validationResponseHandler(400, 'user', 'register-client-user-fail', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                } else {
                    if (register_feedback.statusCode == 403) {
                        const clientSub = register_feedback.data[0].sub;
                        const centralMonitoramento = this.registerForm.get('centralMonitoramento').value === true ? true : false;
                        const botaoPanico = this.registerForm.get('botaoPanico').value === true ? true : false;
                        this.cfgReturn.checkAva = centralMonitoramento;
                        this.cfgReturn.checkDevice = centralMonitoramento ? botaoPanico : false;
                        this.cfgReturn.contactEmergency = centralMonitoramento ? this.registerForm.get('contactEmergency').value : null;
                        this.cfgReturn.phoneEmergency = centralMonitoramento ? this.registerForm.get('phoneEmergency').value : null;
                        this.store.dispatch(UserActions.create_config_alarm({ user_sub: localStorage.getItem('sub'), configList: this.cfgReturn, child_sub: clientSub }));
                    }
                    this.validationResponse.validationResponseHandler(500, 'user', 'register-client-user-not-mail', register_feedback.message);
                    this.closeModal();
                }
            }
        });
    }

    goToClientList() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/clients/list']);
    }

    compareCamera() {
        if (this.registerForm.get('cameraAmount').value === '') {
            this.registerForm.get('cameraAmount').setErrors({'required': true});
        } else if (Number(this.registerForm.get('cameraAmount').value) > (this.data.data.cameras_avaiable)) {
            this.registerForm.get('cameraAmount').setErrors({'invalid': true});
        } else {
            this.registerForm.get('cameraAmount').setErrors(null);
        }
    }

    compareDetections() {
        if (this.registerForm.get('detectionsAmount').value === '') {
            this.registerForm.get('detectionsAmount').setErrors({'required': true});
        } else if (Number(this.registerForm.get('detectionsAmount').value) > (this.data.data.analytics_avaiable)) {
            this.registerForm.get('detectionsAmount').setErrors({'invalid': true});
        } else {
            this.registerForm.get('detectionsAmount').setErrors(null);
        }
    }

    validateEmergencyNumber() {
        if (this.registerForm.get('phoneEmergency').value == '' && this.registerForm.get('centralMonitoramento')) {
            this.registerForm.get('phoneEmergency').setErrors({'required': true});
        } else {
            this.registerForm.get('phoneEmergency').setErrors(null);
        }
    }

    validateEmergencyContact() {
        if (this.registerForm.get('contactEmergency').value == '' && this.registerForm.get('centralMonitoramento')) {
            this.registerForm.get('contactEmergency').setErrors({'required': true});
        } else {
            this.registerForm.get('contactEmergency').setErrors(null);
        }
    }

    filterPlans(plans) {
        const activePlans = this.data.data.kits.map(kit => {
            if (kit.active) {
                return kit.id_plan;
            }
        });

        return plans.filter(plan => {
            return activePlans.includes(plan.id_plan);
        });
    }

    validatePlan(): boolean{
        const cameraAmount = this.registerForm.get('cameraAmount').value;
        const plano = this.registerForm.get('plan').value;
        if (cameraAmount > 0 && plano == '') {
            this.registerForm.get('plan').setErrors({'required': true});
            this.registerForm.get('plan').markAsTouched();
            return true;
        }
        this.registerForm.get('plan').setErrors(null);
        return false;
    }

    goToPrevious() {
        this.currentStep--;
    }

    goToNext() {
        if (this.validateFirstStep()) {
            this.currentStep++;
        }
    }

    public validateFirstStep(): boolean {
        this.confirmation = false;
        let errorPass = true;
        if (this.registerForm.errors != null) {
            if (this.registerForm.errors.passwordMatch == null) {
                this.confirmation = true;
            }
            else {
                this.confirmation = this.registerForm.errors.passwordMatch;
            }
        }
        if (this.confirmation) {
            errorPass = false;
        }
        if (this.registerForm.get('cpf_cnpj').invalid) {
            errorPass = false;
            this.registerForm.get('cpf_cnpj').markAsTouched();
        }
        if (this.registerForm.get('cell').invalid) {
            errorPass = false;
            this.registerForm.get('cell').markAsTouched();
        }
        if (this.registerForm.get('email').invalid) {
            errorPass = false;
            this.registerForm.get('email').markAsTouched();
        }
        if (this.registerForm.get('name').invalid) {
            errorPass = false;
            this.registerForm.get('name').markAsTouched();
        }
        if (this.registerForm.get('password').invalid) {
            errorPass = false;
            this.registerForm.get('password').markAsTouched();
        }
        if (this.registerForm.get('confirm').invalid) {
            errorPass = false;
            this.registerForm.get('confirm').markAsTouched();
        }

        return errorPass;
    }
}
