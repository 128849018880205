import { SharedService } from 'app/Shared/Services/shared.service';
import Swal from 'sweetalert2';
import { ResultAlert } from '../models';
import { showAlert } from '../Helpers/showAlert';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
export class ValidationResponseCamera {
    resultAlert: ResultAlert;
    constructor() {
    }

    swalDanger(title: string) {
        showAlert(title, 'danger');
    }

    swalWarning(title: string) {
        showAlert(title, 'warning');
    }

    swalSuccess(title: string) {
        showAlert(title, 'success');
    }

    showAlertDargersParameters(swalParameters: any) {
        showAlert(swalParameters, 'danger');
    }

    swalWarningParameters(title: string, swalParameters: any) {
        Swal.fire({
            title: title,
            text: swalParameters,
            icon: 'warning'
        });
    }

    camera_form_create_camera_200(title: string) {
        Swal.fire({
            title: title,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    }

    camera_form_limit_inventory_500(title: string) {
        Swal.fire({
            title: 'Limite de câmeras atingido',
            text: 'Você atingiu o limite do seu plano. Entre em contato com seu fornecedor do serviço.',
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#326ABC',
            confirmButtonText: 'OK'
        });
    }

    camera_form_preview_camera_404(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
            confirmButtonColor: '#2CDEE3',
            confirmButtonText: 'Fechar'
        });
    }

    camera_form_create_camera_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/animations/sad_face_animation.gif',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            html:
                '<br> ' +
                '<span style="color: #a0a9ab;font-family: \'Open Sans\';font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.31;letter-spacing: 0.24px;">' +
                title['text'] + '</span>' +
                '<br>' +
                '<br>',
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonColor: '#c5c9d1',
            confirmButtonColor: '#326ABC',
            focusConfirm: false,
            cancelButtonText:
                '<span style="color: #ffffff;text-align: center;letter-spacing: 0.36px;'
                + 'font-weight: 500;font-stretch: normal;font-style: normal;text-transform: uppercase;">' + title['cancelButton'] + '</span>',
            confirmButtonText:
                '<span style="color: #ffffff;text-align: center;letter-spacing: 0.36px; ' +
                'font-weight: 500;font-stretch: normal;font-style: normal;text-transform: uppercase;">' + title['tryAgainButton'] + '</span>',
        }).then((result) => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'tryAgain');
        });
    }

    camera_data_confirm_delete_camera_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteCam');
        });

    }

    camera_form_confirm_camera_rtsp_offline_200(title: string) {
        Swal.fire({
            title: title['title'],
            imageUrl: '../../../assets/img/camera_offline.svg',
            html: title['textOne'] + '<br><br>' + title['textTwo'] + '<br><br>',
            width: 725,
            imageWidth: 67.57,
            imageHeight: 137.41,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton'],
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'offlineRTSPCam');
        });

    }

    camera_form_confirm_camera_rtmp_offline_200(title: string) {
        Swal.fire({
            title: title['title'],
            imageUrl: '../../../assets/img/camera_offline.svg',
            html: title['textOne'] + '<br><br>' + title['textTwo'] + '<br><br>',
            width: 725,
            imageWidth: 67.57,
            imageHeight: 137.41,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton'],
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'offlineRTMPCam');
        });

    }

    camera_view_confirm_delete_guest_camera_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteGuestCam');
        });

    }

    camera_confirm_delete_guest_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteGuest');
        });

    }

    camera_update_active_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'updateActive');
        });

    }

    camera_update_inactive_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'updateInactive');
        });

    }

    camera_not_option_enabled_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF0000',
            cancelButtonColor: '#2CDEE3',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'optionEnabled');
        });

    }

    camera_not_record_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/info_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: title['confirmButtonColor'],
            cancelButtonColor: title['cancelButtonColor'],
            confirmButtonText: title['confirmButtonText'],
            cancelButtonText: title['cancelButtonText'],
        });
    }

    camera_failure_stream_400(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: 'error',
            showCancelButton: true,
            cancelButtonColor: '#2CDEE3',
            cancelButtonText: title['cancelButton']
        });
    }

    camera_save_mosaic_200(title: string) {
        Swal.fire({
            title: title['title'],
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#FF0000',
            cancelButtonColor: '#2CDEE3',
            confirmButtonText: title['confirmButton'],
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'saveMosaic');
        });

    }

    camera_delete_mosaico_200(title: string, swalParameters: any) {
        Swal.fire({
            title: title,
            icon: 'success',
            timer: 5000,
            text: swalParameters
        });
    }

    camera_date_camera_invalid_400(title: string, swalParameters: any) {
        const textWithParameters = title.replace('{}', String(swalParameters));
        showAlert(textWithParameters, 'danger');
    }

    mosaic_create_success_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            icon: title['icon'],
            timer: 5000
        });
    }

    camera_group_confirm_delete_camera_group_200(title: string) {
        Swal.fire({
            title: title['title'],
            text: title['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: title['confirmButton'],
            cancelButtonText: title['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'deleteCamGroup');
        });

    }

    cameras_group_confirm_delete_item_200(title: string, swalParameters: any) {
        Swal.fire({
            title: swalParameters['title'],
            text: swalParameters['text'],
            imageUrl: '../../../assets/img/delete_icon.svg',
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonColor: '#326ABC',
            cancelButtonColor: '#C5C9D1',
            confirmButtonText: swalParameters['confirmButton'],
            cancelButtonText: swalParameters['cancelButton']
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'updateCameraGroup');
        });

    }

    info_group_no_item(mode: string) {
        Swal.fire({
            title: 'Não existem ' + mode + ' para adicionar',
            icon: 'warning',
            timer: 5000,
            confirmButtonColor: '#326ABC',
            confirmButtonText: 'OK',
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'confirmNoItem');
        });
    }

    cameras_add_guest_success_invite_200(title: string, swalParameters: any) {
        Swal.fire({
            title: swalParameters['title'],
            text: swalParameters['text'],
            imageUrl: '../../../assets/img/CheckCircle.svg',
            html: swalParameters['text'] + '<span style="color:rgba(48, 110, 191, 1)";>' + swalParameters['text2'] + '</span>' +  '<br>' + swalParameters['text3'],
            imageWidth: 120,
            imageHeight: 120,
            showCloseButton: true,
            showConfirmButton: false,
            timer: 5000,
            customClass: {
                popup: 'swalStyle',
                title: 'swalTitle',
            }
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'inviteUsers');
        });

    }

    cameras_add_guest_parcial_success_invite_206(title: string, swalParameters: any) {
        let combined = '';
        const messages = swalParameters['text4'].map((string) => {
            const [cam, error] = string.split(':').map((parte) => parte.trim());
            return { cam, error };
        });

        for (let i = 0; i < messages.length; i++) {
            const test = ['<span style="color:rgba(48, 110, 191, 1); font-size: 16px; font-weight: 400;";>' + messages[i].cam + ': '
            + '<span style="color:rgba(7, 51, 93, 1); font-size: 16px; font-weight: 400;";>' + messages[i].error];
            combined += test[0];
        }

        Swal.fire({
            title:  '<span style="font-size: 20px; font-weight: 700;";>' + swalParameters['title'],
            text: swalParameters['text'],
            imageUrl: '../../../assets/img/CheckCircle.svg',
            html: swalParameters['text'] + '<span style="color:rgba(48, 110, 191, 1)";>' + swalParameters['text2'] +
                '</span>' +  '<br>' + swalParameters['text3'] +  '<br>' +  '<br>' +
                '<span style="font-size: 20px; font-weight: 700;";>' + swalParameters['title2'] + '<br>' +
                '<span style="color:rgba(48, 110, 191, 1); font-size: 16px; font-weight: 400;";>' +  '<br>' + combined,
            imageWidth: 120,
            imageHeight: 120,
            showCloseButton: true,
            showConfirmButton: false,
            timer: 8000,
            customClass: {
                popup: 'swalParcialFailure',
                title: 'swalTitle',
            },
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'inviteUsers');
        });
    }

    cameras_add_guest_failed_invite_400(title: string, swalParameters: any) {
        Swal.fire({
            title: swalParameters['title'],
            html: (swalParameters['msg'] ? swalParameters['msg'] : 'Não conseguimos realizar a operação.') + '<br>' + 'Tente Novamente.',
            imageUrl: '../../../assets/img/CloseCircle.svg',
            imageWidth: 120,
            imageHeight: 120,
            timer: 5000,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                popup: 'swalStyle',
                title: 'swalTitle',
            }
        }).then(result => {
            SharedService.instance.statusConfirmationSwalValidation(result, 'inviteUsers');
        });

    }


}
