import { Component, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { UserInventory } from 'app/Users/models';
import { Plan } from '../models';

@Component({
    selector: 'app-camera-detections-stock',
    templateUrl: './camera-detections-stock.component.html',
    styleUrls: ['./camera-detections-stock.component.scss']
})
export class CameraDetectionsStockComponent implements OnInit, OnChanges {

    @Input() inventory: UserInventory;
    @Input() plans: Plan[];

    cameraList: {
        alias: string;
        available: number;
        used: number;
        days_storage: number;
        id: number;
    }[];

    filterList: {
        resolution: string;
        plans: {
            id: number;
            days_storage: number;
        }[];
    }[] = [];

    possibleSelections: {
        id: number;
        selected: boolean;
        days_storage: number;
    }[] = [];

    filter: number[] = [];

    tableColumns = ['plan', 'total', 'using', 'graph', 'available'];

    constructor(private element: ElementRef) { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        if (this.inventory && this.plans) {
            this.makeList();
            this.loadFilter();
        }
    }

    makeList() {
        if (this.inventory && this.plans) {
            this.cameraList = [];

            this.plans.forEach(plan => {
                const cameras = this.inventory.inventory.ci;
                if (cameras[plan.id]) {
                    this.cameraList.push({
                        alias: plan.resolution.alias,
                        available: cameras[plan.id].available,
                        used: cameras[plan.id].used,
                        days_storage: plan.days_storage,
                        id: plan.id
                    });
                }
            });
        }
    }

    loadFilter() {
        this.filterList = [];
        const possiblePlans = this.cameraList.map(element => element.id);
        this.plans.forEach((plan) => {
            if (possiblePlans.includes(plan.id)) {
                const resolution = plan.resolution.alias;
                const index = this.filterList.findIndex((element) => element.resolution === resolution);
                if (index >= 0) {
                    this.filterList[index].plans.push({
                        id: plan.id,
                        days_storage: plan.days_storage
                    });
                } else {
                    this.filterList.push({
                        resolution: resolution,
                        plans: [{
                            id: plan.id,
                            days_storage: plan.days_storage
                        }]
                    });
                }
            }
        });
    }

    toggleSelection(category, event) {
        const ul = document.querySelector('.select');
        this.possibleSelections = [];
        if (ul.classList.contains('open') && document.querySelector('li.active').id === category.resolution) {
            ul.classList.remove('open');
            if (document.querySelector('li.active')) {
                document.querySelector('li.active').classList.remove('active');
            }
            this.possibleSelections = [];
        } else {
            ul.classList.remove('open');
            if (document.querySelector('li.active')) {
                document.querySelector('li.active').classList.remove('active');
            }
            ul.classList.add('open');
            event.currentTarget.classList.add('active');

            this.possibleSelections = category.plans.map(plan => {
                return {
                    id: plan.id,
                    days_storage: plan.days_storage,
                    selected: this.filter.includes(plan.id)
                };
            });
        }
    }

    toggleFilter(plan, event) {
        const li = event.currentTarget;
        const index = this.filter.indexOf(plan.id);
        if (index >= 0) {
            li.classList.remove('selected');
            this.filter.splice(index, 1);
        } else {
            li.classList.add('selected');
            this.filter.push(plan.id);
        }

        this.makeList();
        if (this.filter.length > 0) {
            this.populateFilter();
        }
    }

    populateFilter() {
        this.cameraList = this.cameraList.filter(element => {
            return this.filter.includes(element.id);
        });
    }

    clearFilter() {
        this.filter = [];
        this.makeList();
        this.toggleDropdown(true);
    }

    toggleDropdown(forceClose = false) {
        const dropdown = document.querySelector('.open-filter');

        if (dropdown.classList.contains('active') || forceClose) {
            dropdown.classList.remove('active');
            const ul = document.querySelector('.select');
            ul.classList.remove('open');
            this.possibleSelections = [];
            document.querySelector('li.active')?.classList.remove('active');
            document.querySelector('.filter')?.classList.remove('active');
        } else {
            dropdown.classList.add('active');
            document.querySelector('.filter')?.classList.add('active');
        }
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            this.toggleDropdown(true);
        }
    }

}
