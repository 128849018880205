import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Plan } from 'app/cameras/models';
import { ModalData } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-kits-add',
    templateUrl: './user-kits-add.component.html',
    styleUrls: ['./user-kits-add.component.scss']
})
export class UserKitsAddComponent implements OnInit, OnDestroy {
    readonly createKit$ = this.store.select((state: AppState) => state.Users.createKitResult);
    @Output() modalClose = new EventEmitter<string>();

    public registerForm = new UntypedFormGroup({
        cameraAmount: new UntypedFormControl(0, [Validators.required]),
        detectionsAmount: new UntypedFormControl(0, [Validators.required]),
        plan: new UntypedFormControl('', [Validators.required]),
    });

    createKit: Subscription;
    plans: Plan[];
    loadingPlans: boolean;
    loading: boolean;

    constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.loadingPlans = true;
        this.getPlans();
        this.getRegisterResult();
    }

    ngOnDestroy(): void {
        if (this.createKit) {
            this.createKit.unsubscribe();
        }

        this.store.dispatch(UserActions.create_kit_cleanup());
        this.closeModal();
    }

    getPlans() {
        this.plans = this.data.data.plans;
        if (this.plans.length > 0) {
            this.loadingPlans = false;
        }
    }

    closeModal() {
        this.modalClose.emit();
        this.goToKitList();
    }

    goToKitList() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/kits']);
    }

    getRegisterResult() {
        this.createKit = this.createKit$.subscribe(register_feedback => {
            if (register_feedback) {
                this.loading = false;
                if (register_feedback.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'register-kit', register_feedback.message);
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'register-kit-fail', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                }
            }
        });
    }

    onRegister() {
        if (this.loading) {
            return false;
        }

        this.loading = true;
        let errorPass = false;

        if (this.registerForm.get('cameraAmount').invalid) {
            errorPass = true;
            this.registerForm.get('cameraAmount').markAsTouched();
        }
        if (this.registerForm.get('detectionsAmount').invalid) {
            errorPass = true;
            this.registerForm.get('detectionsAmount').markAsTouched();
        }
        if (this.registerForm.get('plan').invalid) {
            errorPass = true;
            this.registerForm.get('plan').markAsTouched();
        }

        if (!errorPass) {
            const kit =  {
                cameras_amount: this.registerForm.get('cameraAmount').value,
                analytics_amount: this.registerForm.get('detectionsAmount').value,
                id_plan: this.registerForm.get('plan').value,
            };

            this.store.dispatch(UserActions.create_kit({
                sub: localStorage.getItem('sub'),
                kit: kit
            }));
        } else {
            this.loading = false;
        }
    }

}
