import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ResultAlert } from 'app/Shared/models';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Router } from '@angular/router';
import { AppState } from 'app/store/model';
import { UserActions } from '../Services/actions';
import { Plan } from 'app/cameras/models';
import { environment } from 'environments/environment';
import { CameraService } from 'app/cameras/Services/camera.service';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { CameraAmount, UserInventory } from '../models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UserService } from '../Services/user.service';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import { NzOptionComponent, NzSelectComponent, NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NgClass, NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';


@Component({
    selector: 'app-user-add-detections',
    templateUrl: './user-add-detections.component.html',
    standalone: true,
    imports: [
        NzInputNumberComponent,
        NzOptionComponent,
        NzSelectComponent,
        NzIconDirective,
        ReactiveFormsModule,
        NgClass,
        FormsModule,
        NzPopoverDirective,
        MatTable,
        NgIf,
        NgFor,
        MatTableModule,
        NzSelectModule
    ],
    styleUrls: ['./user-add-detections.component.scss']
})
export class UserAddDetectionsComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();
    readonly transferItems$ = this.store.select((state: AppState) => state.Users.transferItemsFeedback);
    readonly deleteClientInventory$ = this.store.select((state: AppState) => state.Users.deleteClientInventory);

    displayedColumns: string[] = ['position', 'name', 'actions'];
    dataSource: MatTableDataSource<CameraAmount> = new MatTableDataSource([] as CameraAmount[]);
    editForm = new UntypedFormGroup({
        cameraAmount: new UntypedFormControl(''),
        detectionsAmount: new UntypedFormControl('', [Validators.required]),
        guestsAmount: new UntypedFormControl('', [Validators.required]),
        plan: new UntypedFormControl(''),
    });

    hide = true;
    deleted: boolean;
    planValues: any = [];
    deleteInventorySub: Subscription;
    swalSub: Subscription;
    transferItems: Subscription;
    plans: Observable<Plan[]>;
    planList: Plan[];
    planPartner: Plan[];
    closeDestroy = false;
    idAssociate = environment.associateId;
    loadingPlans: boolean;
    loadingProducts: boolean;
    loadingSubmit: boolean;
    addProduct: boolean;
    clientInventory: UserInventory;
    partnerInventory: UserInventory;
    user_sub: string;
    client: any;
    cameraAmount: CameraAmount[];
    availableInventory = [];
    plan_id: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sharedService: SharedService, private validationResponse: ValidationResponseHandlerModule,
        private router: Router, private readonly store: Store<AppState>, private camService: CameraService, private service: UserService) { }

    ngOnInit(): void {
        this.user_sub = localStorage.getItem('sub');
        this.cameraAmount = [];
        this.planPartner = [];
        this.dataSource.data = [];
        this.transferItemsResult();
        this.loadingPlans = true;
        this.loadingProducts = true;
        this.addProduct = false;
        this.getPlans();
        this.client = this.data.data.client;
        this.clientInventory = this.data.data.clientInventory;
        this.editForm.get('detectionsAmount').setValue(this.clientInventory.inventory.ai.available + this.clientInventory.inventory.ai.used);
        this.editForm.get('guestsAmount').setValue(this.clientInventory.inventory.guests.available + this.clientInventory.inventory.guests.used);
        this.deleted = false;
        this.swalSub = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
        this.deleteInventoryResult();
    }

    ngOnDestroy () {
        if (this.transferItems) {
            this.transferItems.unsubscribe();
        }

        if (this.swalSub) {
            this.swalSub.unsubscribe();
        }
        if (this.deleteInventorySub) {
            this.deleteInventorySub.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    getPlans() {
        this.plans = this.camService.getPlans(this.idAssociate);
        this.plans.subscribe(async planList => {
            if (planList) {
                this.planList = planList;
                planList.map(plan => {
                    if (undefined == this.clientInventory.inventory.ci[plan.id]) {
                        this.planPartner.push(plan);
                    }
                });
                this.loadingPlans = false;
                if (this.planList) {
                    this.getPartnerInventory();
                }
            }
        });
    }

    getPartnerInventory() {
        this.service.userInventory(this.user_sub, null).subscribe(result => {
            if (result) {
                this.partnerInventory = result[0];

                const camerasInfoPartner = this.partnerInventory.inventory.ci;
                const planLength = this.partnerInventory.plans.length;

                for (let i = 0; i < planLength; i++) {
                    const plan = this.partnerInventory.plans[i];
                    const idPlan = plan.id_plan;
                    if (camerasInfoPartner[idPlan].available > 0) {
                        this.availableInventory.push(camerasInfoPartner[idPlan]);
                    }
                }
                this.listInventoryClient();
            }
        });
    }

    submitForm() {
        const planID = this.editForm.get('plan').value;
        const planId = planID.id_plan;
        this.loadingSubmit = true;
        let errorPass = false;
        if (Number(this.editForm.get('cameraAmount').value) != 0) {
            this.planValues[planId] = Number(this.editForm.get('cameraAmount').value);
        }
        const cams = [];
        this.planValues.map((value, index) => {
            cams.push({
                plan_id: index,
                amount: value
            });
        });

        if (this.addProduct == true) {
            if (((this.editForm.get('plan').value).toString()) == '') {
                errorPass = true;
                this.editForm.get('plan').markAsTouched();
            }
            if (((this.editForm.get('cameraAmount').value).toString()) == '') {
                errorPass = true;
                this.editForm.get('cameraAmount').markAsTouched();
            }
        }
        else {
            if (this.editForm.get('detectionsAmount').invalid) {
                errorPass = true;
                this.editForm.get('detectionsAmount').markAsTouched();
            }
            if (this.editForm.get('guestsAmount').invalid) {
                errorPass = true;
                this.editForm.get('guestsAmount').markAsTouched();
            }
        }

        if (!errorPass) {
            this.store.dispatch(UserActions.transfer_items({
                user_sub: localStorage.getItem('sub'),
                buyers_sub: localStorage.getItem('clientView'),
                kits: null,
                cameras: cams,
                analytics_amount: Number(this.editForm.get('detectionsAmount').value),
                guests_amount: Number(this.editForm.get('guestsAmount').value)
            }));
        } else {
            this.loadingSubmit = false;
        }
    }

    transferItemsResult() {
        this.transferItems = this.transferItems$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.closeDestroy = true;
                    this.validationResponse.validationResponseHandler(200, 'user', 'transfer-items', result.message);
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'transfer-items-fail', result.message);
                }
            }
        });
    }

    submitProduct() {
        if (this.addProduct == false) {
            this.addProduct = true;
        }
    }

    updateAmountInfo() {
        const planId = this.editForm.get('plan').value;
        const camerasInfo = this.clientInventory.inventory.ci;
        if (undefined !== camerasInfo[planId.id] && planId.id != camerasInfo[planId.id].id_plan) {
            this.editForm.get('cameraAmount').setValue(camerasInfo[planId.id].available + camerasInfo[planId.id].used);
        } else {
            this.editForm.get('cameraAmount').setValue(0);
        }
    }

    listInventoryClient() {
        const camerasInfo = this.clientInventory.inventory.ci;
        const camerasInfoPartner = this.partnerInventory.inventory.ci;
        for (const plan of this.planList) {
            if (undefined !== camerasInfo[plan.id] && camerasInfoPartner[plan.id]) {
                const obj = {
                    plan_id: plan.id,
                    name: plan.resolution.alias,
                    days: plan.days_storage,
                    partnerAmount: camerasInfoPartner[plan.id].available,
                    amount: camerasInfo[plan.id].available + camerasInfo[plan.id].used
                };
                this.planValues[plan.id] = camerasInfo[plan.id].available + camerasInfo[plan.id].used;
                this.cameraAmount.push(obj);
            }
        }
        this.dataSource.data = this.cameraAmount;
        this.loadingProducts = false;
    }

    closeModal() {
        this.modalClose.emit();
        if (this.closeDestroy) {
            this.redirect();
        }
    }

    redirect() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/client/view']);
    }

    openTooltipHelpTwo(id) {
        const tooltip = document.querySelector(`#tooltipTwo-${id}`) as HTMLElement;
        if (tooltip.style.visibility == '' || tooltip.style.visibility == 'hidden') {
            tooltip.style.visibility = 'visible';
        } else if (tooltip.style.visibility == 'visible') {
            tooltip.style.visibility = 'hidden';
        }
    }

    toStock() {
        this.closeDestroy = false;
        this.router.navigateByUrl('/cameras/stock');
        this.closeModal();
    }

    toggleLoading(status) {
        const loadingId = document.getElementById('loading' + this.plan_id);
        const deleteId = document.getElementById('delete' + this.plan_id);

        if (status) {
            loadingId.classList.remove('display');
            deleteId.classList.add('display');
            this.deleted = true;
        }
        else {
            loadingId.classList.add('display');
            deleteId.classList.remove('display');
            this.deleted = false;
        }
    }

    deleteInventory(element) {
        const camerasInfo = this.clientInventory.inventory.ci;
        this.plan_id = element.plan_id;
        if (this.client.cameras.lengh > 0){
            for (const cam of this.client.cameras) {
                if (camerasInfo[element.plan_id].used >= 5) {
                    this.openTooltipHelpTwo(element.plan_id);
                    return null;
                } else if (cam.id_plan == element.plan_id && cam.protocol == 'RTMP') {
                    this.openTooltipHelpTwo(element.plan_id);
                    return null;
                } else if (this.deleted) {
                    return null;
                } else if (cam.id_plan == element.plan_id || camerasInfo[element.plan_id].used == 0) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'confirm-delete-client-inventory', 'user.delete_client_inventory');
                }
            }
        }
        else {
            this.validationResponse.validationResponseHandler(200, 'user', 'confirm-delete-client-inventory', 'user.delete_client_inventory');
        }
    }


    statusDelete(element) {
        const camerasInfo = this.clientInventory.inventory.ci;
        for (const cam of this.client.cameras) {
            if (camerasInfo[element.plan_id].used >= 5) {
                return 'void_deletion';
            } else if (cam.id_plan == element.plan_id && cam.protocol == 'RTMP') {
                return 'void_deletion';
            } else if (this.deleted) {
                return 'void_deletion';
            }
        }
    }

    validateLimitFive(element): boolean {
        const camerasInfo = this.clientInventory.inventory.ci;
        if (camerasInfo[element.plan_id].used >= 5) {
            return true;
        }
    }

    validateLimitRTMP(element): boolean {
        for (const cam of this.client.cameras) {
            if (cam.id_plan == element.plan_id && cam.protocol == 'RTMP') {
                return true;
            }
        }
    }

    deleteInventoryResult() {
        this.deleteInventorySub = this.deleteClientInventory$.subscribe(result => {
            if (result) {
                this.toggleLoading(false);
                if (result.status === 2) {
                    this.closeDestroy = true;
                    this.validationResponse.validationResponseHandler(200, 'user', 'delete_client_inventory_success', 'user.delete_client_inventory_success');
                    this.closeModal();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'delete_client_inventory_error', result.message);
                }
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteClientInventory') {
            if (returnResultSwal.isConfirmed) {
                this.store.dispatch(UserActions.delete_client_inventory({
                    sub: this.user_sub,
                    child_sub: localStorage.getItem('clientView'),
                    id_plan: this.plan_id,
                }));
                this.toggleLoading(true);
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

}
