<div style="padding: 25px;height: 600px;overflow: auto;">
  <div class="close-modal">
    <i nz-icon nzType="close" (click)="closeModal()" nzTheme="outline"></i>
  </div>
  
  <div class="container-title">
    <span class="title-config">Configurações do Grupo de Câmeras</span>
    <span class="title-name">{{groupName}}</span>
  </div>
  
  <div *ngIf="!loadingList" class="tabs">
    <div class="tabs-header">
      <button class="btn btn-tab active" (click)="changeTab($event)" id="group">
        Dados do grupo
      </button>
      <button class="btn btn-tab" (click)="changeTab($event)" id="cameras">
        Câmeras
      </button>
      <button class="btn btn-tab" (click)="changeTab($event)" id="clients" *ngIf="groupCamera.is_public">
        Clientes
      </button>
      <button class="btn btn-tab" (click)="changeTab($event)" id="associates">
        Convidados
      </button>
    </div>
    <div class="tabs-content">
      <div *ngIf="activeTab === 'group'">
        <div class="label-container">
          <div class="label-name">
            <span>Nome do grupo:</span>
          </div>
          <input nz-input type="text" placeholder="Digite aqui..." class="input-container" [formControl]="name" [readonly]="!(isPartner || adminAssociate)"/>
        </div>
        <div class="label-container private-differentiate">
          <div class="differentiate" *ngIf="!groupCamera.is_public">
            <div class="label-name">
              <span>Cliente:</span>
            </div>
            <span class="label-time">{{groupCamera.clients_info_group[0].name}}</span>
          </div>
          <div>
            <div class="label-name">
              <span>Criado em:</span>
            </div>
            <span class="label-time">{{groupCamera.registered_at | date : 'short' : '' : 'pt-BR'}}</span>
          </div>
        </div>
        <div class="row" *ngIf="adminAssociate || isPartner">
          <div class="col-md-7 col-sm-7 col-7">
            <button type="button" class="btn btn-ant btn-secondary btn-del" style="margin-bottom: 1rem;" [disabled]="loading"
              (click)="deleteCamGroup()">
                <span>
                  <i style="font-size: 20px;" nz-icon nzType="loading" class="prefix" nzTheme="outline" *ngIf="loading"></i>
                  <i style="font-size: 20px;" nz-icon nzType="delete" class="prefix" nzTheme="outline" *ngIf="!loading"></i> Excluir Grupo</span>
            </button>
          </div>
          <div class="col-md-5 col-sm-5 col-5 margin_buttons" *ngIf="name.value !== groupName">
            <button type="button" class="btn btn-ant btn-secondary button_cancel_camera"
              (click)="cancelNameEdit()"><span>Cancelar</span>
            </button>
            <button type="button" (click)="changeName()" class="btn btn-ant btn-primary btn-save-name" [disabled]="loadingName">
              <span>Salvar Alterações</span>
              <i nz-icon nzType="loading" class="posfix" nzTheme="outline" *ngIf="loadingName"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="activeTab === 'cameras'" class="row">
        <div class="title-tab">
          <span>Lista de câmeras</span>
        </div>
        <div class="btn-edit-item" *ngIf="(!selectingCameras) && (adminAssociate || isPartner)">
          <span class="float-right btn-edit" nz-dropdown [nzDropdownMenu]="dropCam" nzPlacement="bottomRight">
            Editar <i nz-icon nzType="edit" nzTheme="fill"></i>
          </span>
          <nz-dropdown-menu #dropCam="nzDropdownMenu">
            <ul nz-menu class="dropdown-menu-edit">
              <li nz-menu-item class="drop-edit" (click)="addInGroup()"><i nz-icon nzType="plus" nzTheme="outline"></i>Adicionar câmeras</li>
              <li nz-menu-item class="drop-edit" *ngIf="dataSource.data.length > 0" (click)="selectingCameras = true"><i nz-icon nzType="delete" nzTheme="outline"></i>Remover câmeras</li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div class="btn-edit-item" *ngIf="selectingCameras && !allSelectedCameras">
          <span class="float-right btn-edit" (click)="allSelectData(dataSource, true)">
            <i nz-icon nzType="check" nzTheme="outline"></i> Selecionar todas
          </span>
        </div>
        <div class="btn-edit-item" *ngIf="selectingCameras && allSelectedCameras">
          <span class="float-right btn-edit" (click)="allSelectData(dataSource, false)">
            <i nz-icon nzType="close" nzTheme="outline"></i> Desmarcar todas
          </span>
        </div>
        <ng-container *ngIf="hasGroups">
          <table mat-table [dataSource]="dataSource" class="style-table" *ngIf="!loadingList">
    
            <ng-container matColumnDef="camera">
              <th mat-header-cell *matHeaderCellDef>Câmera</th>
              <td mat-cell *matCellDef="let element">{{element.alias}}</td>
            </ng-container>
        
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef>Cliente</th>
              <td mat-cell *matCellDef="let element">{{element.owner}}</td>
            </ng-container>
        
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <label class="checkbox-remove" nz-checkbox *ngIf="selectingCameras" [(ngModel)]="element.select" (ngModelChange)="checkSelecteds(dataSource)"></label>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-container>
        <app-feedback imageUrl="nogroupcameras.png" type="grupo de câmeras" [noGroupCameras]="true" *ngIf="!hasGroups" [transparent]="true"></app-feedback>
      </div>
      <div *ngIf="(selectingCameras && activeTab === 'cameras') && (adminAssociate || isPartner)" class="row float-right">
        <div class="margin-button-remove">
          <span class="cancel-selecting"
            (click)="selectingCameras = false"><span>Cancelar</span>
          </span>
          <span type="button" (click)="updateCameraGroup()" class="btn-primary button-remove" [class.disabled]="noneCameraSelected || loading">
            <span><i nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;" *ngIf="!loading"></i><i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading" class="posfix"></i> Remover câmeras selecionadas</span>
          </span>
        </div>
      </div>
      <div *ngIf="activeTab === 'clients'" class="row">
        <div class="title-tab">
          <span>Lista de clientes</span>
        </div>
        <div class="btn-edit-item" *ngIf="(!selectingClients) && (adminAssociate || isPartner)">
          <span class="float-right btn-edit" nz-dropdown [nzDropdownMenu]="dropClient" nzPlacement="bottomRight">
            Editar <i nz-icon nzType="edit" nzTheme="fill"></i>
          </span>
          <nz-dropdown-menu #dropClient="nzDropdownMenu">
            <ul nz-menu class="dropdown-menu-edit">
              <li nz-menu-item class="drop-edit" (click)="addInGroup()"><i nz-icon nzType="plus" nzTheme="outline"></i>Adicionar clientes</li>
              <li nz-menu-item class="drop-edit" *ngIf="dataSourceTwo.data.length > 0" (click)="selectingClients = true"><i nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;"></i>Remover clientes</li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div class="btn-edit-item" *ngIf="selectingClients && !allSelectedClients">
          <span class="float-right btn-edit" (click)="allSelectData(dataSourceTwo, true)">
            <i nz-icon nzType="check" nzTheme="outline"></i> Selecionar todos
          </span>
        </div>
        <div class="btn-edit-item" *ngIf="selectingClients && allSelectedClients">
          <span class="float-right btn-edit" (click)="allSelectData(dataSourceTwo, false)">
            <i nz-icon nzType="close" nzTheme="outline"></i> Desmarcar todos
          </span>
        </div>
        <table mat-table [dataSource]="dataSourceTwo" class="style-table" *ngIf="!loadingList">
    
          <ng-container matColumnDef="camera">
            <th mat-header-cell *matHeaderCellDef>Cliente</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>
      
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>Quantidade de câmeras no grupo</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.cam_count_in_group > 1">{{element.cam_count_in_group}} câmeras</span>
              <span *ngIf="element.cam_count_in_group == 1">{{element.cam_count_in_group}} câmera</span>
              <span *ngIf="element.cam_count_in_group == 0">Nenhuma câmera</span>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <label nz-checkbox *ngIf="selectingClients" [(ngModel)]="element.select" (ngModelChange)="checkSelecteds(dataSourceTwo)"></label>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div *ngIf="(selectingClients && activeTab === 'clients') && (adminAssociate || isPartner)" class="row float-right">
        <div class="margin-button-remove">
          <span class="cancel-selecting"
            (click)="selectingClients = false"><span>Cancelar</span>
          </span>
          <span type="button" (click)="updateCameraGroup()" class="btn-primary button-remove" [class.disabled]="noneClientSelected || loading">
            <span><i nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;" *ngIf="!loading"></i><i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading" class="posfix"></i> Remover clientes selecionados</span>
          </span>
        </div>
      </div>
      <div *ngIf="activeTab === 'associates'">
        <div class="title-tab">
          <span>Lista de convidados</span>
        </div>
        <div class="btn-edit-item" *ngIf="(!selectingAssociates) && (adminAssociate || isPartner)">
          <span class="float-right btn-edit" nz-dropdown [nzDropdownMenu]="dropAssociate" nzPlacement="bottomRight">
            Editar <i nz-icon nzType="edit" nzTheme="fill"></i>
          </span>
          <nz-dropdown-menu #dropAssociate="nzDropdownMenu">
            <ul nz-menu class="dropdown-menu-edit">
              <li nz-menu-item class="drop-edit" (click)="addInGroup()"><i nz-icon nzType="plus" nzTheme="outline"></i>Adicionar convidado</li>
              <li nz-menu-item *ngIf="dataSourceThree.data.length > 0" class="drop-edit" (click)="selectingAssociates = true"><i nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;"></i>Remover convidado</li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div class="btn-edit-item" *ngIf="selectingAssociates && !allSelectedAssociates">
          <span class="float-right btn-edit" (click)="allSelectData(dataSourceThree, true)">
            <i nz-icon nzType="check" nzTheme="outline"></i> Selecionar todos
          </span>
        </div>
        <div class="btn-edit-item" *ngIf="selectingAssociates && allSelectedAssociates">
          <span class="float-right btn-edit" (click)="allSelectData(dataSourceThree, false)">
            <i nz-icon nzType="close" nzTheme="outline"></i> Desmarcar todos
          </span>
        </div>
        <table mat-table [dataSource]="dataSourceThree" class="style-table" *ngIf="!loadingList">
    
          <ng-container matColumnDef="camera">
            <th mat-header-cell *matHeaderCellDef>Convidado</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>
      
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef>Cliente associado</th>
            <td mat-cell *matCellDef="let element">{{element.client_name}}</td>
          </ng-container>
      
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <label nz-checkbox *ngIf="selectingAssociates" [(ngModel)]="element.select" (ngModelChange)="checkSelecteds(dataSourceThree)"></label>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div *ngIf="(selectingAssociates && activeTab === 'associates') && (adminAssociate || isPartner)" class="row float-right">
        <div class="margin-button-remove">
          <span class="cancel-selecting"
            (click)="selectingAssociates = false"><span>Cancelar</span>
          </span>
          <span type="button" (click)="updateCameraGroup()" class="btn-primary button-remove" [class.disabled]="noneAssociateSelected || loading">
            <span><i nz-icon nzType="delete" nzTheme="outline" style="padding-top: 2px;" *ngIf="!loading"></i><i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading" class="posfix"></i> Remover convidados selecionados</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loadingList" class="loadingModal">
    <span nz-icon nzType="loading" nzTheme="outline"></span>
  </div>
</div>
