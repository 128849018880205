<section>
    <div style="display: grid; grid-template-columns: auto;">
        <div (click)="closeModal()">
            <span style="float: right;
          color: #999999;
          cursor: pointer;
          font-size: 20px;">x</span>
        </div>
    </div>
    <div style="display: grid; grid-template-columns: auto;">
        <div>
            <span class="titulo">GERENCIAR CÂMERA</span>
        </div>
    </div>
</section>

<section>
    <div class="card" style="    display: grid;
    grid-template-columns: 10% 80%;
    column-gap: 10px;
    padding-top: 5%;" [ngClass]="generateSelected === true ? 'active' : ''" (click)="selectGenerate()">
        <i class="material-icons confirm_selection">check_circle</i>
        <div>
            <p class="title_selection">EXCLUIR CÂMERA</p>
            <p class="text_selection">Sua assinatura está em dia! Se você excluir a câmera perderá todos os acessos a
                partir de hoje e não
                haverá
                cobranças seguintes. Deseja realmente excluir a câmera?</p>
        </div>
    </div>
</section>

<section>
    <div style="    display: grid;
    grid-template-columns: 60% 18% 18%;
    column-gap: 10px;
    padding-top: 5%;">
        <div></div>
        <div style="display: flex;
        justify-content: flex-end;
      "> <button type="button" class="btn btn-md tamButton button_cancel_camera"
                (click)="closeModal()"><span>cancelar</span></button>
        </div>
        <div style="display: flex;
        justify-content: flex-end;
      "><button type="button" class="btn btn-md btn-primary tamButton button_save_permission"
                [ngClass]="generateSelected === true ? 'active_button' : ''" (click)="deleteCam()">
                <span>EXCLUIR CÂMERA</span></button></div>
    </div>
</section>