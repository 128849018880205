<div class="main-content">
    <div class="first-row">
        <div class="modal-title">
            Calendário de Feriados
            <span class="client-name">{{cameraName}}</span>
        </div>
        <div class="close-modal">
            <i nz-icon nzType="close" (click)="closeModal()" nzTheme="outline"></i>
        </div>
    </div>
    <div class="scroll-modal">
        <div class="second-row">
            <span class="list-title">Lista de feriados do calendário do cliente</span>
        </div>
        <div class="calendar-table">
            <nz-table #HolidayTable [nzData]="calendarListData"
            [nzShowPagination]="false"
            [nzSize]="'middle'"
            [nzTableLayout]="'auto'"
            [nzData]="calendarListData"
            [nzLoading]="loadingCalendarList"
            [nzLoadingIndicator]="loadingListTemplate"
            class="table style-table">
                <thead>
                    <tr>
                        <th nzWidth="40%" class="table-title">Feriado</th>
                        <th class="table-date">Data</th>
                        <th class="table-hour">Horário de Funcionamento</th>
                    </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let calendar of HolidayTable.data; let i = index">
                    <tr [ngClass]="i % 2 === 0 ? 'odd-row' : 'even-row'">
                        <td class="flex flex-col justify-evenly">
                            {{calendar.alias}}
                        </td>
                        <td>{{formatDateRange(calendar.start_time, calendar.end_time)}}</td>
                        <td>{{formatHourRange(calendar.start_time, calendar.end_time)}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </nz-table>
        </div>
        <div *ngIf="calendarListData?.length === 0 && !loadingCalendarList" style="padding: 60px 24px;">
            <app-feedback [noCalendar]="true" imageUrl="noCalendar.svg" [transparent]="true"></app-feedback>
        </div>
        <ng-template #loadingListTemplate>
            <div class="loadingCalendarList" style="margin-top: 60px;">
                <span nz-icon nzType="loading" nzTheme="outline"></span>
            </div>
        </ng-template>
    </div>
</div>
