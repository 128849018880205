import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoginActions } from '../Services/actions';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { GoogleAnalyticsService } from 'app/Shared/Services/google-analytics.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';
import { UserActions } from 'app/Users/Services/actions';
import { ConfigAlarm } from 'app/Users/models';
import { OneSignal } from 'onesignal-ngx';


declare const $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    readonly result$ = this.store.select((state: AppState) => state.login.loginResult);
    cfgReturn: ConfigAlarm = { checkApp: true, checkAva: false, checkSms: true, numbersCell: ['+55999999999'] };
    loginForm = new UntypedFormGroup({
        username: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required])
    });
    emailFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
    ]);
    passwordFormControl = new UntypedFormControl('', [
        Validators.required
    ]);
    loading = false;
    sub: Subscription;
    keepConected = new UntypedFormControl('', [Validators.required]);
    hide = true;
    reportSub: Subscription;
    camSub: Subscription;
    nextVision: boolean;
    playerId: string;
    listCams;
    pageName = 'login';
    canViewLive = false;
    canViewHistory = false;
    guest = false;
    isNextVision = false;
    step: 'email'|'password';
    isEmailListOpen: boolean;
    storedUsers: {
        name: string;
        email: string;
        image: string;
    }[];
    selectedUser: number;
    last: any;

    get passwordInput() { return this.passwordFormControl.value; }
    constructor(private loginService: LoginService, private router: Router, private readonly store: Store<AppState>, private analytics: GoogleAnalyticsService,
        private validationResponse: ValidationResponseHandlerModule, private service: SharedService, private oneSignal: OneSignal) { }

    ngOnInit() {
        this.clearAllInterval();
        this.storedUsers = this.getUserStore();
        if (this.storedUsers.length > 0) {
            this.emailFormControl.setValue(this.storedUsers[0].email);
            this.step = 'password';
        } else {
            this.step = 'email';
        }
        this.isEmailListOpen = false;
        this.playerId = this.oneSignal.User.PushSubscription.id;
        this.keepConected.setValue(true);
        this.getLoginResult();
        this.nextVision = true;

        if (localStorage.getItem('Envtag') == 'nextvision') {
            this.isNextVision = true;
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.camSub) {
            this.camSub.unsubscribe();
        }
        this.store.dispatch(LoginActions.cleanup());
    }

    getLoginResult() {
        this.sub = this.result$.subscribe(result => {
            if (result) {
                if (result.model == 'feedback') {
                    this.loading = false;
                    if (result.statusCode === 203) {
                        this.router.navigateByUrl('/users/blocked');
                    } else if (result.message === 'Confirme sua conta') {
                        localStorage.setItem('username', this.emailFormControl.value);
                        this.router.navigateByUrl('/login/confirm');
                    } else {
                        this.validationResponse.validationResponseHandler(400, this.pageName, 'authentication-error', 'login.authentication_error', result.message);
                    }
                }
                else if (result.model == 'session') {
                    localStorage.setItem('usernameWeCloud', this.emailFormControl.value);
                    sessionStorage.clear();
                    this.clearAllInterval();
                    localStorage.setItem('identifier', result.sub);
                    if (result.permission_invite) {
                        this.guest = true;
                        const permissionList = [];
                        if (result.permission_invite.view_alert_history) {
                            permissionList.push('view_alert_history');
                            this.canViewHistory = true;
                        }
                        if (result.permission_invite.view_live) {
                            permissionList.push('view_live');
                            this.canViewLive = true;
                        }
                        if (result.permission_invite.view_recording) {
                            permissionList.push('view_recording');
                        }

                        localStorage.setItem('guest_permission', permissionList.join('.'));
                    }
                    switch (result.profile_name) {
                        case 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3': //Parceiro Associado
                            localStorage.setItem('associate_permission', result.association.access_control.identifier);
                            localStorage.setItem('partner_permission', JSON.stringify(result.partner_permission));
                            localStorage.setItem('cost_center', result.cost_center);
                            localStorage.setItem('cost_center_mark', String(result.cost_center_mark));
                            this.router.navigateByUrl('home');
                            this.loginService.navSideShowEmitter.emit(true);
                            this.service.setLogin(true);
                            break;
                        case 'cd343bfc-17e8-11ec-9621-0242ac130002': //Parceiro
                            localStorage.setItem('partner_permission', JSON.stringify(result.partner_permission));
                            localStorage.setItem('cost_center', result.cost_center);
                            localStorage.setItem('cost_center_mark', String(result.cost_center_mark));
                            this.router.navigateByUrl('home');
                            this.loginService.navSideShowEmitter.emit(true);
                            this.service.setLogin(true);
                            break;
                        case '7d4e9f24-17e8-11ec-9621-0242ac130002': //Master
                            this.getPartners();
                            this.loginService.navSideShowEmitter.emit(true);
                            this.service.setLogin(true);
                            break;
                        case 'bb653b3a-fdb3-4438-bce6-012585b5268f': // Convidado de câmera
                            if (this.guest) {
                                if (this.canViewLive) {
                                    this.router.navigateByUrl('/cameras/view');
                                }
                                else {
                                    if (this.canViewHistory) {
                                        this.router.navigateByUrl('/cameras/alert');
                                    } else {
                                        this.router.navigateByUrl('/cameras/retrieve');
                                    }
                                }
                            } else {
                                this.router.navigateByUrl('/cameras/list');
                            }
                            this.toCameras();
                            break;
                        case '61902d2b-3ada-49f3-b42a-1775bc064bb0': //Cliente de Parceiro
                        case 'e50483b8-17e8-11ec-9621-0242ac130002': //Cliente Avantia
                        default:
                            this.router.navigateByUrl('/home');
                            this.toCameras();
                            break;
                    }
                    if (result.avva_integration == false) {
                        if (result.profile_name !== 'bb653b3a-fdb3-4438-bce6-012585b5268f') {
                            this.store.dispatch(UserActions.create_config_alarm({ user_sub: result.sub, configList: this.cfgReturn }));
                        }
                    }

                    const permission = result.analiticos_permission ? 'True' : 'False';
                    localStorage.setItem('sub', result.sub);
                    localStorage.setItem('permission-analytics', permission);
                    localStorage.setItem('picture', result.profile_url);
                    this.storeUser(this.emailFormControl.value, result.user_name, result.profile_url);

                    //Tipo do usuario
                    localStorage.setItem('profile_name', result.profile_name);
                    //Caso tenha este hash de billings, o componente deverá ser mostrado
                    if (result.active_modules.includes('27089cf4-c924-11eb-b8bc-0242ac130003')) {
                        localStorage.setItem('billing-hash', result.active_modules[0].toString());
                    }

                    localStorage.setItem('keep-connected', 'true');
                    localStorage.setItem('id-token', result.token);
                    localStorage.setItem('refresh-token', result.refreshToken);
                    localStorage.setItem('access-token', result.accessToken);
                    sessionStorage.setItem('keep-connected', 'true');
                    sessionStorage.setItem('id-token', result.token);
                    sessionStorage.setItem('refresh-token', result.refreshToken);
                    sessionStorage.setItem('access-token', result.accessToken);

                    if (result.profile_name == '419bea06-5d4e-4a56-b8b5-04b3ad566d59') { // Convidado de Cliente
                        localStorage.setItem('associate', JSON.stringify(result.association.associate ));
                        localStorage.setItem('associate_permission', result.association.access_control.identifier);
                    }
                }
            }
        });
    }

    toCameras() {
        this.loading = false;
        this.analytics.eventEmitter('login');
        this.loginService.navSideShowEmitter.emit(true);
        this.service.setLogin(true);
    }

    getClients() {
        this.router.navigateByUrl('users/clients/list');
    }

    getPartners() {
        this.router.navigateByUrl('/users/partners');
    }

    login() {
        if (this.emailFormControl.valid && this.passwordFormControl.valid && !this.loading) {
            this.loading = true;
            const username = this.emailFormControl.value;
            const password = this.passwordFormControl.value;
            this.store.dispatch(LoginActions.signin({
                username: username,
                password: password,
                player_id: this.playerId
            }));
        }
        else if (this.passwordFormControl.invalid) {
            this.passwordFormControl.markAsTouched();
        }
    }

    nextStep() {
        this.emailFormControl.markAsTouched();
        if (this.emailFormControl.valid) {
            this.step = 'password';
        }
    }

    prevStep(newEmail = false) {
        this.step = 'email';
        this.hide = true;
        this.isEmailListOpen = false;
        this.emailFormControl.markAsUntouched();
        this.passwordFormControl.setValue(null);
        if (newEmail) {
            this.emailFormControl.reset();
        }
    }

    storeUser(email: string, name?: string, image?: string){
        const index = this.storedUsers.findIndex(element => element.email === email);
        if (index < 0) {
            this.storedUsers.unshift({
                email: email,
                name: name,
                image: image
            });
        } else {
            this.storedUsers.splice(index, 1);
            this.storedUsers.unshift({
                email: email,
                name: name,
                image: image
            });
        }
        localStorage.setItem('strdUsr', btoa(JSON.stringify(this.storedUsers)));
    }

    changeUser(index) {
        this.selectedUser = index;
        this.emailFormControl.setValue(this.storedUsers[index].email);
        this.isEmailListOpen = false;
    }

    clearUserStore() {
        this.emailFormControl.setValue(null);
        this.storedUsers = [];
        localStorage.removeItem('strdUsr');
        this.prevStep();
    }

    clearOneUser(index) {
        this.emailFormControl.setValue(null);
        this.storedUsers.splice(index, 1);
        this.last = this.storedUsers[this.storedUsers.length - 1];
        localStorage.setItem('strdUsr', btoa(JSON.stringify(this.storedUsers)));
        if (this.storedUsers.length > 0) {
            if (index == 0) {
                this.emailFormControl.setValue(this.storedUsers[0].email);
            }
            else {
                this.emailFormControl.setValue(this.storedUsers[index - 1].email);
            }
        }
        else if (this.last) {
            this.emailFormControl.setValue(this.storedUsers[0].email);
        }
        else {
            this.prevStep();
        }
    }

    getUserStore() {
        try {
            const storageRaw = localStorage.getItem('strdUsr');
            if (storageRaw) {
                return JSON.parse(atob(storageRaw));
            }
            return [];
        } catch (error) {
            localStorage.removeItem('strdUsr');
            return [];
        }
    }

    getPlaceholder(image) {
        if (image == undefined) {
            return '../../../assets/img/placeholder.jpg';
        } else {
            return (image && (image != String('undefined') && image != '')) ? image : '../../../assets/img/placeholder.jpg';
        }
    }

    isImageBroken(element) {
        return element.error;
    }

    clearAllInterval() {
        const intervalId = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
        for (let i = 1; i < intervalId; i++) {
            window.clearInterval(i);
        }
    }

    displayCamName(name: string, trunc: number = 25): string {
        let displayName = name;
        if (displayName.length > trunc) {
            displayName = name.substring(0, trunc) + '...';
        }
        return displayName;
    }

    goToForgot() {
        this.router.navigateByUrl('/login/forgot');
    }
}
