import { Component, OnInit, OnDestroy, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { CognitoAttributes } from '../models';
import { AppState } from 'app/store/model';
import { OperationStatus } from 'app/Shared/models';
import { isValidURL } from '../../Login/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';


@Component({
    selector: 'app-change-link-modal',
    templateUrl: './modal-change-link.component.html',
    styleUrls: ['./user-change-link.component.scss']
})
export class ChangeLinkModalComponent implements OnInit, OnDestroy {
    readonly updatePartnerResult$ = this.store.select((state: AppState) => state.Users.updatePartnerResult);
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);

    dataSub: Subscription;
    updatePartnerResult: Subscription;

    loading: boolean;
    outsideBoolean = false;
    allAttributes: CognitoAttributes;

    linkForm = new UntypedFormGroup({
        currentLink: new UntypedFormControl(''),
        newLink: new UntypedFormControl('', [isValidURL]),
    });

    constructor(public dialogRef: MatDialogRef<ChangeLinkModalComponent>,
        private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule,
        private element: ElementRef) { }

    ngOnInit() {
        this.loading = false;
        this.getUserData();
    }

    ngOnDestroy() {
        if (this.updatePartnerResult) {
            this.updatePartnerResult.unsubscribe();
        }

        if (this.dataSub) {
            this.dataSub.unsubscribe();
        }
    }

    closeCancel()
    {
        this.dialogRef.close(OperationStatus.Unloaded);
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.element.nativeElement.contains(event.target)) {
            if (this.outsideBoolean) {
                this.closeCancel();
            } else {
                this.outsideBoolean = true;
            }
        }
    }

    validateField(field: any): string {
        return field ? field : '';
    }

    getUserData() {
        this.dataSub = this.userData$.subscribe(data => {
            if (data) {
                if (data.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'return-data-error', 'user.client_cognito_error', data.message);
                }
                else {
                    this.allAttributes = data;
                    this.fillUserForm(this.allAttributes);
                }
            }
        });
    }

    fillUserForm(attr: CognitoAttributes) {
        this.linkForm.get('currentLink').setValue(this.allAttributes.contract_link);
    }

    closeConfirm() {
        this.loading = true;
        let errorPass = false;

        this.allAttributes.contract_link = this.linkForm.get('newLink').value;

        if (this.linkForm.get('newLink').invalid) {
            errorPass = true;
            this.linkForm.get('newLink').markAsTouched();
        }

        if (!errorPass) {
            this.store.dispatch(UserActions.update_partner({
                user_sub: localStorage.getItem('sub'),
                cognitoAttr: this.allAttributes
            }));
            this.updatePartnerResult = this.updatePartnerResult$.subscribe(register_feedback => {
                if (register_feedback) {
                    if (register_feedback.status === 2) {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(200, 'user', 'update-partner-user', register_feedback.message);
                        this.closeCancel();
                    } else {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(400, 'user', 'update-partner-user-fail', register_feedback.message);
                        this.store.dispatch(UserActions.cleanup());
                    }
                }
            });
        } else {
            this.loading = false;
        }
    }
}

@Component({
    selector: 'app-user-change-link',
    templateUrl: './user-change-link.component.html',
    styleUrls: ['./user-change-link.component.scss']
})
export class UserChangeLinkComponent implements OnInit, OnDestroy {
    readonly modal_open$ = this.store.select((state: AppState) => state.Users.modal_change_link);

    currentDialog: MatDialogRef<any> = null;
    destroy = new Subject<any>();
    subDialog: Subscription;
    subCombined: Subscription;
    @Output() loadChangeLinkEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(public dialog: MatDialog, private readonly store: Store<AppState>) { }

    ngOnInit() {
        this.subCombined = this.modal_open$.subscribe(modal => {
            if (modal == OperationStatus.Loading) {
                this.currentDialog = this.dialog.open(ChangeLinkModalComponent, {
                    width: 'fit-content',
                    height: 'fit-content',
                    disableClose: true,
                });
                this.subDialog = this.currentDialog.afterClosed().subscribe(result => {
                    if (result === OperationStatus.Success) {
                        this.loadChangeLinkEmitter.emit(true);
                        this.store.dispatch(UserActions.change_link_modal({ payload: OperationStatus.Success }));
                    } else {
                        this.store.dispatch(UserActions.change_link_modal({ payload: OperationStatus.Unloaded }));
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(UserActions.cleanup());
        this.destroy.next(true);
        if (this.subCombined) {
            this.subCombined.unsubscribe();
        }
        if (this.subDialog) {
            this.subDialog.unsubscribe();
        }
    }

}

