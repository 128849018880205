import { NgModule } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { MatRadioModule } from '@angular/material/radio';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NzSliderModule } from 'ng-zorro-antd/slider';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { analyticsRoutes } from './analytics.routing';
import { AnalyticsControllerComponent } from './analytics-controller/analytics-controller.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupAnalyticsComponent } from './group-analytics/group-analytics.component';
import { AnalyticsListComponent } from './analytics-list/analytics-list.component';
import { DrawAnalyticComponent } from './draw-analytic/draw-analytic.component';
import { ConfigureAnalyticComponent } from './configure-analytic/configure-analytic.component';
import { AnalyticCreateComponent } from './analytic-create/analytic-create.component';
import { SharedModulesModule } from '../Shared/shared-modules/shared-modules.module';
import { ComponentsModule } from 'app/components/components.module';
import { RouterModule } from '@angular/router';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzElementPatchModule } from 'ng-zorro-antd/core/element-patch';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NzRadioModule } from 'ng-zorro-antd/radio';

registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AnalyticsControllerComponent,
        GroupAnalyticsComponent,
        AnalyticsListComponent,
        DrawAnalyticComponent,
        ConfigureAnalyticComponent,
        AnalyticCreateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        FlexLayoutModule,
        RouterModule.forChild(analyticsRoutes),
        NzIconModule,
        NzSelectModule,
        SharedModulesModule,
        ComponentsModule,
        NzSliderModule,
        NzPopoverModule,
        NzTableModule,
        NzToolTipModule,
        NzElementPatchModule,
        NzSpinComponent,
        NgxMaskDirective,
        NgxMaskPipe,
        NzRadioModule
    ],
    exports: [
        AnalyticsControllerComponent,
    ],
    providers: [provideNgxMask()]
})
export class AnalyticsModule { }
