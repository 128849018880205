import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-time-range-picker',
    templateUrl: './time-range-picker.component.html',
    styleUrls: ['./time-range-picker.component.scss']
})
export class TimeRangePickerComponent implements OnChanges {

    constructor() { }

    isOpen = false;
    closeAfterClickOutside = false;
    hour = ['00', '01', '02', '03', '04',
        '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14',
        '15', '16', '17', '18', '19',
        '20', '21', '22', '23'];

    minute = ['00', '01', '02', '03', '04',
        '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14',
        '15', '16', '17', '18', '19',
        '20', '21', '22', '23', '24',
        '25', '26', '27', '28', '29',
        '30', '31', '32', '33', '34',
        '35', '36', '37', '38', '39',
        '40', '41', '42', '43', '44',
        '45', '46', '47', '48', '49',
        '50', '51', '52', '53', '54',
        '55', '56', '57', '58', '59'];


    @Input() time: string[];
    @Output() changeTime = new EventEmitter<string[]>();
    @Input() calendarSelect: boolean;
    @Input() calendarTable: boolean;

    startHour = '00';
    startMinute = '00';
    endHour = '00';
    endMinute = '00';

    @HostListener('document:click', ['$event.target'])
    onClick(btn: HTMLElement) {
        if (this.isOpen && this.closeAfterClickOutside &&
       !btn.classList.contains('selected') &&
       !btn.classList.contains('hours') &&
       !btn.classList.contains('minutes'))
        {
            this.isOpen = false;
            this.closeAfterClickOutside = false;
        }
    }

    ngOnChanges(): void {
        this.startHour = this.formatValue(this.time[0].split(':')[0]);
        this.startMinute = this.formatValue(this.time[0].split(':')[1]);
        this.endHour = this.formatValue(this.time[1].split(':')[0]);
        this.endMinute = this.formatValue(this.time[1].split(':')[1]);
    }

    public openTimePicker(): void {
        this.isOpen = true;

        setTimeout(() => {
            const startTime = document.getElementById('startTime') as HTMLElement;
            const endTime = document.getElementById('endTime') as HTMLElement;

            startTime.children[0].scrollTop = +this.startHour * 30;
            startTime.children[1].scrollTop = +this.startMinute * 30;

            endTime.children[0].scrollTop = +this.endHour * 30;
            endTime.children[1].scrollTop = +this.endMinute * 30;

            this.closeAfterClickOutside = true;
        }, 1);


    }

    public changeValue(): void {
        const startAt = this.startHour + ':' + this.startMinute;
        const endAt = this.endHour + ':' + this.endMinute;

        this.changeTime.emit([startAt, endAt]);
    }

    public formatValue(aux: string): string {
        if (aux.length == 2) {
            return aux;
        } else if (aux.length > 2) {
            return aux.substring(0, 1);
        } else {
            return '0' + aux;
        }
    }

}
