import { Injectable } from '@angular/core';
import { Feedback } from 'app/Shared/models';
import { FluxStandardAction } from 'flux-standard-action';
import { createAction, props } from '@ngrx/store';
import { AnalyticsByCamera, GroupAnalytics, TypeClass, ModalState, ScheduleAnalytic } from '../models';

type Payload = AnalyticsByCamera[] | GroupAnalytics[] | TypeClass[] | ScheduleAnalytic[] | string | Feedback | boolean | ModalState | any;

export interface Metadata {
    camera_id?: number;
    id_analytic?: number;
    idAnalyticType?: number;
    new_analytic?: AnalyticsByCamera;
    put_analytic?: AnalyticsByCamera;
    status?: boolean;
    child_sub?: string;
    sub?: string;
}

export type AnalyticAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class AnalyticActions {
    //Ações de analíticos
    static readonly OPEN_MODAL_ANALYTICS = 'OPEN_MODAL_ANALYTICS';
    static readonly CLOSE_MODAL_ANALYTICS_CONFIRM = 'CLOSE_MODAL_ANALYTICS_CONFIRM';
    static readonly CLOSE_MODAL_ANALYTICS_CANCEL = 'CLOSE_MODAL_ANALYTICS_CANCEL';
    static readonly UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';
    static readonly GET_ANALYTICS_CAMERA = 'GET_ANALYTICS_CAMERA';
    static readonly GET_ANALYTICS_CAMERA_RETURN = 'GET_ANALYTICS_CAMERA_RETURN';
    static readonly GET_ANALYTICS_GROUP = 'GET_ANALYTICS_GROUP';
    static readonly GET_ANALYTICS_GROUP_RETURN = 'GET_ANALYTICS_GROUP_RETURN';
    static readonly GET_ANALYTICS_CLASS = 'GET_ANALYTICS_CLASS';
    static readonly GET_ANALYTICS_CLASS_RETURN = 'GET_ANALYTICS_CLASS_RETURN';
    static readonly POST_ANALYTICS_CAMERA = 'POST_ANALYTICS_CAMERA';
    static readonly POST_ANALYTICS_CAMERA_RETURN = 'POST_ANALYTICS_CAMERA_RETURN';
    static readonly POST_ANALYTICS_CAMERA_CHANGE_FEEDBACK = 'POST_ANALYTICS_CAMERA_CHANGE_FEEDBACK';
    static readonly PUT_ANALYTICS_CAMERA = 'PUT_ANALYTICS_CAMERA';
    static readonly PUT_ANALYTICS_CAMERA_RETURN = 'PUT_ANALYTICS_CAMERA_RETURN';
    static readonly PUT_ANALYTICS_CAMERA_CHANGE_FEEDBACK = 'PUT_ANALYTICS_CAMERA_CHANGE_FEEDBACK';
    static readonly DELETE_ANALYTICS_CAMERA = 'DELETE_ANALYTICS_CAMERA';
    static readonly DELETE_ANALYTICS_CAMERA_RETURN = 'DELETE_ANALYTICS_CAMERA_RETURN';
    static readonly DELETE_ANALYTICS_CAMERA_CHANGE_FEEDBACK = 'DELETE_ANALYTICS_CAMERA_CHANGE_FEEDBACK';
    static readonly PUT_MODAL_DATA = 'PUT_MODAL_DATA';
    static readonly GET_ANALITYCS_TOTAL_CREATES = 'GET_ANALITYCS_TOTAL_CREATES';
    static readonly GET_ANALITYCS_TOTAL_CREATES_RETURN = 'GET_ANALITYCS_TOTAL_CREATES_RETURN';
    static readonly GET_ANALITYCS_OLD_SCHEDULES = 'GET_ANALITYCS_OLD_SCHEDULES';
    static readonly GET_ANALITYCS_OLD_SCHEDULES_RETURN = 'GET_ANALITYCS_OLD_SCHEDULES_RETURN';
    static readonly CLEANUP = 'CLEANUP';
    static readonly CLEANUP_CAMERA_ANALYTICS = 'CLEANUP_CAMERA_ANALYTICS';
    static readonly CLEANUP_GROUP_ANALYTICS = 'CLEANUP_GROUP_ANALYTICS';
    static readonly CLEANUP_CLASSES_ANALYTICS = 'CLEANUP_CLASSES_ANALYTICS';
    static readonly CLEANUP_CONTROLLER_ANALYTICS = 'CLEANUP_CONTROLLER_ANALYTICS';

    static update_modal_state = createAction(
        AnalyticActions.UPDATE_MODAL_STATE,
        props<{ payload: Payload }>()
    );

    static post_analytics_camera = createAction(
        AnalyticActions.POST_ANALYTICS_CAMERA,
        props<Metadata>()
    );

    static post_analytics_camera_return = createAction(
        AnalyticActions.POST_ANALYTICS_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static post_analytics_camera_change_feedback = createAction(
        AnalyticActions.POST_ANALYTICS_CAMERA_CHANGE_FEEDBACK
    );

    static put_analytics_camera = createAction(
        AnalyticActions.PUT_ANALYTICS_CAMERA,
        props<Metadata>()
    );

    static put_analytics_camera_return = createAction(
        AnalyticActions.PUT_ANALYTICS_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static put_analytics_camera_change_feedback = createAction(
        AnalyticActions.PUT_ANALYTICS_CAMERA_CHANGE_FEEDBACK
    );

    static delete_analytics_camera = createAction(
        AnalyticActions.DELETE_ANALYTICS_CAMERA,
        props<Metadata>()
    );

    static delete_analytics_camera_return = createAction(
        AnalyticActions.DELETE_ANALYTICS_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static delete_analytics_camera_change_feedback = createAction(
        AnalyticActions.DELETE_ANALYTICS_CAMERA_CHANGE_FEEDBACK
    );

    static get_analytics_camera = createAction(
        AnalyticActions.GET_ANALYTICS_CAMERA,
        props<Metadata>()
    );

    static get_analytics_camera_return = createAction(
        AnalyticActions.GET_ANALYTICS_CAMERA_RETURN,
        props<{ payload: Payload }>()
    );

    static get_analytics_group = createAction(
        AnalyticActions.GET_ANALYTICS_GROUP
    );

    static get_analytics_group_return = createAction(
        AnalyticActions.GET_ANALYTICS_GROUP_RETURN,
        props<{ payload: Payload }>()
    );

    static get_analytics_class = createAction(
        AnalyticActions.GET_ANALYTICS_CLASS,
        props<Metadata>()
    );

    static get_analytics_class_return = createAction(
        AnalyticActions.GET_ANALYTICS_CLASS_RETURN,
        props<{ payload: Payload }>()
    );

    static get_analitycs_total_creates = createAction(
        AnalyticActions.GET_ANALITYCS_TOTAL_CREATES,
        props<Metadata>()
    );

    static get_analitycs_total_creates_return = createAction(
        AnalyticActions.GET_ANALITYCS_TOTAL_CREATES_RETURN,
        props<{ payload: Payload }>()
    );

    static get_analitycs_old_schedules = createAction(
        AnalyticActions.GET_ANALITYCS_OLD_SCHEDULES,
        props<Metadata>()
    );

    static get_analitycs_old_schedules_return = createAction(
        AnalyticActions.GET_ANALITYCS_OLD_SCHEDULES_RETURN,
        props<{ payload: Payload }>()
    );

    static put_modal_data = createAction(
        AnalyticActions.PUT_MODAL_DATA,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        AnalyticActions.CLEANUP
    );

    static cleanup_camera_analytics = createAction(
        AnalyticActions.CLEANUP_CAMERA_ANALYTICS
    );

    static cleanup_group_analytics = createAction(
        AnalyticActions.CLEANUP_CAMERA_ANALYTICS
    );

    static cleanup_classes_analytics = createAction(
        AnalyticActions.CLEANUP_CLASSES_ANALYTICS
    );

    static cleanup_controller_analytics = createAction(
        AnalyticActions.CLEANUP_CONTROLLER_ANALYTICS
    );
}

