import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { Camera, Address } from '../models';
import { Icon, Style } from 'ol/style';
import { Translate } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { Collection } from 'ol';
import { TranslateEvent } from 'ol/interaction/Translate';
import { SharedService } from 'app/Shared/Services/shared.service';
import { environment } from 'environments/environment';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { defaults as defaultControls} from 'ol/control.js';

@Component({
    selector: 'app-camera-address',
    templateUrl: './camera-address.component.html',
    styleUrls: ['./camera-address.component.scss']
})
export class CameraAddressComponent implements OnInit, OnDestroy {
    public map: Map;
    public source: OSM;
    public view: View;
    loadingCep = false;
    loadingGeo = false;
    mapShow = false;
    longitude: number;
    latitude: number;
    addressForm = new UntypedFormGroup({
        CEP: new UntypedFormControl('', [Validators.required]),
        street: new UntypedFormControl('', [Validators.required]),
        num: new UntypedFormControl(''),
        complemento: new UntypedFormControl(''),
        cidade: new UntypedFormControl('', [Validators.required]),
        estado: new UntypedFormControl('', [Validators.required]),
        bairro: new UntypedFormControl('', [Validators.required]),
    });
    addressDataSub: Subscription;
    camera: Camera;
    hasMarker = false;
    iconFeature: Feature;
    iconStyle: Style;
    vectorSource: VectorSource;
    translate: Translate;
    vectorLayer: VectorLayer<VectorSource>;
    save_changes;
    states: string[] = environment.estados;
    estados = environment.Estados;
    @Input() loading: boolean;
    @Input() addressDataReceived: Observable<Camera>;
    @Output() camEditData = new EventEmitter<boolean>();
    @Output() cancelEmmiter = new EventEmitter<void>();
    @Output() saveEmmiter = new EventEmitter<Object>();
    constructor(private service: SharedService, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.addressDataSub = this.addressDataReceived.subscribe(value => {
            this.camera = value;
            this.setAddressInformation(value.address);
            if (this.mapShow == false) {
                this.initMap();
            }
        });
        this.addressForm.valueChanges.subscribe(value => {
            this.save_changes = true;
            if (this.addressForm.valid) {
                const cameraData = {
                    rua: this.addressForm.get('street').value,
                    numero: this.addressForm.get('num').value,
                    complemento: this.addressForm.get('complemento').value,
                    cep: this.addressForm.get('CEP').value,
                    bairro: this.addressForm.get('bairro').value,
                    estado: this.addressForm.get('estado').value,
                    cidade: this.addressForm.get('cidade').value,
                    latitude: this.latitude ? String(this.latitude) : null,
                    longitude: this.longitude ? String(this.longitude) : null,
                };
                this.saveEmmiter.emit(cameraData);
            }
        });
        this.mapShow = false;
    }

    closeModal() {
        this.cancelEmmiter.emit();
    }

    setAddressInformation(address: Address) {
        this.latitude = address.latitude ? Number(address.latitude) : null;
        this.longitude = address.longitude ? Number(address.longitude) : null;
        const num = Number(address.numero) ? Number(address.numero) : null;
        this.addressForm.get('CEP').setValue(address.cep);
        this.addressForm.get('num').setValue(num);
        this.addressForm.get('bairro').setValue(address.bairro);
        this.addressForm.get('estado').setValue(address.estado);
        this.addressForm.get('cidade').setValue(address.cidade);
        this.addressForm.get('street').setValue(address.rua);
    }

    ngOnDestroy() {
        if (this.addressDataSub) {
            this.addressDataSub.unsubscribe();
        }
    }

    // Carrega o mapa segundo as especificações anteriores (Latitude/Longitude)
    initMap() {
        this.view = new View({
            center: [0, 0],
            zoom: 16
        });
        this.map = new Map({
            layers: [
                new TileLayer({
                    preload: 3,
                    source: new OSM({})
                })
            ],
            controls: defaultControls({attribution: false, zoom: false, rotate: false}),
            view: this.view
        });
        const element = document.getElementById('map');
        //centraliza o mapa para a localização do navegador
        this.map.setTarget(element);
        this.view.animate({
            center: fromLonLat([this.longitude, this.latitude]),
            duration: 2000
        });
        this.placeMarker(this.longitude, this.latitude);
        this.map.addEventListener('postrender', this.showMap.bind(this));
    }

    showMap() {
        setTimeout(() => this.mapShow = true, 1500);
        this.map.removeEventListener('postrender', this.showMap.bind(this));
    }

    placeMarker(long: number, lat: number) {
    //Atualizar posição do marcador
        if (this.hasMarker) {
            this.iconFeature.setGeometry(new Point(fromLonLat([long, lat])));
        }
        //cria marcador
        else {
            this.iconFeature = new Feature({
                geometry: new Point(fromLonLat([long, lat]))
            });
            this.iconStyle = new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: '../../../assets/img/overley.png'
                })
            });
            this.iconFeature.setStyle(this.iconStyle);
            //adiciona marcador ao mapa
            this.vectorSource = new VectorSource({
                features: [this.iconFeature]
            });
            this.vectorLayer = new VectorLayer({
                source: this.vectorSource
            });
            this.map.addLayer(this.vectorLayer);
            //habilita translação no marcador
            this.translate = new Translate({
                features: new Collection([this.iconFeature])
            });
            //adiciona callback a evento disparado no fim de uma translação
            this.translate.on('translateend', this.logPosition.bind(this));
            this.map.addInteraction(this.translate);
            //Indica que já existe um marcador
            this.hasMarker = true;
        }
    }

    //Recupera endereço a partir de coordenadas
    logPosition(event: TranslateEvent) {
        const coord = toLonLat(event.coordinate);
        // this.addressForm.disable();
        this.longitude = coord[0];
        this.latitude = coord[1];
        this.service.getReverseGeo(coord).toPromise().then(result => {
            this.replaceForm(result);
            // this.addressForm.enable();
        }).catch(error => console.log(error));
    }

    //atualiza formulário com dados do novo endereço
    replaceForm(result: any) {
        this.addressForm.get('CEP').setValue(result.address.postcode);
        this.addressForm.get('street').setValue(result.address.road);
        this.addressForm.get('bairro').setValue(result.address.suburb);
        this.addressForm.get('cidade').setValue(result.address.city);
        const estadoUf = result.address.state;
        const campo = this.addressForm;
        // Função para inserir o estado no section
        this.estados.forEach(function (uf) {
            if (uf.estado === estadoUf) {
                campo.get('estado').setValue(uf.uf);
            }
        });
    }

    //Consulta latitude e longitude através do endereço
    getLongLat(): void {
        this.loadingGeo = true;
        const data = {
            logradouro: this.addressForm.get('street').value,
            bairro: this.addressForm.get('bairro').value,
            cidade: this.addressForm.get('cidade').value,
            estado: this.addressForm.get('estado').value,
            country: 'Brasil'
        };
        this.service.getGeoLocation(data).toPromise().then(result => {
            if (result.length) {
                this.latitude = parseFloat(result[0].lat);
                this.longitude = parseFloat(result[0].lon);
                //Centraliza mapa para novas coordenadas
                this.view.animate({
                    center: fromLonLat([this.longitude, this.latitude]),
                    duration: 2000,
                    zoom: 16
                });
                this.placeMarker(this.longitude, this.latitude);
            }
            // else {
            //   this.validationResponse.validationResponseHandler(404, 'camera-form', 'address-not-found', 'cameras.address_not_found');
            // }
            this.loadingGeo = false;
        });
    }

    //Consulta o endereço a partir do CEP e seta no formulário
    getAddress(): void {
        this.loadingCep = true;
        if (this.addressForm.get('CEP').value == null){
            this.validationResponse.validationResponseHandler(400, 'camera-form', 'cep-null', 'cameras.cep_null');
            this.loadingCep = false;
        }
        let cep: string = this.addressForm.get('CEP').value;
        cep = cep.replace(/\D/g, '');
        if (this.addressForm.get('CEP').invalid) {
            this.validationResponse.validationResponseHandler(400, 'camera-form', 'cep-null', 'cameras.cep_null');
            this.loadingCep = false;
        }
        else {
            this.service.getAddressFromCEP(cep).toPromise().then(result => {
                if (result.erro) {
                    this.validationResponse.validationResponseHandler(404, 'camera-form', 'cep-not-found', 'cameras.cep_not_found');
                }
                else {
                    this.addressForm.get('num').setValue('');
                    this.addressForm.get('street').setValue(result.logradouro);
                    this.addressForm.get('bairro').setValue(result.bairro);
                    this.addressForm.get('cidade').setValue(result.localidade);
                    this.addressForm.get('estado').setValue(result.uf);
                    this.getLongLat();
                }
                this.loadingCep = false;
            }).catch(error => {
                this.loadingCep = false;
                this.validationResponse.validationResponseHandler(400, 'camera-form', 'cep-calculator', 'cameras.cep_calculator_error');
            });
        }
    }

    saveChanges() {
        if (this.addressForm.valid) {
            this.camEditData.emit(true);
        } else {
            this.addressForm.markAllAsTouched();
        }
    }
}
