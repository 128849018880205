import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {map, catchError, mergeMap} from 'rxjs/operators';
import { GLPIAction, GLPIActions, Metadata } from './glpi.actions';
import { GlpiService } from './glpi.service';
import { HandleError } from 'app/Shared/Helpers/HandleError';
import { OperationStatus } from 'app/Shared/models';
import {Observable} from 'rxjs';

@Injectable()
export class GlpiEffects {
    constructor(private service: GlpiService, private actions: Actions, private error: HandleError) {
    }

    createTicket$ = createEffect(() =>
        this.actions.pipe(
            ofType(GLPIActions.CREATE_TICKET),
            mergeMap((action: Metadata) => this.service.createTicket(action.user_sub, action.child_sub, action.ticket, action.document_ids).pipe(
                map(result => {
                    return GLPIActions.create_ticket_return({ payload: result });
                }),
                catchError(this.error.handleError<GLPIAction>('createTicket', GLPIActions.create_ticket_return({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                GLPIActions.create_ticket(action)))
            )
            )
        )
    );

    getSession$ = createEffect(() =>
        this.actions.pipe(
            ofType(GLPIActions.GET_SESSION),
            mergeMap((action: Metadata) => this.service.getSession(action.user_sub).pipe(
                map(result => {
                    return GLPIActions.get_session_return({ payload: result });
                }),
                catchError(this.error.handleError<GLPIAction>('getSession', GLPIActions.get_session_return({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                GLPIActions.get_session(action)))
            )
            )
        )
    );

    uploadDocument$ = createEffect(() =>
        this.actions.pipe(
            ofType(GLPIActions.UPLOAD_DOCUMENT),
            mergeMap((action: Metadata) => this.service.uploadDocument(action.document, action.session_token).pipe(
                map(result => {
                    return GLPIActions.upload_document_return({ payload: result });
                }),
                catchError(this.error.handleError<GLPIAction>('uploadDocument', GLPIActions.upload_document_return({
                    payload: {
                        message: 'Falha de rede',
                        status: OperationStatus.Fail
                    }
                }),
                GLPIActions.upload_document(action)))
            )
            )
        )
    );
}
