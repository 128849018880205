<nav class="navbar navbar-expand-lg navbar-light" [ngStyle]="{'z-index': 200}">
    <div class="container-fluid">
        <div class="menu">
            <span class="menu-text">MENU</span>
            <img class="" src="/assets/img/menu.svg" width="72" (click)="toggleSidebar()">
        </div>
        <a class="container-notify" (click)="toggleNotify()">
            <span *ngIf="notifyWarning" class="notif"></span>
            <span nz-icon nzType="bell" nzTheme="outline" class="notif-icon"></span>
        </a>
        <div class="notifi-box" id="box">
            <ng-container *ngIf="down">
                <div class="top-buttons">
                    <div class="">
                        <span class="markAllAsRead" (click)="markAllAsRead()" [hidden]="!notifyWarning" [class.disabled]="isMarkAll">
                            Marcar todas como lidas
                            <span nz-icon nzType="loading" style="font-size: 14px; margin-left: 10px;" nzTheme="outline" *ngIf="isMarkAll" class="posfix"></span>
                        </span>
                    </div>
                    <span class="close-notification" (click)="toggleNotify()"> 
                        <i class="material-icons">close</i>
                    </span>
                </div>
            </ng-container>
            <ng-container *ngIf="!isEmptyNotification() && !loading">
                <ng-container *ngIf="todayNotification.length > 0">
                    <h2 class="labels">Hoje</h2>
                    <ng-container *ngFor="let notification of todayNotification">
                        <div class="notifi-item" (click)="notifyRedirect(notification)"
                        [ngClass]="notification.visibility == false ? 'notify-no-visible' : ''">
                            <img [src]="selectIconSvg(notification.notification_type)" alt="img">
                            <div class="text">
                               <h4>{{notification.content.title}}</h4>
                               <p>{{notification.content.body}}</p>
                            </div>
                            <div class="date-time">
                                <p>{{notification.timestamp}}</p>
                            </div>
                            <div class="notificationFlag" *ngIf="!notification.visibility"></div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="lastWeekNotification.length > 0">
                    <h2 class="labels">Essa semana</h2>
                    <ng-container *ngFor="let notification of lastWeekNotification">
                        <div class="notifi-item" (click)="notifyRedirect(notification)"
                        [ngClass]="notification.visibility == false ? 'notify-no-visible' : ''">
                            <img [src]="notification.notification_type == 'Analítico Configurado' ? '../../../assets/img/CheckCircle.svg' : '../../../assets/img/NoCamCircle.svg'" alt="img">
                            <div class="text">
                               <h4>{{notification.content.title}}</h4>
                               <p>{{notification.content.body}}</p>
                            </div>
                            <div class="date-time">
                                <p>{{notification.timestamp}}</p>
                            </div>
                            <div class="notificationFlag" *ngIf="!notification.visibility"></div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="lastMonthNotification.length > 0">
                    <h2 class="labels">Esse mês</h2>
                    <ng-container *ngFor="let notification of lastMonthNotification">
                        <div class="notifi-item" (click)="notifyRedirect(notification)"
                        [ngClass]="notification.visibility == false ? 'notify-no-visible' : ''">
                            <img [src]="notification.notification_type == 'Analítico Configurado' ? '../../../assets/img/CheckCircle.svg' : '../../../assets/img/NoCamCircle.svg'" alt="img">
                            <div class="text">
                               <h4>{{notification.content.title}}</h4>
                               <p>{{notification.content.body}}</p>
                            </div>
                            <div class="date-time">
                                <p>{{notification.timestamp}}</p>
                            </div>
                            <div class="notificationFlag" *ngIf="!notification.visibility"></div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <div *ngIf="loading" class="loading-container">
                <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size: 40px"></span>
            </div>
            <div *ngIf="isEmptyNotification() && !loading" class="not-notify">
                <span>Ainda não há notificações</span>
            </div>
        </div>
        <div class="navbar-nav">
            <li class="nav-item">
                <ul class="font-text-nav">
                    <img class="profile_pic cursor_menu" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                        [src]="getPlaceholder()" height="48" width="48">
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu class="dropdown-template">
                            <li nz-menu-item (click)="userSummary()"><a>Minha conta</a></li>
                            <li nz-menu-item (click)="logout()"><a style="color: red;">Sair</a></li>
                        </ul>
                    </nz-dropdown-menu>
                </ul>
            </li>
        </div>
    </div>
</nav>
