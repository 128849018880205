<div class="modal-container">
    <div class="row header">
        <div class="title-button">
            <span class="title-modal">Entre em contato</span>
        </div>
        <div (click)="closeModal()" class="close-modal">
            <span nz-icon nzType="close" nzTheme="outline" style="top: -3px; position: relative;"></span>
        </div>
    </div>
    <div class="row" [formGroup]="glpiForm">
        <div class="label-container">
            <div class="label-title">
                <span>E-mail: </span>
            </div>
            <nz-input-group [nzSuffix]="spinEmail" class="input-container">
                <input nz-input type="email" placeholder="Digite aqui..." id="email" formControlName="email" />
            </nz-input-group>
            <ng-template #spinEmail>
                <div class="loadingCamera" *ngIf="loadingEmail">
                    <span nz-icon nzType="loading" nzTheme="outline"></span>
                </div>
            </ng-template>
            <mat-error
                *ngIf="glpiForm.get('email').hasError('email') && !glpiForm.get('email').hasError('required')">
                Por favor digite um formato de e-mail válido
            </mat-error>
            <mat-error *ngIf="glpiForm.get('email').hasError('required') && glpiForm.get('email').touched">
                *O e-mail deve ser preenchido
            </mat-error>
        </div>
        <div class="label-container">
            <div class="label-title">
                <span>Assunto:</span>
            </div>
            <input nz-input type="text" placeholder="Digite aqui..." id="subject" formControlName="subject" class="input-container" />
            <mat-error *ngIf="glpiForm.get('subject').hasError('required') && glpiForm.get('subject').touched">
                *O assunto deve ser preenchido
            </mat-error>
        </div>
        <div class="label-container">
            <div class="label-title">
                <span>Descrição:</span>
            </div>
            <textarea rows="3" nz-input type="text" placeholder="Digite aqui..." id="message" formControlName="message" class="input-container"></textarea>
            <mat-error *ngIf="glpiForm.get('message').hasError('required') && glpiForm.get('message').touched">
                *A descrição deve ser preenchida
            </mat-error>
        </div>
        <div class="label-container">
            <div class="label-title">
                <span>Imagem: (Opcional)</span>
                <div>
                    <div class="row list-container">
                        <div class="card" *ngIf="this.documentIds.length > 0">
                            <span class="title"><i class="file-icon" nz-icon nzType="file" nzTheme="outline"></i>
                                {{documentIds.length}}{{documentIds.length > 1? ' imagens' : ' imagem'}}
                            </span>
                            <span class="close" (click)="removeItem()"><span nz-icon nzType="close" nzTheme="outline"></span></span>
                        </div>
                    </div>
                    <label for="imagem" class="image-container"><span nz-icon style="font-size:24px" [nzType]="session_token ? 'paper-clip' : 'loading'" nzTheme="outline"></span> Adicionar uma imagem</label>
                    <input nz-up type="file" name="imagem" id="imagem" #fileInput accept="image/*" [disabled]="!session_token" multiple (change)="onFileUpload($event)">
                </div>
            </div>
        </div>
        <div class="row button-container">
            <div id="line"><hr/></div>
            <button class="btn btn-primary btn-ant enter-button" [disabled]="loadingImage"
            (click)="sendInfo()" *ngIf="!loading">
              Enviar
            </button>
            <div *ngIf="loading" class="loading-container">
                <span nz-icon class="center" nzType="loading" nzTheme="outline"></span>
                <button [disabled]="loading" class="btn btn-primary btn-ant enter-button">
                    Enviando
                </button>
            </div>
          </div>
    </div>

</div>
