<div class="card card-cards-groups">
    <div class="flex flex-row gap-8 responsive_cards">
        <div *ngFor="let analyticGroup of groupAnalytic" class="flex-grow-1 flex-shrink-0 w-20">
            <div class="card card-analitic-group container_groups">
                <div class="card-header">
                    <div class="row name-cardGroup">
                        <h4 class="responsive-group-font-size">{{analyticGroup.alias}}</h4>
                    </div>
                    <div class="row row_margin">
                        <div class="lt-md:hidden col-0 col-sm-0 col-md-0 col-lg-8 col-xl-8 col-xga-8 col-fhd-8">
                            <img class="dim-img-groups img-group-position" fill="svg"
                                src="../../assets/img/Icons/{{analyticGroup.alias}}.svg" [ngClass]="{
                                    'img_plus_analytic':analyticGroup.alias === 'Produtividade'
                                  }" alt="">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 container_align">
                            <span *ngIf="this.analytic_available !== 0" class="card-img-top dim-img-add" (click)="selectGroup(analyticGroup.id)">
                                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
                            </span>
                            <span *ngIf="this.analytic_available === 0" class="card-img-top dim-img-add">
                                <i class="tooltip-show" nz-icon nzType="plus-circle" nzTheme="outline"></i>
                                <div *ngIf="this.analytic_available === 0" class="tooltiptext">
                                    <span class="tooltiptextsoft">Limite de detecções atingido</span>
                                    <br><br>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
