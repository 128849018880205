import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { Store } from '@ngrx/store';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { filter, Subscription } from 'rxjs';
import { Holidays } from '../models';

@Component({
    selector: 'app-user-calendar-list',
    standalone: false,
    templateUrl: './user-calendar-list.component.html',
    styleUrl: './user-calendar-list.component.scss'
})
export class UserCalendarListComponent implements OnInit, OnDestroy {

    @Output() modalClose = new EventEmitter<string>();
    cameraName: string;
    idCamera: number;
    heightModal: string;
    calendarListData: Holidays[];
    loadingCalendarList: boolean;
    user_sub: string;
    calendarSub: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) {
        this.user_sub = localStorage.getItem('sub');
    }

    getCalendaList() {
        this.store.dispatch(UserActions.get_calendar_list({
            user_sub: this.user_sub,
            idCamera: this.idCamera
        }));
    }

    getCalendaListResult() {
        this.calendarSub =  this.store.select((state: AppState) => state.Users.calendarList).pipe(filter((value) => !!value)).subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.loadingCalendarList = false;
                    this.calendarListData = result.data;
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-result-fail', 'user.calendar-result-fail');
                    this.store.dispatch(UserActions.get_calendar_list_clear());
                    this.loadingCalendarList = false;
                }
            }
        });
    }

    ngOnInit(): void {
        this.cameraName = this.data.data.name;
        this.idCamera = this.data.data.id;
        this.heightModal = 'fit-content';
        this.loadingCalendarList = true;
        this.getCalendaList();
        this.getCalendaListResult();
    }

    closeModal() {
        this.modalClose.emit();
    }

    ngOnDestroy() {
        this.closeModal();
        if (this.calendarSub) {
            this.calendarSub.unsubscribe();
        }
        this.loadingCalendarList = false;
    }

    formatDateRange(start_time: string, end_time: string) {
        const start = new Date(start_time);
        const end = new Date(end_time);
        if (start.toLocaleDateString() === end.toLocaleDateString()) {
            return start.toLocaleDateString();
        }
        return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
    }

    formatHourRange(start_time: string, end_time: string) {
        const start = new Date(start_time);
        const end = new Date(end_time);
        if (start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 23 && end.getMinutes() === 59) {
            return '24 horas';
        }
        return start.toLocaleTimeString() + ' - ' + end.toLocaleTimeString();
    }

}
