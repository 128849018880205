import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Camera, Plan, CheckResolution, CameraThumbnail } from '../models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { UserInventory } from '../../Users/models';
import { CameraActions } from '../Services/actions';
import { UserActions } from 'app/Users/Services/actions';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { CameraService } from '../Services/camera.service';

@Component({
    selector: 'app-camera-form-csv',
    templateUrl: './camera-form-csv.component.html',
    styleUrls: ['./camera-form-csv.component.scss'],
    standalone: false
})
export class CameraFormCsvComponent implements OnInit, OnDestroy {
    readonly userInventoryResult$ = this.store.select((state: AppState) => state.Users.userInventory);

    // postCam: Subscription;
    // check_resolution: Subscription;
    // camera_thumbnail: Subscription;
    userInventory: Subscription;
    plans: Observable<Plan[]>;

    planList: Plan[];
    inventory: UserInventory;
    idAssociate = environment.associateId;
    fileName = '';
    sendList = false;
    read_csv: any[];
    userSub: string;
    client_sub: string;
    offlineCam: boolean;
    finishCams: boolean;
    pageName = 'camera-form';

    @ViewChild('fileCSVbutton')
        fileCSVbutton: ElementRef;

    pathPreviousRouter = 'users/client/view';
    previousRouter = 'Início';
    activeSteps: string[];
    currentStep = 0;

    constructor(private router: Router, private service: CameraService, private readonly store: Store<AppState>, private camService: CameraService,
        private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.activeSteps = ['Importar arquivo', 'Verificar lista de câmeras', 'Cadastro de câmeras'];
        this.currentStep = 0;
        this.offlineCam = false;
        this.userSub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.finishCams = false;
        this.getPlans();
        this.getUserInventory();
    }

    ngOnDestroy() {
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        this.store.dispatch(CameraActions.cleanup_post());
        this.store.dispatch(CameraActions.clear_camera_resolution());
        this.store.dispatch(CameraActions.clear_camera_create_thumbnail());
        this.store.dispatch(UserActions.cleanup());
    }

    getPlans() {
        this.plans = this.camService.getPlans(this.idAssociate);
        this.plans.subscribe(async planList => {
            if (planList) {
                planList.forEach(x => x.fantasy_name = x.resolution.alias + ' - ' + x.days_storage + ' dias');
                if (!this.inventory) {
                    this.planList = planList;
                } else {
                    this.planList = this.filterPlans(planList);
                }
            }
        });
    }

    filterPlans(plans) {
        return plans.filter(plan => {
            const ci = this.inventory.inventory.ci[plan.id];
            return ci ? ci.available > 0 : false;
        });
    }

    getUserInventory() {
        this.store.dispatch(UserActions.get_user_inventory({
            user_sub: this.userSub,
            child_sub: this.client_sub
        }));
        this.userInventory = this.userInventoryResult$.subscribe(result => {
            if (result) {
                this.inventory = result[0];
                if (this.planList) {
                    this.planList = this.filterPlans(this.planList);
                }
            }
        });
    }

    toPreviousPath() {
        this.router.navigateByUrl(this.pathPreviousRouter);
    }

    goToNextStep() {
        switch (this.currentStep) {
            case 0:
                this.resetPageScroll();
                this.currentStep++;
                this.sendCSV();
                break;
        }
    }

    resetPageScroll() {
        const mainPanel = document.querySelector('.main-panel');
        mainPanel.scrollTo(0, 0);
    }

    changeListener(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const files: FileList = inputElement.files;
        if (files && files.length > 0) {
            const file: File = files.item(0);
            this.fileName = file.name;
            const reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                const csv = reader.result;
                this.csvJSON(csv);
            };
        }
    }

    deleteUploadCSV() {
        this.fileName = '';
        this.fileCSVbutton.nativeElement.value = '';
        this.sendList = false;
        this.read_csv = [];
    }

    csvJSON(csv) {
        this.sendList = false;
        this.read_csv = [];
        let numError = 0;
        const csvToRowArray = csv.split('\n');
        const headers = ['alias', 'url', 'plan', 'cep', 'num', 'street', 'online'];
        for (let i = 1; i < csvToRowArray.length; i++) {
            const obj = {};
            const currentline = csvToRowArray[i].replace(/"/g, '').split(',');
            if (currentline.length !== headers.length) {
                numError += 1;
                if (numError === csvToRowArray.length - 1) {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'form-required', 'cameras.csv_incorrect');
                }
            } else {
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j]] = currentline[j].replace('\r', '');
                }
                obj['status'] = 'loading';
                obj['online'] = obj['online'].toLowerCase() == 'sim';
                this.read_csv.push({
                    requests: {
                        plan: { status: 'loading', message: 'Ainda não executado' },
                        create: { status: 'loading', message: 'Ainda não executado' },
                        thumbnail: { status: 'loading', message: 'Ainda não executado' },
                    },
                    data: obj
                });
            }
        }
        this.sendList = true;
    }

    checkFinishCameras() {
        const notFinished = this.read_csv.find(x => x.requests.thumbnail.status == 'loading');
        this.finishCams = !notFinished;
    }

    async sendCSV() {
        this.sendList = false;
        for (const [index, camera] of this.read_csv.entries()) {
            await this.checkCameraResolution(camera);
        }
        await this.checkFinishCameras();
    }

    //cadastra câmera
    async checkCameraResolution(line: any) {
        const camera = line.data;
        const findResolutionInformations = this.planList.find(plan => (plan.id == camera.plan || plan.fantasy_name == camera.plan));
        if (findResolutionInformations) {
            const checkResolution: CheckResolution = {
                uri: camera.url,
                height: Number(findResolutionInformations.resolution.height),
                width: Number(findResolutionInformations.resolution.width),
                fps: Number(findResolutionInformations.max_fps)
            };
            line.requests.plan.status = 'loading';
            line.requests.plan.message = 'Executando...';
            await this.checkFinishCameras();
            await this.getCheckResolutionResult(line, checkResolution);
        } else {
            line.requests.plan.status = 'close';
            line.requests.plan.message = 'Plano não encontrado';
            line.requests.create.status = 'close';
            line.requests.create.message = 'Não é possível criar sem plano.';
            line.requests.thumbnail.status = 'close';
            line.requests.thumbnail.message = 'Não é possível criar sem plano.';
            this.checkFinishCameras();
        }
    }

    async getCheckResolutionResult(line: any, cameraResolution: CheckResolution) {
        return await new Promise(async (resolve, reject) => {
            await this.service.CheckCameraResolution(cameraResolution)
                .toPromise()
                .then(async result => {
                    if (result) {
                        if (result.status === OperationStatus.Success) {
                            line.requests.plan.status = 'check-circle';
                            line.requests.plan.message = 'Validação feita com sucesso!';
                            await resolve(this.insertCamera(line));
                        }
                        else if (result.status === OperationStatus.Fail) {
                            if (line.data.online) {
                                resolve(line.requests.plan.status = 'close');
                                resolve(line.requests.plan.message = result.message ? result.message : 'Não foi possível checar a resolução da camera');
                                resolve(line.requests.create.status = 'close');
                                resolve(line.requests.create.message = 'Falha na validação dos dados.');
                                resolve(line.requests.thumbnail.status = 'close');
                                resolve(line.requests.thumbnail.message = 'Falha na validação dos dados.');
                            } else {
                                resolve(line.requests.plan.status = 'check-circle');
                                resolve(line.requests.plan.message = 'Câmera off-line!');
                                await resolve(this.insertCamera(line));
                            }
                        }
                    }
                }).catch (async e => {
                    if (line.data.online) {
                        resolve(line.requests.plan.status = 'close');
                        resolve(line.requests.plan.message = 'Não foi possível checar a resolução da camera');
                        resolve(line.requests.create.status = 'close');
                        resolve(line.requests.create.message = 'Falha na validação dos dados.');
                        resolve(line.requests.thumbnail.status = 'close');
                        resolve(line.requests.thumbnail.message = 'Falha na validação dos dados.');
                    } else {
                        resolve(line.requests.plan.status = 'check-circle');
                        resolve(line.requests.plan.message = 'Câmera off-line!');
                        await resolve(this.insertCamera(line));
                    }
                });
        });
    }

    async insertCamera(line: any) {
        const address = {
            'cep': line.data.cep,
            'rua': line.data.street,
            'numero': line.data.num,
            'cidade': '',
            'estado': '',
            'bairro': '',
            'complemento': '',
            'latitude': '',
            'longitude': ''
        };
        const camera: Camera = {
            src: line.data.url,
            alias: line.data.alias,
            address: address ? address : {},
            id_plan: this.planList.find(plan => (plan.id == line.data.plan || plan.fantasy_name == line.data.plan)).id,
        };
        line.requests.create.status = 'loading';
        line.requests.create.message = 'Executando...';
        await this.getPostCamResult(line, camera, this.userSub, this.client_sub, !line.data.online);
        await this.checkFinishCameras();
    }

    async getPostCamResult(line: any, camera: Camera, sub: string, child_sub: string, off: boolean) {
        return await new Promise(async (resolve, reject) => {
            await this.service.postCam(camera, sub, child_sub, off)
                .toPromise()
                .then(async result => {
                    if (result) {
                        if (result.status === OperationStatus.Success) {
                            line.requests.create.status = 'check-circle';
                            line.requests.create.message = line.data.online ? 'Streaming conectado!' : 'Câmera off-line criado com sucesso!';
                            if (line.data.online) {
                                await resolve(this.createThumbnail(result.data['hashname'], line));
                            } else {
                                resolve(line.requests.thumbnail.status = 'check-circle');
                                resolve(line.requests.thumbnail.message = 'Câmera off-line criado com sucesso!');
                            }
                        }
                        else if (result.status === OperationStatus.Fail) {
                            resolve(line.requests.create.status = 'close');
                            resolve(line.requests.create.message = result.message ? result.message : 'Não foi possível criar câmera');
                            resolve(line.requests.thumbnail.status = 'close');
                            resolve(line.requests.thumbnail.message = 'Falha na criação da câmera');
                        }
                    }
                }).catch(async e => {
                    resolve(line.requests.create.status = 'close');
                    resolve(line.requests.create.message = 'Não foi possível criar câmera');
                    resolve(line.requests.thumbnail.status = 'close');
                    resolve(line.requests.thumbnail.message = 'Falha na criação da câmera');
                });
        });
    }

    async createThumbnail(hashname: string, line: any) {
        const currentCam = line.data;
        const userSub = localStorage.getItem('sub');
        const cameraThumbnail: CameraThumbnail = {
            hashname: hashname,
            sub: userSub,
            uri: currentCam.url,
        };
        line.requests.thumbnail.status = 'loading';
        line.requests.thumbnail.message = 'Executando...';
        await this.getCreateThumbnailResult(line, cameraThumbnail);
        await this.checkFinishCameras();
    }

    async getCreateThumbnailResult(line: any, thumb: CameraThumbnail) {
        return await new Promise(async (resolve, reject) => {
            await this.service.CreateCameraThumbnail(thumb)
                .toPromise()
                .then(async result => {
                    if (result) {
                        result = result as Feedback;
                        if (result.status === OperationStatus.Success) {
                            resolve(line.requests.thumbnail.status = 'check-circle');
                            resolve(line.requests.thumbnail.message = 'Câmera criada com sucesso!');
                        }
                        else if (result.status === OperationStatus.Fail) {
                            resolve(line.requests.thumbnail.status = 'close');
                            resolve(line.requests.create.message = result.message ? result.message : 'Não foi possível criar imagem da câmera');
                        }
                    }
                }).catch(async e => {
                    resolve(line.requests.thumbnail.status = 'close');
                    resolve(line.requests.create.message = 'Não foi possível criar imagem da câmera');
                });
        });
    }

}
