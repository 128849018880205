import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BaseDetectionComponent } from './base-detection/base-detection.component';
import { AuthGuard } from 'app/Shared/Guards/auth.guard';

export const detectionRoutes: Routes = [
    {
        path: 'cameras/alert',
        component: BaseDetectionComponent,
        canActivate: [AuthGuard]
    }
];


