import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationResponseCamera } from 'app/Shared/ValidationResponse/validation-response-camera';
import { ValidationResponseLogin } from './validation-response-login';
import { ValidationResponseReports } from './validation-response-reports';
import { ValidationResponseUsers } from './validation-response-users';
import { ValidationResponseAnalytics } from './validation-response-analytic';

/**
 * Classe reponsável por validar as requisições e chamar o feedback do usuario
 */
@Injectable()
export class ValidationResponseHandlerModule {
    constructor(private translate: TranslateService) {
        if (window.navigator.language.toLowerCase() != 'en-us' && window.navigator.language.toLowerCase() != 'pt-br') {
            translate.setDefaultLang('pt-br');
        } else {
            translate.setDefaultLang(window.navigator.language.toLowerCase());
        }
    }

    /**
     *Função que valida as requisições
     * @param page_name nome da pagina
     * @param endPoint endpoint da mensagem
     * @param status_code statusCode
     */

    validationCamera = new ValidationResponseCamera();
    validationReport = new ValidationResponseReports();
    validationLogin = new ValidationResponseLogin();
    validationUser = new ValidationResponseUsers();
    validationAnalytic = new ValidationResponseAnalytics();
    tittle = null;
    swalParameters = null;
    methods: Record<string, any> = {
        // Cameras
        '200_cameras-group_confirm-delete-item': this.validationCamera.cameras_group_confirm_delete_item_200,
        '200_cameras-group_delete-item-success': this.validationCamera.swalSuccess,
        '400_cameras-group_delete-item-error': this.validationCamera.swalDanger,
        '200_cameras-group_confirm-delete-camera-group': this.validationCamera.camera_group_confirm_delete_camera_group_200,
        '400_cameras-group_delete_camera_group_error': this.validationCamera.swalDanger,
        '200_cameras-group_delete_camera_group_success': this.validationCamera.swalSuccess,
        '200_camera-form_create-camera': this.validationCamera.camera_form_create_camera_200,
        '200_camera-form_confirm-camera-rtsp-offline': this.validationCamera.camera_form_confirm_camera_rtsp_offline_200,
        '200_camera-form_confirm-camera-rtmp-offline': this.validationCamera.camera_form_confirm_camera_rtmp_offline_200,
        '200_camera-data_confirm-delete-camera': this.validationCamera.camera_data_confirm_delete_camera_200,
        '200_camera-list_confirm-delete-camera': this.validationCamera.camera_data_confirm_delete_camera_200,
        '200_camera-view_confirm-delete-guest-camera': this.validationCamera.camera_view_confirm_delete_guest_camera_200,
        '404_camera-form_preview-camera': this.validationCamera.camera_form_preview_camera_404,
        '404_camera-form_address-not-found': this.validationCamera.swalWarning,
        '400_camera-form_create-camera': this.validationCamera.camera_form_create_camera_400,
        '500_camera-form_create-camera-limit': this.validationCamera.camera_form_limit_inventory_500,
        '400_camera-form_cep-null': this.validationCamera.swalDanger,
        '400_camera-form_cep-not-found': this.validationCamera.swalDanger,
        '400_camera-form_cep-calculator': this.validationCamera.swalDanger,
        '400_camera-form_plan-null': this.validationCamera.swalDanger,
        '400_camera-form_invalid-rtsp': this.validationCamera.swalDanger,
        '400_camera-form_camera-name-null': this.validationCamera.swalDanger,
        '400_camera-form_form-required': this.validationCamera.swalDanger,
        '400_camera-form_cep-required': this.validationCamera.swalDanger,
        '400_camera-form_address-required': this.validationCamera.swalDanger,
        '400_camera-form_number-required': this.validationCamera.swalDanger,
        '400_camera-form_neighborhood-required': this.validationCamera.swalDanger,
        '400_camera-form_city-required': this.validationCamera.swalDanger,
        '400_camera-form_state-required': this.validationCamera.swalDanger,
        '400_camera-form_name-required': this.validationCamera.swalDanger,
        '400_camera-form_plan-required': this.validationCamera.swalDanger,
        '400_reports_alert-rename': this.validationCamera.swalDanger,
        '400_camera-edit_all-inputs-camera': this.validationCamera.swalDanger,
        '400_cameras-add-guest_failed-invite': this.validationCamera.cameras_add_guest_failed_invite_400,
        '200_cameras-add-guest_success-invite': this.validationCamera.cameras_add_guest_success_invite_200,
        '206_cameras-add-guest_parcial-success-invite': this.validationCamera.cameras_add_guest_parcial_success_invite_206,
        '400_camera-invite_failure-list-guest': this.validationCamera.swalWarningParameters,
        '200_camera-invite_permission-update-guest': this.validationCamera.swalSuccess,
        '400_camera-invite_email-invalid': this.validationCamera.swalDanger,
        '400_camera-invite_select-access-type': this.validationCamera.swalDanger,
        '400_camera-invite_update-invite': this.validationCamera.swalDanger,
        '400_camera-invite_not-guest-delete': this.validationCamera.swalDanger,
        '400_camera-invite_not-changes': this.validationCamera.swalWarning,
        '200_camera-invite_operation-success': this.validationCamera.swalSuccess,
        '400_camera-invite_operation-failure': this.validationCamera.swalDanger,
        '400_camera-invite_resend-email-update': this.validationCamera.showAlertDargersParameters,
        '400_camera-list-invite_not-cameras-shared': this.validationCamera.swalDanger,
        '200_camera-list_success-delete-camera': this.validationCamera.swalSuccess,
        '400_camera-list_failure-delete-camera': this.validationCamera.swalDanger,
        '200_camera-list_success-update-camera': this.validationCamera.swalSuccess,
        '400_camera-list_failure-update-camera': this.validationCamera.swalDanger,
        '400_camera-list_not-name-camera': this.validationCamera.swalDanger,
        '400_camera-list_not-cameras': this.validationCamera.swalDanger,
        '200_camera-list_update-thumbnail': this.validationCamera.swalSuccess,
        '500_camera-list_update-thumbnail': this.validationCamera.swalDanger,
        '400_list-camera-address_select-address-camera': this.validationCamera.swalDanger,
        '400_camera-modal-mosaic_close-cam-current': this.validationCamera.swalWarning,
        '400_camera-modal-mosaic_input-empty-name': this.validationCamera.swalDanger,
        '400_camera-playlist_select-camera': this.validationCamera.swalDanger,
        '400_camera-playlist_date-invalid': this.validationCamera.swalDanger,
        '200_camera-invite_delete-guest': this.validationCamera.camera_confirm_delete_guest_200,
        '200_camera-list_update-active': this.validationCamera.camera_update_active_200,
        '200_camera-list_update-inactive': this.validationCamera.camera_update_inactive_200,
        '400_camera-list_not-option-enabled': this.validationCamera.camera_not_option_enabled_400,
        '200_camera-playlist_not-record': this.validationCamera.camera_not_record_200,
        '500_camera-playlist_not-processing-record': this.validationCamera.swalDanger,
        '400_camera-playlist_date-camera-invalid': this.validationCamera.camera_date_camera_invalid_400,
        '400_camera-view_dimension-mosaic': this.validationCamera.swalWarning,
        '200_camera-view_save-mosaic': this.validationCamera.swalSuccess,
        '200_camera-view_delete-mosaico': this.validationCamera.swalSuccess,
        '400_camera-view_failure-stream': this.validationCamera.camera_failure_stream_400,
        '400_camera-view_camera-not-mosaic': this.validationCamera.swalWarning,
        '400_camera-modal-mosaic_camera-name-max': this.validationCamera.camera_date_camera_invalid_400,
        '400_camera-form_create-camera-resolution-error': this.validationCamera.swalWarning,
        '400_group-create_creation-fail': this.validationCamera.swalDanger,
        '200_group-create_creation-success': this.validationCamera.swalSuccess,
        '204_cameras-group_no-item': this.validationCamera.info_group_no_item,
        // Reports
        '400_base-reports_alert-rename': this.validationReport.swalDanger,
        '200_base-reports_without-register-for-this-data': this.validationReport.without_register_for_this_data_200,
        '400_base-reports_erro-return-report': this.validationReport.erro_return_report_400,
        '400_base-reports_not-selected-alert': this.validationReport.not_selected_alert_400,
        '200_base-reports_selected-alert': this.validationReport.selected_alert_200,
        '200_base-reports_remove-selected-alert': this.validationReport.remove_selected_alert_200,
        '200_base-reports_alert-feedback-success': this.validationReport.alert_feedback_success_200,
        '400_base-reports_alert-feedback-error': this.validationReport.alert_feedback_error_400,
        '400_report-list_invalid-time-range': this.validationReport.swalDanger,
        '400_report-list_select-report-range': this.validationReport.swalDanger,
        // Logins
        '200_confirm-register_resend-code': this.validationLogin.swalSuccess,
        '400_confirm-register_login-message': this.validationLogin.login_message_400,
        '400_confirm-register_code-first': this.validationLogin.swalDanger,
        '200_confirm-register_send-email': this.validationLogin.swalSuccess,
        '400_confirm-register_code-invalid': this.validationLogin.swalDanger,
        '200_confirm-register_reset-password': this.validationLogin.swalSuccess,
        '400_login_authentication-error': this.validationLogin.authentication_error_400,
        '400_login_authentication-logout-error': this.validationLogin.authentication_logout_error_400,
        '400_register_login-erro': this.validationLogin.login_message_400,
        // Users
        '400_user-config-alarm_phone-number-max': this.validationUser.swal_error_400,
        '400_user-summary_input-number-invalid': this.validationCamera.swalWarning,
        '200_user-summary_pass-change-confirm': this.validationUser.swalSuccess,
        '200_user_alert-resend-email': this.validationUser.swalSuccess,
        '400_user_alert-resend-email-fail': this.validationUser.swalDanger,
        '200_user_register-partner-user': this.validationUser.swalSuccess,
        '400_user_register-partner-user-fail': this.validationUser.swalDanger,
        '200_user_update-partner-user': this.validationUser.swalSuccess,
        '400_user_update-partner-user-fail': this.validationUser.swalDanger,
        '200_user-summary_analityc-disabled-success': this.validationUser.swalSuccess,
        '400_user-summary_pass-change-error': this.validationUser.swalDanger,
        '200_user-summary_image-loading-success': this.validationUser.swalSuccess,
        '400_user-summary_config-alarm-error': this.validationUser.swalDanger,
        '400_user-summary_return-data-error': this.validationUser.swalDanger,
        '200_user-summary_data-update-success': this.validationUser.swalSuccess,
        '400_user-summary_data-update-error': this.validationUser.swalDanger,
        '400_user-summary_not-action-complete': this.validationUser.swal_error_400,
        '400_user-summary_photo-max-size-error': this.validationUser.photo_max_size_error_400,
        '400_user-summary_photo-min-size-error': this.validationUser.photo_min_size_error_400,
        '200_user-summary_disable-analytic': this.validationUser.disable_analytic_200,
        '403_user-summary_userinventory-error': this.validationUser.userInventory_403,
        '200_edit-payment_edit-payment': this.validationUser.return_edit_payment_success_200,
        '400_edit-payment-error_edit-payment-error': this.validationUser.return_edit_payment_success_400,
        '500_edit-credit-card-error_edit-credit-card-error': this.validationUser.swalDanger,
        '200_edit-payment_edit-address': this.validationUser.swalSuccess,
        '400_edit-payment-error_edit-address-error': this.validationUser.swalDanger,
        '400_edit-payment-error_cep-null': this.validationUser.swalDanger,
        '404_edit-payment-error_cep-not-found': this.validationUser.swalDanger,
        '400_edit-payment-error_cep-calculator': this.validationUser.swalDanger,
        '400_edit-payment-error_form-invalid': this.validationUser.swalDanger,
        '200_user_register-client-user': this.validationUser.swalSuccess,
        '500_user_register-client-user-not-mail': this.validationUser.swalWarning,
        '400_user_register-client-user-fail': this.validationUser.swalDanger,
        '400_user-update_all-inputs-user': this.validationCamera.swalDanger,
        '200_user_edit-client-user': this.validationUser.swalSuccess,
        '400_user_edit-client-user-fail': this.validationUser.swalDanger,
        '202_user_edit-client-user-fail': this.validationUser.swalWarning,
        '200_user_transfer-items': this.validationUser.swalSuccess,
        '400_user_transfer-items-fail': this.validationUser.swalDanger,
        '200_user_create-ticket': this.validationUser.swalSuccess,
        '400_user_create-ticket-fail': this.validationUser.swalDanger,
        '200_client-view_confirm-delete-client': this.validationUser.client_view_confirm_delete_client_200,
        '200_client-view_confirm-delete-partner': this.validationUser.client_view_confirm_delete_partner_200,
        '200_user-associated_confirm-delete-guest': this.validationUser.user_associated_confirm_delete_guest_200,
        '400_user-associated_delete-guest-error': this.validationUser.swalDanger,
        '200_user-associated_delete-guest-success': this.validationUser.swalSuccess,
        '200_user_delete-client-result': this.validationUser.swalSuccess,
        '400_user_delete-client-result-fail': this.validationUser.swalDanger,
        '200_user_delete-partner-result': this.validationUser.swalSuccess,
        '400_user_delete-partner-result-fail': this.validationUser.swalDanger,
        '200_client-view_confirm-block-client': this.validationUser.client_view_confirm_block_client_200,
        '200_client-view_confirm-block-partner': this.validationUser.client_view_confirm_block_partner_200,
        '200_client-view_confirm-unblock-client': this.validationUser.client_view_confirm_unblock_client_200,
        '200_user_block-client-result': this.validationUser.swalSuccess,
        '400_user_block-client-result-fail': this.validationUser.swalDanger,
        '200_user-kits_edit-kit': this.validationUser.swalSuccess,
        '400_user-kits_edit-kit-fail': this.validationCamera.swalDanger,
        '200_user-kits_delete-kit': this.validationUser.swalSuccess,
        '400_user-kits_delete-kit-fail': this.validationCamera.swalDanger,
        '400_user-kits_not-name-kit': this.validationCamera.swalDanger,
        '200_user-kits_confirm-delete-kit': this.validationUser.user_kits_delete_kit_confirmation,
        '200_user_register-kit': this.validationUser.swalSuccess,
        '400_user_register-kit-fail': this.validationUser.swalDanger,
        '400_user_notify-fail': this.validationUser.swalDanger,
        '200_user_confirm-delete-client-inventory': this.validationUser.user_confirm_delete_client_inventory_200,
        '400_user_delete_client_inventory_error': this.validationUser.swalDanger,
        '200_user_delete_client_inventory_success': this.validationUser.swalSuccess,
        '200_user_invite-associated': this.validationUser.swalSuccess,
        '400_user_invite-associated-fail': this.validationUser.swalDanger,
        '400_user_error-associated-form': this.validationUser.swalDanger,
        '400_user_calendar-result-fail': this.validationUser.swalDanger,
        '200_user_calendar-create-success': this.validationUser.swalSuccess,
        '204_user_calendar-create-warning': this.validationUser.swalWarning,
        '400_user_calendar-create-fail': this.validationUser.swalDanger,
        '200_user_calendar-update-success': this.validationUser.swalSuccess,
        '400_user_calendar-update-fail': this.validationUser.swalDanger,
        '403_user_calendar-update-hashname-fail': this.validationUser.swalDanger,
        '200_calendar-list_update-active': this.validationUser.calendar_update_active_200,
        '200_calendar-list_update-inactive': this.validationUser.calendar_update_inactive_200,
        '200_calendar-list_confirm-delete': this.validationUser.calendar_confirm_delete_200,
        '400_user_calendar-holiday-delete-fail': this.validationUser.swalDanger,
        '200_user_calendar-holiday-delete-success': this.validationUser.swalSuccess,
        '400_user_calendar-holiday-disable-fail': this.validationUser.swalDanger,
        '200_user_calendar-holiday-disable-success': this.validationUser.swalSuccess,
        '200_user_calendar-holiday-able-success': this.validationUser.swalSuccess,

        // Analíticos
        '400_configure-analytic_scheduling-invalid': this.validationAnalytic.swalDanger,
        '200_configure-analytic_success-delete': this.validationAnalytic.success_operation_200,
        '400_configure-analytic_failure-delete': this.validationAnalytic.failure_operation_400,
        '200_analytics-list_confirm-delete-analytic': this.validationAnalytic.configure_analytic_confirm_delete_200,
        '400_analytic-create_select-type-analytic': this.validationAnalytic.swalDanger,
        '400_analytic-create_draw-analytic': this.validationAnalytic.swalDanger,
        '400_analytic-create_scheduling-required': this.validationAnalytic.swalDanger,
        '400_analytic-create_input-type-valid': this.validationAnalytic.swalDanger,
        '400_analytic-create_amount-objects-required': this.validationAnalytic.swalDanger,
        '200_analytic-create_create-analytic-success': this.validationAnalytic.success_operation_200,
        '400_analytic-create_create-analytic-failure': this.validationAnalytic.failure_operation_400,
        '200_analytic-create_update-analytic-success': this.validationAnalytic.success_operation_200,
        '400_analytic-create_update-analytic-failure': this.validationAnalytic.failure_operation_400,
        '200_mosaic-create_mosaic-create-success': this.validationCamera.swalSuccess,
        // GLPI - Support Management
        '200_glpi-support_ticket-create-success': this.validationAnalytic.swalSuccess,
        '400_glpi-support_ticket-create-success': this.validationAnalytic.swalDanger,
    };


    // Retorna o feedback da pasta de mensagens padrões
    public returnMessageFeedback(pathMessage): string {
        let message = '';
        this.translate.get(pathMessage).subscribe((text: string) => { message = text; });
        return message;
    }

    // Recebe as informações das requisições nas telas e faz a monstagem da HashString e retorno da mensagem traduzida
    public validationResponseHandler(status_code: number, page_name: string, endPoint: string, status_message?: string, swal_parameters?: any) {
        // this.tittle = this.returnMessageFeedback(status_message);
        this.swalParameters = swal_parameters ? swal_parameters : null;
        if (status_message) {
            this.tittle = this.returnMessageFeedback(status_message);
        }
        this.methods[String(status_code + '_' + page_name + '_' + endPoint)](this.tittle, this.swalParameters);
    }
}
