import { Injectable } from '@angular/core';
import { AnalyticService } from './analytic.service';
import { AnalyticActions, AnalyticAction, Metadata } from './actions';
import { HandleError } from 'app/Shared/Helpers/HandleError';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { OperationStatus } from 'app/Shared/models';

@Injectable()
export class AnalyticEffects {
    constructor(private service: AnalyticService, private actions: Actions, private error: HandleError) {}

    getGroupAnalytics$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.GET_ANALYTICS_GROUP),
            mergeMap(action => this.service.getGroupAnalytics().pipe(
                map(result => {
                    return AnalyticActions.get_analytics_group_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('getGroupAnalytics', AnalyticActions.get_analytics_group_return({ payload: [] }),
                    AnalyticActions.get_analytics_group()))
            )
            )
        )
    );

    getClassTypeAnalytic$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.GET_ANALYTICS_CLASS),
            mergeMap((action: Metadata) => this.service.getClassTypeAnalytic(action.idAnalyticType).pipe(
                map(result => {
                    return AnalyticActions.get_analytics_class_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('getClassTypeAnalytic', AnalyticActions.get_analytics_class_return({ payload: [] }),
                    AnalyticActions.get_analytics_class(action)))
            )
            )
        )
    );

    getAllAnalyticsCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.GET_ANALYTICS_CAMERA),
            mergeMap((action: Metadata) => this.service.getAnalyticsCam(action.camera_id).pipe(
                map(result => {
                    return AnalyticActions.get_analytics_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('getAnalyticsCam', AnalyticActions.get_analytics_camera_return({ payload: [] }),
                    AnalyticActions.get_analytics_camera(action)))
            )
            )
        )
    );

    postAnalyticCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.POST_ANALYTICS_CAMERA),
            mergeMap((action: Metadata) => this.service.postAnalyticCam(action.new_analytic, action.child_sub).pipe(
                map(result => {
                    return AnalyticActions.post_analytics_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('postAnalyticCam', AnalyticActions.post_analytics_camera_return({
                    payload: {
                        status: OperationStatus.Fail,
                        message: null
                    }
                }),
                AnalyticActions.post_analytics_camera(action)))
            )
            )
        )
    );

    putAnalyticCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.PUT_ANALYTICS_CAMERA),
            mergeMap((action: Metadata) => this.service.putAnalyticCam(action.put_analytic, action.child_sub).pipe(
                map(result => {
                    return AnalyticActions.put_analytics_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('putAnalyticCam', AnalyticActions.put_analytics_camera_return({
                    payload: {
                        status: OperationStatus.Fail,
                        message: null
                    }
                }),
                AnalyticActions.put_analytics_camera(action)))
            )
            )
        )
    );

    deleteAnalyticCamera$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.DELETE_ANALYTICS_CAMERA),
            mergeMap((action: Metadata) => this.service.deleteAnalyticCam(action.id_analytic, action.sub).pipe(
                map(result => {
                    return AnalyticActions.delete_analytics_camera_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('deleteAnalyticCam', AnalyticActions.delete_analytics_camera_return({
                    payload: {
                        status: OperationStatus.Fail,
                        message: null
                    }
                }),
                AnalyticActions.delete_analytics_camera(action)))
            )
            )
        )
    );

    analitycsTotalCreates$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.GET_ANALITYCS_TOTAL_CREATES),
            mergeMap((action: Metadata) => this.service.getAnalitycsTotalCreates().pipe(
                map(result => {
                    return AnalyticActions.get_analitycs_total_creates_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('statusTotalCreatesAnalytics',
                    AnalyticActions.get_analitycs_total_creates_return({
                        payload: {
                            status: OperationStatus.Fail,
                            message: null
                        }
                    }),
                    AnalyticActions.get_analitycs_total_creates(action)))
            )
            )
        )
    );

    lastAnalyticSchedule$ = createEffect(() =>
        this.actions.pipe(
            ofType(AnalyticActions.GET_ANALITYCS_OLD_SCHEDULES),
            mergeMap((action: Metadata) => this.service.getLastAnalyticSchedule().pipe(
                map(result => {
                    return AnalyticActions.get_analitycs_old_schedules_return({ payload: result });
                }),
                catchError(this.error.handleError<AnalyticAction>('last_analytic_schedule', AnalyticActions.get_analitycs_old_schedules_return({ payload: [] }),
                    AnalyticActions.get_analitycs_old_schedules(action)))
            )
            )
        )
    );
}
