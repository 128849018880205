import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CameraRtmpDeletionTutorialComponent } from 'app/cameras/camera-rtmp-deletion-tutorial/camera-rtmp-deletion-tutorial.component';
import { Camera } from 'app/cameras/models';
import { SharedService } from 'app/Shared/Services/shared.service';

@Component({
    selector: 'app-user-rtmp-warning',
    templateUrl: './user-rtmp-warning.component.html',
    styleUrls: ['./user-rtmp-warning.component.scss']
})
export class UserRtmpWarningComponent implements OnInit {
    rtmpWarning: boolean;
    camera: Camera;

    constructor(
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<CameraRtmpDeletionTutorialComponent>,
        private service: SharedService,
        @Inject(MAT_DIALOG_DATA) private data: any) { }

    ngOnInit(): void {
    }
    rtmpTutorial(): void {
        this.rtmpWarning = true;
        this.camera = this.data;
        this.camera.rtmp_warning = this.rtmpWarning;
        const dataModal = {
            data: {
                camera: this.camera
            },
            tipoModal: 'delete_rtmp',
            modalWidth: '60vw'
        };
        this.service.returnDataToModal(dataModal);
        this.close();
    }
    close(){
        this.dialogRef.close();
    }

}
