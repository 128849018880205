import { Injectable, EventEmitter } from '@angular/core';
import { User } from '../../Users/models';
import { Session } from '../models';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { getToken, getRefreshToken, updateToken } from 'app/Shared/Helpers/getToken';
import { Feedback, OperationStatus } from 'app/Shared/models';
@Injectable({
    providedIn: 'root'
})
export class LoginService {

    navSideShowEmitter = new EventEmitter<boolean>();
    private backend_url = environment.apiUrl;
    constructor(private http: HttpClient) { }

    register(user: User): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/registeruser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        //Pré-processando dados
        user.cpf_cnpj = user.cpf_cnpj.replace(/\D/g, '');
        user.telephone = '+55' + user.telephone.replace(/\D/g, '');
        const data = {
            name: user.name,
            email: user.email,
            password: user.password,
            cpf: user.cpf_cnpj.length > 11 ? '' : user.cpf_cnpj,
            cnpj: user.cpf_cnpj.length > 11 ? user.cpf_cnpj : '',
            phone_number: user.telephone,
            address: {
                street_address: user.address.street + ', ' + user.address.number + '\n' + user.address.bairro + '\n' + user.address.comp,
                city: user.address.city,
                state: user.address.state,
                cep: user.address.CEP,
                country: 'Brazil'
            }
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    confirmRegistration(username: string, code: string, resend: boolean): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/authuser`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const data = {
            email: username,
            code: code,
            resend: resend
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        status: OperationStatus.Success,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    login(username: string, password: string, player_id: string): Observable<Session | Feedback> {
        const url = `${this.backend_url}/api/resources/users/userlogin`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const data = {
            email: username,
            password: password,
            player_id: player_id
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200) {
                    const feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message,
                        statusCode: event['statusCode']
                    };
                    return feedback as Feedback;
                }
                else {
                    const result = event['results'][0].data[0];
                    const session = {
                        model: 'session',
                        token: result.token,
                        refreshToken: result.refreshToken,
                        accessToken: result.accessToken,
                        active_modules: result.active_modules,
                        sub: result.sub,
                        analiticos_permission: result.analiticos_permission,
                        profile_url: result.profile_url,
                        profile_name: result.profile_name,
                        avva_integration: result.avva_integration,
                        permission_invite: result.permission_invite,
                        user_name: result.user_name,
                        partner_permission: result.permission_partner,
                        association: result.association,
                        cost_center: result.cost_center,
                        cost_center_mark: result.cost_center_mark
                    };
                    return session as Session;
                }
            })
        );
    }

    logout(sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/userlogout`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        success: false,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        status: OperationStatus.Success,
                        success: true,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
            })
        );
    }

    forgotPassword(username: string, password?: string, code?: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/users/resetpassword`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const final = !code;
        const data = {
            email: username,
            password: password ? password : '',
            code: code ? code : ''
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                let feedback: Feedback;
                if (event['statusCode'] != 200 && event['statusCode'] != 401) {
                    feedback = {
                        model: 'feedback',
                        status: OperationStatus.Fail,
                        success: false,
                        message: event['results'][0].message
                    };
                    return feedback;
                }
                else {
                    feedback = {
                        model: 'feedback',
                        success: true,
                        statusCode: event['statusCode'],
                        status: OperationStatus.Success,
                        message: event['results'][0].message,
                        final: final
                    };
                    return feedback;
                }
            })
        );
    }

    renewToken(): Observable<boolean> {
        const url = `${this.backend_url}/api/resources/users/refreshtoken`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            refresh_token: getRefreshToken(),
            sub: localStorage.getItem('identifier')
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200) {
                    return false;
                }
                else {
                    const newToken = event['results'][0].data.idToken;
                    updateToken(newToken);
                    return true;
                }
            })
        );
    }
}
