<div class="modal-card">
    <div class="header-modal">
        <div class="container-header">
            <span class="title-modal">Configurações do Kit</span>
        </div>
        <div (click)="closeModal()" class="close-modal">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </div>
    </div>
      
    
    <div class="form-container" *ngIf="optionsKits">
        <form [formGroup]="editForm">
            <div class="input-row">
                <div class="input-container product">
                    <div class="input-label">
                        <span>Produto:</span>
                    </div>
                    <app-filter type="multiweight" [list]="optionsKits" placeholder="Selecionar produto" [selected]="kit.plan_id"
                        (filterStatusChange)="changePlan($event)" [showSelected]="true" [hideHover]="true" [hideActiveFill]="true"></app-filter>
                </div>
            </div>
      
            <div class="input-row">
                <div class="input-container camera-amount">
                    <div class="input-label">
                        <span>Quantidade de câmeras:</span>
                    </div>
                    <nz-input-number class="input inputNumberCustom" formControlName="cameraAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
                    <mat-error *ngIf="editForm.get('cameraAmount').hasError('required') && editForm.get('cameraAmount').touched">
                        Quantidade de câmeras <strong>é requerido</strong>
                    </mat-error>
                </div>
                <div class="input-container detection-amount">
                    <div class="input-label">
                        <span>Quantidade de detecções:</span>
                    </div>
                    <nz-input-number class="input inputNumberCustom" formControlName="detectionsAmount" [nzMin]="0" [nzStep]="1"></nz-input-number>
                    <mat-error *ngIf="editForm.get('detectionsAmount').hasError('required') && editForm.get('detectionsAmount').touched">
                        Quantidade de detecções <strong>é requerido</strong>
                    </mat-error>
                </div>
                <div class="input-container status">
                    <div class="input-label">
                        <span>Status:</span>
                    </div>
                    <nz-switch formControlName="status"></nz-switch>
                </div>
            </div>
        </form>

        <div class="input-row buttons">
            <button class="btn btn-ant btn-secondary delete-button" (click)="deleteKit()">
                <span nz-icon nzType="delete" nzTheme="outline" class="prefix"></span>
                Excluir Kit
                <span nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loadingDelete"></span>
            </button>
            
            <div class="right-buttons">
                <button class="btn btn-ant btn-secondary cancel-button" (click)="closeModal()">
                    Cancelar
                </button>
                <button class="btn btn-ant btn-primary submit-button" (click)="submitForm()">
                    Salvar Alterações
                    <span nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loadingSubmit"></span>
                </button>
            </div>
        </div>
    </div>
      
</div>