import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultAlert } from '../models';
import { ModalData } from '../models';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    static instance: SharedService;
    private cep_url = 'https://viacep.com.br/ws/';
    private nominatim_url = 'https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=';
    private nominatim_reverse = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&';
    // Retorna o resultado da decisão dentro do sweetAlert de validationResponse
    private objectResultSwal = new BehaviorSubject<Object>(null);
    private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    swalDecision = this.objectResultSwal.asObservable();
    resultAlert: ResultAlert;
    public static EMPTY = {};

    private modelObject = new BehaviorSubject<ModalData>(null);
    modelObjectResult = this.modelObject.asObservable();

    private isSidebarActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {
        SharedService.instance = this;
    }

    getAddressFromCEP(cep: string): Observable<any> {
        const url = this.cep_url + cep + '/json/';
        return this.http.get(url, httpOptions);
    }

    getAddressFromCEPUserSummary(cep: string): Promise<any> {
        const url = this.cep_url + cep + '/json/';
        return this.http.get(url, { observe: 'response' }).toPromise();
    }

    getGeoLocation(address: any): Observable<any> {
        const query = `${address.logradouro != '' ? address.logradouro + ',' : ''}${address.bairro != '' ? address.bairro + ',' : ''}${address.cidade},${address.estado},${address.country}`;
        const url = this.nominatim_url + query + '&limit=1';
        return this.http.get(url, httpOptions);
    }

    getReverseGeo(coordinates: number[]): Observable<any> {
        const url = `${this.nominatim_reverse}&lat=${coordinates[1]}&lon=${coordinates[0]}`;
        return this.http.get(url, httpOptions);
    }

    // Retorna as escolhas e swal
    statusConfirmationSwalValidation(resultSwal, methodCalledName: string) {
        this.resultAlert = resultSwal;
        this.resultAlert.methodCalled = methodCalledName;
        this.objectResultSwal.next(this.resultAlert);
    }

    // Retorna as escolhas e swal
    returnDataToModal(data) {
        this.modelObject.next(data);
    }

    // Refresh no componente
    public getRefresh(): Observable<boolean> {
        return this.refresh.asObservable();
    }

    public setRefresh(value: boolean): void {
        this.refresh.next(value);
    }

    // Retorna se user ta logado ou não
    public getLogin(): Observable<boolean> {
        return this.isLoggedIn.asObservable();
    }

    public setLogin(value: boolean): void {
        this.isLoggedIn.next(value);
    }

    // Limpa o behaiour subject
    cleanSwal() {
        this.objectResultSwal.next(SharedService.EMPTY);
    }

    // Altera o estado do menu lateral
    setSidebarStatus(value: boolean): void {
        this.isSidebarActive.next(value);
    }

    // Retorna o status do menu lateral
    getSidebarStatus(): Observable<boolean> {
        return this.isSidebarActive.asObservable();
    }

    getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    getImageAsText(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, { responseType: 'blob' });
    }

    async cacheImage(hashname: string, imageData: string) {
        localStorage.setItem(hashname, imageData);
    }

    async delCacheImage(hashname: string) {
        localStorage.removeItem(hashname);
    }

    // Função para recuperar a imagem do cache
    getImageFromCache(hashname: string): string | null {
        return localStorage.getItem(hashname);
    }

    async getImageAndCache(hashname, url: string) {
        this.getImageAsText(url).subscribe(result => {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onloadend = async () => {
                this.cacheImage(hashname, reader.result as string);
            };
        });
    }
}
