// import { CameraListComponent } from './cameras/camera-list/camera-list.component';
// import { CameraViewComponent } from './cameras/camera-view/camera-view.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
// import { LoginComponent } from './Login/login/login.component';
import { AuthGuard } from './Shared/Guards/auth.guard';
// import { HomeComponent } from './components/home/home.component';

const getHomeRoute = () => {
    const keepConnected = localStorage.getItem('keep-connected');
    if (keepConnected != null) {
        if (keepConnected === 'false') {
            if (!sessionStorage.getItem('id-token')) {
                return '/login/signin';
            }
            else {
                return '/home';
            }
        }
        else {
            if (localStorage.getItem('id-token') != null) {
                return '/home';
            }
            else {
                return '/login/signin';
            }
        }
    }
    else {
        return '/login/signin';
    }
};

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'cameras',
        loadChildren: () => import('./cameras/cameras.module').then(m => m.CamerasModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        loadChildren: () => import('./Users/users.module').then(m => m.UsersModule),
    },
    // {
    //   path: 'home',
    //   component: CameraViewComponent,
    //   canActivate: [AuthGuard]
    // },
    {
        path: '',
        redirectTo: getHomeRoute(),
        pathMatch: 'full'
    },
    /*{
    path: 'cadastros',
    loadChildren: 'app/Plans/plan.module#PlanModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/Profiles/profile.module#ProfileModule'
  },
  {
    path: 'deals',
    loadChildren: 'app/Requests/requests.module#RequestModule'
  }*/
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
