import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GLPIFile, Ticket } from './glpi.models';
import { getToken, getAccessToken } from 'app/Shared/Helpers/getToken';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class GlpiService {

    constructor(private http: HttpClient) { }

    private backend_url = environment.apiUrl;
    private backend_glpi = 'https://servicemanager.wesafer.com.br/apirest.php/Document/';

    createTicket(sub: string, child_sub = null, ticket: Ticket, document_ids: number[]): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/supportmanagement/createticket`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub,
            child_sub: child_sub,
            subject: ticket.subject,
            message: ticket.message,
            document_ids: document_ids
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401 && event['statusCode'] != 202) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    const feedback: Feedback = {
                        model: 'feedback',
                        message: event['results'][0].message,
                        success: true,
                        status: OperationStatus.Success
                    };
                    return feedback;
                }
            })
        );
    }

    getSession(sub: string): Observable<Feedback> {
        const url = `${this.backend_url}/api/resources/supportmanagement/initsession`;
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': getToken()
            })
        };
        const data = {
            sub: sub
        };
        return this.http.post(url, data, headers).pipe(
            map(event => {
                if (event['statusCode'] != 200 && event['statusCode'] != 401 && event['statusCode'] != 202) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                }
                else {
                    return Array.isArray(event['results'][0].data) ? event['results'][0].data : [];
                }
            })
        );
    }

    uploadDocument(docFile: File | any, sessionToken: string): Observable<Feedback | GLPIFile>{
        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Session-Token': sessionToken,
            }),
            observer: 'events',
            reportProgress: true
        };

        const data = new FormData();
        data.append('filename[0]', docFile, docFile.name);
        data.append('uploadManifest', `{\"input\":{\"name\":\"Uploaded document\",\"_filename\":[\"${docFile.name}\"]}}`);

        return this.http.post(this.backend_glpi, data, options).pipe(
            map(event => {
                if (Array.isArray(event)) {
                    const result: Feedback = {
                        model: 'feedback',
                        success: false,
                        status: OperationStatus.Fail,
                        message: event['results'][0].message
                    };
                    return result;
                }
                else {
                    event['type'] = 'file';
                    return event ? event as GLPIFile : null;
                }
            })
        );
    }
}
