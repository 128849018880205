import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { UserSummaryComponent } from './user-summary/user-summary.component';
import { usersRoutes } from './users.routing';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserCropImageComponent, ModalCropComponent } from './user-crop-image/user-crop-image.component';
import { UserChangePasswordComponent, ChangePasswordModalComponent } from './user-change-password/user-change-password.component';
import { ClientListComponent } from './user-client-list/user-client-list.component';
import { ClientViewComponent } from './user-client-view/user-client-view.component';
import { MatTableModule } from '@angular/material/table';
import { SharedModulesModule } from '../Shared/shared-modules/shared-modules.module';
import { UserPartnersComponent } from './user-partners/user-partners.component';
import { CamerasModule } from 'app/cameras/cameras.module';
import { UserBlockedComponent } from './user-blocked/user-blocked.component';
import { UserKitsComponent } from './user-kits/user-kits.component';
import { DetectionsModule } from 'app/detections/detections.module';
import { RouterModule } from '@angular/router';
import { UserClientInfoComponent } from './user-client-info/user-client-info.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ComponentsModule } from 'app/components/components.module';
import { UserRtmpWarningComponent } from './user-rtmp-warning/user-rtmp-warning.component';
import { UserPartnerViewComponent } from './user-partner-view/user-partner-view.component';
import { UserKitListComponent } from './user-kit-list/user-kit-list.component';
import { ChangeLinkModalComponent, UserChangeLinkComponent } from './user-change-link/user-change-link.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { UserClientGuestsComponent } from './user-client-guests/user-client-guests.component';
import { UserInviteGuestsComponent, UserInviteGuestsModalComponent } from './user-invite-guests/user-invite-guests.component';
import { PhonePipe } from 'app/pipes/phone.pipe';
import { UserGuestSettingsComponent, GuestSettingsModalComponent } from './user-guest-settings/user-guest-settings.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [
        UserSummaryComponent,
        UserCropImageComponent,
        ModalCropComponent,
        ChangePasswordModalComponent,
        UserChangePasswordComponent,
        UserPartnersComponent,
        ClientListComponent,
        ClientViewComponent,
        UserBlockedComponent,
        UserKitsComponent,
        UserClientInfoComponent,
        UserRtmpWarningComponent,
        UserKitListComponent,
        ChangeLinkModalComponent,
        UserChangeLinkComponent,
        UserClientGuestsComponent,
        UserInviteGuestsComponent,
        UserInviteGuestsModalComponent,
        PhonePipe,
        UserGuestSettingsComponent,
        GuestSettingsModalComponent,
        UserPartnerViewComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatCardModule,
        ImageCropperModule,
        MatTableModule,
        MatDividerModule,
        SharedModulesModule,
        CamerasModule,
        DetectionsModule,
        RouterModule.forChild(usersRoutes),
        NzSelectModule,
        NzInputNumberModule,
        NzInputModule,
        NzSwitchModule,
        NzPopoverModule,
        NzToolTipModule,
        NzTableModule,
        ComponentsModule,
        NzCheckboxModule,
        NzStepsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    exports: [
        ClientListComponent,
        UserBlockedComponent,
        UserKitListComponent,
        UserClientInfoComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [provideNgxMask()]
})
export class UsersModule { }
