import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OperationStatus } from 'app/Shared/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { ClientsToGroup } from '../models';
import { CameraActions } from '../Services/actions';

@Component({
    selector: 'app-camera-group-create',
    templateUrl: './camera-group-create.component.html',
    styleUrls: ['./camera-group-create.component.scss']
})
export class CameraGroupCreateComponent implements OnInit, OnDestroy {
    readonly clientsTogroup$ = this.store.select((state: AppState) => state.camera.clientsGroup);
    readonly createGroup$ = this.store.select((state: AppState) => state.camera.createGroup);

    @Input() isPublic: boolean;

    @Output() modalClose = new EventEmitter<string>();
    clientsGroupSub: Subscription;
    clientsGroup: ClientsToGroup[];

    createGroupSub: Subscription;

    groupForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        client: new UntypedFormControl('', [Validators.required]),
        associate: new UntypedFormControl('', [Validators.required]),
        camera: new UntypedFormControl('', [Validators.required]),
    });

    clientSimple: {
        id: number|string;
        text: string;
    }[];
    associateSimple: {
        id: number|string;
        text: string;
    }[];
    cameraSimple: {
        id: number|string;
        text: string;
    }[];

    loadingClients = true;
    loadingFilter = false;
    loadingSubmit = false;
    currentStep = 0;

    constructor(private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.loadingClients = true;
        this.associateSimple = [];
        this.cameraSimple = [];
        this.getClientsToGroup();
    }

    ngOnDestroy(): void {
        if (this.clientsGroupSub) {
            this.clientsGroupSub.unsubscribe();
        }
        if (this.createGroupSub) {
            this.createGroupSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.create_group_clear());
        this.closeModal();
    }

    closeModal() {
        this.modalClose.emit();
    }

    goToNext() {
        if (this.validateFirstStep()) {
            this.currentStep++;
        }
    }

    goToPrevious() {
        this.currentStep--;
    }

    public validateFirstStep(): boolean {
        let errorPass = true;
        if (this.groupForm.get('name').invalid) {
            errorPass = false;
            this.groupForm.get('name').markAsTouched();
        }
        if (this.groupForm.get('client').invalid) {
            errorPass = false;
            this.groupForm.get('client').markAsTouched();
        }
        if (!this.isPublic && this.groupForm.get('associate').invalid) {
            errorPass = false;
            this.groupForm.get('associate').markAsTouched();
        }
        return errorPass;
    }

    getClientsToGroup() {
        this.store.dispatch(CameraActions.get_clients_group({
            user_sub: localStorage.getItem('sub')
        }));
        this.clientsGroupSub = this.clientsTogroup$.subscribe(result => {
            if (result && result.length > 0) {
                this.loadingClients = false;
                this.clientsGroup = result;
                this.clientSimple = result.map(element => {
                    return {
                        id: element.client_id,
                        text: element.client_name
                    };
                });
            }
        });
    }

    changeClient(event) {
        this.groupForm.get('client').setValue(event);
        this.filterSimple(event);
    }

    filterSimple(clients: number[]) {
        this.loadingFilter = true;
        const filterClients = this.clientsGroup.filter(element => clients.includes(element.client_id));
        if (filterClients) {
            this.associateSimple = [];
            this.cameraSimple = [];
            filterClients.forEach(client => {
                client.associates.forEach(associate => {
                    this.associateSimple.push({
                        id: associate.id,
                        text: associate.name
                    });
                });
                client.cameras.forEach(camera => {
                    this.cameraSimple.push({
                        id: camera.id,
                        text: camera.name
                    });
                });
            });
        }
        setTimeout(() => {
            this.loadingFilter = false;
        }, 1);
    }

    changeAssociate(event) {
        this.groupForm.get('associate').setValue(event);
    }

    changeCamera(event) {
        this.groupForm.get('camera').setValue(event);
    }

    createGroup() {
        if (this.groupForm.get('camera').invalid) {
            this.groupForm.get('camera').markAsTouched();
            return false;
        }
        this.loadingSubmit = true;

        const payload = {
            sub: localStorage.getItem('sub'),
            name: this.groupForm.get('name').value,
            cameras_id: this.groupForm.get('camera').value,
            associateds_id: this.groupForm.get('associate').valid ? this.groupForm.get('associate').value : null,
            clients_id: this.groupForm.get('client').value,
            is_public: this.isPublic
        };

        this.store.dispatch(CameraActions.create_group({createCamGroup: payload}));

        this.createGroupSub = this.createGroup$.subscribe(result => {
            if (result != null) {
                this.loadingSubmit = false;
                if (result.status === OperationStatus.Fail) {
                    this.validationResponse.validationResponseHandler(400, 'group-create', 'creation-fail', result.message);
                    this.store.dispatch(CameraActions.create_group_clear());
                } else {
                    this.validationResponse.validationResponseHandler(200, 'group-create', 'creation-success', result.message);
                    this.closeModal();
                }
            }
        });
    }
}
