import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UserActions } from '../Services/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/model';
import { OperationStatus, ResultAlert} from 'app/Shared/models';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AssociatedUsers } from '../models';
import { Subscription } from 'rxjs';
import { ValidationResponseHandlerModule } from '../../Shared/ValidationResponse/validation-response-handler';
import { SharedService } from 'app/Shared/Services/shared.service';

@Component({
    selector: 'app-user-client-guests',
    templateUrl: './user-client-guests.component.html',
    styleUrls: ['./user-client-guests.component.scss']
})
export class UserClientGuestsComponent implements OnInit, OnDestroy {
    readonly associatedUsers$ = this.store.select((state: AppState) => state.Users.associatedUsers);
    readonly inviteAssociatedResult$ = this.store.select((state: AppState) => state.Users.inviteAssociatedResult);
    readonly deleteAssociated$ = this.store.select((state: AppState) => state.Users.deleteAssociated);
    @Input() context: string;

    deleteAssociatedSub: Subscription;
    guestSubscription: Subscription;
    associatedUsers: Subscription;
    swalSub: Subscription;
    loading: boolean;
    loadingResend: boolean;
    isPartner: boolean;
    pageName: string;
    blockDelete: boolean;
    id: number;
    subDelete: string;

    displayedColumns: string[] = ['name', 'email', 'phone', 'details', 'delete'];
    dataSource: MatTableDataSource<AssociatedUsers> = new MatTableDataSource([] as AssociatedUsers[]);
    dataLoading = false;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(private validationResponse: ValidationResponseHandlerModule, private sharedService: SharedService,
        private readonly store: Store<AppState>, private router: Router) { }

    ngOnInit(): void {
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        } else {
            this.isPartner = false;
        }

        this.pageName = 'user-associated';
        this.id = Number(localStorage.getItem('index_guest'));
        this.loading = true;
        this.loadingResend = false;
        this.blockDelete = true;
        this.dataSource.data = [];
        this.getAssociatedUsers();
        this.confirmDeleteAssociated();
        this.deleteAssociatedResult();
    }

    ngOnDestroy(): void {
        this.subDelete = null;
        this.store.dispatch(UserActions.invite_associated_cleanup());
        this.store.dispatch(UserActions.cleanup());
        if (this.guestSubscription) {
            this.guestSubscription.unsubscribe();
        }
        if (this.associatedUsers) {
            this.associatedUsers.unsubscribe();
        }
        if (this.deleteAssociatedSub) {
            this.deleteAssociatedSub.unsubscribe();
        }
        if (this.swalSub) {
            this.swalSub.unsubscribe();
        }
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (this.context == 'summary') {
            this.router.navigate(['/users/profile']);
        }
        else {
            this.router.navigate(['/users/client/view']);
        }
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'deleteAssociated') {
            if (returnResultSwal.isConfirmed && !this.blockDelete && this.subDelete) {
                this.store.dispatch(UserActions.delete_associated({
                    sub: localStorage.getItem('sub'),
                    child_sub: this.subDelete
                }));
                this.loading = true;
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    deleteAssociated(index) {
        this.subDelete = this.dataSource.data[index].sub;
        this.blockDelete = false;
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-guest', 'user.delete_associated');
    }

    confirmDeleteAssociated() {
        this.swalSub = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    deleteAssociatedResult() {
        this.deleteAssociatedSub = this.deleteAssociated$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-guest-success', result.message);
                    this.loading = false;
                    setTimeout(() => { this.reload(); }, 400);
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete-guest-error', result.message);
                    this.loading = false;
                }
            }
        });
    }

    getAssociatedUsers() {
        this.store.dispatch(UserActions.get_associated_users({
            user_sub: localStorage.getItem('sub'),
            child_sub: (this.isPartner || this.isAssociate) && this.context === 'summary' ? null : localStorage.getItem('clientView')
        }));
        this.associatedUsers = this.associatedUsers$.subscribe(result => {
            if (result) {
                result = result as AssociatedUsers[];
                result.forEach(r => r['loadingResend'] = false);
                this.dataSource.data = result;
                this.loading = false;
            }
        });
    }

    openModalAddGuest() {
        localStorage.setItem('context', this.context);
        this.store.dispatch(UserActions.add_guest_modal({ payload: OperationStatus.Loading }));
    }

    upUserAddGuest(event) {
        this.dataLoading = true;
    }

    resendInvite (element) {
        if (this.isPartner || this.adminAssociate) {
            element.loadingResend = true;
            this.store.dispatch(UserActions.invite_associated({
                sub: localStorage.getItem('sub'),
                child_sub: (this.isPartner || this.isAssociate) && this.context === 'summary' ? null : localStorage.getItem('clientView'),
                resend: true,
                associates: [],
                email: element.email
            }));
            this.subscribeGuestResponse(element);
        }
    }
    subscribeGuestResponse(element) {
        this.guestSubscription = this.inviteAssociatedResult$.subscribe(result => {
            if (result) {
                element.loadingResend = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'invite-associated', 'user.invite_associated');
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'invite-associated-fail', 'user.invite_associated_fail');
                }
            }
        });
    }

    openModalGuestSettings(id) {
        localStorage.setItem('index_guest', id);
        localStorage.setItem('context', this.context);
        this.store.dispatch(UserActions.guest_settings_modal({ payload: OperationStatus.Loading }));
    }

    upUserGuestSettings(event) {
        this.dataLoading = true;
    }

}
