<div class="flex flex-col md:flex-row grid gap-[2%] grid-cols-100% main-content background-globals container-fluid container_global" id="container">
    <div class="flex sm:flex-row sm:flex-wrap sm:gap-[2%] md:flex-col md:gap-[5%]">
        <div class="w-full sm:w-auto md:w-auto">
            <div class="card custom-card">
                <div class="card-header">
                    <h3 class="card-title">
                        <img class="back" *ngIf="playlistLoaded" (click)="resetPlaylist()" src="../../../assets/img/arrow_left.svg">
                        Gravações das câmeras
                    </h3>
                </div>
                <div class="flex flex-row box-border card-body column-flex">
                    <div class="w-full sm:w-3/5 lt-md:w-full card-content" [ngStyle]="{'display': playlistLoaded ? 'block' : 'none' }">
                        <span class="videoHeader">
                            <i nz-icon nzType="play-circle" nzTheme="outline"></i>
                            Gravações de {{cameraName}}
                        </span>
                        <div class="video-container" [ngClass]="{'processing-video': isVideoProcessing}" id="video-container">
                            <!-- PLAYER DE VIDEO -->
                            <video class="video cover-fit" id="video" [ngClass]="{'processing-video': isVideoProcessing}"
                                   preload="metadata" autoplay [poster]="getPreview()"></video>
                            <!-- ICONE DE PLAY/PAUSE -->
                            <div class="playback-animation" id="playback-animation">
                                <svg class="playback-icons">
                                    <use class="hidden" href="#play-icon"></use>
                                    <use href="#pause"></use>
                                </svg>
                            </div>
                            <!-- ICONE DE LOADING -->
                            <div class="loadingVideo" *ngIf="!videoLoaded && !isVideoProcessing">
                                <span class="processing-video-loading-processed" nz-icon nzType="loading" nzTheme="outline"></span>
                            </div>
                            <div class="loadingVideo processing" *ngIf="isVideoProcessing">
                                <span class="processing-video-loading" nz-icon nzType="loading" nzTheme="outline"></span>
                                <span class="processing-video-text">A gravação está sendo processada, aguarde um pouco</span>
                            </div>
                            <!-- BOTÕES DO VIDEO -->
                            <div class="video-controls hidden" id="video-controls">
                                <div class="video-progress">
                                    <progress id="progress-bar" value="0" min="0"></progress>
                                    <div id="alertsOfVideo"></div>
                                    <input class="seek" id="seek" value="0" min="0" type="range" step="1">
                                    <div class="seek-tooltip" id="seek-tooltip">00:00:00</div>
                                    <div class="time" id="time">00:00</div>
                                </div>
                
                                <div class="bottom-controls">
                                    <div class="left-controls">
                                        <button data-title="Play (k)" id="play" class="button_player">
                                            <img src="./../../../assets/img/Icons/play_circle.svg" alt="" [hidden]="!(video.paused || video.ended)">
                                            <span style="font-size: 24px;" nz-icon nzType="pause" nzTheme="outline" [hidden]="video.paused || video.ended"></span>
                                        </button>
                
                                        <button data-title="Skip (s)" id="replay">
                                            <img src="./../../../assets/img/Icons/replay_10.svg" alt="" class="button_player">
                                        </button>

                                        <button data-title="Skip (s)" id="forward">
                                            <img src="./../../../assets/img/Icons/forward_10.svg" alt="" class="button_player">
                                        </button>
                                        
                                        <div class="time">
                                            <time id="time-elapsed">00:00</time>
                                            <span> / </span>
                                            <time id="duration">00:00</time>
                                        </div>
                                    </div>
                                    <div class="right-controls">
                                        <nz-select ngModel="selected" class="speed_selected speed_container select_for_permission time"
                                            nzPlaceHolder="1x" (ngModelChange)="changePlaySpeed($event)">
                                            <nz-option nzLabel="1x" nzValue="1" nzLabel="1x"></nz-option>
                                            <nz-option nzLabel="1.5x" nzValue="1.5"></nz-option>
                                            <nz-option nzLabel="2x" nzValue="2"></nz-option>
                                            <nz-option nzLabel="3x" nzValue="3"></nz-option>
                                          </nz-select>
                                        <button data-title="Full screen (f)" class="fullscreen-button button_player"
                                            id="fullscreen-button">
                                            <svg>
                                                <use href="#fullscreen"></use>
                                                <use href="#fullscreen-exit" class="hidden"></use>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- SVG's -->
                            <svg id="svg">
                                <defs>
                                    <symbol id="skip-icon" viewBox="-6 -6 26 26">
                                        <path
                                            d="M15.4,6.531,6.008.057A.324.324,0,0,0,5.5.323V13.272a.324.324,0,0,0,.508.266L15.4,7.064a.324.324,0,0,0,0-.533Z"
                                            transform="translate(-5.5)" fill="#fff" />
                                        <path d="M9013.669,3157.677v13.638" transform="translate(-9000.215 -3157.677)"
                                            fill="none" stroke="#fff" stroke-width="1.5" />
                                    </symbol>
                    
                                    <symbol id="pause" viewBox="0 0 24 24">
                                        <path
                                            d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="play-icon" viewBox="0 0 24 24">
                                        <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
                                    </symbol>
                    
                                    <symbol id="volume-high" viewBox="0 0 24 24">
                                        <path
                                            d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="volume-low" viewBox="0 0 24 24">
                                        <path
                                            d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="volume-mute" viewBox="0 0 24 24">
                                        <path
                                            d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="fullscreen" viewBox="0 0 24 24">
                                        <path
                                            d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="fullscreen-exit" viewBox="0 0 24 24">
                                        <path
                                            d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z">
                                        </path>
                                    </symbol>
                    
                                    <symbol id="pip" viewBox="0 0 24 24">
                                        <path
                                            d="M21 19.031v-14.063h-18v14.063h18zM23.016 18.984q0 0.797-0.609 1.406t-1.406 0.609h-18q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h18q0.797 0 1.406 0.586t0.609 1.383v14.016zM18.984 11.016v6h-7.969v-6h7.969z">
                                        </path>
                                    </symbol>
                                    <symbol id="download" viewBox="0 0 24 24">
                                        <path d="M20.061 10.467h-6.145v6.981h-3.84l6.913 6.5 6.913-6.5h-3.84v-6.98z" transform="translate(-8.265 -10.467)" style="fill:#fff"/>
                                        <path data-name="Retângulo 17745" transform="translate(0 16.658)" style="fill:#fff" d="M0 0h17v3H0z"/>
                                    </symbol>
                                </defs>
                            </svg>
                        </div>
                        <div class="video-details" *ngIf="dateSelected">
                            <div class="content">
                                <span *ngIf="day.recordings[videoIndex].url" class="content-title">
                                    Gravação {{videoIndex + 1}}
                                </span>
                                <span *ngIf="!day.recordings[videoIndex].url" class="content-title">
                                    Gravação {{videoIndex + 1}} - <b>Em andamento</b>
                                </span>
                                <span class="content-date">
                                    {{day.recordings[0].day_rec | date: 'dd/MM/yy'}} {{day.recordings[videoIndex].media_create_date | date: 'HH:mm:ss'}} - {{day.recordings[videoIndex].media_end_date | date: 'HH:mm:ss'}}
                                </span>
                            </div>
                            <a id="download-button" target="_blank" download=""  *ngIf="!isVideoProcessing" (click)="downloadVideo(video.src, videoIndex)" >
                                <span nz-icon nzType="download" nzTheme="outline" *ngIf="!isDownloading"></span>
                                <span nz-icon nzType="loading" class="downloading-icon" nzTheme="outline" *ngIf="isDownloading"></span>
                            </a>
                        </div>
                        <div *ngIf="dateSelected" >
                        <div [ngSwitch]="day.recordings[videoIndex].alarms">
                            <div *ngSwitchCase="0"></div>
                            <div *ngSwitchDefault [ngStyle]="{'padding': day.recordings[videoIndex].alarms <= 3 ? '24px 25px 24px 20px' : ''}" class="alertListContainer">
                                    <div class="alerts-header"> 
                                        <div class="alertsTitle"> Alertas do Vídeo </div>
                                        <div class="alerts-size"> {{day.recordings[videoIndex].alarms}} alerta(s) </div>
                                    </div>
                                    <nz-spin [nzSpinning]="loadingAlerts" [nzSize]="'large'" [nzIndicator]="loadingCameraTemplate">
                                        <div class="align-carousel">
                                            <button *ngIf="showPrevArrow && day.recordings[videoIndex].alarms >= 3"
                                                class="arrow-left" (click)="previousScroll(divToScroll)">
                                                <img src="../../../assets/img/prevArrow.png" alt="Anterior">
                                            </button>
            
                                            <div class="alerts-list" id="divToSCroll" #divToScroll>
                                                <div id="alertCard" class="alertCard" [ngClass]="alert['statusClass']"
                                                (click)="goToAlert(day.recordings[videoIndex], index); setEffect(day.recordings[videoIndex], index); 
                                                day.recordings[videoIndex].alarms > 3 ? scrollToSelectedCard(divToScroll, day.recordings[videoIndex], index) : null" 
                                                *ngFor="let alert of day.recordings[videoIndex].alerts; let index = index">
                                                    <div class="alertImage">
                                                        <img src="{{alert.screenshotfile}}" alt="Imagem do alerta" style="border-radius: 7px;" width="91px" height="64px"> 
                                                    </div>
                                                    <div class="align-texts">
                                                        <div class="analytic-type">
                                                            {{alert.analytic_type}}
                                                        </div>
                                                        <div class="date-time">
                                                            {{alert.startedat | date: 'dd/MM/yy'}} <br/>
                                                            {{alert.startedat | date: 'HH:mm:ss'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            
                                            <button *ngIf="day.recordings[videoIndex].alarms >= 3 && showNextArrow" class="arrow-right" (click)="nextScroll(divToScroll)">
                                                <img src="../../../assets/img/nextArrow.png" alt="Próximo">
                                            </button>                        
                                        </div>
                                    </nz-spin>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 rightbar">
                        <div class="flex flex-col md:flex-row" style="display: flex !important;">
                            <div class="flex-1 md:mr-4 video_list" [ngClass]="{'responsive-no-cam' : allCameras && allCameras.length == 0}">
                                <div *ngIf="!playlistLoaded" class="playlist-text">
                                    <span class="title_top" [ngClass]="{'fullWidth': isPartner || adminAssociate || isAssociate}"><i nz-icon nzType="video-camera" nzTheme="outline"></i> Selecione uma câmera</span>
                                    <div class="filter-container" *ngIf="(isPartner || adminAssociate || isAssociate) && !loadingClients">
                                        <app-filter *ngIf="!reRender" [list]="clientListSimple" type="simple" placeholder="Selecionar cliente"
                                            (filterStatusChange)="selectClient($event)" [showSelected]="true" [selected]="selectedClient" [search]="true"
                                            [hasIconPrefix]="true" iconType="user" [hasToggle]="true"></app-filter>
                                    </div>
                                    <nz-input-group [nzSuffix]="suffixIconSearch" class="input-container" *ngIf="!loadingClients">
                                        <input type="text" nz-input placeholder="Pesquisar câmera" [(ngModel)]="searchText" (keyup)="searchCameras()" class="input-value"/>
                                    </nz-input-group>
                                    <ng-template #suffixIconSearch>
                                        <i nz-icon nzType="search" (click)="searchCameras()"></i>
                                    </ng-template>
                                </div>
                                <div class="rightbar-content">
                                    <app-feedback imageUrl="nocamera.png" type="câmeras" [noCameras]="true" [hidden]="allCameras != undefined" context="camera-playlist"></app-feedback>
                                    <div *ngIf="(camerasLoaded && !loadingClients) && allCameras != undefined; else loadingCameras">
                                        <app-feedback imageUrl="nocamera.png" type="câmeras" [noCameras]="true" *ngIf="allCameras.length == 0 && !searchText && !loadingCameras" context="camera-playlist"></app-feedback>
                                        <app-feedback [noFound]="true" imageUrl="nofound.png" [transparent]="true" *ngIf="allCameras.length == 0 && searchText"></app-feedback>
                                        <div id="containerCameras" class="flex md:flex md:flex-row sm:flex-col md:h-80 card-body camera-body search-results"
                                        [ngClass]="{'flex': true, 'flex-col': playlistLoaded, 'flex-row': !playlistLoaded, 'gap-y-{1%}': !playlistLoaded, 'gap-2.5': playlistLoaded}"
                                        [ngStyle]="sizePlaylist()" *ngIf="allCameras.length !== 0"
                                        infinite-scroll [infiniteScrollDistance]="0.05" [infiniteScrollThrottle]="1"
                                        [scrollWindow]="false" (scrolled)="onScroll()" style="overflow-x: hidden;">
                                            <ng-container *ngIf="!playlistLoaded; else listSegments">
                                                <div *ngFor="let camera of allCameras; let index = index" class="cam">
                                                    <i *ngIf="isView(camera)" nz-icon nzType="check-circle" nzTheme="fill" class="check-confirm"></i>
                                                    <div>
                                                        <img class="round" [ngClass]="isView(camera) ? 'thumb' : ''"
                                                            (click)="chooseCam(camera)" [src]="camera.cache_thumb | async | safeHtml" (error)="brokenImageHandler($event)">
                                                    </div>
                                                    <div class="camera_name_container">
                                                        <span class="cam-name camera_alias" nz-tooltip [nzTooltipTitle]="camera.alias" nzTooltipOverlayClassName="cam-name"
                                                        nzTooltipPlacement="topLeft" [nzTooltipOverlayStyle]="{'top': '12px'}" (click)="chooseCam(camera)">
                                                            {{displayCamName(camera.alias)}}
                                                            <span *ngIf="!camera.id_plan">
                                                                <img id="img_cam" [ngClass]="isView(camera) ? 'icon_cam_share' : ''"
                                                                    (click)="chooseCam(camera)" src="../../../assets/img/Icons/icon_for_share_cam.svg">
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="loadingAlerts" *ngIf="loadingCam">
                                                    <span nz-icon nzType="loading" nzTheme="outline"></span>
                                                </div>
                                            </ng-container>
                                            <ng-template #listSegments>
                                                <div class="listSegments" id="listSegmentss">
                                                    <span class="listHeader">Vídeos encontrados de {{beginDateFormated | date: 'dd/MM/yy'}} à {{endDateFormated | date: 'dd/MM/yy'}} </span>
                                                    <ng-container *ngIf="dateSelected; else listOfDates">
                                                        <div class="listRecordings">
                                                            <div class="day_group_container" (click)="clearDay()">
                                                                <div style="display: flex; justify-content: flex-start; align-items: center">
                                                                    <div class="status_icon">
                                                                        <div class="wave_container">
                                                                            <ng-container [ngTemplateOutlet]="waveIcon"></ng-container>
                                                                        </div>
                                                                    </div>
                                                                    <div class="content_container">
                                                                        <span class="day_title">Vídeo do dia {{day.recordings[0].day_rec | date: 'dd/MM/yy'}}</span>
                                                                        <span class="day_subtitle">{{day.recordings.length}} vídeos</span>
                                                                        <span class="day_subtitle">
                                                                            {{day.alerts_amount}}
                                                                            <span nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Número de alertas do dia" 
                                                                                nzTooltipOverlayClassName="camera_playlist_detections" nzTheme="outline" class="clickable info"></span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <button>
                                                                    <span nz-icon nzType="close" nzTheme="outline" class="close"></span>
                                                                </button>
                                                            </div>
                                                            <div class="recording_container clickable" [ngClass]="{playing: videoIndex == index}" 
                                                            *ngFor="let recording of playlist[dayIndex].recordings; let index = index" (click)="selectVideo(index); clearScroll()">
                                                                <div style="display: flex; justify-content: flex-start; align-items: center">
                                                                    <div *ngIf="playlist[dayIndex].recordings[index].url" class="status_icon">
                                                                        <div class="wave_container no_border">
                                                                            <ng-container [ngTemplateOutlet]="waveIcon"></ng-container>
                                                                        </div>
                                                                        <span nz-icon nzType="caret-right" nzTheme="outline" class="play_icon smaller"></span>
                                                                    </div>
                                                                    <div *ngIf="!playlist[dayIndex].recordings[index].url" class="status_icon">
                                                                        <div class="wave_container no_border">
                                                                            <ng-container [ngTemplateOutlet]="waveIcon"></ng-container>
                                                                        </div>
                                                                        <span nz-icon nzType="loading" class="play_icon smaller" nzTheme="outline" nz-tooltip nzTooltipTitle="Essa gravação ainda está sendo processada. Aguarde um pouco."
                                                                            nzTooltipOverlayClassName="camera_playlist_processing "></span>
                                                                    </div>
                                                                    <div class="content_container" style="width: 100%;">
                                                                        <span *ngIf="playlist[dayIndex].recordings[index].url" class="day_title">
                                                                            Gravação {{index + 1}} <span class="day_subtitle">{{day.recordings[index].media_create_date | date: 'HH:mm:ss'}}{{index + 1 == playlist[dayIndex].recordings.length ? ' - ' + (playlist[dayIndex].recordings[index].media_end_date | date: 'HH:mm:ss') : ''}}</span>
                                                                        </span>
                                                                        <span *ngIf="!playlist[dayIndex].recordings[index].url" class="day_title">
                                                                            Gravação {{index + 1}} - <b>Em andamento</b>
                                                                            <span class="day_subtitle">{{playlist[dayIndex].recordings[index].media_create_date | date: 'HH:mm:ss'}} - {{playlist[dayIndex].recordings[index].media_end_date | date: 'HH:mm:ss'}}</span>
                                                                        </span>
                                                                        <span class="day_subtitle">{{playlist[dayIndex].recordings[index].day_rec | date: 'dd/MM/yy'}}</span>
                                                                        <span class="day_subtitle">
                                                                            {{playlist[dayIndex].recordings[index].alarms}}
                                                                            <span nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Número de alertas do vídeo" 
                                                                                nzTooltipOverlayClassName="camera_playlist_detections" nzTheme="outline" class="clickable info"></span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #listOfDates>
                                                        <div (click)="selectDay(day, index);" class="day_group_container" *ngFor="let day of playlist; let index = index">
                                                            <div style="display: flex; justify-content: flex-start; align-items: center">
                                                                <div class="status_icon">
                                                                    <span nz-icon nzType="play-circle" nzTheme="outline" class="play_icon"></span>
                                                                </div>
                                                                <div class="content_container">
                                                                    <span class="day_title">Vídeo do dia {{playlist[index].recordings[0].day_rec | date: 'dd/MM/yy'}}</span>
                                                                    <span class="day_subtitle">{{playlist[index].recordings.length}} vídeos</span>
                                                                    <span class="day_subtitle">
                                                                        {{playlist[index].alerts_amount}}
                                                                        <span nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Número de alertas do dia" 
                                                                            nzTooltipOverlayClassName="camera_playlist_detections" nzTheme="outline" class="clickable info"></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <button>
                                                                <span nz-icon nzType="menu" nzTheme="outline" class="menu"></span>
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <ng-template #loadingCameras>
                                        <div class="loadingCameras" [hidden]="allCameras == undefined">
                                            <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
                                        </div>
                                    </ng-template>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!playlistLoaded" class="playlistTitle">
                            <span class="title_top"><i nz-icon nzType="node-index" nzTheme="outline"></i> Selecione o intervalo de busca</span>
                        </div>
                        <div *ngIf="!playlistLoaded" class="time-container">
                            <div class="col-md-7 col-sm-7 col-7">
                                <div class="card_time_right">
                                    <span class="label-input">Selecionar data :</span>
                                    <mat-form-field>
                                        <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker">
                                          <input matStartDate formControlName="start" [(ngModel)]="beginDate" placeholder="Data inicial" mask="00/00/0000" [showMaskTyped]="false" #dateRangeStart (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                                          <input matEndDate formControlName="end" [(ngModel)]="endDate" placeholder="Data final" mask="00/00/0000" [showMaskTyped]="false" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-5 col-5">
                                <form class="flex-1 mt-4 md:mt-0 example-form removeLineDatetime card_time_left">
                                    <span class="label-input">Selecionar horário :</span>
                                    <nz-input-group nzCompact>
                                        <input type="text" nz-input #initialTime [(ngModel)]="beginTime" class="time-begin input-date-style"
                                        mask="Hh:m0" name="beginTime" shownMaskExpression="__:__" showMaskTyped="true" [dropSpecialCharacters]="false" />
                                        <input
                                          type="text"
                                          disabled
                                          nz-input
                                          placeholder="-"
                                          style="width: 30px; height: 40px; border-left: 0px; border-right: 0px; pointer-events: none; background-color: rgb(255, 255, 255);"
                                          class="input-date-style input-date-style-mid"
                                        />
                                        <input type="text" nz-input  #finalTime [(ngModel)]="endTime" class="time-end input-date-style"
                                        mask="Hh:m0" name="endTime" shownMaskExpression="__:__" showMaskTyped="true" [dropSpecialCharacters]="false" />
                                        <div class="time-range-picker">
                                            <app-time-range-picker (changeTime)="getTime($event)"
                                                [time]="[beginTime, endTime]"
                                                [calendarSelect]="false"
                                                [calendarTable]="false">
                                            </app-time-range-picker>
                                        </div>
                                    </nz-input-group>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="!playlistLoaded">
                            <div class="search-button edit-button" nz-tooltip [nzTooltipTitle]="blockRetrieveVideos()" nzTooltipOverlayClassName="cam-name">
                                <button (click)="retrieveVideos()"
                                    mat-raised-button class="btn sm:btn-sm md:btn-sm btn-ant download-button"
                                    [ngClass]="{'disabled': !!blockRetrieveVideos(true) || loading}">
                                    Buscar <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loading"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingCameraTemplate>
    <div class="loadingAlerts">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
</ng-template>

<ng-template #waveIcon>
    <div class="wave-icon animated">
        <div class="bar bar-1"></div>
        <div class="bar bar-2"></div>
        <div class="bar bar-3"></div>
        <div class="bar bar-4"></div>
        <div class="bar bar-5"></div>
    </div>
</ng-template>

<ng-template>
    <div class="flex-1 gt-sm:w-3/5 lt-md:w-full card-content" [ngStyle]="{'display': playlistLoaded ? 'block' : 'none' }">
        <div class="video-container" id="video-container">
        </div>
    </div>
</ng-template>
