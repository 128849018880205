<div class="main-content background card-main-content" [ngStyle]="{'height': heightModal}">
    <div class="container-fluid">
        <div [hidden]="loading">
            <div class="row" style="margin-bottom: 15px;">
                <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xga-10 col-fhd-10">
                    <h3 class="tam-title title_style_1" *ngIf="!is_guest && !groupView && (!isAssociate || adminAssociate || adminClientAssociate)">Adicionar nova detecção inteligente</h3>
                    <h3 class="tam-title title_style_1" *ngIf="is_guest  || groupView || (isAssociate && !adminAssociate && !adminClientAssociate)">Detecções Inteligentes</h3>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 button-close">
                    <i class="material-icons hover-close" (click)="closeCancel()">close</i>
                </div>
            </div>
            <div *ngIf="!is_guest && !groupView" class="row tooltip-show" [ngStyle]="{'display': (limitAnalitic === false && (!isAssociate || adminAssociate || adminClientAssociate)) ? 'block' : 'none' }">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12">
                    <app-group-analytics [analytics_available]="this.camera.analytic_available"  (groups)="setAnalyticGroup($event)" (analyticGroupId)="goToCreate($event)">
                    </app-group-analytics>
                </div>
            </div>
            <div class="row" *ngIf="limitAnalitic && !is_guest && !groupView">
                <div class="card analytics-limit-message">
                    <span>Limite de 3 detecções por câmera atingido. Remova alguma, caso deseje adicionar uma outra
                        detecção.</span>
                </div>
            </div>
            <div class="card card-img card_image">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 inicio-block">
                            <span class="name-cam">{{camera.alias}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Imagem -->
                        <div [ngClass]="'lt-xl:col-lg-12'" class="col-xga-6 col-fhd-6 col-xl-6 image_style">
                            <div class="card canvas_container">
                                <canvas #plotImageView class="canvas-back" width="640" height="480"></canvas>
                                <!-- <canvas #viewObjectAnalytic class="canvas-middle" width="640" height="480"></canvas> -->
                                <canvas #viewAnalytic class="canvas-front" width="640" height="480"></canvas>
                            </div>
                        </div>
                        <!-- INÍCIO PAINEL DE ANALÍTICOS -->
                        <nz-spin [nzSpinning]="loadingAnalytics" [nzSize]="'large'" [nzIndicator]="matSpinner" class="col-6">
                        <div class="flex-1 md:flex-1/2 lg:flex-1/3 xl:flex-1/4">
                            <div class="row">
                                <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xga-9 col-fhd-9">
                                    <span [hidden]="loadingAnalytics" class="detections_list_title">Todas as detecções da câmera</span>
                                    <scan [hidden]="!(cameraAnalytics && cameraAnalytics.analytic_status == 'disabled')" nz-icon nzType="exclamation-circle" nzTheme="outline"
                                            class="icon-table" nz-element #unabledAnalytic="nzElement" nz-tooltip nzTooltipOverlayClassName="camera-actived-date"
                                            nzTooltipTitle="Detecções desativadas por excesso de alertas, edite o(s) analítico(s) para voltar a receber alertas"></scan>
                                </div>
                                <div *ngIf="cameraAnalytics == null && !loadingAnalytics">
                                    <span class="detections_list_title">0 Resultados</span>
                                </div>
                                <div *ngIf="cameraAnalytics != null"
                                    class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 align-text-resp">
                                    <span *ngIf="cameraAnalytics.analytics.length == 1" class="detections_list_title">1
                                        Resultado</span>
                                    <span *ngIf="cameraAnalytics.analytics.length > 1" class="detections_list_title">
                                        {{cameraAnalytics.analytics.length}} Resultados</span>
                                </div>
                            </div>
                            <div [ngStyle]="{'min-width.px': 400}" class="lt-md:min-w-400px md:min-w-0 row container_cards">
                                <div class="container-fluid">
                                    <div class="no-analytic-row" *ngIf="cameraAnalytics == null && !loadingAnalytics">
                                        <img class="without-analytic-pic" src="../../../assets/img/noanalytic.png" alt="">
                                        <span class="without-analytic">Essa câmera ainda não possui<br>detecções inteligentes cadastradas.</span>
                                    </div>
                                    <!-- INÍCIO CARDS -->
                                    <div *ngIf="cameraAnalytics != null" nz-tooltip [nzTooltipOrigin]="unabledAnalytic.elementRef"
                                    [nzTooltipTitle]="cameraAnalytics && cameraAnalytics.analytic_status == 'disabled' ? 'Detecções desativadas por excesso de alertas, edite o(s) analítico(s) para voltar a receber alertas' : ''"
                                        nzTooltipOverlayClassName="camera-actived-date">
                                        <div *ngFor="let analytic of cameraAnalytics.analytics">
                                            <div class="row" style="margin-bottom: 5px;">
                                                <div class="lt-lg:col-xl-12"
                                                    class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xga-10 col-fhd-10">
                                                    <div class="card cards-select1" [id]="analytic.id_analytic"
                                                        (click)="selectAnalytic(analytic)" [ngClass]="analytic.status == 'running' && cameraAnalytics.analytic_status != 'disabled' ? '' : 'disabled opacity'"
                                                        [ngStyle]="{'opacity': analytic.status != 'running' || cameraAnalytics.analytic_status == 'disabled' ? '40%' : 'unset' }">
                                                        <i *ngIf="analytic.status == 'error'" class="material-icons error_analitic_true">error</i>
                                                        <div class="background cards-select2">
                                                            <div class="row" *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate) && !groupView">
                                                                <div class="lt-lg:block col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xga-11 col-fhd-11 container_icons">
                                                                    <i class="material-icons icons_Style_create"
                                                                        (click)="goToUpdate(analytic)">create</i>
                                                                    <i class="material-icons icons_Style_close"
                                                                        (click)="deleteAnalytic(analytic.id_analytic)">close</i>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 analitics_svg">
                                                                    <!-- <i class="material-icons"
                                                                        [ngStyle]="{'color': analytic.border_color}">brightness_1</i> -->
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9"
                                                                        height="18" viewBox="0 0 9 18" fill="svg">
                                                                        <rect id="Retângulo_16912"
                                                                            data-name="Retângulo 16912" width="9"
                                                                            height="18" rx="4"
                                                                            fill="svg" />
                                                                    </svg>
                                                                </div>
                                                                <div
                                                                    class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 analitics_alias">
                                                                    <h4 class="analitic-info">{{analytic.alias}}</h4>
                                                                </div>
                                                                <div
                                                                    class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 scheduling_alias">
                                                                    <span>Agendado para</span>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1">
                                                                </div>
                                                                <div
                                                                    class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 group_analitics_alias">
                                                                    <div class="group_analitics_test">
                                                                        <span class="analitic-info">{{getGroupName(analytic.id_group)}}</span>
                                                                        <span *ngIf="analytic.analytic_category == 'validation'" class="test_analitic">TESTE</span>
                                                                        <span *ngIf="analytic.analytic_category != 'validation'" class="active_analitic">ATIVO</span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 group_days">
                                                                    <div>
                                                                        <span *ngIf="analytic.schedules_flag == 'comercial'">Horário comercial</span>
                                                                        <span *ngIf="analytic.schedules_flag == 'night'">Período noturno</span>
                                                                        <span *ngIf="analytic.schedules_flag !== 'night' && analytic.schedules_flag !== 'comercial'">Horário livre</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex lt-lg:hidden col-2 col-xl-2 col-xga-2 col-fhd-2 container_icons_down"
                                                    *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate) && !groupView">
                                                    <div *ngIf="analytic.status == 'running'" class="tooltip-show flex">
                                                        <button [disabled]="analytic.analytic_category == 'validation' && client_sub == null"  (click)="goToUpdate(analytic)"
                                                            class="btn btn-sm btn-just-icon btn-round edit">
                                                            <i class="material-icons">edit</i>
                                                        </button>
                                                            <span [class.disabled]="analytic.analytic_category == 'validation' && client_sub == null" (click)="deleteAnalytic(analytic.id_analytic)" class="delete">
                                                            <i nz-icon nzType="close-circle" nzTheme="fill"></i>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="analytic.analytic_category == 'validation' && client_sub == null" class="tooltiptext">
                                                        <span class="tooltiptextsoft">Você não pode editar analíticos em teste.</span>
                                                        <br><br>
                                                      </div>
                                                    <div *ngIf="analytic.status == 'pending' || analytic.status == 'updating'" class="processing">
                                                        <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 20px"></span>
                                                        <span>Processando...</span>
                                                    </div>
                                                    <div *ngIf="analytic.status == 'error'" class="configuration_error">
                                                        <span class="message_configuration_error">Erro de configuração</span>
                                                        <span class="delete_configuration_error" (click)="deleteAnalytic(analytic.id_analytic)"><i class="material-icons" style="font-size: 10px;">delete</i> Excluir</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- FIM CARDS -->
                                </div>
                            </div>
                        </div>
                        </nz-spin>
                        <!-- FIM PAINEL DE ANALÍTICOS -->
                    </div>
                </div>
            </div>
        </div>
        <ng-template #matSpinner [hidden]="!loading">
            <div class="spinner_container">
                <span nz-icon nzType="loading" nzTheme="outline" style="font-size: 50px"></span>
            </div>
        </ng-template>
    </div>
</div>
