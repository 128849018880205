import { Usertype } from '../Shared/Enums/Usertype';
import { Notification } from '../Shared/Enums/Notification';
import { UntypedFormControl, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { Feedback, OperationStatus } from 'app/Shared/models';
import { Color } from 'ol/color';
import { Plan } from 'app/cameras/models';

export interface User {
    id?: number;
    imgURL?: string;
    name: string;
    email: string;
    email_suport?: string;
    contract_link?: string;
    active?: boolean;
    id_profile?: number;
    password?: string;
    telephone: string;
    address: Address;
    cpf_cnpj: string;
    usertype?: Usertype;
    user_details?: UserPJ | UserPF;
    associated?: Associated;
}

export interface Associated {
    name: string;
    email: string;
    sub: string;
    phone_number: string;
    address: Address;
    cpf: string;
    permissions?: {
        alias: string;
        view: boolean;
        create: boolean;
        delete: boolean;
        edit: boolean;
    };
}

export interface Partners {
    partners?: Partner[];
    clients_total_amount?: number;
}

export interface Partner {
    partner_name: string;
    email?: string;
    clients_amount: number;
    analytics_used: number;
    analytics_total: number;
    cameras_used: number;
    cameras_total: number;
    sub: string;
}

export interface AssociatedUsers {
    id_user?: number;
    name: string;
    email: string;
    phone_number?: string;
    sub?: string;
    created_at?: string;
    last_access?: string;
    last_device?: string;
    full_created?: boolean;
    permission?: string;
    profile_alias?: string;
    permissions?: {
        alias: string;
        view: boolean;
        create: boolean;
        delete: boolean;
        edit: boolean;
    };
    associate_permission?: string;
}

export interface UserInventory {
    model?: 'UserInventory';
    message?: '';
    kits: [
        {
            id: number;
            cameras_amount: number;
            analytics_amount: number;
            price: number;
            alias: string;
            active: boolean;
            id_plan: number;
            available: boolean;
            kits_amount?: number;
            showed?: boolean;
            days_storage?: number;
            plan?: Plan;
        }
    ];
    inventory: {
        name: string;
        email: string;
        sub: string;
        profile_name: string;
        ci: {
            id_plan: number;
            available: number;
            used: number;
            total: {
                available: number;
                used: number;
            };
        };
        ai: {
            available: number;
            used: number;
        };
        guests: {
            available: number;
            used: number;
        };
    };
    plans: {
        alias: string;
        color: Color;
        days_storage: number;
        id_plan: number;
        max_fps: number;
        price: number;
        resolution: {
            alias: string;
            height: number;
            width: number;
        };
        length: number;
    };
}

export interface UpdateClient {
    sub: string;
    child_sub: string;
    password: string;
    avva: boolean;
    createDevice: boolean;
    name: string;
    email: string;
    telephone: string;
    contactEmergency: string;
    phoneEmergency: string;
    partner_permission?: boolean;
}

export interface UserBillings {
    dashboard_status: string;
    cameras: number;
    analytics: 0;
    billing_data: {
        bairro: string;
        cep: string;
        cidade: string;
        complemento: string;
        numero: string;
        rua: string;
        estado: string;
        name: string;
        email: string;
    };
    billings: string[];
}

export interface TicketKit {
    kits: [
        {
            kit_id: number;
            kit_amount: number;
            observation: string;
        }
    ];
}

export interface UserBillingsHistory {
    month: string;
    value: number;
    paid: boolean;
}

export interface UserBillingsDetails {
    id_camera: number;
    camera_name: string;
    plan: [
        {
            id: number;
            alias: string;
            days_storage: number;
        }
    ];
    note: [
        {
            id_nota_not: string;
            dt_emissao_not: string;
            nm_nfse_not?: string;
            vl_valor_not: string;
            st_urlnfse_not?: string;
            fl_status_not: string;
            fl_nfestatus_not?: string;
            fl_nfsestatus_not: string;
            nm_rps_not?: string;
            st_numero_not?: string;
            id_recebimento_recb: string;
        }
    ];
    payment: {
        method: string;
        data_payment: {
            second_way: string;
            format?: string;
        };
    };
    value_bill: number;
    payment_status: string;
    due_date: string;
}

export interface CreditCard {
    month: string;
    year: string;
    brand: string;
    cardMask: string;
}

export interface PartnerPermission {
    view_live: boolean;
    view_recording: boolean;
}

export interface CognitoAttributes {
    model?: 'cognito';
    sub: string;
    active?: boolean;
    child_sub?: string;
    zoneinfo?: string;
    'custom:CNPJ'?: string;
    'custom:CPF'?: string;
    address?: string;
    birthdate?: string;
    email_verified?: boolean;
    email_suport?: string;
    gender?: string;
    phone_number_verified?: boolean;
    picture?: string;
    updated_at?: string;
    name: string;
    phone_number: string;
    family_name?: string;
    email: string;
    avva?: boolean;
    device?: boolean;
    contactEmergency?: string;
    phoneEmergency?: string;
    child_profile?: string;
    partner_permission?: {view_live: boolean; view_recording: boolean} | boolean;
    contract_link?: string;
    password?: string;
    invites?: [{
        cam_name: string;
        user_email: string;
        user_name: string;
    }];
    associated?: Associated;
    cost_center?: string;
    cost_center_mark?: boolean;
}

export interface UserChangePassword {
    lastPassword: string;
    newPassword: string;
}

export interface UserChangeBillingAddress {
    street: string;
    number: number;
    complement: string;
    zipCode: string;
    state: string;
    city: string;
    district: string;
}

export interface ConfigAlarm {
    checkApp: boolean;
    checkAva: boolean;
    checkSms: boolean;
    checkDevice?: boolean;
    numbersCell: string[];
    contactEmergency?: string;
    phoneEmergency?: string;
}

export interface AlarmTag {
    name: string;
    value: boolean;
}

export interface UserPF {
    RG: string;
    CPF: string;
    CEP: string;
    address: Address;
}

export interface UserPJ {
    company_name: string;
    CNPJ: string;
    IE: string;
    IM: string;
    fantasy_name: string;
    address?: Address;
}

export interface Address {
    state: string;
    city: string;
    street: string;
    number: number;
    comp?: string;
    bairro?: string;
    CEP: string;
}

export interface BillingAddress {
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    numero: string;
    rua: string;
    estado: string;
    name: string;
    email: string;
}

export interface UserState {
    userList?: User[];
    user_detail?: User;
    user_handler?: User;
    req_success?: Notification;
    profile_url?: string;
    modal_open_config_alarm: number;
    modal_change_password: number;
    modal_change_link: number;
    modal_add_guest: number;
    modal_guest_settings: number;
    modal_crop_image: number;
    upPassword?: OperationStatus;
    upAddress?: Feedback;
    crop_event?: any;
    upConfigAlarm: number;
    delConfigAlarm: number;
    config_alarm?: ConfigAlarm;
    userData?: CognitoAttributes | Feedback;
    updateResult?: Feedback;
    updatePartnerResult?: Feedback;
    convertResult?: Feedback;
    convertGuestResult?: Feedback;
    updateClientResult?: Feedback;
    updatePayment?: Feedback;
    user_billings?: UserBillings | Feedback;
    user_billings_history?: UserBillingsHistory | Feedback;
    user_billings_details?: UserBillingsDetails | Feedback;
    user_payment_method?: CreditCard | Feedback;
    partnersList?: Partners | Feedback;
    client_list?: PaginationClients | UserClients;
    guests_list?: PaginationGuests | Guest[];
    resendEmail?: Feedback;
    notificationVisibility?: Feedback;
    registerPartnerUser?: Feedback;
    userInventory?: UserInventory | Feedback;
    userNotification?: NotificationDays[] | Feedback;
    associatedUsers?: AssociatedUsers[] | Feedback;
    transferItemsFeedback?: Feedback;
    deleteResult?: Feedback;
    blockClient?: Feedback;
    kitsList?: Kit[];
    updateKitResult?: Feedback;
    createKitResult?: Feedback;
    deleteClientInventory?: Feedback;
    deleteKitResult?: Feedback;
    editKitModalStatus?: AlarmModalState;
    inviteAssociatedResult?: Feedback;
    deleteAssociated?: Feedback;
    updateAssociated?: Feedback;
    createTicketKit?: Feedback;
    deletePartner?: Feedback;
    pageIndexClient?: number;
    pageIndexGuest?: number;
    nationalHolidays?: Feedback;
    calendarList?: Feedback;
    holidays?: Feedback;
    deleteHoliday?: Feedback;
}

export enum AlarmModalState {
    Select,
    Config,
    Load,
    Confirm,
    Confirmed,
    Closed,
    Cancel
}

export function validatePassword(c: UntypedFormControl): ValidationErrors | null {
    let validation: ValidationErrors = null;
    const regexList: RegExp[] = [/[0-9]+/, /[A-Z]+/, /[a-z]+/, /\W+/];
    const errorList: string[] = ['numberError', 'upperCaseError', 'lowerCaseError', 'symbolError'];
    let count = 0;
    regexList.forEach(regex => {
        if (!regex.test(c.value)) {
            validation = {
                ...validation,
                [errorList[count]]: true
            };
        }
        count++;
    });
    return validation;
}

export function confirmPassword(c: UntypedFormGroup): ValidationErrors | null {
    const password = c.get('newPass');
    const confirm = c.get('confirmNewPass');
    const exp = password.value != confirm.value;
    return exp ? { 'passwordMatch': true } : null;
}

export function analiticPassword(c: UntypedFormGroup): ValidationErrors | null {
    const password = c.get('password');
    const confirm = c.get('confirm');
    const exp = password.value != confirm.value;
    return exp ? { 'passwordMatch': true } : null;
}

export interface TypePayment {
    sub: string;
    method_code: number;
    method_details?: CardPayment;
}

export interface CardPayment {
    brand: string;
    card_number: string;
    month: number;
    year: number;
    security_number: number;
    name: string;
}


export interface Client {
    name?: string;
    email?: string;
    sub?: string;
    id?: number;
    profilePicture?: string;
    ci?: Inventory;
    ai?: Inventory;
    gi?: Inventory;
    invitation_time?: string;
    cnpj?: string;
    phonenumber?: string;
    password?: string;
    cameras?: CameraAmount[];
    analytcsAmount?: number;
    childProfile?: string;
    guests_count?: number;
    active?: boolean;
    costCenter?: string;
    costCenterMark?: boolean;
}

export interface Inventory {
    available: number;
    used: number;
}

export interface Guest {
    guest_name?: string;
    guest_sub?: string;
    type_invite?: string;
    profile_alias?: string;
    client_name?: string[];
    cameras_count?: number;
    permissions?: {
        alias: string;
        view: boolean;
        create: boolean;
        delete: boolean;
        edit: boolean;
    };
}

export interface UserClients {
    clients?: Client[];
    guests?: Guest[];
}

export interface PaginationGuests {
    guests: Guest[];
    actual: number;
    pages: number;
    limit: number;
    total: number;
}

export interface PaginationClients {
    clients?: Client[];
    actual: number;
    pages: number;
    limit: number;
    total: number;
}


export interface Camera {
    id?: number;
    name?: string;
    guests?: number;
    active?: boolean;
    detections?: number;
}

export interface CameraAmount {
    plan_id?: number;
    name?: string;
    days?: number;
    amount?: number;
}

export interface KitAmount {
    kit_id?: number;
    amount?: number;
}

export interface Kit {
    active: boolean;
    alias: string;
    analytics_amount: number;
    available: boolean;
    cameras_amount: number;
    id: number;
    id_plan: number;
    price: number;
    kits_amount?: number;
    showed?: boolean;
    plan_id?: number;
    status?: boolean;
}

export interface Notifications {
    today: UserNotification[];
    last_week: UserNotification[];
    last_month: UserNotification[];
    notification_status: boolean;
}

export interface UserNotification {
    timestamp: string;
    content: {
        title: string;
        body: string;
    };
    notification_type: string;
    visibility: boolean;
    hashnotification: string;
}

export interface NotificationDays {
    notification_status?: boolean;
    today: UserNotification[];
    last_week: UserNotification[];
    last_month: UserNotification[];
}

export interface Holidays {
    active?: boolean;
    alias?: string;
    all_day?: boolean;
    end_time?: string;
    foreignhash?: string;
    hash_holiday?: string;
    id_cameras?: string[] | [{
        id_camera: number;
        hash_holiday: string;
    }];
    cameras_info?: [{
        id?: number;
        alias?: string;
        hash_holiday?: string;
    }];
    national_holiday?: boolean;
    start_time?: string;
    to_all_cameras_user?: boolean;
    id?: number;
    beginTime?: string;
    endTime?: string;
}
export interface CameraInfoHolidays {
    alias: string;
    id: number;
    id_customer: number;
}
