<div class="main-content background">
    <div class="container-header">
     <span class="style-title">
         Lista de Usuários
     </span>
     <span class="style-details" *ngIf="!loadingClients && inventories">
         Estoque de Câmeras: {{inventories.inventory.ci.total.used}}/{{inventories.inventory.ci.total.used + inventories.inventory.ci.total.available}}
     </span>
     <span class="style-details" *ngIf="!loadingClients && inventories">
         Estoque de Detecções: {{inventories.inventory.ai.used}}/{{inventories.inventory.ai.used + inventories.inventory.ai.available}}
     </span>
 </div>

 
 <div class="first-row">
     <div class="search-input">
         <input type="text" placeholder="Pesquisar na lista de clientes"
             [formControl]="searchInputControl" (keyup)="requestSearch()">

         <i nz-icon nzType="search" nzTheme="outline" class="clickable" (click)="requestSearch()"></i>
     </div>
     <div class="add-client">
         <button class="btn btn-primary add-button"(click)="addClient()" *ngIf="!isAssociate || adminAssociate">
             <i nz-icon nzType="plus" nzTheme="outline"></i> ADICIONAR CLIENTE
         </button>
     </div>
 </div>

 <!-- LISTAGEM DE CLIENTES -->
 <div class="clientes-container">
     <div class="section-header">
         <i nz-icon nzType="user" nzTheme="outline"></i>
         <span class="section-title">Lista de clientes</span>
         <span class="section-amount">{{formatPlural(clientTotal, 'cliente')}}</span>
         <i nz-icon [nzType]="isClientExpanded ? 'up-circle' : 'down-circle'" nzTheme="outline" 
         (click)="toggleExpandedList('client')" class="expandButton clickable" *ngIf="clientTotal > 3"></i>
     </div>
     <div class="list-container" [hidden]="!hasClients">
         <nz-table #clientTable class="table style-table antd-table"
         [nzData]="clientData" 
         [nzPaginationPosition]="'both'"
         [nzFrontPagination]="false"
         [nzSize]="'small'"
         [nzTotal]="clientTotal"
         [(nzPageSize)]="clientLimit"
         [(nzPageIndex)]="clientIndex"
         (nzPageIndexChange)="onPageIndexChangeClient()"
         [nzTableLayout]="'auto'"
         [nzLoading]="loadingClients" [nzLoadingIndicator]="loadingList">
             <thead>
                 <tr>
                     <th>Cliente</th>
                     <th>E-mail</th>
                     <th>Câmeras</th>
                     <th>Detecções</th>
                     <th>Convidados</th>
                     <th></th>
                 </tr>
             </thead>
             <tbody [hidden]="loadingClients">
                 <tr class="clickable" *ngFor="let client of clientTable.data" (click)="viewClient(client)">
                     <!-- Nome do cliente -->
                     <td>{{client.name}}</td>
                      <!-- E-mail do cliente -->
                     <td>{{client.email}}</td>
                      <!-- Câmeras do cliente -->
                     <td>
                         <span class="number-inventory"
                               nzTooltipOverlayClassName="inventory"
                               nzTheme="outline"
                               nzTooltipPlacement="bottom"
                               nz-tooltip
                               nzTooltipTitle="Câmeras usadas"> {{client.ci.used}}
                         </span>
                          / 
                         <span class="number-inventory"
                                 nzTooltipOverlayClassName="inventory"
                                 nzTheme="outline"
                                 nzTooltipPlacement="bottom"
                                 nz-tooltip nzTooltipTitle="Total de câmeras">
                                 {{usedAndAvailableSum(client.ci)}}
                         </span>
                     </td>
                     <!-- Analíticos do cliente -->
                     <td>
                         <span class="number-inventory"
                             nzTooltipOverlayClassName="inventory"
                             nzTheme="outline"
                             nzTooltipPlacement="bottom"
                             nz-tooltip nzTooltipTitle="Analíticos usados">
                             {{client.ai.used}}
                         </span>
                          / 
                         <span class="number-inventory" 
                               nzTooltipOverlayClassName="inventory"
                               nzTheme="outline"
                               nzTooltipPlacement="bottom"
                               nz-tooltip
                               nzTooltipTitle="Total de analíticos">
                               {{usedAndAvailableSum(client.ai)}}
                         </span>
                     </td>
                     <!-- Convidados do cliente -->
                     <td>
                         <span class="number-inventory"
                               nzTooltipOverlayClassName="inventory"
                               nzTheme="outline"
                               nzTooltipPlacement="bottom"
                               nz-tooltip nzTooltipTitle="Convites usados">
                               {{client.gi.used}}
                         </span>
                          / 
                         <span class="number-inventory"
                             nzTooltipOverlayClassName="inventory"
                             nzTheme="outline"
                             nzTooltipPlacement="bottom"
                             nz-tooltip nzTooltipTitle="Total de convites">
                             {{usedAndAvailableSum(client.gi)}}
                         </span>
                     </td>
                     <td class="actions">
                         <i nz-icon nzType="right" nzTheme="outline" class="clickable" (click)="viewClient(client)"></i>
                     </td>
                 </tr>
             </tbody>
         </nz-table>
         <button [hidden]="loadingClients" class="show-more" *ngIf="clientTotal > 3" (click)="toggleExpandedList('client')">
             <i nz-icon [nzType]="isClientExpanded ? 'line' : 'more'" nzTheme="outline" [nzRotate]="isClientExpanded ? 0 : 90"></i>
             <span>Mostrar {{isClientExpanded ? 'menos' : 'todos'}}</span>
         </button>
     </div>
     <div [hidden]="hasClients">
         <app-feedback [noUsers]="true" imageUrl="no_client.svg" [transparent]="true" type="clientes" *ngIf="!loadingClients && queryClients == '' && clientData.length === 0"></app-feedback>
         <app-feedback [noFound]="true" imageUrl="nofound.png" [transparent]="true" *ngIf="queryClients != '' && !loadingClients"></app-feedback>
     </div>
 </div>
 
 <div class="guests-container">
     <div class="section-header">
         <i nz-icon nzType="user" nzTheme="outline"></i>
         <span id="guest-list" class="section-title">Lista de convidados</span>
         <span class="section-amount">{{formatPlural(guestTotal, 'convidado')}}</span>
         <i nz-icon [nzType]="isGuestExpanded ? 'up-circle' : 'down-circle'" nzTheme="outline" 
         (click)="toggleExpandedList('guest')" class="expandButton clickable" *ngIf="guestTotal > 3"></i>
     </div>
     <div class="list-container" [hidden]="!hasGuests">
         <nz-table #guestTable class="table style-table antd-table"
         [nzData]="guestData" 
         [nzPaginationPosition]="'both'"
         [nzFrontPagination]="false"
         [nzSize]="'small'"
         [nzTotal]="guestTotal"
         [(nzPageSize)]="guestLimit"
         [(nzPageIndex)]="guestIndex"
         (nzPageIndexChange)="onPageIndexChangeGuest()"
         [nzTableLayout]="'auto'"
         [nzLoading]="loadingGuests" [nzLoadingIndicator]="loadingList">
             <thead>
                 <tr>
                     <th>Convidado</th>
                     <th class="guests-type"></th>
                     <th>Cliente</th>
                     <th>Câmeras compartilhadas</th>
                     <th class="actions"></th>
                 </tr>
             </thead>
             <tbody [hidden]="loadingGuests">
                 <tr class="clickable" *ngFor="let guest of guestTable.data" (click)="viewGuest(guest)">
                     <!-- Nome do convidado -->
                     <td>{{guest.guest_name}}</td>
                     <!-- Tipo do convidado -->
                     <td class="type-invite">
                         <span *ngFor="let type of format(guest.type_invite)"
                               [ngClass]="type == 'Câmera' ? 'invite-camera' : 'invite-account'" 
                               class="type-title">
                               {{type}} {{type !== 'Câmera' && guest.permissions ? ' - ' + guest.permissions[0].alias : ''}}
                         </span>
                     </td>
                     <!-- Nome do cliente que convidou -->
                     <td>{{processClientName(guest.client_name)}}</td>
                     <!-- Quantidade de câmeras compartilhadas -->
                     <td>{{guest.cameras_count}}</td>
                     <!-- Botão de ver convidado -->
                     <td>
                         <i nz-icon nzType="right" nzTheme="outline" class="clickable" (click)="viewGuest(guest)"></i>
                     </td>
                 </tr>
             </tbody>
         </nz-table>
         <button  [hidden]="loadingGuests" class="show-more" *ngIf="guestTotal > 3" (click)="toggleExpandedList('guest')">
             <i nz-icon [nzType]="isGuestExpanded ? 'line' : 'more'" nzTheme="outline" [nzRotate]="isGuestExpanded ? 0 : 90"></i>
             <span>Mostrar {{isGuestExpanded ? 'menos' : 'todos'}}</span>
         </button>
     </div>
     <div [hidden]="hasGuests || loadingGuests">
         <app-feedback [imageUrl]="queryClients == '' ? 'no_client.svg' : 'nofound.png'" [noFound]="queryClients != ''" [transparent]="true"
                         [noUsers]="queryClients == ''" type="convidados"></app-feedback>
     </div>
 </div>
</div>


<ng-template #loadingList>
 <div class="loading-list" style="margin-top: 85px;">
     <span nz-icon class="center" nzType="loading" nzTheme="outline"></span>
 </div>
</ng-template>

<app-modal></app-modal>
