import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { Session } from '../models';
import { User } from 'app/Users/models';
import { Feedback } from 'app/Shared/models';
import { createAction, props } from '@ngrx/store';

type Payload = Session | Feedback | any;

export interface Metadata
{
    user?: User;
    username?: string;
    player_id?: string;
    session?: Session;
    error_msg?: string;
    code?: string;
    resend?: boolean;
    password?: string;
    userSub?: string;
}

export type LoginAction = FluxStandardAction<Payload, Metadata>;

@Injectable()
export class LoginActions{
    static readonly SIGNIN = 'SIGNIN';
    static readonly SIGNIN_STATUS = 'SIGNIN_STATUS';
    static readonly SIGNOUT = 'SIGNOUT';
    static readonly SIGNOUT_FEEDBACK = 'SIGNOUT_FEEDBACK';
    static readonly REGISTER = 'REGISTER';
    static readonly REGISTER_FEEDBACK = 'REGISTER_FEEDBACK';
    static readonly CONFIRM_REGISTER = 'CONFIRM_REGISTER';
    static readonly CONFIRM_REGISTER_FEEDBACK = 'CONFIRM_REGISTER_FEEDBACK';
    static readonly RESET_PASSWORD = 'RESET_PASSWORD';
    static readonly RESET_PASSWORD_RESULT = 'RESET_PASSWORD_RESULT';
    static readonly CHANGE_PASSWORD = 'CHANGE_PASSWORD';
    static readonly RENEW_TOKEN = 'RENEW_TOKEN';
    static readonly GET_USER_ATTRIBUTES = 'GET_USER_ATTRIBUTES';
    static readonly PUBLISH_PICTURE = 'PUBLISH_PICTURE';
    static readonly PUBLISH_PICTURE_SUCCESS = 'PUBLISH_PICTURE_SUCCESS';
    static readonly CLEANUP = 'CLEANUP';

    static register = createAction(
        LoginActions.REGISTER,
        props<Metadata>()
    );

    static register_result = createAction(
        LoginActions.REGISTER_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static confirm_register = createAction(
        LoginActions.CONFIRM_REGISTER,
        props<Metadata>()
    );

    static confirm_register_result = createAction(
        LoginActions.CONFIRM_REGISTER_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static signin = createAction(
        LoginActions.SIGNIN,
        props<Metadata>()
    );

    static signin_status = createAction(
        LoginActions.SIGNIN_STATUS,
        props<{ payload: Payload }>()
    );

    static signout = createAction(
        LoginActions.SIGNOUT,
        props<Metadata>()
    );

    static signout_feedback = createAction(
        LoginActions.SIGNOUT_FEEDBACK,
        props<{ payload: Payload }>()
    );

    static reset_password = createAction(
        LoginActions.RESET_PASSWORD,
        props<Metadata>()
    );

    static reset_password_result = createAction(
        LoginActions.RESET_PASSWORD_RESULT,
        props<{ payload: Payload }>()
    );

    static cleanup = createAction(
        LoginActions.CLEANUP
    );
}

