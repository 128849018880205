import { Feedback, OperationStatus } from 'app/Shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { Detections, DetectionState } from '../models';
import { DetectionAction, DetectionActions } from './actions';

const INITIAL_STATE: DetectionState = {
    detections: {
        model: 'detections',
        detections: [],
        status: OperationStatus.Unloaded
    },
    reviewStatus: {
        model: 'feedback',
        status: OperationStatus.Unloaded
    },
    deleteStatus: {
        model: 'feedback',
        status: OperationStatus.Unloaded
    },
};


export const detectionReducer = createReducer(
    INITIAL_STATE,
    on(
        DetectionActions.get_detections, state => state
    ),
    on(
        DetectionActions.get_detections_return, (state, { payload }) => ({
            ...state,
            detections: payload.result as Detections | Feedback,
        })
    ),
    on(
        DetectionActions.cleanup, state => ({
            ...state,
            detections: {
                model: 'detections',
                detections: [],
                status: OperationStatus.Unloaded
            } as Detections,
            reviewStatus: {
                model: 'feedback',
                status: OperationStatus.Unloaded
            } as Feedback,
            deleteStatus: {
                model: 'feedback',
                status: OperationStatus.Unloaded
            } as Feedback,
        })
    ),
    on(
        DetectionActions.review_alerts, state => state
    ),
    on(
        DetectionActions.review_alerts_return, (state, { payload }) => ({
            ...state,
            reviewStatus: payload.result as Feedback
        })
    ),
    on(
        DetectionActions.delete_alerts, state => state
    ),
    on(
        DetectionActions.delete_alerts_return, (state, { payload }) => ({
            ...state,
            deleteStatus: payload.result as Feedback
        })
    ),
    on(
        DetectionActions.cleanup, state => ({
            ...state,
            reviewStatus: {
                model: 'feedback',
                status: OperationStatus.Unloaded
            } as Feedback
        })
    ),
);
