<div class="main-content background-container" id="containerEdit">
    <!-- Formulario para preenchimento do email do convidado -->
    <div class="col-md-11.5 button-close">
        <i class="material-icons hover-close" (click)="closeCancel()">close</i>
    </div>
    <div class="row title_style_1">
        <span class="style-title">Configurações da Câmera</span>
    </div>
    <div class="row title_style_2">
        <span class="style-title1">{{cameraName}}</span>
    </div>
    <div class="row container_tabs">
        <div class="mat-tab-group">
            <div class="mat-tab-header">
                <!-- Tab referente a dados -->
                <div (click)="changeTabs('dados')" id="data" class="tab_style mat-tab-label" 
                    [class]="{tab_style_active: activeTab === 'dados'}">
                    Dados da câmera
                </div>
                <!--Tab referente a saúde da conexão -->
                <div (click)="changeTabs('health')" id="health" class="tab_style mat-tab-label" [class]="{tab_style_active: activeTab === 'health'}">
                    Conexão de Rede
                </div>
                <!-- Tab referente a planos -->
                <div *ngIf="!(partnerClient) && !is_guest && !adminClientAssociate" (click)="changeTabs('planos')" id="plan" (click)="selectedPlan(plano_id)"
                    class="tab_style mat-tab-label" [class]="{tab_style_active: activeTab === 'planos'}">
                    Plano
                </div>
                <!-- Tab referente a endereço -->
                <div (click)="changeTabs('endereco')" id="address" (click)="SendDataToAddress(cameras)"
                    class="tab_style mat-tab-label" [class]="{tab_style_active: activeTab === 'endereco'}" *ngIf="!is_guest">
                    Endereço
                </div>
            </div>
            <div class="mat-tab-body-wrapper">
                <div class="mat-tab-body-active mat-tab-body">
                    <div class="mat-tab-body-content">
                        <!-- Tab de dados -->
                        <div class="inner_style " id="dados_tab">
                            <app-camera-data [loading]="loading" [hidden]="activeTab !== 'dados'" [camera]="currentCamera" (cancelEdit)="closeCancel()"
                                (SaveCamera)="saveDataChanges($event)" (camEditData)="camEditDataTrue($event)" (integratedCameraData)="integratedCameraDataChanges($event)"
                                (camRetryData)="camRetryDataTrue($event)"></app-camera-data>
                        </div>
                        <!--Tab de saúde-->
                        <div class="inner_style" id="health_tab">
                            <app-camera-health [hidden]="activeTab !== 'health'" [camera]="camera"></app-camera-health>
                        </div>
                        <!-- Tab de Planos -->
                        <div class="inner_style" id="planos_tab">
                            <app-camera-plans [loading]="loading" [hidden]="activeTab !== 'planos'" (planId)="getIdPlan($event)" (cancelEmmiter)="closeCancel()"
                                (saveEmmiter)="changePlan($event)" (camEditData)="camEditDataTrue($event)" [plan_id]="camera.id_plan"></app-camera-plans>
                        </div>
                        <!-- Tab de Endereço -->
                        <div class="inner_style" id="endereco_tab">
                            <!-- Primeira linha - Mapa -->
                            <app-camera-address [loading]="loading" [hidden]="activeTab !== 'endereco'" (saveEmmiter)="changeAddress($event)" (cancelEmmiter)="closeCancel()"
                                [addressDataReceived]="addressData.asObservable()" (camEditData)="camEditDataTrue($event)"></app-camera-address>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
