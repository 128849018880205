import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GLPIActions } from './glpi.actions';
import { GLPIFile } from './glpi.models';
import { Subscription } from 'rxjs';
import { AppState } from 'app/store/model';
import { OperationStatus } from 'app/Shared/models';
import { Store } from '@ngrx/store';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { UserActions } from 'app/Users/Services/actions';

@Component({
    selector: 'app-glpi',
    templateUrl: './glpi.component.html',
    styleUrls: ['./glpi.component.scss']
})
export class GLPIComponent implements OnInit, OnDestroy {
    readonly getSession$ = this.store.select((state: AppState) => state.glpi.getSession);
    readonly createTicket$ = this.store.select((state: AppState) => state.glpi.createTicket);
    readonly uploadFile$ = this.store.select((state: AppState) => state.glpi.uploadDocument);
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);

    @Output() modalClose = new EventEmitter<string>();
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    getSessionSub: Subscription;
    uploadFileSub: Subscription;
    createTicketSub: Subscription;
    userDataSub: Subscription;

    public glpiForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        subject: new UntypedFormControl('', [Validators.required]),
        message: new UntypedFormControl('', [Validators.required]),
    });

    pageName = 'glpi-support';
    loading = false;
    userEmail: string;
    loadingEmail: boolean;

    session_token: string;
    documentIds: number[] = [];
    uri: string;
    name: string;
    loadingImage: boolean;

    constructor(private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        if (!localStorage.getItem('usernameWeCloud')) {
            this.getUserData();
        }
        this.glpiForm.get('email').setValue(localStorage.getItem('usernameWeCloud'));

        if (localStorage.getItem('sessionGLPI')) {
            this.session_token = localStorage.getItem('sessionGLPI');
        } else {
            this.store.dispatch(GLPIActions.get_session({user_sub: localStorage.getItem('sub')}));
        }
        this.getSessionResult();
        this.getFilesResult();
        if (this.documentIds.length > 0) {
            this.documentIds = [];
        }
    }

    ngOnDestroy(): void {
        if (this.getSessionSub) {
            this.getSessionSub.unsubscribe();
        }
        if (this.createTicketSub) {
            this.createTicketSub.unsubscribe();
        }
        if (this.userDataSub) {
            this.userDataSub.unsubscribe();
        }
        if (this.uploadFileSub) {
            this.uploadFileSub.unsubscribe();
        }
        GLPIActions.upload_document_clear();
        GLPIActions.cleanup();
        this.closeModal();
    }

    closeModal() {
        this.documentIds = [];
        this.glpiForm.reset();
        GLPIActions.cleanup();
        if (!localStorage.getItem('usernameWeCloud')) {
            UserActions.cleanup();
        }
        this.modalClose.emit();
    }

    onFileUpload(event){
        if (this.session_token) {
            const inputElement = event.target as HTMLInputElement;
            const selectedFiles: FileList = inputElement.files;

            if (selectedFiles) {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.loadingImage = true;
                    this.store.dispatch(GLPIActions.upload_document({
                        document: selectedFiles.item(i),
                        session_token: this.session_token
                    }));
                }
            }
        }
    }

    getFilesResult() {
        this.uploadFileSub = this.uploadFile$.subscribe(result => {
            if (result && result.type == 'file') {
                result = result as GLPIFile;
                if (!this.documentIds.includes(result.id)) {
                    this.documentIds.push(result.id);
                }
                this.loadingImage = false;
            }
        });
    }

    removeItem() {
        this.documentIds = [];
        this.fileInput.nativeElement.value = '';
        GLPIActions.create_ticket_clear();
    }

    sendInfo() {
        let errorPass = false;

        if (this.glpiForm.get('email').invalid) {
            errorPass = true;
            this.glpiForm.get('email').markAsTouched();
        }
        if (this.glpiForm.get('subject').invalid) {
            errorPass = true;
            this.glpiForm.get('subject').markAsTouched();
        }
        if (this.glpiForm.get('message').invalid) {
            errorPass = true;
            this.glpiForm.get('message').markAsTouched();
        }

        if (!errorPass) {
            this.loading = true;
            this.store.dispatch(GLPIActions.create_ticket({
                user_sub: localStorage.getItem('sub'),
                child_sub: localStorage.getItem('childView'),
                document_ids: this.documentIds,
                ticket: {
                    email: this.glpiForm.get('email').value,
                    subject: this.glpiForm.get('subject').value,
                    message: this.glpiForm.get('message').value
                }
            }));
            this.createTicketResult();
        }

    }

    getSessionResult() {
        this.getSessionSub = this.getSession$.subscribe(result => {
            if (result) {
                this.session_token = result[0].session_token;
                localStorage.setItem('sessionGLPI', this.session_token);
            }
        });
    }

    createTicketResult() {
        this.createTicketSub = this.createTicket$.subscribe(result => {
            if (result) {
                this.loading = false;
                if (result.status === OperationStatus.Success) {
                    // adicionar anexação das imagens ao ticket
                    this.glpiForm.reset();
                    this.documentIds = [];
                    this.glpiForm.get('email').setValue(this.userEmail);
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'ticket-create-success', 'glpi.success_create_ticket', result.message);
                    this.ngOnDestroy();
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'ticket-create-success', 'glpi.error_create_ticket', result.message);
                }
            }
        });
    }

    getUserData() {
        this.loadingEmail = true;
        this.store.dispatch(UserActions.get_user_data({
            user_sub: localStorage.getItem('sub')
        }));
        this.userDataSub = this.userData$.subscribe(client_data => {
            if (client_data) {
                if (client_data.model == 'cognito') {
                    this.glpiForm.get('email').setValue(client_data.email);
                    this.loadingEmail = false;
                }
            }
        });
    }
}
