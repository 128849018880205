import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Plan } from 'app/cameras/models';
import { ResultAlert } from 'app/Shared/models';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { Kit } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-kit-edit-modal',
    templateUrl: './edit-kit-modal.component.html',
    styleUrls: ['./edit-kit-modal.component.scss']
})
export class EditKitModalComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();
    readonly updateSub$ = this.store.select((state: AppState) => state.Users.updateKitResult);
    readonly deleteSub$ = this.store.select((state: AppState) => state.Users.deleteKitResult);

    updateSub: Subscription;
    deleteSub: Subscription;
    swalDecisionSub: Subscription;
    plans: Plan[];
    kit: Kit;
    optionsKits: any;
    selectedPlan: number;
    loadingSubmit: boolean;
    loadingDelete: boolean;

    public editForm = new UntypedFormGroup({
        cameraAmount: new UntypedFormControl(0, [Validators.required]),
        detectionsAmount: new UntypedFormControl(0, [Validators.required]),
        status: new UntypedFormControl('', [])
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private readonly store: Store<AppState>,
        private service: SharedService, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.loadingSubmit = false;
        this.loadingDelete = false;
        this.plans = this.data.data.plans;
        this.kit = this.data.data.kit;
        this.selectedPlan = this.kit.plan_id;

        this.optionsKits = this.processList(this.plans);
        this.loadData();

        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        if (this.updateSub) {
            this.updateSub.unsubscribe();
        }
        if (this.deleteSub) {
            this.deleteSub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }

        this.service.cleanSwal();
        // this.store.dispatch(UserActions.update_kit_cleanup(null));
        // this.store.dispatch(UserActions.delete_kit_cleanup(null));
        this.store.dispatch(UserActions.edit_kit_modal_cleanup());
        this.closeModal();
    }

    closeModal() {
        this.modalClose.emit();
    }

    deleteKit() {
        if (this.loadingDelete || this.loadingSubmit) {
            return false;
        }

        this.validationResponse.validationResponseHandler(200, 'user-kits', 'confirm-delete-kit', 'user.delete_kit');
    }

    submitForm() {
        if (this.loadingSubmit || this.loadingDelete) {
            return false;
        }

        let errorPass = false;
        this.loadingSubmit = true;

        if (this.editForm.get('cameraAmount').invalid) {
            errorPass = true;
            this.editForm.get('cameraAmount').markAsTouched();
        }
        if (this.editForm.get('detectionsAmount').invalid) {
            errorPass = true;
            this.editForm.get('detectionsAmount').markAsTouched();
        }

        if (!errorPass) {
            this.store.dispatch(UserActions.update_kit({
                kit: {
                    status: this.editForm.get('status').value,
                    id: this.kit.id,
                    cameras_amount: this.editForm.get('cameraAmount').value,
                    analytics_amount: this.editForm.get('detectionsAmount').value,
                    plan_id: this.selectedPlan
                },
                sub: localStorage.getItem('sub')
            }));
        } else {
            this.loadingSubmit = false;
        }
    }

    loadData() {
        this.editForm.get('cameraAmount').setValue(this.kit.cameras_amount);
        this.editForm.get('detectionsAmount').setValue(this.kit.analytics_amount);
        this.editForm.get('status').setValue(this.kit.status);
    }

    processList(list) {
        return list.map(element => {
            return {
                id: element.id,
                title: `Câmera ${element.resolution.alias}`,
                description: `${element.days_storage} ${this.processPlural('dia', element.days_storage)}`
            };
        });
    }

    processPlural(text, number) {
        return text + (number > 1 ? 's' : '');
    }

    changePlan(event) {
        this.selectedPlan = event[0];
    }

    initSubscriptions() {
        this.updateSub = this.updateSub$.subscribe(result => {
            if (result) {
                this.loadingSubmit = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user-kits', 'edit-kit', result.message);
                    this.store.dispatch(UserActions.edit_kit_modal_update());
                    this.ngOnDestroy();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user-kits', 'edit-kit-fail', result.message);
                }
            }
        });

        this.deleteSub = this.deleteSub$.subscribe(result => {
            if (result) {
                this.loadingDelete = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user-kits', 'delete-kit', result.message);
                    this.store.dispatch(UserActions.edit_kit_modal_update());
                    this.ngOnDestroy();
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user-kits', 'delete-kit-fail', result.message);
                }
            }
        });

        this.swalDecisionSub = this.service.swalDecision.subscribe(result => {
            if (result) {
                this.validateReturnMethodCalled(result as ResultAlert);
            }
        });
    }

    validateReturnMethodCalled(returnSwal: ResultAlert) {
        if (returnSwal.methodCalled === 'deleteKit') {
            if (returnSwal.isConfirmed) {
                this.loadingDelete = true;
                this.store.dispatch(UserActions.delete_kit({
                    kit: {
                        id: this.kit.id
                    },
                    sub: localStorage.getItem('sub')
                }));
            }
        }
    }
}
