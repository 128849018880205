import { Store } from '@ngrx/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feedback, ModalData, OperationStatus } from 'app/Shared/models';
import { AppState } from 'app/store/model';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import { DetectionActions } from '../Services/actions';

@Component({
    selector: 'app-video-detection',
    templateUrl: './video-detection.component.html',
    styleUrls: ['./video-detection.component.scss'],
    animations: [
        trigger('hideButton', [
            state('show', style({
                opacity: 1,
                display: 'inherit'
            })),
            state('hide', style({
                opacity: 0,
                display: 'none'
            })),
            transition('show <=> hide', animate('1s ease-in'))
        ])
    ]
})
export class VideoDetectionComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly reviewStatus$ = this.store.select((appState: AppState) => appState.detection.reviewStatus);
    @Output() modalClose = new EventEmitter<string>();
    currentAlert;
    loadingVideo = true;
    playButton: HTMLElement;
    video: HTMLVideoElement;
    videoControls: HTMLElement;
    playbackIcons: NodeListOf<HTMLElement>;
    fullScreenButton: HTMLButtonElement;
    download: HTMLAnchorElement;
    videoContainer: HTMLElement;
    fullScreenIcons: NodeListOf<SVGUseElement>;
    timeElapsed: HTMLElement;
    duration: HTMLElement;
    progressBar: HTMLProgressElement;
    seek: HTMLInputElement;
    seekToolTip: HTMLElement;
    playbackAnimation: HTMLElement;
    options: AnimationOptions = {
        path: '/assets/animations/feedback_happy.gif'
    };
    reviewAlertId: number;
    sub: Subscription;


    constructor(@Inject(MAT_DIALOG_DATA) public alert: ModalData, public dialogRef: MatDialogRef<any>, private readonly store: Store<AppState>) { }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('modal-background');
        this.getElements();
        this.reviewFeedback();
        this.currentAlert = this.alert.data;
        this.getDownload(this.currentAlert.alert.link_video);
        this.video.currentTime = this.currentAlert.timeActual;
        if (window.innerWidth < 599) {
            this.toggleFullScreen();
        }
    }

    ngOnDestroy() {
        this.removeEvents();
        this.closeModal();
    }

    ngAfterViewInit() {
        this.putEvents();
        this.hideVideo();
    }

    getElements() {
        this.playButton = document.getElementById('play');
        this.video = document.getElementById('video') as HTMLVideoElement;
        this.videoControls = document.getElementById('video-controls');
        this.playbackIcons = document.querySelectorAll('.playback-icons use');
        this.timeElapsed = document.getElementById('time-elapsed');
        this.duration = document.getElementById('duration');
        this.progressBar = document.getElementById('progress-bar') as HTMLProgressElement;
        this.seek = document.getElementById('seek') as HTMLInputElement;
        this.seekToolTip = document.getElementById('seek-tooltip');
        //Animação
        this.playbackAnimation = document.getElementById('playback-animation');
        //Full-screen
        this.fullScreenButton = document.getElementById('fullscreen-button') as HTMLButtonElement;
        this.videoContainer = document.querySelector('.video-container');
        this.fullScreenIcons = this.fullScreenButton.querySelectorAll('use');
    }

    getDownload(link) {
        this.download = document.getElementById('download-button') as HTMLAnchorElement;
        this.download.href = String(link);
    }

    putEvents() {
    //Controle de pausar/play do vídeo
        this.playButton.addEventListener('click', this.togglePlay.bind(this));
        this.video.addEventListener('play', this.updatePlayButton.bind(this));
        this.video.addEventListener('pause', this.updatePlayButton.bind(this));
        this.video.addEventListener('click', this.togglePlay.bind(this));
        this.video.addEventListener('click', this.animatePlayback.bind(this));
        //Inserir duração do vídeo
        this.video.addEventListener('loadedmetadata', this.initVideo.bind(this));
        this.video.addEventListener('timeupdate', this.updateProgress.bind(this));
        //Seek
        this.seek.addEventListener('mousemove', this.updateSeekToolTip.bind(this));
        this.seek.addEventListener('input', this.skip.bind(this));
        //Fullscreen
        this.fullScreenButton.addEventListener('click', this.toggleFullScreen.bind(this));
        this.videoContainer.addEventListener('fullscreenchange', this.updateFullscreenButton.bind(this));
    }

    removeEvents() {
        this.playButton.removeEventListener('click', this.togglePlay.bind(this));
        this.video.removeEventListener('play', this.updatePlayButton.bind(this));
        this.video.removeEventListener('pause', this.updatePlayButton.bind(this));
        this.video.removeEventListener('click', this.togglePlay.bind(this));
        //Inserir duração do vídeo
        this.video.removeEventListener('loadedmetadata', this.initVideo.bind(this));
        this.video.removeEventListener('timeupdate', this.updateProgress.bind(this));
        //Seek
        this.seek.removeEventListener('mousemove', this.updateSeekToolTip.bind(this));
        this.seek.removeEventListener('input', this.skip.bind(this));
        //Fullscreen
        this.fullScreenButton.removeEventListener('click', this.toggleFullScreen.bind(this));
        this.videoContainer.removeEventListener('fullscreenchange', this.updateFullscreenButton.bind(this));
    }

    hideVideo() {
        const canPlay = !!document.createElement('video').canPlayType;
        if (canPlay) {
            this.video.controls = false;
            this.videoControls.classList.remove('hidden');
        }
    }

    animatePlayback() {
        this.playbackAnimation.animate([
            {
                opacity: 1,
                transform: 'scale(1)',
            },
            {
                opacity: 0,
                transform: 'scale(1.3)'
            }
        ], {
            duration: 500
        });
    }

    //Atualizando o tool tip do seek
    updateSeekToolTip(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const skipTo = Math.round((event.offsetX / target.clientWidth) * Number(target.getAttribute('max')));
        this.seek.setAttribute('data-seek', skipTo.toString());
        const t = this.formatDuration(skipTo);
        this.seekToolTip.textContent = `${t.minutes}:${t.seconds}`;
        const rect = this.video.getBoundingClientRect();
        this.seekToolTip.style.left = `${event.pageX - rect.left}px`;
    }

    skip(event: Event) {
        const target = event.target as HTMLInputElement;
        const skipTo = target.dataset.seek ? target.dataset.seek : target.value;
        this.video.currentTime = Number(skipTo);
    }

    togglePlay() {
        if (this.video.paused || this.video.ended) {
            this.video.play();
        }
        else {
            this.video.pause();
        }
    }

    updatePlayButton() {
        this.playbackIcons.forEach(icon => icon.classList.toggle('hidden'));
        if (this.video.paused) {
            this.playButton.setAttribute('data-title', 'Play (k)');
        }
        else {
            this.playButton.setAttribute('data-title', 'Pausar (k)');
        }
    }

    //Fullscreen
    toggleFullScreen() {
        if (document.fullscreenElement) {
            if (window.innerWidth < 599) {
                this.closeModal();
            } else {
                document.exitFullscreen();
            }
        }
        else {
            this.videoContainer.requestFullscreen();
        }
    }

    updateFullscreenButton() {
        this.fullScreenIcons.forEach(icon => icon.classList.toggle('hidden'));

        if (document.fullscreenElement) {
            this.fullScreenButton.setAttribute('data-title', 'Sair da tela cheia (f)');
        }
        else {
            this.fullScreenButton.setAttribute('data-title', 'Tela cheia (f)');
        }
    }

    //Inicializa a barra de progresso do vídeo
    initVideo() {
        const videoDuration = Math.round(this.video.duration);
        this.seek.setAttribute('max', videoDuration.toString());
        this.progressBar.setAttribute('max', videoDuration.toString());
        const time = this.formatDuration(videoDuration);
        this.duration.innerText = `${time.minutes}:${time.seconds}`;
        this.duration.setAttribute('datetime', `${time.minutes}:${time.seconds}`);
        // finaliza o loading que aparece no inicio, antes do video estar carregado
        if (videoDuration > 0) {
            this.loadingVideo = false;
        }
    }

    //Atualiza a barra de progresso
    updateProgress() {
        const time = this.formatDuration(Math.round(this.video.currentTime));
        this.timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
        this.timeElapsed.setAttribute('datetime', `${time.minutes}:${time.seconds}`);
        this.seek.value = Math.round(this.video.currentTime).toString();
        this.progressBar.value = Math.round(this.video.currentTime);
    }

    //Duração de segundos para mm:ss
    formatDuration(seconds: number): { minutes: string; seconds: string } {
        const inDate = new Date(seconds * 1000).toISOString().substr(11, 8);
        return {
            minutes: inDate.substr(3, 2),
            seconds: inDate.substr(6, 2)
        };
    }

    closeModal() {
        this.store.dispatch(DetectionActions.reset_status_review());
        this.modalClose.emit();
    }

    // Review dos alertas
    // Review positivo
    reviewAlertThumbsUp(alert_id: number) {
        this.reviewAlertId = alert_id;
        this.store.dispatch(DetectionActions.review_alerts({
            userSub: localStorage.getItem('sub'),
            id_alert: this.reviewAlertId,
            evaluation: true,
        }));
    }

    // Review negativo
    reviewAlertThumbsDown(alert_id: number) {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        this.reviewAlertId = alert_id;
        Swal.fire({
            icon: 'warning',
            iconHtml: '<img src="/assets/img/Icons/sadFace.png">',
            customClass: {
                popup: 'swal2-popup alert-popup swalDark',
                container: 'swal2-container alert-container'
            },
            html: `
      <button id="1" class="btn btn-round alert-eval">Detectou errado</button>
      <button id="2" class="btn btn-round alert-eval">Objeto irrelevante</button>
      <button id="3" class="btn btn-round alert-eval">Falha de vídeo</button>
      <button id="input" class="btn btn-round btn-just-icon alert-eval">
        <i class="material-icons">edit</i>
      </button>
      `,
            onOpen: this.onOpenSwal.bind(this),
            showCloseButton: true,
            title: 'O que aconteceu?',
            showConfirmButton: false
        } as SweetAlertOptions);
    }

    // Ultima etapa do review negativo
    reviewAlertFinalStep(button: HTMLButtonElement) {
        const comment = button.textContent;
        Swal.fire({
            icon: 'warning',
            iconHtml: '<img src="/assets/img/Icons/sadFace.png">',
            customClass: {
                popup: 'swal2-popup alert-popup swalDark',
                container: 'swal2-container alert-container'
            },
            showCloseButton: true,
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#acacac',
            html: `
        <button class="btn btn-round eval-button">${comment}</button>
      `,
            title: 'O que aconteceu?',
        }).then((result) => {
            if (result.isConfirmed) {
                this.store.dispatch(DetectionActions.review_alerts({
                    userSub: localStorage.getItem('sub'),
                    id_alert: this.reviewAlertId,
                    evaluation: false,
                    comment: comment
                }));
            }
        });
    }

    // Input de texto do review negativo
    reviewAlertInputStep(button: HTMLButtonElement) {
        Swal.fire({
            title: 'O que aconteceu?',
            showCloseButton: true,
            customClass: {
                popup: 'swal2-popup alert-popup swalDark',
                container: 'swal2-container alert-container'
            },
            html: `
        <mat-form-field>
          <input id="alertComment" class="alert-comment" placeholder="Escreva aqui" maxlength="180">
          <p id="counter" class="counter">0/180</p>
        </mat-form-field>
      `,
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#f2bf00',
            onOpen: this.onOpenInputSwal.bind(this)
        } as SweetAlertOptions).then((result) => {
            if (result.isConfirmed) {
                const input = document.getElementById('alertComment') as HTMLInputElement;
                this.store.dispatch(DetectionActions.review_alerts({
                    userSub: localStorage.getItem('sub'),
                    id_alert: this.reviewAlertId,
                    evaluation: false,
                    comment: input.value
                }));
            }
        });
    }

    // Seletor do tipo de review negativa
    onOpenSwal(popup: HTMLElement) {
        const context = this;
        const button1 = document.getElementById('1') as HTMLButtonElement;
        const button2 = document.getElementById('2') as HTMLButtonElement;
        const button3 = document.getElementById('3') as HTMLButtonElement;
        const buttonInput = document.getElementById('input') as HTMLButtonElement;
        button1.onclick = function (ev) {
            Swal.close();
            context.reviewAlertFinalStep(button1);
        };
        button2.onclick = function (ev) {
            Swal.close();
            context.reviewAlertFinalStep(button2);
        };
        button3.onclick = function (ev) {
            Swal.close();
            context.reviewAlertFinalStep(button3);
        };
        buttonInput.onclick = function (ev) {
            Swal.close();
            context.reviewAlertInputStep(buttonInput);
        };
    }

    onOpenInputSwal(popup: HTMLElement) {
        const context = this;
        const counter = document.getElementById('counter');
        const input = document.getElementById('alertComment') as HTMLInputElement;
        input.onkeyup = function (ev) {
            counter.textContent = `${input.value.length}/180`;
        };
    }

    getIconHtml(feedback: Feedback): string {
    // tslint:disable-next-line: max-line-length
        return feedback.success ?
            (feedback.message.includes('Avanuv') ? '<img style="width: 98% !important;src="/assets/animations/feedback_happy.gif">' : '<i class="material-icons alert-icon">thumb_up</i>')
            : null;
    }

    reviewFeedback() {
        this.sub = this.reviewStatus$.subscribe(feedback => {
            if (feedback.status != OperationStatus.Unloaded) {
                if (feedback.message == 'Obrigado por contribuir com o Avanuv!') {
                    const alert = document.getElementById(`card-${this.reviewAlertId}`);
                    alert.style.display = 'none';

                    this.store.dispatch(DetectionActions.delete_alerts({
                        userSub: localStorage.getItem('sub'),
                        list_alert: [this.reviewAlertId],
                        evaluation: false,
                    }));
                    Swal.fire({
                        icon: feedback.success ? 'info' : 'info',
                        iconHtml: this.getIconHtml(feedback),
                        text: feedback.message,
                        customClass: {
                            popup: 'swal2-popup alert-popup',
                            container: 'swal2-container alert-container'
                        },
                        showConfirmButton: false,
                        timer: 6000
                    }).then(() => {
                        this.removeFeedbackButtons();
                        this.store.dispatch(DetectionActions.reset_status_review());
                        location.reload();
                    });
                } else {
                    Swal.fire({
                        icon: feedback.success ? 'info' : 'info',
                        iconHtml: this.getIconHtml(feedback),
                        text: feedback.message,
                        customClass: {
                            popup: 'swal2-popup alert-popup',
                            container: 'swal2-container alert-container'
                        },
                        showConfirmButton: false,
                        timer: 6000
                    }).then(() => {
                        this.removeFeedbackButtons();
                        this.store.dispatch(DetectionActions.reset_status_review());
                    });
                }
            }
        });
    }

    // Metodo usado para remover botões de feedback após clicados
    removeFeedbackButtons() {
        const feedback = document.getElementById('feedback-buttons');
        feedback.style.display = 'none';
    }

}
