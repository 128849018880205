<div class="main-content">
  <div class="flex justify-content-between align-items-center">
    <span class="title-modal">Adicionar Kit ao estoque do parceiro</span>
    <div (click)="closeModal()" class="close-modal">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </div>
  </div>

  <div class="form-container" *ngIf="!loadingKits && optionsInventory">
    <form [formGroup]="editForm">
      <div class="container-input-select">
        <div class="label-input">
          <span>Selecionar Kit :</span>
        </div>
        <app-filter type="multiweight" [list]="optionsInventory" placeholder="Selecionar Kit" 
          (filterStatusChange)="changeKit($event)" [showSelected]="true" [hideHover]="true" [hideActiveFill]="true"></app-filter>
        <mat-error *ngIf="editForm.get('kit').hasError('required') && editForm.get('kit').touched">
          O campo Kit é <strong>obrigatório</strong>
        </mat-error>
      </div>
      <div class="container-input-select-two">
        <div class="label-input">
          <span>Quantidade :</span>
        </div>
        <nz-input-number class="input-container-number" formControlName="amount" (change)="checkLimit()" [nzMin]="1" [nzStep]="1"></nz-input-number>
        <mat-error *ngIf="editForm.get('amount').hasError('required') && editForm.get('amount').touched">
          O campo Quantidade é <strong>obrigatório</strong>
        </mat-error>
        <mat-error *ngIf="editForm.get('amount').hasError('invalid')">
          Você excedeu o limite de kits. <strong>O seu limite para esse kit é de {{amountLimit}} kits.</strong>
        </mat-error>
      </div>
    </form>
    <button class="btn btn-primary enter-button" *ngIf="!loadingSubmit" (click)="submitForm()">
      Adicionar
    </button>
    <div *ngIf="loadingSubmit" style="margin-top: 20px;">
      <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
    </div>
  </div>

  <div *ngIf="loadingKits" style="width: 100%; display: flex; align-items: center; justify-content: center; height: 300px; margin-top: 24px;">
    <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
  </div>
</div>