<div class="modal-container">
    <div class="flex header">
        <span class="title-modal">Adicionar {{mode}} no grupo {{group_name}}</span>
        <div (click)="closeModal()" class="close-modal">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </div>
    </div>
    
    <div class="flex body" *ngIf="!loading">
        <div class="label-container">
            <div class="label-name">
                <span>{{mode}} :</span>
            </div>
            <app-filter-list
            [styles]="['restrained', 'multiselect-alternate-style']"
            [list]="simpleList"
            [search]="true"
            type="multiselect"
            placeholder="Pesquisar {{mode}}..."
            (filterListStatusChange)="changeFilter($event)"></app-filter-list>
            <mat-error *ngIf="groupForm.get('list').hasError('required') && groupForm.get('list').touched">
                Selecionar ao menos 1 {{mode}} <strong>é requerido</strong>
            </mat-error>
        </div>
    </div>
    <div class="loadingModal" *ngIf="loading">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>

    <div class="flex footer">
        <button class="btn btn-ant btn-primary" (click)="addToGroup()" [disabled]="loadingSubmit">
            Adicionar
            <span *ngIf="loadingSubmit" class="posfix" nz-icon nzType="loading" nzTheme="outline"></span>
        </button>
    </div>
</div>