import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { KitAmount, Partner, Partners, UserInventory } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-partners',
    templateUrl: './user-partners.component.html',
    styleUrls: ['./user-partners.component.scss']
})
export class UserPartnersComponent implements OnInit, OnDestroy {
    readonly getPartnersList$ = this.store.select((state: AppState) => state.Users.partnersList);
    readonly getUserInventory$ = this.store.select((state: AppState) => state.Users.userInventory);

    isMaster: boolean;
    partnersList: Subscription;
    userInventory: Subscription;
    partners: Partners;
    inventories: UserInventory;

    displayedColumns: string[] = ['partner', 'email', 'client', 'camera', 'analytic', 'details'];
    dataSource: MatTableDataSource<Partner> = new MatTableDataSource([] as Partner[]);
    searchInputControl = new UntypedFormControl('', []);

    loading: boolean;
    no_table: boolean;
    storage: KitAmount[];

    cameras: {
        used: number;
        total: number;
    };

    analytics: {
        used: number;
        total: number;
    };

    debounceSearch: ReturnType<typeof setTimeout>;

    isAssociate: boolean;
    adminAssociate: boolean;

    constructor(private router: Router, private sharedService: SharedService, private readonly store: Store<AppState>) { }
    ngOnInit(): void {
        this.isMaster = localStorage.getItem('profile_name') === '7d4e9f24-17e8-11ec-9621-0242ac130002';
        this.getPartnersList();

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }

        if (!this.isMaster) {
            this.getUserInventory();
        }
    }

    ngOnDestroy() {
        if (this.partnersList) {
            this.partnersList.unsubscribe();
        }
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        clearTimeout(this.debounceSearch);

    // this.store.dispatch(UserActions.cleanup());
    }

    getPartnersList() {
        this.partnersList = this.getPartnersList$.subscribe(result => {
            if (result) {
                this.partners = result[0];
                this.dataSource.data = this.partners.partners;
                this.loading = false;
                this.getResumo(result[0]);
                if (this.dataSource.data.length !== 0) {
                    this.no_table = false;
                }
            } else {
                this.dataSource.data = [];
                this.loading = true;
                this.no_table = true;
                this.store.dispatch(UserActions.get_partners({
                    user_sub: localStorage.getItem('sub')
                }));
            }
        });
    }

    getUserInventory() {
        this.partnersList = this.getUserInventory$.subscribe(inventory => {
            if (inventory) {
                const fullInventory = inventory[0];
                this.inventories = inventory[0];
                this.storage = [];
                fullInventory.kits.forEach(kit => {
                    if (kit.available) {
                        const cameraInventory = fullInventory.inventory.ci[kit.id_plan] ? fullInventory.inventory.ci[kit.id_plan].available : null;
                        if (cameraInventory !== null) {
                            const analyticsInventory = fullInventory.inventory.ai.available;
                            const maxCamera = Math.floor(cameraInventory / kit.cameras_amount);
                            const maxAnalytics = Math.floor(analyticsInventory / kit.analytics_amount);
                            if (maxCamera > maxAnalytics) {
                                this.storage.push({
                                    kit_id: kit.id,
                                    amount: maxAnalytics
                                });
                            } else {
                                this.storage.push({
                                    kit_id: kit.id,
                                    amount: maxCamera
                                });
                            }
                        }
                    }
                });
            } else {
                this.store.dispatch(UserActions.get_user_inventory({
                    user_sub: localStorage.getItem('sub')
                }));
            }
        });
    }

    addPartner() {
        const dataModal = {
            data: 1,
            tipoModal: 'adicionar_parceiro',
            modalHeight: '85vh',
            modalWidth: '60em',
            backdropClass: 'lightBackdrop'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

    viewPartner(partner: Partner) {
        localStorage.setItem('clientView', partner.sub);
        localStorage.setItem('clientName', partner.partner_name);
        localStorage.setItem('childProfile', 'Parceiro');
        localStorage.setItem('previousRouter', this.router.url);
        localStorage.setItem('storageKit', JSON.stringify(this.storage));
        localStorage.setItem('inventoryPartner', JSON.stringify(this.inventories));
        this.router.navigateByUrl('users/partner/view');
    }

    getResumo(partners: Partners) {
        this.cameras = {
            used: 0,
            total: 0
        };
        this.analytics = {
            used: 0,
            total: 0
        };
        partners.partners.forEach(partner => {
            this.cameras.used += partner.cameras_used;
            this.cameras.total += partner.cameras_total;
            this.analytics.used += partner.analytics_used;
            this.analytics.total += partner.analytics_total;
        });
    }

    requestSearch() {
        clearTimeout(this.debounceSearch);
        const query = this.searchInputControl.value;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(UserActions.get_partners({
                user_sub: localStorage.getItem('sub'),
                str_filter: this.searchInputControl.value
            }));
            this.loading = true;
            this.partners.partners = [];
            this.dataSource.data = [];
            this.no_table = true;
        }, 300);
    }
}
