import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { confirmPassword, validateCPFCNPJ, validatePassword } from 'app/Login/models';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { CognitoAttributes, UpdateClient } from '../models';
import { UserActions } from '../Services/actions';

@Component({
    selector: 'app-user-client-edit',
    templateUrl: './user-client-edit.component.html',
    styleUrls: ['./user-client-edit.component.scss']
})
export class UserClientEditComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();
    client: CognitoAttributes;
    readonly editClient$ = this.store.select((state: AppState) => state.Users.updateClientResult);
    readonly upResult$ = this.store.select((state: AppState) => state.Users.updateResult);

    public editForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        cpf_cnpj: new UntypedFormControl('', [Validators.required, validateCPFCNPJ]),
        cell: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', []),
        panicButton: new UntypedFormControl('', []),
        monitoringCenter: new UntypedFormControl('', []),
        contactEmergency: new UntypedFormControl('', [Validators.required]),
        phoneEmergency: new UntypedFormControl('', [Validators.required]),
    }, { validators: confirmPassword });

    public telMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    public cpfcnpjMask = function (rawValue) {
        const strip = rawValue.replace(/\D/g, '');
        if (strip.length > 11) {
            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
        else {
            return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
    };

    edit_client: Subscription;
    updateSub: Subscription;
    loading: boolean;
    update_final: boolean;

    constructor(private readonly store: Store<AppState>, private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any, private validationResponse: ValidationResponseHandlerModule) { }

    ngOnInit(): void {
        this.client = this.data.data.client;
        this.setValueForms();
        this.getUpdateResult();
    }

    ngOnDestroy(): void {
        if (this.edit_client) {
            this.edit_client.unsubscribe();
        }
        if (this.updateSub) {
            this.updateSub.unsubscribe();
        }
        this.store.dispatch(UserActions.cleanup());
        this.closeModal();
    }

    checkPassword() {
        if (this.editForm.get('confirm').value == '') {
            this.editForm.get('confirm').setErrors({ 'required': true });
        } else if (this.editForm.get('password').value === this.editForm.get('confirm').value) {
            this.editForm.get('confirm').setErrors(null);
        } else {
            this.editForm.get('confirm').setErrors({ 'unequal': true });
        }
    }

    closeModal() {
        this.modalClose.emit();
        this.goToClientView();
    }

    onEdit() {
        this.loading = true;
        let errorPass = false;

        if (this.editForm.get('cpf_cnpj').invalid) {
            errorPass = true;
            this.editForm.get('cpf_cnpj').markAsTouched();
        }
        if (this.editForm.get('cell').invalid) {
            errorPass = true;
            this.editForm.get('cell').markAsTouched();
        }
        if (this.editForm.get('email').invalid) {
            errorPass = true;
            this.editForm.get('email').markAsTouched();
        }
        if (this.editForm.get('name').invalid) {
            errorPass = true;
            this.editForm.get('name').markAsTouched();
        }
        if (this.editForm.get('confirm').value !== this.editForm.get('password').value && this.editForm.get('password').value != '') {
            errorPass = true;
            this.editForm.get('confirm').markAsTouched();
        }
        if (this.editForm.get('contactEmergency').invalid && this.editForm.get('monitoringCenter').value) {
            errorPass = true;
            this.editForm.get('contactEmergency').markAsTouched();
        }
        if (this.editForm.get('phoneEmergency').invalid && this.editForm.get('monitoringCenter').value) {
            errorPass = true;
            this.editForm.get('phoneEmergency').markAsTouched();
        }

        if (!errorPass) {
            let numNoMask: string = this.editForm.get('cell').value;
            numNoMask = numNoMask.replace(/\D/g, '');

            // tslint:disable-next-line: no-shadowed-variable
            const client: UpdateClient = {
                sub: localStorage.getItem('sub'),
                child_sub: localStorage.getItem('clientView'),
                avva: this.editForm.get('monitoringCenter').value,
                createDevice: this.editForm.get('panicButton').value,
                password: this.editForm.get('password').value,
                name: this.editForm.get('name').value,
                email: this.editForm.get('email').value,
                telephone: `${numNoMask}`,
                contactEmergency: this.editForm.get('contactEmergency').value,
                phoneEmergency: this.editForm.get('phoneEmergency').value,
            };

            this.store.dispatch(UserActions.edit_client({
                edit_client: client
            }));
            this.updateUser();
        } else {
            this.loading = false;
        }
    }

    setValueForms() {
        const cpfCnpj = this.client['custom:CNPJ'] ? this.client['custom:CNPJ'] : this.client['custom:CPF'];
        this.editForm.get('name').setValue(this.client.name);
        this.editForm.get('cpf_cnpj').setValue(cpfCnpj);
        this.editForm.get('cell').setValue(this.processPhone(this.client.phone_number));
        this.editForm.get('email').setValue(this.client.email);
        this.editForm.get('panicButton').setValue(this.client.device);
        this.editForm.get('monitoringCenter').setValue(this.client.avva);
        this.editForm.get('contactEmergency').setValue(this.client.contactEmergency != 'None' ? this.client.contactEmergency : null);
        this.editForm.get('phoneEmergency').setValue(this.client.phoneEmergency != 'None' ? this.client.phoneEmergency : null);
    }

    processPhone(initial_number: string) {
        let finalNumber = '';
        if (initial_number.includes('(')) {
            finalNumber = initial_number;
        } else {
            if (initial_number.length == 14 || initial_number.length == 13) {
                initial_number = initial_number.slice(3, initial_number.length);
            }
            if (initial_number.length == 11 || initial_number.length == 10) {
                initial_number = '(' + initial_number.slice(0, 2) + ') ' + initial_number.slice(2, initial_number.length);
            }
            finalNumber = initial_number;
        }
        return finalNumber;
    }

    getUpdateResult() {
        this.edit_client = this.editClient$.subscribe(register_feedback => {
            this.updateSub = this.upResult$.subscribe(result => {
                if (register_feedback && result) {
                    if (register_feedback.status === 2 && result.status === 2) {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(200, 'user', 'edit-client-user', register_feedback.message);
                        this.closeModal();
                    } else {
                        this.loading = false;
                        this.validationResponse.validationResponseHandler(400, 'user', 'edit-client-user-fail', register_feedback.message);
                        this.store.dispatch(UserActions.cleanup());
                    }
                }
            });
        });
    }

    updateUser() {
        const cpfCnpj = this.editForm.get('cpf_cnpj').value;
        const address = this.client.address;
        let numNoMask: string = this.editForm.get('cell').value;
        numNoMask = numNoMask.replace(/\D/g, '');

        const attributeList: CognitoAttributes = {
            sub: localStorage.getItem('sub'),
            child_sub: localStorage.getItem('clientView'),
            name: this.editForm.get('name').value,
            email: this.editForm.get('email').value,
            phone_number: `${numNoMask}`,
            address: address,
            picture: 'undefined',
        };
        if (cpfCnpj.length > 11) {
            attributeList['custom:CNPJ'] = cpfCnpj;
        }
        else {
            attributeList['custom:CPF'] = cpfCnpj;
        }

        this.store.dispatch(UserActions.update_user({
            user_sub: localStorage.getItem('sub'),
            cognitoAttr: attributeList
        }));
    }

    validateEmergencyNumber() {
        if (this.editForm.get('phoneEmergency').value == '' && this.editForm.get('monitoringCenter')) {
            this.editForm.get('phoneEmergency').setErrors({'required': true});
        } else {
            this.editForm.get('phoneEmergency').setErrors(null);
        }
    }

    validateEmergencyContact() {
        if (this.editForm.get('contactEmergency').value == '' && this.editForm.get('monitoringCenter')) {
            this.editForm.get('contactEmergency').setErrors({'required': true});
        } else {
            this.editForm.get('contactEmergency').setErrors(null);
        }
    }

    goToClientView() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/client/view']);
    }

}
